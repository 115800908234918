import React from 'react';
import './SelectedSetItems.css';
import {ItemSquareInventory} from "../itemCard/itemCard";

const SelectedSetItems = ({ carouselSet, showNumbers = true }) => {
    let bananFullSizeArray = [];
    if (carouselSet !== undefined && carouselSet !== null  && carouselSet.itemCards !== undefined && carouselSet.itemCards !== null) {
        let selectedItemNum = 0;
        bananFullSizeArray = carouselSet.itemCards.map((item, index) => {
                if (item !== undefined && item !== null) {
                    selectedItemNum++;
                }
                return <ItemSquareInventory item={item} key={`key_circle_${index}`}
                                            setUId={carouselSet.uId} showNumbers={showNumbers}/>
            }
        );

        const SelectedUidInfo = () => {
            if (carouselSet.uId) {
                return <>#{carouselSet.uId}</>
            }

            if (selectedItemNum === 4) {
                return <>New set ready to mint!</>
            }

            return <>New set: {4-selectedItemNum} NFT item{selectedItemNum === 3 ? '' : 's'} missing</>
        }

        return (
            <>
                <div id={''} className={'flex-container-rows selected-items-list'}>{bananFullSizeArray}</div>
                <div id={``} className={`selected-uid-info`}><SelectedUidInfo/></div>
            </>
        );
    }
}

export default SelectedSetItems;