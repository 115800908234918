import React, {useEffect, useState} from 'react';
import {VictoryBar, VictoryChart, VictoryHistogram, VictoryPie, VictoryStack, VictoryGroup} from "victory";
import axios from "axios";
import {externalLootBoxLabelUpdate} from "../component/LootBoxHeaderComponent/LootBoxHeaderComponent";
import {FACTION, SET_PIECE} from "../helper/ENUMS.ts";

const HealthCheck = () => {

    const data = [
        {occurrence: 1, dmg: 13000},
        {occurrence: 2, dmg: 16500},
        {occurrence: 3, dmg: 14250},
        {occurrence: 4, dmg: 19000}
    ];

    const [damageDataNormalItems, setDamageDataNormalItems] = useState(data);
    const [damageDataLabItems, setDamageDataLabItems] = useState(data);
    const [damageDataRookieItems, setDamageDataRookieItems] = useState(data);
    const [setPieceItems, setSetPieceItems] = useState([]);
    const [factionItems, setFactionItems] = useState([]);
    const [visualItems, setVisualItems] = useState([
        [{x: "a", y: 1}, {x: "b", y: 4}, {x: "c", y: 5}],
        [{x: "c", y: 5}],
        [{x: 'a', y: 2}, {x: 'z', y: 9}],
        [{x: 'z', y: 2}]
    ]);
    const urlHost = process.env.REACT_APP_BACK_END_HOST;


    useEffect(
        () => {
            axios.get(urlHost + "/health/damages", {
                headers: {
                    'Content-Type': "application/json",
                    'Accept': "application/json",
                    // "Access-Control-Allow-Origin": "*",
                },
                responseType: "json",
            }).then((damageResponse) => {
                const {itemArray, itemLabArray} = damageResponse.data;
                let normalArr = [];
                if (itemArray.length > 0) {
                    console.log(itemArray);
                    const rookieObjs = itemArray.filter(i => i.is_rookie);
                    const normalObjs = itemArray.filter(i => !i.is_rookie);
                    console.log(rookieObjs);
                    console.log("normalObjs", normalObjs);
                    normalObjs.map(o => {
                        normalArr[o.damage] = (normalArr[o.damage] === 1) ? ++normalArr[o.damage] : 1;
                    });
                    let xyDataNormal = [];
                    normalArr.map((k, a) => {
                        xyDataNormal.push({dmg: a, occurrence: k});
                    });
                    // console.log(xyDataNormal);
                    setDamageDataNormalItems(normalObjs);
                    setDamageDataRookieItems(rookieObjs);
                }
                if (itemLabArray && itemLabArray.length > 0) {
                    normalArr = [];
                    const normalLabObjs = itemLabArray.filter(i => !i.is_rookie);
                    // normalLabObjs.map(o => {
                    //     normalArr[o.damage] = (normalArr[o.damage] === 1) ? ++normalArr[o.damage] : 1;
                    // });
                    setDamageDataLabItems(normalLabObjs);
                }
            })
                .catch((x) => {
                    console.error(x);
                })
            ;
            axios.get(urlHost + "/health/visuals", {
                headers: {
                    'Content-Type': "application/json",
                    'Accept': "application/json",
                    // "Access-Control-Allow-Origin": "*",
                },
                responseType: "json",
            }).then((visualsResponse) => {
                const {itemVisualsArray} = visualsResponse.data;
                if (itemVisualsArray.length > 0) {
                    console.log(itemVisualsArray);
                    let setPieceArray = [0, 0, 0, 0], factionArray = [0, 0, 0],
                        fullVisualsArray = [

                        ];
                    const setPieces = itemVisualsArray.map(i => {
                        setPieceArray[i.set_piece] += parseInt(i.g_count);
                        factionArray[i.faction] += parseInt(i.g_count);
                        const obj = {};
                        // fullVisualsArray[i.faction].push({
                        //     x: `${i.set_piece}${i.skin_set}`,
                        //     y: i.g_count,
                        //     label: `${SET_PIECE[i.set_piece].toLowerCase()}${FACTION[i.faction].toLowerCase()}${i.skin_set}`
                        // });
                        fullVisualsArray.push([{x: ''+i.set_piece, y: i.g_count,  label: `${SET_PIECE[i.set_piece].toLowerCase()}${FACTION[i.faction].toLowerCase()}${i.skin_set}`}])
                        // fullVisualsArray.push(obj);
                    });
                    console.log(setPieceArray);
                    console.log(factionArray);
                    console.log(fullVisualsArray);
                    setSetPieceItems(setPieceArray);
                    setFactionItems(factionArray);
                    setVisualItems(fullVisualsArray);
                    // set(factionArray)
                    // console.log(rookieObjs);
                    // setDamageDataRookieItems(rookieObjs);
                }
            })
                .catch((x) => {
                    console.error(x);
                })
            ;
        }, // <- function that will run on every dependency update
        [] // <-- empty dependency array, effect runs once
    )

    return (
        <div id={'health-check-page'}>
            <h1 style={{color: "green"}}>
                This is a Contact Page.</h1>

            {/*<VictoryBar*/}
            {/*    data={damageDataNormalItems}*/}
            {/*    // data accessor for x values*/}
            {/*    x="dmg"*/}
            {/*    // data accessor for y values*/}
            {/*    y="occurrence"*/}
            {/*    domainPadding={{x: 100, y: 1}}*/}
            {/*/>*/}
            <div className={'flex-container-columns'}>
                <div style={{width: '25%',}}>

                    <VictoryChart
                        // domainPadding={{ x: 20 }}
                        style={{
                            axis: {stroke: "#9d6d43"},
                            axisLabel: {fontSize: 20, padding: 30},
                            grid: {stroke: ({tick}) => tick > 0.5 ? "red" : "grey"},
                            ticks: {stroke: "grey", size: 5},
                            tickLabels: {fontSize: 15, padding: 5}
                        }}
                    >
                        <VictoryHistogram
                            // animate={{
                            // duration: 2000,
                            // onLoad: { duration: 1000 }
                            //}}
                            style={{
                                data: {fill: "rgba(19,153,239,0.61)", stroke: "rgb(14,107,162)"}

                            }}
                            data={damageDataNormalItems}
                            x={'damage'}
                            // bins={[100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]}
                            bins={20}
                            binSpacing={1}
                            width={400}
                            // minDomain={1}
                            // domainPadding={{x: [10, -10], y: 5}}

                        />

                    </VictoryChart>

                </div>
                <div style={{
                    width: '15%',
                }}>
                    <VictoryPie
                        data={[
                            {x: 'SKIN', y: setPieceItems[0]},
                            {x: 'HEAD', y: setPieceItems[1]},
                            {x: 'BODY', y: setPieceItems[2]},
                            {x: 'WEAPON', y: setPieceItems[3]},
                        ]}
                    />
                </div>
                <div style={{
                    width: '15%',
                }}>
                    <VictoryPie
                        colorScale={["crimson", "rgb(29,112,29)", "mediumblue"]}
                        data={[
                            {x: 'RED', y: factionItems[0]},
                            {x: 'GREEN', y: factionItems[1]},
                            {x: 'BLU', y: factionItems[2]},
                        ]}
                    />
                </div>
                <div style={{
                    width: '45%',
                }}>
                    {/*<VictoryPie*/}
                    {/*    // style={{width: '70%'}}*/}
                    {/*    // colorScale={["crimson", "rgb(29,112,29)", "mediumblue" ]}*/}
                    {/*    // data={visualItems}*/}
                    {/*    labelRadius={({innerRadius}) => innerRadius + 5}*/}
                    {/*    radius={({datum}) => 50 + datum.y * 20}*/}
                    {/*    innerRadius={50}*/}
                    {/*    style={{labels: {fill: "white", fontSize: 10}}}*/}
                    {/*/>*/}
                    <VictoryGroup
                        offset={25}
                        colorScale={["tomato", "orange", "gold"]}
                    >
                        {visualItems.map(v => {
                            return(<VictoryStack data={v}/>);
                        })}
                    {/*    <VictoryBar*/}
                    {/*        data={[{x: "a", y: 2}, {x: "b", y: 3}, {x: "c", y: 5}]}*/}
                    {/*    />*/}
                    {/*    <VictoryBar*/}
                    {/*        data={[{x: "a", y: 1}, {x: "b", y: 4}, {x: "c", y: 5}]}*/}
                    {/*    />*/}
                    {/*    <VictoryBar*/}
                    {/*        data={[{x: "a", y: 3}, {x: "b", y: 2}, {x: "c", y: 6}]}*/}
                    {/*    />*/}
                    {/*    <VictoryBar*/}
                    {/*        data={[{x: "a", y: 3}, {x: "b", y: 2}, {x: "c", y: 6}]}*/}
                    {/*    />*/}
                    </VictoryGroup>
                </div>
            </div>
        </div>
    )
};

export default HealthCheck;
