import React, {useEffect, useState} from 'react';
import './DuelResolution.css';
import {appendNewLog} from "../PopUpProcessLog/PopUpProcessLog";
import {DUEL_RESULT, DUEL_VIEWED, LOG_STATUS} from "../../helper/ENUMS.ts";
import {CurrencyIcon, GovIcon} from "../../App";

const CoolNumber = ({number}) => {
    return (
        <div className={'cool-number-container'}>
            <div className={'cool-number'}>{number}</div>
        </div>
    );
}

const DuelResolvedBlock = ({duelId, duelComp}) => {
    console.log('DuelResolvedBlock', duelComp);

    const resolutionTitle = () => {
        switch (duelComp.currentUserResult) {
            case DUEL_RESULT.VICTORY:
                return 'VICTORY';
                break;
            case DUEL_RESULT.DEFEAT:
                return 'DEFEAT';
                break;
            case DUEL_RESULT.DRAW:
            case DUEL_RESULT.UNRESOLVED:
            default:
                return 'LOADING...';
                break;
        }
    }

    const payoutContent = () => {
        const p = 50; // TODO: get payout from duelComp or mirror based on tier of duel
        switch (duelComp.currentUserResult) {
            case DUEL_RESULT.VICTORY:
                return <>+{p} <CurrencyIcon size={64}/></>;
                break;
            case DUEL_RESULT.DEFEAT:
                return <>-{p} <CurrencyIcon size={64}/></>;
                break;
            case DUEL_RESULT.DRAW:
            case DUEL_RESULT.UNRESOLVED:
            default:
                return '';
                break;
        }
    }

    return (
        <div className={'duel-resolved-block'}>
            <h1 id={'duel-result-title'}>{resolutionTitle()}</h1>
            <h2 id={'duel-payout'}>{payoutContent()}</h2>
        </div>
    );
}

const DuelActionsBlock = ({duelId, duelComp, openModalFunc}) => {
    return (
        <div id={'duel-actions-block'} className={'flex-container-rows'}>
            <button className={'secondary btn button'}>How did this happened?</button>
            <button className={'primary btn button'} onClick={() => {
                console.log('TODO: DuelActionsBlock - View Duel');
                openModalFunc(true);
            }}>PLAY AGAIN?
            </button>
        </div>
    );
}

const DuelResolution = ({duelId, duelComp, setDuelResolvedStateFunction, openModalFunc, duelResolvedState = false, timerCount = 15}) => {


    const [numbers, setNumbers] = useState([]);

    const populateTimer = async () => {
        setNumbers([]);
        for (let i = 0; i < timerCount; i++) {
            setNumbers([...numbers, (timerCount-i)]);
            await new Promise(resolve => setTimeout(resolve, 1200))
                .then(() => {
                    const tempt = numbers;
                    tempt.pop();
                    setNumbers(tempt);
                });
            console.log('populateTimer', i, timerCount, numbers, duelResolvedState)
            // if (i === timerCount) {
            //     setDuelResolved(true);
            // }
        }
    }

    useEffect(() => {
        setDuelResolvedStateFunction(false);
        if (timerCount > 0) {
            populateTimer().then(() => {
                setDuelResolvedStateFunction(true);
            });
        } else if (duelComp.viewedByUser === DUEL_VIEWED.ALREADY_VIEWED) {
            setDuelResolvedStateFunction(true);
        }
    }, [duelId, timerCount]);

    return (
        <div className={'duel-resolution-container'}>
            {numbers && numbers.map((number) => {
                return <CoolNumber key={`cool-number-${number}`} number={number}/>
            })}
            {duelResolvedState.valueOf() && <DuelResolvedBlock duelComp={duelComp} duelId={duelId}/>}
            {duelResolvedState.valueOf() && <DuelActionsBlock duelComp={duelComp} duelId={duelId} openModalFunc={openModalFunc}/>}
        </div>
    );
}

export default DuelResolution;