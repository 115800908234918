import './LootBoxHeaderComponent.css';

import React, {useEffect} from "react";
import {CurrencyIcon} from "../../App";
import {FiRefreshCw} from "react-icons/fi";
import {getYVCurrencyAddressSync} from "../../helper/mirrrorNode";
import {getHederaMirrorHost} from "../../connector/config";
import {getAccountIds} from "../../connector/blockchain/hashconnect";
import axios from "axios";
import {EVENT_TYPE} from "../../helper/ENUMS.ts";
import {yvcMod} from "../../connector/generateAllowanceTransactions";
import MyCustomEventEmitter from "../myCustomEvents";
import {getAccountIdV3} from "../WalletConnectComponent/WalletConnectComponentV3";

const CurrencyBalanceComponent = ({currencyBalance, currencyKey}) => {

    // let associationDefault = {tokens: [null, null, null]};
    // let associations = (
    //         JSON.parse(localStorage.getItem('associatedTokens'))
    //             ? JSON.parse(localStorage.getItem('associatedTokens'))
    //             : associationDefault
    //     )
    // ;

    const [isSpinning, setIsSpinning] = React.useState(false);
    const [currencyColour, setCurrencyColour] = React.useState('yellow');
    const [YVCurrencyBalance, setYVCurrencyBalance] = React.useState(currencyBalance);

    const updateYVCurrencyBalance = async (delay = 1) => {
        setIsSpinning(true);
        const oldBalance = YVCurrencyBalance ? YVCurrencyBalance : 0;
        setYVCurrencyBalance(`-.--`);
        const YVCurrencyAddress = await getYVCurrencyAddressSync();
        const mirrorHost = getHederaMirrorHost();
        console.log("updateYVCurrencyBalance launched");
        new Promise(resolve => setTimeout(resolve, delay)) // delay to let mirror node update
            .then(() => {
                let accountId = getAccountIdV3();
                if (accountId !== null && accountId !== undefined && accountId !== "") {
                    console.log("updateYVCurrencyBalance account found");
                    axios.get(`${mirrorHost}/api/v1/accounts/${accountId}/tokens?limit=100`, {
                        headers: {
                            'Content-Type': "application/json",
                            'Accept': "application/json",
                            'Authorization-Web3': null,
                            // "Access-Control-Allow-Origin": "*",
                        },
                        responseType: "json",
                    }).then((mirrorNodeTokensResponse) => {
                        const tokensFound = mirrorNodeTokensResponse.data.tokens;
                        const YVCurrency = tokensFound.find(({token_id}) => token_id === YVCurrencyAddress);
                        let YVCurrencyParsedBalance = 0;
                        if (YVCurrency !== undefined) {
                            console.log("YVCurrency found ", YVCurrency);
                            YVCurrencyParsedBalance = YVCurrency.balance / yvcMod;
                            console.log("YVCurrency balance ", YVCurrencyParsedBalance, " oldBalance ", oldBalance);
                            if (YVCurrencyParsedBalance > oldBalance) {
                                setCurrencyColour('green');
                                setTimeout(() => {
                                    setCurrencyColour('yellow');
                                }, 3000);
                            } else if (YVCurrencyParsedBalance < oldBalance) {
                                setCurrencyColour('red');
                                setTimeout(() => {
                                    setCurrencyColour('yellow');
                                }, 1000);
                            }
                            // TODO: uncomment this to enable association, not sure if it's needed
                            // if (YVCurrency.balance > 0) {
                            //     if (associations[ASSOCIATIONS.CURRENCY] === null || associations[ASSOCIATIONS.CURRENCY] === false) {
                            //         associations[ASSOCIATIONS.CURRENCY] = true;
                            //         localStorage.setItem('associatedTokens', JSON.stringify({tokens: associations}));
                            //     }
                            //     console.log(`YVCurrency and other associations: `, associations);
                            // }
                        } else {
                            console.log("YVCurrency not found, association may be required");
                            // TODO: uncomment this to enable association, not sure if it's needed
                            // associations = (JSON.parse(localStorage.getItem('associatedTokens')) || associations).tokens;
                            // associations[ASSOCIATIONS.CURRENCY] = false;
                            // localStorage.setItem('associatedTokens', JSON.stringify({tokens: associations}));
                        }

                        setYVCurrencyBalance(YVCurrencyParsedBalance);
                        localStorage.setItem('YVCurrencyBalance', `${YVCurrencyParsedBalance}`);
                        setIsSpinning(false);
                    })
                        .catch((x) => {
                            console.error(x);
                            setYVCurrencyBalance(`X.XX`);
                            setIsSpinning(false);
                        })
                    ;
                } else {
                    console.log("updateYVCurrencyBalance account not found ")
                }
            });

    }

    const formatYVCurrencyBalance = (yvBalance) => {
        if (yvBalance !== undefined && yvBalance !== null && typeof (yvBalance) === "number") {
            return yvBalance.toFixed(2);
        }
        return yvBalance || '-.--';
    }

    useEffect(
        () => {
            setYVCurrencyBalance(`-.--`);
            MyCustomEventEmitter.subscribe("pairingDataFound", () => {
                console.log("updateYVCurrencyBalance pairingDataFound fired");
                updateYVCurrencyBalance(); // TODO verify if async works here
            });
            MyCustomEventEmitter.subscribe(EVENT_TYPE.FORCE_CURRENCY_UPDATE, async () => {
                console.log("updateYVCurrencyBalance FORCE_CURRENCY_UPDATE fired");
                await new Promise(resolve => setTimeout(resolve, 1500)) // delay to let mirror node update
                    .then(() => {
                        updateYVCurrencyBalance(1500); // TODO verify if async works here
                    });
            });
            // in order to update balance the pairing Data must be set and account number must be known, otherwise we are queueing empty account balance

        }, // <- function that will run on every dependency update
        [] // <-- empty dependency array, effect runs once
    )

    return (
        <div id={'yv-currency-label-header'}>
            <CurrencyIcon onClickFunction={() => updateYVCurrencyBalance()} size={32}/>
            <span className={`${currencyColour}`}>{formatYVCurrencyBalance(YVCurrencyBalance)}&nbsp;</span>
            <FiRefreshCw className={`action ${isSpinning ? 'rotate' : ''}`} onClick={() => updateYVCurrencyBalance()}/>
        </div>
    );
}

export default CurrencyBalanceComponent;