import {FACTION, SET_PIECE} from "../../helper/ENUMS.ts";
import * as React from "react";
import {getAssetHostCDN} from "../../helper/externalLinks";

const assetsHost = getAssetHostCDN();
const ItemWholeSet = ({card, set}) => {

    if (set !== undefined && set !== null) {
        const faction = FACTION[set[0].faction].toLowerCase();
        const skinSet = set[0].skinSet;
        const season = set[0].season;
        const imgSrc = `${assetsHost}/all-skins-integration/season_${season//path
        }/set_season_${season}_full_${faction}_${skinSet}.png`; //file
        return (
            <img className={" full"} src={`${process.env.PUBLIC_URL}` + imgSrc} alt={`Full ${faction} set ${skinSet}`}/>
        );
    }
};

export default ItemWholeSet;