import Item from "./adapter/item";
import axios from "axios";

const GetInventoryList = () => {
    let cards = [
        Item("bce18865", 2, 0, 3, 3, 974),
        Item("gr7786", 1, 0, 3, 3, 778),
        Item("cce423", 1, 0, 1, 3, 838),
        Item("0x32221", 2, 0, 0, 3, 354),
        Item("0.0.56123", 0, 0, 1, 2, 492),
        Item("5557", 1, 0, 0, 3, 577),
        Item("0_2211", 1, 0, 2, 3, 543),
        Item("3322", 1, 0, 2, 3, 112),
        Item("657", 2, 0, 2, 3, 283),
        Item("1235", 0, 0, 1, 2, 678),
        Item("84181", 0, 0, 3, 3, 435),
        Item("0.0.12455", 1, 0, 2, 1, 383),
        Item("0.0.3245", 0, 0, 0, 2, 234),
        Item("zxcaaaa", 2, 0, 1, 3, 105)
    ];

    return cards;
};

export const GetInventorySets = () => {
    const urlHost = process.env.REACT_APP_BACK_END_HOST;
    return axios.get(urlHost + "/full-set/post-mint-sets", {
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
            // "Access-Control-Allow-Origin": "*",
        },
        responseType: "json",
    })
        .then(function (response) {
            // console.log('parsed post minted items: ', response.data)
            // setPostMintedItems(response.data.items);
            return response.data;
        })
        .catch(function (response) {
            console.log('axios error occurred', response);
        });
}

export const getDuelDetails = (duelId) => {
    const urlHost = process.env.REACT_APP_BACK_END_HOST;
    return axios.get(`${urlHost}/arena/duel-event/${duelId}`, {
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
            // "Access-Control-Allow-Origin": "*",
        },
        responseType: "json",
    })
        .then(function (response) {
            // console.log('parsed post minted items: ', response.data)
            // setPostMintedItems(response.data.items);
            return response.data;
        })
        .catch(function (response) {
            console.log('axios error occurred', response);
        });
}

export const getDuelHistory = (pageNumber = 0) => {
    const urlHost = process.env.REACT_APP_BACK_END_HOST;
    return axios.get(`${urlHost}/arena/duel-history?pagination=${pageNumber}`, {
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
            // "Access-Control-Allow-Origin": "*",
        },
        responseType: "json",
    })
        .then(function (response) {
            // console.log('parsed post minted items: ', response.data)
            // setPostMintedItems(response.data.items);
            return response.data;
        })
        .catch(function (response) {
            console.log('axios error occurred', response);
            return null;
        });
}

export const notifyServerOfDuelView = (duelId) => {
    console.log("notifyServerOfDuelView", duelId    );
    const urlHost = process.env.REACT_APP_BACK_END_HOST;
    axios.put(`${urlHost}/arena/duel-viewed`, {
        duelId: duelId
    },{
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
            // "Access-Control-Allow-Origin": "*",
        },
        responseType: "json",
    })
        .then(function (response) {
            console.log("Duel view notification sent to server", response.status);
            return response.data;
        })
        .catch(function (response) {
            console.log('axios error occurred', response);
            return null;
        });
}

export default GetInventoryList;