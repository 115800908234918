import React, {useState} from "react";
import Popup from "reactjs-popup";
import {ArenaHistoryList} from "../../pages/arena-history/ArenaHistory";

export const MenuHistoryModal = (props) => {
    const [openCardMintModal, setOpenCardMintModal] = useState(false);

    const closeCardMintModal = () => {
        setOpenCardMintModal(false)
    };
    return (
        <Popup className={'history-popup'}
               trigger={
                   <a className={"menu-link"} style={{color: '#8e9fc4'}}>HISTORY</a>
               }
               modal
               closeOnDocumentClick={true}
               open={openCardMintModal}
               onClose={closeCardMintModal}
        >
            {close => (
                <div id='mint-from-inventory-modal' className="modal">
                    <button
                        className={`close `}
                        onClick={() => {
                            close();
                        }}>
                        &times;
                    </button>

                    <div className={'content-after-x'}>
                        <ArenaHistoryList additionalLinkActions={close}/>
                    </div>
                </div>
            )}
        </Popup>
    );
}