import axios from "axios";
import {appendNewLog} from "../component/PopUpProcessLog/PopUpProcessLog";
import {EVENT_TYPE, LOG_STATUS} from "../helper/ENUMS.ts";
import {refreshLootBoxBalance} from "../component/LootBoxHeaderComponent/LootBoxHeaderComponent";
import MyCustomEventEmitter from "../component/myCustomEvents";
import React from "react";

const recordFactionBox = ({txId, processLog, setProcessLog, setItemCard = null,  setUnpackIsFinished = null}) => {
    const urlHost = process.env.REACT_APP_BACK_END_HOST;
    return axios.post(urlHost + "/item/pre-mint", {
        txId: txId,
    }, {
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
            // "Access-Control-Allow-Origin": "*",
        },
        responseType: "json",
    })
        .then(backEndMirrorNodeResponse => {
            const {item, lootBoxBalanceUpdated, stored} = backEndMirrorNodeResponse.data;

            if (setItemCard !== null) {
                setItemCard(item);
            }

            console.log(`backEndMirrorNodeResponse.data.item`, item);
            setProcessLog([...appendNewLog(processLog, <>
                Item #{item.bxId} stored & ready to be minted
            </>, stored ? LOG_STATUS.SUCCESS : LOG_STATUS.ERROR)]);
            if (lootBoxBalanceUpdated) {
                refreshLootBoxBalance();
            }

            if (setUnpackIsFinished !== null ) {
                setUnpackIsFinished(true);
            }
            MyCustomEventEmitter.dispatch(EVENT_TYPE.FORCE_CURRENCY_UPDATE);
            return true;
        })
        .catch(function (response) {
            setProcessLog([...appendNewLog(processLog, <>error: {response.data.error}</>, LOG_STATUS.ERROR)]);

            if (setUnpackIsFinished !== null ) {
                setUnpackIsFinished(true);
            }
            MyCustomEventEmitter.dispatch(EVENT_TYPE.FORCE_CURRENCY_UPDATE);
            return false;
        })
    ;
}

export default recordFactionBox;