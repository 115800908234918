import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
const nodeEnv = process.env.REACT_APP_NODE_ENV;
console.log("NODE_ENV configuration: ", nodeEnv);
if (nodeEnv === 'development') {
    console.log("environment initialized ", process.env.REACT_APP_SOME_KIND_OF_ENV);
    root.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    );
} else {
    // const ipList = process.env.IP_WHITELIST || "?e1f052fd-5b19-4cbe-9b40-68ff9d81bfe9";
    // const urlQuery = window.location.search;
    // if (ipList === urlQuery) {
        root.render(
            <App/>
        );
    // } else {
    //     root.render(
    //         <span>IP not allowed :)</span>
    //     );
    // }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
