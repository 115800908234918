export enum BLOCKCHAIN { HEDERA, BINANCE, ETHEREUM};

export enum FACTION {
    RED, GREEN, BLUE, DARK
};

export enum SET_PIECE{SKIN, HEAD, BODY, WEAPON};

export enum ARENA_POOLS{
    WOOD,
    SILVER,
    GOLD,
    CUSTOM
}

export enum EVENT {
    STORAGE_UPDATE,
    PROXY_UPDATE,
    PASSWORD_UPDATE,
    BANAN_WITHDRAWAL,
    FUNDS_WITHDRAWAL,
    ALL_WITHDRAWAL,
    SELF_DESTRUCT,
    CHANGE_OWNER};

export enum MODAL_TYPE {
    DEFAULT,
    UNPACK_BOX,
    UNPACK_ROOKIE,
    BATTLE_INFO,
}

export enum PATH_NAME {
    INVENTORY = "/inventory",
    ARENA_PICKER = '/arena',
    ARENA_PASSIVE_LIST = '/arena-passives',
    ARENA_RESULT_PAGE = '/arena-result',
    DUEL_RESULT_PAGE = '/duel-result',
    SHOP = '/shop',
}

export enum DUEL_RESULT {
    VICTORY,
    DEFEAT,
    DRAW,
    UNRESOLVED // this is for when the duel was not calculated yet (PENDING? UNDECIDED?)
}

export enum DUEL_WINNER {
    LEFT,
    RIGHT,
    DRAW
}

export enum DUEL_PLAYER {
    LEFT,
    RIGHT,
    LOADING,
}

export enum DUEL_VIEWED {
    NEW_DUEL_OPENED,
    LOADING,
    ACC_NOT_FOUND,
    NEW_DUEL,
    ALREADY_VIEWED,
}

export enum ASSOCIATIONS {
    CURRENCY,
    ITEMS,
    SETS,
}

export enum LOG_STATUS {
    SUCCESS,
    ERROR,
    WARNING,
    PENDING,
}

// while this is a redundant enum, process and log status are not the same,
// process refers to the overall status, while log refers to the status of a particular step
export enum PROCESS_STATUS {
    INITIALISED,
    ERROR,
    FINISHED,
    WAITING,
}

export enum ALIGNMENT {
    LEFT,
    CENTER,
    RIGHT,
}

export enum EVENT_TYPE {
    DUEL_EVENT_CREATED = "DUEL_EVENT_CREATED",
    DUEL_EVENT_VIEWED = "DUEL_EVENT_VIEWED",
    FORCE_CURRENCY_UPDATE = "FORCE_CURRENCY_UPDATE",
    OPEN_ITEM_UNPACKING_MODAL = "OPEN_ITEM_UNPACKING_MODAL",
}

export enum PROCESS_TYPE {
    FULL_SET_MINT,
    ITEM_MINT,
    ITEM_UNPACK,
    ITEM_ROOKIE_UNPACK,
    ITEM_MINT_AND_UNPACK,
}
