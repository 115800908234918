import {FACTION} from "./ENUMS.ts";

export const indexOfMax = (arr) => {
    if (arr.length === 0) {
        return -1;
    }

    var max = arr[0];
    var maxIndex = 0;

    for (var i = 1; i < arr.length; i++) {
        if (arr[i] > max) {
            maxIndex = i;
            max = arr[i];
        }
    }

    return max > 0 ? maxIndex : FACTION.DARK;
}

export const formatNumber = (num, symbols = 5) => {
    if (num < 0.4) {
        return (symbols === 4) ? '0.00' : '00.00';
    }
    if (num > 100) {
        return num.toFixed(1);
        // todo fix this
    }

    if (symbols !== 4 && symbols !== 5) {
        throw new Error("Symbols must be either 4 or 5");
    }

    let formattedNum;

    if (num < 10) {
        // formattedNum = symbols === 4 ? num.toFixed(1) : num.toFixed(2);
        formattedNum = num.toFixed(2);
    } else if (num < 100) {
        // formattedNum = symbols === 4 ? num.toFixed(0) : num.toFixed(1);
        formattedNum = symbols === 4 ? num.toFixed(1) : num.toFixed(2);
    } else {
        formattedNum = symbols === 4 ? (num / 100).toFixed(1) + ' H' : num.toFixed(0);
    }

    while (formattedNum.length < symbols) {
        formattedNum = '0' + formattedNum;
    }

    return formattedNum;
};