import React, {useEffect, useRef, useState} from 'react';
import 'swiper/css';
import './ArenaHistory.css';
import {ARENA_POOLS, DUEL_PLAYER, DUEL_RESULT, FACTION} from "../../helper/ENUMS.ts";
import {getDuelHistory} from "../../connector/GetInventoryList";
import {MdOutlineNewReleases, MdTrendingDown, MdTrendingFlat, MdTrendingUp} from "react-icons/md";
import {useNavigate, useParams} from "react-router-dom";
import {BiLoader} from "react-icons/bi";
import MyCustomEventEmitter from "../../component/myCustomEvents";
import {getAccountId, getAccountInSolidity} from "../../connector/blockchain/hashconnect";
import {WiMoonAltNew} from "react-icons/wi";
import {ImNotification} from "react-icons/im";
import {TbRosetteFilled} from "react-icons/tb";
import {VscBlank, VscCircleSmallFilled} from "react-icons/vsc";
import {FaRegDizzy, FaRegGrinStars, FaRegSadCry} from "react-icons/fa";
import {SiHedera} from "react-icons/si";


const DuelInfoBar = ({index, duel, accountId, additionalLinkActions}) => {
    // const playerAccount = '0x' + (AccountId.fromString(useParams().userId)).toSolidityAddress();
    console.log('duel', duel);
    console.log('accountId', accountId);
    const playerSide = duel.playerLeft.ownerAddress === accountId ? DUEL_PLAYER.LEFT : DUEL_PLAYER.RIGHT;
    let DuelResoulutionIcon = null,
        NewDuelIcon = <><VscBlank/></>,
        playerResult = null;

    if (playerSide === DUEL_PLAYER.LEFT) {
        if (!duel.playerLeft.viewed) {
            // NewDuelIcon = <TbRosetteFilled className={'new-icon'}/>;
            NewDuelIcon = <VscCircleSmallFilled className={'new-icon'}/>;
        }
        if (duel.winner.serial === duel.playerLeft.serial) {
            playerResult = DUEL_RESULT.VICTORY;
            DuelResoulutionIcon = <FaRegGrinStars/>;
        } else if (duel.winner.serial === duel.playerRight.serial) {
            playerResult = DUEL_RESULT.DEFEAT;
            DuelResoulutionIcon = <FaRegDizzy/>;
        } else {
            playerResult = DUEL_RESULT.UNRESOLVED
            DuelResoulutionIcon = <BiLoader/>;
        }
    } else {
        if (!duel.playerRight.viewed) {
            NewDuelIcon = <VscCircleSmallFilled className={'new-icon'}/>;
        }
        if (duel.winner.serial === duel.playerRight.serial) {
            playerResult = DUEL_RESULT.VICTORY;
            DuelResoulutionIcon = <MdTrendingUp/>;
        } else if (duel.winner.serial === duel.playerLeft.serial) {
            playerResult = DUEL_RESULT.DEFEAT;
            DuelResoulutionIcon = <FaRegSadCry/>;
        } else {
            playerResult = DUEL_RESULT.UNRESOLVED
            DuelResoulutionIcon = <BiLoader/>;
        }
    }
    const navigateRedirect = useNavigate();
    return (
        <div key={`duel-id-${duel.id}`}
             className={`duel flex-container-columns  ${playerResult === DUEL_RESULT.VICTORY ? 'victory' : 'defeat'}`}>
            <div className={'duel-info-block'}>

                <div className={'duel-id flex-container-columns'}>
                    #{duel.id}
                </div>
                <div className={'duel-id '}>
                    (#{duel.bxId}
                </div>
                <div className={'blockchain-icon'}>
                    <SiHedera className={'icon'}/>)
                </div>
            </div>
            <div
                className={`flex-container-columns battle-players-info ${playerResult === DUEL_RESULT.VICTORY ? 'victory' : 'defeat'}`}>
                <div className={'battle-player-name'}>

                    <span
                        className={`universal-address ${playerSide === DUEL_PLAYER.LEFT ? `highlighted` : ''}`}>{duel.playerLeft.universalAddress}</span>
                </div>
                Vs.
                <div className={'battle-player-name'}>
                    <span
                        className={`universal-address ${playerSide === DUEL_PLAYER.RIGHT ? `highlighted` : ''}`}>{duel.playerRight.universalAddress}</span>
                </div>
            </div>
            {/*<div>*/}
            {/*    {duel.winner.serial}*/}
            {/*</div>*/}
            {/*<div className={'battle-result-icon'}>*/}
            {/*    {DuelResoulutionIcon}*/}
            {/*</div>*/}
            <div className={'battle-actions'}>
                <button className={`button secondary btn`} onClick={() => {
                    additionalLinkActions();
                    navigateRedirect(`/duel-result/${duel.id}`);
                }}><span>VIEW</span>{NewDuelIcon}</button>
            </div>
        </div>
    );
}

export const ArenaHistoryList = ({additionalLinkActions}) => {

    const [duels, setDuels] = useState([]);
    const [accountId, setAccountId] = useState('0x0');
    const [pageNumber, setPageNumber] = useState(0);
    const [maxPageNumber, setMaxPageNumber] = useState(9999999);
    const [loading, setLoading] = useState(false);

    const showNextPage = () => {
        setLoading(true);
        getDuelHistory(pageNumber + 1).then((data) => {
            if (data !== null) {
                setPageNumber(pageNumber + 1);
                setDuels(data.duels);
                setLoading(false);
                if (data.duels.length < 5) {
                    setMaxPageNumber(pageNumber);
                }
            }
        }).catch((x) => {
            console.error(x);
            setLoading(false);
        });
    }
    const showPreviousPage = () => {
        if (pageNumber > 0) {
            setLoading(true);
            getDuelHistory(pageNumber - 1).then((data) => {
                if (data !== null) {
                    setPageNumber(pageNumber - 1);
                    setDuels(data.duels);
                }
                setLoading(false);
            }).catch((x) => {
                console.error(x);
                setLoading(false);
            });
        }
    }

    useEffect(() => {
        setLoading(true);
        // not sure why we need to use a useEffect here
        // but maybe it's better for a smooth loads for later
        if (getAccountId() !== null) {
            parseDuelHistory(setDuels, setAccountId, setLoading);
        }

        MyCustomEventEmitter.subscribe("pairingDataFound", () => {
            parseDuelHistory(setDuels, setAccountId, setLoading);
        });

    }, []);

    if (duels.length === 0) {
        console.log('duels.size === 0');
    }

    let userInSolidity = accountId;

    return (
        <div id={'history-panel'} className={'flex-column flex-container-rows'}>
            <div id={'middle-buffer-top'}>

            </div>
            <div id={'history-panel-contents'} className={'flex-container-rows'}>
                <div id={'game-history-title'} className={'flex-row flex-container-rows'}>
                    <h2>BATTLE HISTORY</h2>
                    <div id={'numbers-top-buffer'} className={'flex-row flex-container-rows'}>
                        {/*<div className={'divider'}/>*/}
                    </div>
                </div>
                <div className={'battle-list'}>
                    {duels.length > 0 ?
                        <> {duels.map((duel, index) => {
                            return <DuelInfoBar key={`duel-id-${duel.id}`} index={index} duel={duel}
                                                accountId={userInSolidity}
                                                additionalLinkActions={additionalLinkActions}/>
                        })} </>
                        : <div className={'no-battles'}>No battles yet</div>}
                </div>
                <div className={`flex-row flex-container-rows pagination actions`}>
                    <div className={'previous-container pagination-button-container'}>
                        <button className={`button secondary btn ${pageNumber === 0 ? 'disabled' : ''}`} disabled={pageNumber === 0} onClick={() => {
                            showPreviousPage();
                        }}><span>PREVIOUS</span></button>
                    </div>
                    <div className={'pagination-loader-container'}>{loading ? <BiLoader className={'pagination-icon loader rotate'}/> : <VscBlank className={'pagination-icon'}/> }</div>
                    <div className={'next-container pagination-button-container'}>
                        <button className={`button secondary btn ${pageNumber > maxPageNumber ? 'disabled' : ''}`} disabled={pageNumber > maxPageNumber} onClick={() => {
                            showNextPage();
                        }}><span>NEXT</span></button>
                    </div>
                </div>
            </div>
        </div>
    );
}


let selectedSet, setSelectedSet;
let duelInfo, setDuelInfo;

const parseDuelHistory = (setDuels, setAccount, setLoading) => {
    getDuelHistory(0).then((data) => {
        if (data !== null) {
            // console.log('getDuelHistory', data);
            setDuels(data.duels);
            setAccount(getAccountInSolidity());
        }
        setLoading(false);
    }).catch((x) => {
        console.error(x);
        setLoading(false);
    });
}

const ArenaHistory = () => {

    return (
        <div id={'arena-result-page'} className={'flex-container-columns'}>
            <div id={'main'}>
                {/*<ArenaResultPortal playerInfo={duelInfo.playerLeft} htmlId={'left'}/>*/}
                <ArenaHistoryList/>
                {/*<ArenaResultPortal playerInfo={duelInfo.playerRight} htmlId={'right'}/>*/}
            </div>
        </div>
    )
};

export default ArenaHistory;
