import {BiLoaderCircle} from "react-icons/bi";
import * as React from 'react';
import './FullSetCarousel.css';
import {useState} from "react";
import {ItemFullSize} from "../itemCard/itemCard";
import {PATH_NAME} from "../../helper/ENUMS.ts";
import ItemWholeSet from "./ItemWholeSet";

export const isFullSet = (itemCards) => {
    if (itemCards === undefined || itemCards === null || itemCards.length === 0) {
        return false;
    }

    const initialFaction = (itemCards[0] !== null) ? itemCards[0].faction : 5; // non existent faction
    const initialSkinSet = (itemCards[0] !== null) ? itemCards[0].skinSet : 5; // non existent skinSet
    const allSameFactionAndSkinSet = (itemCards.length === 4) ? itemCards.every(item =>
        item !== null && item.faction === initialFaction && item.skinSet === initialSkinSet
    ) : false;
    return allSameFactionAndSkinSet;

}

const FullSetCarousel = ({glowColour, glowReady, carouselIndex, itemCards, lastIndex, setIndexFunc}) => {

    let bananFullSizeArray = [];
    let allSameFactionAndSkinSet = false;
    console.log(`FullSetCarousel : `, itemCards);
    if (itemCards !== undefined && itemCards !== null && itemCards.length > 0) {
        allSameFactionAndSkinSet = isFullSet(itemCards);
        if (allSameFactionAndSkinSet) {
            // All items have the same faction and skinSet
            bananFullSizeArray = <ItemWholeSet set={itemCards}/>
        } else {
            // Not all items have the same faction and skinSet
            bananFullSizeArray = itemCards.map((item, index) =>
                <ItemFullSize card={item} key={`key_${index}`} index={index}/>
            );
        }
    }

    let glowURL = `https://asset-host.b-cdn.net/assets/i/portal_netral.png`;

    if (glowReady === true) {
        glowURL = `https://asset-host.b-cdn.net/assets/i/${glowColour}_portal_${allSameFactionAndSkinSet ? 'full' : 'random'}_set.png`;
    }

    return (
        <>
            <div id={'portal-front'} className={`portal-glow portal-glow-${glowColour}`}
                // style={{backgroundImage: `url('${'https://asset-host.b-cdn.net'}/assets/inventory/portal_${glowColour}.png')`}}
                 style={{backgroundImage: `url(${glowURL})`}}
            />
            <div id="banan-full-size">
                <div className={"assembly"}>
                    {/*<img className="aspect-ratio" src="/assets/inventory/aspect-ratio.png"/>*/}
                    <div className="assembly-container">{bananFullSizeArray}</div>
                    {/*<img id="selection-overlay" className="aspect-ratio"*/}
                    {/*     src="/assets/inventory/aspect-ratio.png"/>*/}
                </div>
            </div>

        </>
    );
}

export default FullSetCarousel;