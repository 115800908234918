import './App.css';
import {BrowserRouter, NavLink, Route, Routes} from "react-router-dom";
import {Analytics} from '@vercel/analytics/react';
import Inventory from "./pages/inventory/Inventory";
import Store from "./pages/store/Store";
import React, {useState} from "react";
import axios from "axios";
import MyCustomEventEmitter from "./component/myCustomEvents";
import LootBoxHeaderComponent from "./component/LootBoxHeaderComponent/LootBoxHeaderComponent";
import HealthCheck from "./pages/HealthCheck";
import ArenaPickerPageOld from "./pages/arena/ArenaPickerPageOld";
import ArenaResultPage from "./pages/arena-result-page/ArenaResultPage";
import ArenaMatchmaking from "./pages/arena-matchmaking/ArenaMatchmaking";
import {AiOutlineHome} from "react-icons/ai";
import ArenaHistory from "./pages/arena-history/ArenaHistory";
import {GiHealthCapsule} from "react-icons/gi";
import {MdHelpOutline} from "react-icons/md";
import ToolTip from "./component/Modal/ToolTip";
import {EVENT_TYPE, PATH_NAME} from "./helper/ENUMS.ts";
import {MenuHistoryModal} from "./component/Modal/MenuHistoryModal";
import HomePage from "./pages/homepage/HomePage";
import MenuSettingsIcon from "./component/Settings/Settings";
import Investor from "./pages/investor/Investor";
import Terms from "./pages/Terms";
import Distribution from "./pages/distribution/Distribution";
import Shop from "./pages/store/Shop";
import InventoryNew from "./pages/inventory/InventoryNew";
import ArenaPickerPage from "./pages/arena-picker/ArenaPickerPage";
import DuelResultPage from "./pages/duel-result/DuelResultPage";
import Popup from "reactjs-popup";
import {FiMenu} from "react-icons/fi";
import {MenuResolverModal} from "./component/Modal/MenuResolverModal";
import WalletConnectComponentV3 from "./component/WalletConnectComponent/WalletConnectComponentV3";

function axiosInitialiseAuthHeader() {
    axios.defaults.headers.common['Authorization-Web3'] = localStorage.authToken || "undef";
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
}

export const CurrencyIcon = ({size, onClickFunction}) => {
    return (
        <img className={`yv-currency-icon currency-icon-${size}`} onClick={onClickFunction}
             src={`https://asset-host.b-cdn.net/assets/global/logos/logo_diamond_64x64.png`}/>
    )
}
export const GovIcon = ({size, onClickFunction}) => {
    return (
        <img className={`bncp-gov-icon currency-icon-${size}`} onClick={onClickFunction}
             src={`https://asset-host.b-cdn.net/assets/global/logos/logo_banana_48x48.png`}/>
    )
}

export const CurrencyIconDiv = ({size, onClickFunction, customId}) => {
    return (
        <div className={`yv-currency-icon-div currency-icon-${size}`} onClick={onClickFunction}
             id={customId ? `${customId}` : undefined}
        />
    );
}


function App() {

    axiosInitialiseAuthHeader(); // should it even be here?

    const [isAuthTokenPresent, setIsAuthTokenPresent] = useState(false);

    function authTokenStatusChange(tokenStatus) {
        console.log("App() event authTokenPresent parsed: ", tokenStatus);
        axiosInitialiseAuthHeader();
        setIsAuthTokenPresent(tokenStatus);
    }

    MyCustomEventEmitter.subscribe("authTokenPresent", tokenStatus => authTokenStatusChange(tokenStatus));


    const isLinkActive = (isActive) => {
        return {
            color: `#${isActive ? 'cecece' : '8e9fc4'}`
        }
    }
    const linkClass = (isActive) => {
        return `menu-link${isActive ? ' active' : ''}`;
    }

    // let isNotificationShown = useRef(false);
    const [isNotificationShown, setIsNotificationShown] = useState(false);
    const [notificationText, setNotificationText] = useState('test');


    MyCustomEventEmitter.subscribe(EVENT_TYPE.DUEL_EVENT_CREATED, (data) => {
        console.log("newDuelEvent in App.js", data);
        const redirectUrl = `/duel-result/${data.duelId}`;
        localStorage.setItem("notificationDuelId", data.duelId);
        if (window.location.pathname !== redirectUrl) {
            setNotificationText(<>Someone has matched your duel request!<br/><a href={redirectUrl}
                                                                                onClick={() => setIsNotificationShown(false)}>Click
                here to view it.</a></>);
            setIsNotificationShown(true);
        } else {
            console.log("same page, not showing notification");
            setIsNotificationShown(false);
        }
    });

    MyCustomEventEmitter.subscribe(EVENT_TYPE.DUEL_EVENT_VIEWED, (data) => {
        console.log("duelEventViewed in App.js", data);
        if (localStorage.getItem("notificationDuelId") === (data.duelId).toString()) {
            setIsNotificationShown(false);
            localStorage.setItem("notificationDuelId", null);
        } else {
            console.log("different duelId, not hiding notification", localStorage.getItem("notificationDuelId"), data.duelId);
        }
    });

    const contentLock = process.env.REACT_APP_NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'testnet-vercel'
    if (!contentLock) {
        console.log("not connecting to socket: ", process.env.REACT_APP_NODE_ENV);
    }
    console.info(`contentLock config: ${contentLock} (environment: ${process.env.REACT_APP_NODE_ENV})`);

    return (
        <div className={`App`}>
            <BrowserRouter>
                <div id={"route-content"}>
                    <div id={"browser-router"} style={{
                        // height: '100%'
                        justifyContent: 'center'
                    }}>

                        <div id={'menu'} style={{
                            alignContent: 'center',
                            alignItems: 'center',
                        }}>
                            <Popup
                                trigger={<div id="mobile-menu">
                                    <div className={'menu-link'}><span id={'mobile-big-menu'}>MENU</span><span
                                        id={'mobile-small-menu'}><FiMenu/></span></div>
                                </div>}
                                position="bottom right"
                                on="click"
                                closeOnDocumentClick
                                mouseLeaveDelay={300}
                                mouseEnterDelay={0}
                                contentStyle={{padding: '0px', border: 'none'}}
                                arrow={false}
                                keepTooltipInside={true}
                                nested={true}
                            >
                                <div className="menu">
                                    <div className={'mobile-menu'}>
                                        <NavLink to="/" end style={({isActive}) => (isLinkActive(isActive))}>
                                            HOME
                                        </NavLink>
                                    </div>
                                    <div className={'mobile-menu'}>
                                        <NavLink to="https://docs.banana.capital/banana-capital/intro" target={'_blank'}
                                                 style={({isActive}) => (isLinkActive(isActive))}>
                                            DOCS
                                        </NavLink>
                                    </div>
                                    <div className={'mobile-menu'}>
                                        <MenuResolverModal/>
                                    </div>
                                    <div className={'mobile-menu'}>
                                        <NavLink to="/shop" className={({isActive}) => (linkClass(isActive))}
                                                 style={({isActive}) => (isLinkActive(isActive))}>
                                            SHOP
                                        </NavLink>
                                    </div>
                                    <div className={'mobile-menu'}>
                                        <NavLink to="/inventory" className={({isActive}) => (linkClass(isActive))}
                                                 style={({isActive}) => (isLinkActive(isActive))}>
                                            INVENTORY
                                        </NavLink>
                                    </div>
                                    <div className={'mobile-menu'}>
                                        <NavLink to="/arena" className={({isActive}) => (linkClass(isActive))}
                                                 style={({isActive}) => (isLinkActive(isActive))}>
                                            ARENA
                                        </NavLink>
                                    </div>
                                    <div className={'mobile-menu'}>
                                        <MenuHistoryModal/>
                                    </div>
                                </div>
                            </Popup>

                            <div className={'desktop-menu'}>
                                <NavLink to="/" end style={({isActive}) => (isLinkActive(isActive))}>
                                    <AiOutlineHome/>
                                </NavLink>
                            </div>
                            <div className={'desktop-menu'}>
                                <NavLink to="https://docs.banana.capital/banana-capital/intro" target={'_blank'}
                                         style={({isActive}) => (isLinkActive(isActive))}>
                                    <MdHelpOutline/>
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/distribution" className={({isActive}) => (linkClass(isActive))}>
                                    <GovIcon size={32} onClickFunction={null}/>
                                </NavLink>
                            </div>
                            {isAuthTokenPresent ?
                                (<>
                                    <div className={'desktop-menu'}>
                                        <NavLink to="/shop" className={({isActive}) => (linkClass(isActive))}>
                                            SHOP
                                        </NavLink>
                                    </div>

                                    {contentLock ?
                                        <>
                                            <div className={'desktop-menu'}>
                                                <MenuResolverModal/>
                                            </div>
                                            <div className={'desktop-menu'}>
                                                <NavLink to="/inventory"
                                                         className={({isActive}) => (linkClass(isActive))}>
                                                    INVENTORY
                                                </NavLink>
                                            </div>
                                            <div className={'desktop-menu'}>
                                                <NavLink to="/arena" className={({isActive}) => (linkClass(isActive))}>
                                                    ARENA
                                                </NavLink>
                                            </div>
                                            {/*<div>*/}
                                            {/*    <NavLink to="/arena-passives" className={({isActive}) => (linkClass(isActive))}>*/}
                                            {/*        ARENA PASSIVES*/}
                                            {/*    </NavLink>*/}
                                            {/*</div>*/}
                                            <div className={'desktop-menu'}>
                                                <MenuHistoryModal/>
                                            </div>
                                            <div className={'header-right'}>
                                                {/*<WalletConnect key={'headerWalletConnect'}/>*/}
                                            </div>
                                        </> : null}
                                </>) :
                                null
                            }

                        </div>
                        <img id={'header-logo'}
                             src={'https://asset-host.b-cdn.net/assets/global/logos/logo_header.png'}/>
                        {/*{contentLock ?*/}
                        {/*    <>*/}
                        <LootBoxHeaderComponent/>
                            {/*</> : null}*/}
                        <WalletConnectComponentV3/>
                    </div>
                    <Routes>
                        <Route exact path="/" element={<HomePage/>}/>
                        <Route exact path="/distribution" element={<Distribution/>}/>
                        <Route exact path="/shop" element={<Shop/>}/>
                        <Route exact path="/terms-and-conditions/" element={<Terms/>}/>
                        {/*<Route exact path="/about" element={<About/>}/>*/}
                        {contentLock ?
                            <>
                                <Route exact path="/health-check" element={<HealthCheck/>}/>
                                <Route exact path="/investor" element={<Investor/>}/>
                                <Route exact path="/inventory-old" element={<Inventory/>}/>
                                <Route exact path="/inventory" element={<InventoryNew page={PATH_NAME.INVENTORY}/>}/>
                                <Route exact path="/store" element={<Store/>}/>
                                <Route exact path="/arena" element={<ArenaPickerPage/>}/>
                                <Route exact path="/arena-old" element={<ArenaPickerPageOld/>}/>
                                {/*<Route exact path="/arena-passives" element={<ArenaPassiveList/>}/>*/}
                                <Route exact path="/arena-matchmaking" element={<ArenaMatchmaking/>}/>
                                <Route exact path="/arena-result/:id" element={<ArenaResultPage/>}/>
                                <Route exact path="/duel-result/:id" element={<DuelResultPage/>}/>
                                <Route exact path="/arena-history/" element={<ArenaHistory/>}/>
                            </>
                            : null}
                    </Routes>
                </div>
                {contentLock ?
                    <ToolTip
                        key={'coco-notification'}
                        content={notificationText}
                        position={'left'}
                        id={'coco-notification'}
                        onActions={['hover', 'focus']}
                        trigger={<img id={'coco-notification'} onDoubleClick={() => {
                            console.log('closing notifications');
                            setIsNotificationShown(false)
                        }} className={`notification-sticky coco ${(isNotificationShown) ? '' : 'hidden'}`}
                                      src={"https://asset-host.b-cdn.net/assets/global/cocount_alert.png"}/>}
                    /> : null}
            </BrowserRouter>
            <Analytics/>
        </div>
    );
}

export default App;
