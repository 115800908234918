
export const BananaCapitalMainConfig = {
    HEDERA: {
        ENV : process.env.REACT_APP_HEDERA_ENV || 'TESTNET',
        MIRROR_NODE: {
            MAINNET: "https://mainnet-public.mirrornode.hedera.com",
            TESTNET: "https://testnet.mirrornode.hedera.com",
            PREVIEWNET: "https://previewnet.mirrornode.hedera.com",
        }
    }
}

export const HEDERA_CONTRACTS = {
    YV_CURRENCY: {
        TESTNET: "0.0.3910458",
    },
    ITEMS_NFT: {
        TESTNET: "0.0.3910462",
    },
    SETS_NFT: {
        TESTNET: "0.0.3910465",
    },
    MINT_ITEM_CONTRACT_ID: {
        TESTNET: "0.0.3910462",
    }
}

export const getHederaMirrorHost = () => {
    return BananaCapitalMainConfig.HEDERA.MIRROR_NODE[BananaCapitalMainConfig.HEDERA.ENV];
}


export default BananaCapitalMainConfig;