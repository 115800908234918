import React, {useEffect, useState} from 'react';
import './YVCurrencyManagement.css';
import Slider from "rc-slider";
import {createUSDAllowance, createYVCAllowance} from "../../connector/generateAllowanceTransactions";
import {assetsHost} from "../../helper/externalLinks";
import axios from "axios";
import {getHederaMirrorHost} from "../../connector/config";
import {getConfigFor} from "../../connector/blockchain/ContractParameters";
import {getUSDCurrencyAddress, isCurrencyAssociated} from "../../helper/mirrrorNode";
import {ContractExecuteTransaction, ContractFunctionParameters, ContractId} from "@hashgraph/sdk";
import MyCustomEventEmitter from "../myCustomEvents";
import {EVENT_TYPE} from "../../helper/ENUMS.ts";
import {
    associateTokensWithAccountV3,
    getAccountIdV3, getAccountIdWithWaitV3,
    getSigner,
    sendTransactionV3
} from "../WalletConnectComponent/WalletConnectComponentV3";


const pairingToken = {
    key: `token-0`,
    name: 'USDC(hts)',
    balance: 0,
    decimals: 6,
    hts: true,
    icon: 'USDC_HTS.png',
    tokenId: process.env.REACT_APP_NODE_ENV === 'production' ? `0.0.1055459` : `0.0.3578047`
};
// TODO get this from database asap

const YVCToken = {
    key: `token-yvc`,
    name: 'YVC',
    balance: 0,
    decimals: 4,
    hts: true,
    icon: 'logo_diamond_64x64.png',
    tokenId: process.env.REACT_APP_NODE_ENV === 'production' ? `0.0.4638593` : `0.0.3578107`
}

const initiateCurrencyPurchase = (amount = 10000) => {

    const purchaseTransactionPrep = getConfigFor('CURRENCY_PURCHASE').then(async callConfig => {
        if (!(await isCurrencyAssociated(await getAccountIdWithWaitV3())).isAssociated) {
            await associateTokensWithAccountV3();
        }
        return createUSDAllowance(callConfig.contractId, amount)
            .then((allowanceGiven) => {
                if (allowanceGiven) {
                    console.log("purchase amount going into TX", amount);
                    return new ContractExecuteTransaction()
                        .setContractId(ContractId.fromString(callConfig.contractId))
                        .setGas(callConfig.gasLimit) // Increase if revert
                        .setTransactionMemo(`Banana.Capital: Purchase of ${amount} YVC`)
                        .setFunction(callConfig.contractFunc
                            , new ContractFunctionParameters()
                                .addInt64(amount)
                        );
                } else {
                    return null;
                }
            }).catch((x) => {
                console.log("error in createCurrencyAllowance", x.status, x.error);
                console.error(x);
                return null;
            });

    });

    purchaseTransactionPrep.then(contractExecuteTx => {
        if (contractExecuteTx !== null) {
            sendTransactionV3({tx: contractExecuteTx, acc: ""}).then((purchaseCallResult) => {
                if (purchaseCallResult.success) {
                    console.log("purchase success");
                    MyCustomEventEmitter.dispatch(EVENT_TYPE.FORCE_CURRENCY_UPDATE);
                }
            });
        }
    });
}


const initiateCurrencyBurn = (amount = 10000) => {
    const signingAccount = getAccountIdV3();

    const burnTxPrep = getConfigFor('CURRENCY_BURN').then(async callConfig => {
        if (!(await isCurrencyAssociated(signingAccount)).isAssociated) {
            await associateTokensWithAccountV3();
        }
        // const usd = await getUSDCurrencyAddress(); // TODO verify USDC is associated
        return createYVCAllowance(signingAccount, callConfig.contractId, amount)
            .then((allowanceGiven) => {
                if (allowanceGiven) {
                    console.log("burn amount going into TX", amount);
                    return new ContractExecuteTransaction()
                        .setContractId(ContractId.fromString(callConfig.contractId))
                        .setGas(callConfig.gasLimit) // Increase if revert
                        .setTransactionMemo(`Banana.Capital: Burn of ${amount / 10 ** YVCToken.decimals} YVC`)
                        .setFunction(callConfig.contractFunc
                            , new ContractFunctionParameters()
                                .addInt64(amount)
                        );
                } else {
                    return null;
                }
            }).catch((x) => {
                console.log("error in createCurrencyAllowance", x.status, x.error);
                console.error(x);
            });

    });

    burnTxPrep.then(contractExecuteTx => {
        if (contractExecuteTx !== null) {
            sendTransactionV3({tx: contractExecuteTx, acc: ""}).then((burnCallResult) => {
                if (burnCallResult.success) {
                    console.log("burn success");
                    MyCustomEventEmitter.dispatch(EVENT_TYPE.FORCE_CURRENCY_UPDATE);
                }
            }).catch((x) => {
                console.error(x);
            });

        }
    });
}

const YVCurrencyManagement = () => {
    let [maxSellingTokenAmount, setMaxSellingTokenAmount] = useState(1_000); // todo get this from mirror

    const [sellingTokenAmount, setSellingTokenAmount] = useState(10);

    const [parsedUSDCBalance, setParsedUSDCBalance] = useState(0);
    const [parsedYVCBalance, setParsedYVCBalance] = useState(0);
    const [purchaseRate, setPurchaseRate] = useState(10);
    const [burnRate, setBurnRate] = useState(0.05);

    const getUSDCBalance = async () => {
        const mirrorHost = getHederaMirrorHost();
        const accountId = await getAccountIdWithWaitV3();

        axios.get(`${mirrorHost}/api/v1/accounts/${accountId}/tokens`, {
            headers: {
                'Content-Type': "application/json",
                'Accept': "application/json",
                'Authorization-Web3': null,
                // "Access-Control-Allow-Origin": "*",
            },
            responseType: "json",
        }).then((mirrorNodeTokensResponse) => {
            const tokensFound = mirrorNodeTokensResponse.data.tokens;
            const usdBalance = tokensFound.find(({token_id}) => token_id === pairingToken.tokenId);
            const yvcBalance = tokensFound.find(({token_id}) => token_id === YVCToken.tokenId);
            let usdParsedBalance = 0;
            let yvcParsedBalance = 0;
            if (usdBalance !== undefined) {
                console.log(`${pairingToken.name} found `, usdBalance);
                usdParsedBalance = usdBalance.balance /*/ (10 ** pairingToken.decimals)*/;
            } else {
                console.log("usd not found, looping may be required");
            }
            if (yvcBalance !== undefined) {
                console.log(`${YVCToken.name} found `, yvcBalance);
                yvcParsedBalance = yvcBalance.balance /*/ (10 ** YVCToken.decimals)*/;
            } else {
                console.log("yvc not found, looping may be required");
            }
            setParsedUSDCBalance(usdParsedBalance);
            setParsedYVCBalance(yvcParsedBalance);
            pairingToken.balance = usdParsedBalance;
            YVCToken.balance = yvcParsedBalance;
        })
            .catch((x) => {
                console.error(x);
            })
        ;
    }


    useEffect(() => {
        getUSDCBalance();
    }, []);

    MyCustomEventEmitter.subscribe(EVENT_TYPE.FORCE_CURRENCY_UPDATE, async () => {
        console.log("updateYVCurrencyBalance FORCE_CURRENCY_UPDATE fired");
        await new Promise(resolve => setTimeout(resolve, 3500)) // delay to let mirror node update
            .then(() => {
                getUSDCBalance();
            });
    });


    const PaymentAmountSelector = ({
                                       maxSellingTokenAmount,
                                       tokenExchanged,
                                       tokenExchangedBalance,
                                       selectorClass,
                                       exchangeRate,
                                       tokenReceived,
                                       conversionFunction,
                                   }) => {

        const [currencyBuyAmount, setCurrencyBuyAmount] = useState(10 ** tokenExchanged.decimals);

        const updateCurrencyBuyAmount = (newValue) => {
            // if (newValue > maxSellingTokenAmount) {
            //     newValue = maxSellingTokenAmount;
            // }
            // if (newValue < 1) {
            //     newValue = 1;
            // }
            setCurrencyBuyAmount(newValue);
        }


        return (
            <div className={`currency-form-inputs ${selectorClass} flex-container-rows form-row`}>
                <div
                    className={'split-30 upper-balance flex-container-columns'}
                >
                    <h3>Amount of ${tokenExchanged.name}&nbsp;<img className={`icon selected-token`}
                                                                   src={`${assetsHost}/assets/global/logos/tokens/${tokenExchanged ? tokenExchanged.icon : 'USDC_HTS.png'}`}
                                                                   alt={`USDC hts token icon`}/>&nbsp;available:&nbsp;
                        <span>
                            {(tokenExchangedBalance / (10 ** tokenExchanged.decimals)).toFixed(tokenExchanged.decimals)}
                        </span>
                    </h3>
                </div>
                <div className={'split-50 amount-selector-container flex-container-columns'}>
                    <Slider
                        className={'amount-selector-slider split-70'}
                        trackStyle={[{backgroundColor: '#febd4e'}]}
                        handleStyle={{backgroundColor: '#ffd152', border: '2px solid #ffd152'}}
                        activeDotStyle={{boxShadow: '0 0 0 5px #fff, 0 0 0 10px red'}}
                        railStyle={{backgroundColor: 'black'}}
                        dotStyle={{backgroundColor: 'purple'}}
                        step={1} // minimal step, we can't send 1.5 into SC
                        max={tokenExchangedBalance}
                        min={10 ** tokenExchanged.decimals}
                        value={currencyBuyAmount}
                        onChange={updateCurrencyBuyAmount}
                        keyboard={true}
                    />
                    {/*m={tokenExchangedBalance} v={currencyBuyAmount}*/}
                    <div className={'amount-selector-input split-30 flex-container-columns'}>
                        {/*<img className={`icon selected-token`}*/}
                        {/*     src={`${assetsHost}/assets/global/logos/tokens/${selectedToken ? selectedToken.icon : null}`}/>*/}
                        <input type={"number"} value={currencyBuyAmount / (10 ** tokenExchanged.decimals)}
                               max={maxSellingTokenAmount}
                               onChange={(e) => updateCurrencyBuyAmount(e.target.value * (10 ** tokenExchanged.decimals))}
                        />
                        <img className={`icon selected-token`}
                             src={`${assetsHost}/assets/global/logos/tokens/${tokenExchanged ? tokenExchanged.icon : 'USDC_HTS.png'}`}
                             alt={`USDC hts token icon`}/>
                    </div>
                </div>
                <div className={'split-20 action-flex flex-container-columns'}>
                    <p className={`flex-container-columns`}>Amount of {tokenReceived.name}&nbsp;<img
                        className={`icon selected-token`}
                        src={`${assetsHost}/assets/global/logos/tokens/${tokenReceived ? tokenReceived.icon : 'USDC_HTS.png'}`}
                        alt={`USDC hts token icon`}/>&nbsp;to
                        receive:</p>
                    <span>{(currencyBuyAmount * exchangeRate / (10 ** tokenExchanged.decimals)).toFixed(4)}&nbsp;
                        <img className={`icon selected-token`}
                             src={`${assetsHost}/assets/global/logos/tokens/${tokenReceived ? tokenReceived.icon : 'USDC_HTS.png'}`}
                             alt={`USDC hts token icon`}/></span>
                    <div className={'actions'}>
                        <button className={'btn button'} onClick={() => {
                            conversionFunction(currencyBuyAmount);
                        }}>CONVERT
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <PaymentAmountSelector maxSellingTokenAmount={parsedUSDCBalance}
                                   sellingTokenAmount={sellingTokenAmount}
                                   tokenExchanged={pairingToken}
                                   tokenExchangedBalance={parsedUSDCBalance}
                                   selectorClass={`USD-to-YVC`}
                                   exchangeRate={purchaseRate}
                                   tokenReceived={YVCToken}
                                   conversionFunction={initiateCurrencyPurchase}

            />
            <PaymentAmountSelector maxSellingTokenAmount={maxSellingTokenAmount}
                                   sellingTokenAmount={sellingTokenAmount}
                                   tokenExchanged={YVCToken}
                                   tokenExchangedBalance={parsedYVCBalance}
                                   selectorClass={`YVC-to-USD`}
                                   exchangeRate={burnRate}
                                   tokenReceived={pairingToken}
                                   conversionFunction={initiateCurrencyBurn}
            />
        </>
    );
}

export default YVCurrencyManagement;