import React from 'react';
import './CardList.css';

export const applyFactionFilter = (card, filter) => filter.factions[card.faction] && (filter.setPiece === null || filter.setPiece === card.setPiece);

export const applySort = (cards, sortObject) => {
    if (sortObject.id !== 0) {
        cards.sort((a, b) => {
            if (sortObject.id === 1) {
                return a.bxId - b.bxId;
            } else {
                return b.bxId - a.bxId;
            }
        });
    }

    if (sortObject.damage !== 0) {
        cards.sort((a, b) => {
            if (sortObject.damage === 1) {
                return a.damage - b.damage;
            } else {
                return b.damage - a.damage;
            }
        });
    }

    return cards;
}