import React, {useRef, useState} from "react";
import Popup from "reactjs-popup";
import {LOG_STATUS, PROCESS_TYPE} from "../../helper/ENUMS.ts";
import axios from "axios";
import {PrepSetNft} from "../../connector/adapter/PrepSetNft";
import {getAccountIds} from "../../connector/blockchain/hashconnect";
import PopUpProcessLogItems from "../PopUpProcessLog/PopUpProcessLog";
import {performNextStep} from "../Portal/PortalMintButton";
import './MenuResolverModal.css';
import recordFactionBox from "../../connector/UnpackWeb2Caller";
import {CgBandAid} from "react-icons/cg";

const ResolverForm = (props) => {

    const urlHost = process.env.REACT_APP_BACK_END_HOST;
    const processType = useRef(null);
    const processId = useRef(null);
    const unpackTx = useRef(null);
    const processFeeTx = useRef(null);
    const [mintProcess, setMintProcess] = useState(null);
    const [unpackProcess, setUnpackProcess] = useState(null);
    const [unpackProcessLogItems, setUnpackProcessLogItems] = useState([{
        status: LOG_STATUS.PENDING,
        text: `Sending given Tx...`
    }]);
    const [itemCard, setItemCard] = useState(null);
    const [selectedProcessType, setSelectedProcessType] = useState(PROCESS_TYPE.FULL_SET_MINT);
    let processFoundError = true;
    let foundNFT = null;

    const handleSubmit = event => {
        console.log('handleSubmit ran');
        event.preventDefault(); // 👈️ prevent page refresh

        processFoundError = false;
        const signingAcct = getAccountIds()[0]; // todo this is a potential issue if our backend is faster than users hashpack connection;

        if (selectedProcessType === PROCESS_TYPE.FULL_SET_MINT || selectedProcessType === PROCESS_TYPE.ITEM_MINT) {
            axios.get(`${urlHost}/resolver/${processType.current.value}/${processId.current.value}`, {
                headers: {
                    'Content-Type': "application/json",
                    'Accept': "application/json",
                    // "Access-Control-Allow-Origin": "*",
                },
                responseType: "json",
            })
                .then(function (response) {
                    processFoundError = false;
                    if (response.data.process !== null) {
                        const processFound = response.data.process;
                        setMintProcess(processFound);
                        foundNFT = new PrepSetNft({data: processFound, signingAcct: signingAcct});
                        console.log('parsed post minted items: ', processFound);
                    }
                })
                .catch(function ({response}) {
                    processFoundError = true;
                    if (response.status === 403) {
                        setMintProcess(response.data);
                    } else if (response.status === 404) {
                        setMintProcess(response.data);
                    }
                    console.log('axios error occurred', response);
                    return null;
                });
        } else if (selectedProcessType === PROCESS_TYPE.ITEM_UNPACK || selectedProcessType === PROCESS_TYPE.ITEM_ROOKIE_UNPACK) {

            recordFactionBox({
                txId: unpackTx.current.value,
                processLog: unpackProcessLogItems,
                setProcessLog: setUnpackProcessLogItems,
                setItemCard: setItemCard,
                setUnpackIsFinished: null
            }).then((result) => {
                if (result) {
                    setUnpackProcess({
                        itemCard: itemCard,
                        processLog: unpackProcessLogItems,
                        unpackTx: unpackTx.current.value
                    });
                }
            });
        }

        // 👇️ clear all input values in the form
        // event.target.reset();
    };

    const ResolveProcessForm = ({foundProcess}) => {

        let processMissingPiece = [];

        if (foundProcess.lastStep >= 1) {
            if (foundProcess.feeTxId === null || foundProcess.feeTxId === '' || foundProcess.feeTxId === undefined) {
                processMissingPiece.push(
                    <div className={'form-row flex-container-columns'}>
                        <label htmlFor={'mintProcess-fee-tx-id'} className={'form-label'}>Fee Transaction is
                            missing:</label>
                        <input id={'mintProcess-fee-tx-id'} ref={processFeeTx} className={'form-input'} type={'text'}
                               placeholder={'0.0.1234567@1698711249.988179357'}/>
                    </div>);
            } else {
                console.log(`foundProcess.feeTxId`, foundProcess.feeTxId);
            }
        }

        console.log(`foundProcesss`, foundProcess);

        const [processLogItems, setProcessLogItems] = useState([{
            status: LOG_STATUS.PENDING,
            text: `Waiting for players' inputs...`
        }]);

        const handleResolverSkip = event => {
            console.log('handleSubmit ran');
            event.preventDefault(); // 👈️ prevent page refresh
            setProcessLogItems([{
                status: LOG_STATUS.PENDING,
                text: `Waiting for players' inputs...`
            }]);
            const signingAcct = getAccountIds()[0]; // todo this is a potential issue if our backend is faster than users hashpack connection;
            foundNFT = new PrepSetNft({data: foundProcess, signingAcct: signingAcct});
            if (foundProcess.lastStep === 1) {
                foundNFT.feeTxId = processFeeTx.current.value;
            }
            performNextStep(foundNFT, processLogItems, {processLogFunc: setProcessLogItems});
        }

        return (
            <form id={'resolver-finisher-form'} className={'flex-container-rows'} onSubmit={handleResolverSkip}>
                <div className={'form-row flex-container-columns text-only'}>
                    Process found!
                    {foundProcess.error === null || foundProcess.error === false || foundProcess.error === undefined ?
                        <>&nbsp;{PROCESS_TYPE[foundProcess.processType]} process is {foundProcess.lastStep < 4 ? <>in
                            step: {foundProcess.lastStep}/5</> : <>complete!</>}</>
                        : <>&nbsp;ERROR: {foundProcess.error}</>}
                </div>
                {processMissingPiece}
                {foundProcess.lastStep < 4 ?
                    <>
                        <div className={'form-row actions'}>
                            <button type="submit" className={'form-input button btn primary'}>Resolve process</button>
                        </div>
                        <div className={'form-row text-only flex-container-rows'}>
                            <PopUpProcessLogItems proccessLog={processLogItems}/>
                        </div>
                    </>
                    : null}
            </form>
        );
    }

    const ResolveUnpackProcessForm = ({unpackProcess}) => {


        return (<>
            <div className={'form-row flex-container-columns text-only'}>
                Attempting to resolve unpack process... Note: You won't be able to see the NFT until the process is
                finished. Check inventory upon completion.
            </div>
            <div className={'form-row text-only flex-container-rows'}>
                <PopUpProcessLogItems proccessLog={unpackProcess.processLog}/>
            </div>
        </>);
    }

    return (
        <>
            <form id={'resolver-form'} className={'flex-container-rows'} onSubmit={handleSubmit}>
                <div className={'form-row flex-container-columns text-only'}>
                    Process Resolver can help you fix unfinished minting processes! As long as you have process ID and
                    required transactions.
                </div>
                <div className={'form-row flex-container-columns'}>
                    <label htmlFor={'mintProcess-type'} className={'form-label'}>Process Type</label>
                    <select name="process-type" className={'form-input'} id="process-type" ref={processType}
                            onChange={e => setSelectedProcessType(parseInt(e.target.value))}>
                        <option value={PROCESS_TYPE.FULL_SET_MINT} disabled={false}>Mint Set</option>
                        <option value={PROCESS_TYPE.ITEM_MINT} disabled={true}>Mint Item (to be added)</option>
                        <option value={PROCESS_TYPE.ITEM_UNPACK} disabled={false}>Unpack Item</option>
                        <option value={PROCESS_TYPE.ITEM_ROOKIE_UNPACK} disabled={true}>Unpack Rookie (to be added)
                        </option>
                    </select>
                </div>
                {selectedProcessType === PROCESS_TYPE.FULL_SET_MINT || selectedProcessType === PROCESS_TYPE.ITEM_MINT ?
                    <div className={'form-row flex-container-columns'}>
                        <label htmlFor={'mintProcess-id'} className={'form-label'}>Process ID</label>
                        <input id={'mintProcess-id'} ref={processId} className={'form-input'} type={'text'}
                               placeholder={'AAAAAAAA-BBBB-CCCC-DDDD-EEEEFFFFGGGG'}/>
                    </div>
                    : <div className={'form-row flex-container-columns'}>
                        <label htmlFor={'unpackTx-id'} className={'form-label'}>Unpack Tx</label>
                        <input id={'unpackTx-id'} ref={unpackTx} className={'form-input'} type={'text'}
                               placeholder={'0.0.123456@1698975831.065718138'}/>
                    </div>
                }
                <div className={'form-row actions'}>
                    <button type="submit" className={'form-input button btn primary'}>Find process</button>
                </div>
            </form>
            {mintProcess && mintProcess.processId !== null ? <ResolveProcessForm foundProcess={mintProcess}/> : null}
            {unpackProcess && unpackProcess.txId !== null ?
                <ResolveUnpackProcessForm unpackProcess={unpackProcess}/> : null}
        </>
    );
}

export const MenuResolverModal = (props) => {
    const [openCardMintModal, setOpenCardMintModal] = useState(false);

    const closeCardMintModal = () => {
        setOpenCardMintModal(false)
    };
    return (
        <Popup className={'resolver-popup history-popup'}
               trigger={
                   <a className={'menu-link'}>
                       <span className={"desktop"} style={{color: '#8e9fc4', cursor: 'pointer'}}><CgBandAid/></span>
                       <span className={"mobile"} style={{color: '#8e9fc4', cursor: 'pointer'}}>RESOLVER</span>
                   </a>
               }
               modal
               closeOnDocumentClick={true}
               open={openCardMintModal}
               onClose={closeCardMintModal}
        >
            {close => (
                <div id='mint-from-inventory-modal' className="modal">
                    <button
                        className={`close `}
                        onClick={() => {
                            close();
                        }}>
                        &times;
                    </button>

                    <div className={'content-after-x'}>
                        <ResolverForm/>
                    </div>
                </div>
            )}
        </Popup>
    );
}