export class PrepSetNft {
    constructor({data, signingAcct}) {
        this.serials = data.serialsArray;
        this.lastStep = data.lastStep;
        this.processId = data.processUUID;
        this.feeTxId = data.feeTxId;
        this.fullSetCID = data.fullSetCID;
        this.cardImageCID = data.cardImageCID;
        this.generatedId = data.generatedId;
        this.metadataCID = data.metadataCID;
        this.damages = data.damages;
        this.signer = signingAcct;
    }

    setSerialBytes(bytes) {
        this.serialBytes = bytes;
    }
}