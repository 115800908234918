import {ContractExecuteTransaction, ContractFunctionParameters} from "@hashgraph/sdk";
import {getConfigFor} from "../ContractParameters";
import {makeBytes, sendTransaction} from "../hashconnect";
import axios from "axios";
import {createCurrencyAllowance, createGovTokenAllowance} from "../../generateAllowanceTransactions";
import {appendNewLog} from "../../../component/PopUpProcessLog/PopUpProcessLog";
import {LOG_STATUS, PROCESS_STATUS} from "../../../helper/ENUMS.ts";

export const createRewardDepositTx = async (amount, signingAcct) => {
    const callConfig = await getConfigFor('CREATE_REWARD_DEPOSIT');

    const depositTx = await createCurrencyAllowance(
        signingAcct,
        callConfig.contractId,
        amount)
        .then((allowanceGiven) => {
            if (allowanceGiven) {
                return new ContractExecuteTransaction()
                    .setContractId(callConfig.contractId)
                    .setGas(callConfig.gasLimit)
                    .setFunction(callConfig.contractFunc,
                        new ContractFunctionParameters()
                            .addInt64(amount)
                    );
            } else {
                return null;
            }
        }).catch((x) => {
            console.log("error in createCurrencyAllowance", x.status, x.error);
            console.error(x);
        });

    let transactionBytes = await makeBytes(await depositTx, signingAcct);
    return await sendTransaction(transactionBytes, signingAcct, false, true);
}

export const createGovPoolExitTx = async (signingAcct) => {

    const callConfig = await getConfigFor('EXIT_GOV_POOL');

    const depositTx = await   new ContractExecuteTransaction()
        .setContractId(callConfig.contractId)
        .setGas(callConfig.gasLimit)
        .setFunction(callConfig.contractFunc);

    let transactionBytes = await makeBytes(await depositTx, signingAcct);
    return await sendTransaction(transactionBytes, signingAcct, false, true);
};

export const createGovPoolEntryTx = async (amount, signingAcct) => {
    const callConfig = await getConfigFor('ENTER_GOV_POOL');

    const depositTx = await createGovTokenAllowance(
        signingAcct,
        callConfig.contractId,
        amount)
        .then((allowanceGiven) => {
            if (allowanceGiven) {
                return new ContractExecuteTransaction()
                    .setContractId(callConfig.contractId)
                    .setGas(callConfig.gasLimit)
                    .setFunction(callConfig.contractFunc,
                        new ContractFunctionParameters()
                            .addInt64(amount)
                    );
            } else {
                return null;
            }
        }).catch((x) => {
            console.log("error in createGovTokenAllowance", x.status, x.error);
            console.error(x);
        });


    let transactionBytes = await makeBytes(await depositTx, signingAcct);
    return await sendTransaction(transactionBytes, signingAcct, false, true);
}

export const pullGovRewardsTx = async (signingAcct) => {
    const callConfig = await getConfigFor('PULL_GOV_REWARDS');

    const depositTx = await   new ContractExecuteTransaction()
                    .setContractId(callConfig.contractId)
                    .setGas(callConfig.gasLimit)
                    .setFunction(callConfig.contractFunc);

    let transactionBytes = await makeBytes(await depositTx, signingAcct);
    return await sendTransaction(transactionBytes, signingAcct, false, true);
}

