// import React from 'react';
import React, {useEffect, useRef, useState} from "react";
// import 'rc-slider/assets/index.css';
import './Investor.css';
import {
    createGovPoolEntryTx,
    createGovPoolExitTx,
    createRewardDepositTx,
    pullGovRewardsTx
} from "../../connector/blockchain/Hedera/investorTx";
import axios from "axios";
import {getAccountIds} from "../../connector/blockchain/hashconnect";
import {GovTokenChartComponent} from "../../component/Chart/ChartComponent";
import {CurrencyIcon, GovIcon} from "../../App";

const processRewardDeposit = (amount) => {

    const signingAcct = getAccountIds()[0];
    createRewardDepositTx(amount, signingAcct).then((res) => {

        if (res === undefined || res === null || !res.success) {
            alert("error");
        }
        const urlHost = process.env.REACT_APP_BACK_END_HOST;

        axios.post(urlHost + "/investments/store-payout-deposit", {
            txId: res.response.transactionId,
        }, {
            headers: {
                'Content-Type': "application/json",
                'Accept': "application/json",
                // "Access-Control-Allow-Origin": "*",
            },
            responseType: "json",
        }).then((storePayoutResponse) => {
            const {amount, timestamp, allocation} = storePayoutResponse.data;

        }).catch((err) => {
            alert(err.message);
            return;
        });
    }).catch(
        // todo
    );
}

const processGovPoolEntry = (amount) => {

    const signingAcct = getAccountIds()[0];
    createGovPoolEntryTx(amount, signingAcct).then((res) => {

        if (res === undefined || res === null || !res.success) {
            alert("error");
        }
        const urlHost = process.env.REACT_APP_BACK_END_HOST;

        axios.post(urlHost + "/investments/store-lockup-entry", {
            txId: res.response.transactionId,
        }, {
            headers: {
                'Content-Type': "application/json",
                'Accept': "application/json",
                // "Access-Control-Allow-Origin": "*",
            },
            responseType: "json",
        }).then((storePayoutResponse) => {
            const {amount, timestamp, allocation} = storePayoutResponse.data;

        }).catch((err) => {
            alert(err.message);
            return;
        });
    }).catch(
        // todo
    );
}
const processGovPoolExit = () => {

    const signingAcct = getAccountIds()[0];
    createGovPoolExitTx(signingAcct).then((res) => {

        if (res === undefined || res === null || !res.success) {
            alert("error");
        }
        const urlHost = process.env.REACT_APP_BACK_END_HOST;

        axios.post(urlHost + "/investments/exit-lockup", {
            txId: res.response.transactionId,
        }, {
            headers: {
                'Content-Type': "application/json",
                'Accept': "application/json",
                // "Access-Control-Allow-Origin": "*",
            },
            responseType: "json",
        }).then((storePayoutResponse) => {
            alert("pool exited");
        }).catch((err) => {
            alert(err.message);
            return;
        });
    }).catch(
        // todo
    );
}

const RewardManagement = () => {

    const [rewardToDump, setRewardToDump] = useState(0);
    const updateReward = (newValue) => {
        setRewardToDump(newValue);
    }

    function handleChange(event) {
        setRewardToDump(event.target.value);
    }

    return (
        <div className={'management-only'}>
            <input type={'number'} onChange={handleChange}/>
            <label>{rewardToDump}</label>

            <button onClick={() => {
                processRewardDeposit(rewardToDump);
            }}>Add into reward pool
            </button>
        </div>
    );


}

const GovPoolComponent = () => {
    const [entryAmount, setEntryAmount] = useState(0);

    function handleChange(event) {
        setEntryAmount(event.target.value);
    }

    return (
        <>
            <div className={'enter-gov-pool-form'}>
                <input type={'number'} onChange={handleChange}/>
                <label>{entryAmount}</label>

                <button onClick={() => {
                    processGovPoolEntry(entryAmount);
                }}>Commit into reward pool
                </button>
            </div>
            <div className={'exit-gov-pool-form'}>
                <button onClick={() => {
                    processGovPoolExit();
                }}>
                    Exit reward pool
                </button>
            </div>
        </>
    );
}

const WithdrawRewardsComponent = () => {
    const queueForRewardPullAction = () => {
        const signingAcct = getAccountIds()[0];
        pullGovRewardsTx(signingAcct).then((res) => {

            if (res === undefined || res === null || !res.success) {
                alert("error");
            }
            const urlHost = process.env.REACT_APP_BACK_END_HOST;

            axios.post(urlHost + "/investments/store-lockup-entry", {
                txId: res.response.transactionId,
            }, {
                headers: {
                    'Content-Type': "application/json",
                    'Accept': "application/json",
                    // "Access-Control-Allow-Origin": "*",
                },
                responseType: "json",
            }).then((storePayoutResponse) => {
                const {amount, timestamp, allocation} = storePayoutResponse.data;

            }).catch((err) => {
                alert(err.message);
                return;
            });
        }).catch(
            // todo
        );
    }

    return (
        <button onClick={() => queueForRewardPullAction()}>WITHDRAW REWARDS</button>
    );
}

let rewardSum = 0;
let lastRewardObj = {};

function handleRewardSumIncrease(increaseAmount) {
    rewardSum += increaseAmount;
}

function handleLastRewardObjUpdate(newRewardObj) {
    lastRewardObj = newRewardObj;
}

const RewardsListComponent = ({setLastPayoutObj}) => {
    const [rewards, setRewards] = useState([]);
    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const getRewards = () => {
        const urlHost = process.env.REACT_APP_BACK_END_HOST;
        return axios.get(urlHost + "/investments/rewards", {
            headers: {
                'Content-Type': "application/json",
                'Accept': "application/json",
                // "Access-Control-Allow-Origin": "*",
            },
            responseType: "json",
        }).then((rewardsResponse) => {
            const {rewards} = rewardsResponse.data;
            setRewards(rewards);
            return rewards;
        }).catch((err) => {
            alert(err.message);
            return;
        });
    }

    useEffect(() => {
        getRewards().then((rew) => {
            handleLastRewardObjUpdate(rew[rew.length - 1]);
            new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
                scrollToBottom();
                setLastPayoutObj(rew[rew.length - 1]);
                console.log('rewardSum',rewardSum, lastRewardObj   );
            });
        });
    }, []);

    return (
        <div className={'rewards-list flex-container-columns'}>
                {rewards.map((reward, index) => {
                    // {
                    //     bxKey: bx_key,
                    //     amount: reward_amount,
                    //     poolAtRecord: pool_at_record,
                    //     timestamp: reward_timestamp,
                    //     user: {
                    //         address: userAddressInSolidity,
                    //         poolOnRecord: pool_on_record,
                    //         shareOnRecord: tokens_deposited,
                    //     }
                    // }
                    if (reward.user !== null) {
                        handleRewardSumIncrease(reward.user.shareOnRecord / (reward.poolAtRecord / 100) / 100 * reward.amount);
                    }
                    return (
                        <div className={'investor-payout-sample flex-container-rows'} id={`payout-sample-key-${reward.bxKey}`}>
                            <div className={'investor-payout-sample-timestamp flex-container-columns'}>
                                <span className={'reward-timestamp'}>{new Date(reward.timestamp).toUTCString()}</span>
                            </div>
                            <div className={'investor-payout-sample-amount flex-container-columns'}>
                                <label className={'amount-label label'}
                                       for={`reward-payout-${reward.bxKey}`}>Payout (total):&nbsp;
                                </label>
                                <span
                                    id={`reward-payout-${reward.bxKey}`}
                                    name={`reward-payout-${reward.bxKey}`}
                                    className={'reward-payout flex'}>{parseFloat(reward.amount/100).toFixed(2)}<CurrencyIcon
                                    size={32}/></span>
                            </div>
                            <div className={'investor-payout-sample-eligible flex-container-rows'}>
                                <div className={'investor-pool-share flex-container-columns'}>

                                    <label className={'eligible-label label'}> Pool share (?):</label>
                                    <span className={'reward-eligible'}>
                                {reward.user !== null ? `${((reward.user.shareOnRecord / (reward.poolAtRecord / 100))).toFixed(4)}%` : '--'}
                            </span>
                                </div>
                                <div className={'investor-pool-reward flex-container-columns'}>
                                    <label className={'eligible-label label'}> Potential reward (?):</label>
                                    <span className={'reward-eligible'}>
                                {reward.user !== null ? `${((reward.user.shareOnRecord / (reward.poolAtRecord / 100) / 100 * reward.amount)/100).toFixed(2)}` : '--'}
                                        <CurrencyIcon size={16}/>
                                </span>
                                </div>
                            </div>
                        </div>
                    )
                        ;
                })}

            <div ref={messagesEndRef} className={'scroller'} />


        </div>
    );
}

const TokenInfoComponent = ({lastPayoutObj, lastRealPERatio, lastTheoreticPERatio}) => {
    if (lastPayoutObj.amount === null || lastPayoutObj.amount === undefined) {
        return (
            <div className={'token-info flex-container-rows'}>
                <div className={'token-info-header flex-container-rows'}>
                    <h3>Token info: </h3>
                </div>
            </div>
        );
    }
    return (
        <div className={'token-info flex-container-rows'}>
            <div className={'token-info-header flex-container-rows'}>
                <h3>Token info: </h3>
            </div>
            <div className={'token-info-container flex-container-rows'}>
                <div className={'token-info-container-row flex-container-rows'}>
                    <div className={'token-info-container-row-label flex-container-rows'}>
                        <label className={'label'}>Last payout:</label>
                    </div>
                    <div className={'token-info-container-row-value flex-container-rows'}>
                        <span className={'value'}>{parseFloat(lastPayoutObj.amount/100).toFixed(2)}<CurrencyIcon size={32}/></span>
                    </div>
                </div>
                <div className={'token-info-container-row flex-container-rows'}>
                    <div className={'token-info-container-row-label flex-container-rows'}>
                        <label className={'label'}>Last payout PE ratio (based on pool alloc):</label>
                    </div>
                    <div className={'token-info-container-row-value flex-container-rows'}>
                        <span className={'value'}>{lastRealPERatio.toFixed(2)}</span>
                    </div>
                </div>
                <div className={'token-info-container-row flex-container-rows'}>
                    <div className={'token-info-container-row-label flex-container-rows'}>
                        <label className={'label'}>Last theoretic PE ratio (based on token supply):</label>
                    </div>
                    <div className={'token-info-container-row-value flex-container-rows'}>
                        <span className={'value'}>{lastTheoreticPERatio.toFixed(2)}</span>
                    </div>
                </div>
                <div className={'token-info-container-row flex-container-rows'}>
                    <a href={'/store'} target={"_blank"} className={'buy-gov-token'}> <button className={'btn button buy-gov-token'}> BUY BNCP <GovIcon size={32}/></button></a>
                </div>
            </div>
        </div>
    );
}

const Investor = () => {

    const [lastPayoutObj, setLastPayoutObj] = useState({});
    const [lastRealPERatio, setLastRealPERatio] = useState(0);
    const [lastTheoreticPERatio, setLastTheoreticPERatio] = useState(0);

    useEffect(() => {
        setLastRealPERatio(0.035/ (lastPayoutObj.amount/100/lastPayoutObj.poolAtRecord));
        setLastTheoreticPERatio(0.035/ (lastPayoutObj.amount/100/21_000_000));

    } , [lastPayoutObj]);

    return (
        <div id={'investor-page'} className={'page-contents'}>
            <div className={'page-container'}>
                <div className={'rewards-list-header flex-container-rows'}>
                    <h3>Previous payouts: </h3>
                </div>
                <div className={'rewards-list-container flex-container-rows'}>
                    <RewardsListComponent setLastPayoutObj={setLastPayoutObj} />
                    <RewardManagement/>
                </div>
                <div className={'gov-token-main-container flex-container-columns'}>

                    <div className={'gov-token-chart-container'}>
                        <GovTokenChartComponent/>
                    </div>
                    <div className={'got-token-mngmnt-container flex-container-rows'}>
                        <TokenInfoComponent lastRealPERatio={lastRealPERatio} lastTheoreticPERatio={lastTheoreticPERatio} lastPayoutObj={lastPayoutObj}/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <GovPoolComponent/>
                        <br/>
                        <WithdrawRewardsComponent/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Investor;