import React, {useEffect, useRef, useState} from 'react';
import 'swiper/css';
import './ArenaPickerPage.css';
import InventoryRightSide from "../inventory/sides/inventoryRightSide";
import {ARENA_POOLS} from "../../helper/ENUMS.ts";
import {CurrencyIcon} from "../../App";
import Popup from "reactjs-popup";
import axios from "axios";
import {getConfigFor} from "../../connector/blockchain/ContractParameters";
import {
    AccountAllowanceApproveTransaction,
    ContractExecuteTransaction,
    ContractFunctionParameters,
    NftId,
    TokenId
} from "@hashgraph/sdk";
import {
    getAccountIds,
    makeBytes,
    sendAllowanceTransaction,
    sendTransaction
} from "../../connector/blockchain/hashconnect";
import {TbInfoSquareRoundedFilled} from "react-icons/tb";
import {useNavigate} from 'react-router-dom';
import PassiveEntryModal from "./PassiveEntryModal";
import ActiveEntryModal from "./ActiveEntryModal";
import {FaQuestionCircle} from "react-icons/fa";
import {MdOutlineDoNotDisturbOn, MdWatchLater} from "react-icons/md";
import {CgSandClock} from "react-icons/cg";
import {formatYVCurrency} from "../../connector/adapter/currency";
import ToolTip from "../../component/Modal/ToolTip";
import {SiHedera} from "react-icons/si";
import {getSocketPoolInfo, requestPoolInfoUpdate} from "../../connector/socket/socketInit";
import MyCustomEventEmitter from "../../component/myCustomEvents";


let setActivePoolQueue, setPassivePoolQueue;
let activePoolQueue = [], passivePoolQueue = [];

const poolFees = [10, 50, 500, 'UNKNOWN'];
const poolRewards = [19, 99, 499, 'UNKNOWN'];
// int64[3] internal ARENA_ENTRY_FEES = [int64(10), int64(50), int64(500)];
// int64[3] internal ARENA_WINNING_FEES = [int64(19), int64(99), int64(499)];
const poolLock = [true, false, true, true];
let poolType, setPoolType, clickedPoolType, setClickedPoolType;

const isPoolLocked = (poolTypeInQuestion) => {
    return poolLock[poolTypeInQuestion];
}

export const getPoolFees = () => {
    return poolFees;
}
export const getPoolFee = (poolType) => {
    return poolFees[poolType];
}

const ArenaPortal = ({
                         portalPoolType,
                         passivePopupTrigger,
                         activeModalTrigger,
                         onPoolSelect,
                         selectedSet,
                         dataFromServer
                     }) => {
    console.log("dataFromServer", dataFromServer);
    const hasBanana = selectedSet !== undefined && selectedSet !== null && selectedSet.itemCards !== undefined && selectedSet.itemCards[0] !== null;

    if (isPoolLocked(portalPoolType) && portalPoolType === ARENA_POOLS.CUSTOM) {
        return (
            <div className={`portal disabled`}
            ><h4>{ARENA_POOLS[portalPoolType]}</h4>
                <div className={'info'}><span className={'reward'}>{poolFees[portalPoolType]}</span></div>
            </div>
        );
    } else {
        return (
            <div
                className={`portal ${ARENA_POOLS[portalPoolType].toLowerCase()} ${clickedPoolType === portalPoolType ? 'selected' : ''} ${isPoolLocked(portalPoolType) ? 'disabled' : ''}`}
                onClick={() => {
                    if (!isPoolLocked(portalPoolType)) {
                        setClickedPoolType(portalPoolType)
                    }
                }}><h4 className={'pool-title'}>{ARENA_POOLS[portalPoolType]}</h4>
                <div className={'info flex-container-rows'}>
                    <div className={'fees-wrapper'}>

                        <div className={'fees'}>
                            <div className={'fee-container flex-container-columns'}>
                                <span className={'label'}>Participant's fee: </span>
                                <span className={'content'}>{formatYVCurrency(poolFees[portalPoolType])}</span>
                                <CurrencyIcon size={32}/>
                            </div>
                            <div className={'reward-container flex-container-columns'}>
                                <span className={'label'}>Winner's payout: </span>
                                <span className={'content'}>{formatYVCurrency(poolFees[portalPoolType] * 1.9)}</span>
                                <CurrencyIcon
                                    size={32}/>
                            </div>
                        </div>

                        <ToolTip trigger={<div className={'info-tooltip'}><FaQuestionCircle/></div>}
                                 key={`${ARENA_POOLS[portalPoolType]}_fees`} position={'bottom right'}
                            // keepInside={`.portal.${ARENA_POOLS[portalPoolType].toLowerCase()}`}
                                 keepInside={true}
                                 content={<div className={'portal-fee-tooltip flex-container-rows'}><p><span>The participant's fee</span> is
                                     the amount you pay to enter the duel.
                                     On <span><SiHedera/> HEDERA</span> chain <span>95%</span>&nbsp;
                                     goes to the <span>winner's payout</span>.</p>
                                     <p>Remaining <span>5%</span> is retained as a duel tx & processing fee. Players
                                         only pay for a fair matchmaking event, a separate transaction from the
                                         duel itself.</p><p>By separating these transactions, we can ensure that
                                         execution
                                         costs remain relatively
                                         equal for players who find a match or only queue for one.</p>
                                     <p>Like the entry fee, <span>The winner's payout</span> varies based on Pool Rank.
                                         On <span><SiHedera/> HEDERA</span> blockchain it amounts
                                         to ~<span>x1.9</span> of the participant's fee.</p></div>}/>
                    </div>

                    <div className={'icon-container'}/>
                    <div className={'pool-info-container'}>
                        <div className={'active-container flex-container-rows'}>
                            <h5 className={'label'}>
                                {!isPoolLocked(portalPoolType) ? <>&sim;<span
                                    className={`value${dataFromServer.activePoolSize === null ? 'less' : ''}`}>
                                    {/*{(activePoolQueue[portalPoolType] * (Math.random() * 100).toFixed(0))}</span></> : <>--</>} bananas ready to rumble*/}
                                    {dataFromServer.activePoolSize === null ?
                                        <CgSandClock/> : dataFromServer.activePoolSize}</span></> : <>--</>} bananas
                                ready to rumble
                            </h5>
                            <button
                                className={`btn btn-primary ${!hasBanana ? 'disabled' : ''}`}
                                onClick={() => {
                                    if (hasBanana && !isPoolLocked(portalPoolType)) {
                                        onPoolSelect(portalPoolType);
                                        activeModalTrigger();
                                    }
                                }}
                                disabled={isPoolLocked(portalPoolType) || !hasBanana}
                            > {hasBanana ? 'Start search' : 'no Banana :('}
                            </button>
                        </div>
                        {/*<div className={'passive-container flex-container-rows'}>
                            <h5 className={'label'}>{!isPoolLocked(portalPoolType) ? <>&sim;<span
                                className={`value${dataFromServer.passivePoolSize === null ? 'less' : ''}`}>{dataFromServer.passivePoolSize === null ?
                                <CgSandClock/> : dataFromServer.passivePoolSize}</span></> : <>--</>} bananas
                                seeking their half</h5>

                            <button
                                className={`btn btn-primary ${!hasBanana ? 'disabled' : ''}`}
                                onClick={() => {
                                    if (hasBanana && !isPoolLocked(portalPoolType)) {
                                        onPoolSelect(portalPoolType);
                                        passivePopupTrigger();
                                    }
                                }}
                                disabled={isPoolLocked(portalPoolType) || !hasBanana}
                            > {hasBanana ? 'Pool banana' : 'Missing banana :('}
                            </button>
                        </div>*/}
                    </div>

                    <br/>
                </div>
            </div>
        );
    }

}

const LeftSideArenaPicker = () => {

    const [selectedPool, setSelectedPool] = useState(ARENA_POOLS.LOW);
    [clickedPoolType, setClickedPoolType] = useState(ARENA_POOLS.LOW);
    [poolType, setPoolType] = useState(ARENA_POOLS.LOW);

    const [openPassiveModal, setOpenPassiveModal] = useState(false);
    const [openActiveModal, setOpenActiveModal] = useState(false);

    [activePoolQueue, setActivePoolQueue] = useState([0, 0, 0, 0]);

    const closePassiveModal = () => setOpenPassiveModal(false);
    const closeActiveModal = () => setOpenActiveModal(false);

    const passiveModalTrigger = () => {
        setOpenPassiveModal(o => !o);
    }
    const activeModalTrigger = () => {
        setOpenActiveModal(o => !o);
    }

    const [poolDataFromServer, setPoolDataFromServer] = useState({
        0: {
            0: {passivePoolSize: null, activePoolSize: null, updatedOn: null},
            1: {passivePoolSize: null, activePoolSize: null, updatedOn: null},
            2: {passivePoolSize: null, activePoolSize: null, updatedOn: null},
        },
        1: {}
    });
    const currentBlockchain = 0;

    useEffect(() => {
        const urlHost = process.env.REACT_APP_BACK_END_HOST;
        axios.get(urlHost + "/arena/pools-data", {}, {
            headers: {
                'Content-Type': "application/json",
                'Accept': "application/json",
                // "Access-Control-Allow-Origin": "*",
            },
            responseType: "json",
        }).then((response) => {
            console.log("poolsData", response)
            setPoolDataFromServer(response.data.pools);
        }).catch((error) => {
            console.error("poolsData", error);
        });

    }, []);

    // todo we will convert to live data from socket later
    /*
    useEffect(() => {
        setPoolDataFromServer(getSocketPoolInfo());
        console.log("poolDataFromServer", poolDataFromServer);
        MyCustomEventEmitter.subscribe("poolInfoUpdated", () => {
            console.log("poolInfoUpdated event received");
            // poolInfoLastUpdated = `${Date.now().toLocaleString()}0`;
            setPoolDataFromServer(getSocketPoolInfo());
        });
    }, []);
    */
    const poolTypeArray = [ARENA_POOLS.WOOD, ARENA_POOLS.SILVER, ARENA_POOLS.GOLD];
    const [poolTypeIndex, setPoolTypeIndex] = useState(1);
    const handlePoolTypeIncrement = () => {
        if (poolTypeIndex < 2) {
            setPoolTypeIndex(poolTypeIndex + 1);
        }
        if (poolTypeIndex === 2) {
            setPoolTypeIndex(0);
        }
    }
    const handlePoolTypeDecrement = () => {
        if (poolTypeIndex > 0) {
            setPoolTypeIndex(poolTypeIndex - 1);
        }
        if (poolTypeIndex === 0) {
            setPoolTypeIndex(2);
        }
    }

    return (<div id={'left-side'}>
        <div id={'vertical-pre-wrap'}/>
        <div id={'portal-outer-container'} className={'flex-container-rows'}>
            <div id={'portal-picker-default-container'} className={'flex-container-columns container '}>
                {poolTypeArray.map((poolType, index) => {
                    return (<>
                            {index > 0 ? <div className={'vertical-divider'}/> : null}
                            <ArenaPortal portalPoolType={poolType} passivePopupTrigger={passiveModalTrigger}
                                         activeModalTrigger={activeModalTrigger}
                                         onPoolSelect={setSelectedPool}
                                         selectedSet={selectedSet}
                                         dataFromServer={poolDataFromServer[currentBlockchain][poolType]}/>
                        </>
                    )
                })}
            </div>
            <div id={'portal-picker-mobile-container'} className={'flex-container-columns container'}>
                <button className="arrows arrow-left" onClick={handlePoolTypeDecrement}></button>
                <ArenaPortal portalPoolType={poolTypeArray[poolTypeIndex]}
                             passivePopupTrigger={passiveModalTrigger}
                             activeModalTrigger={activeModalTrigger}
                             onPoolSelect={setSelectedPool}
                             selectedSet={selectedSet}
                             dataFromServer={poolDataFromServer[currentBlockchain][poolTypeArray[poolTypeIndex]]}/>
                <button className="arrows arrow-right" onClick={handlePoolTypeIncrement}></button>
            </div>
        </div>
        <PassiveEntryModal openModal={openPassiveModal} closeOnDocumentClick closeModal={closePassiveModal}
                           selectedSet={selectedSet} selectedPool={selectedPool}/>
        <ActiveEntryModal openModal={openActiveModal} closeOnDocumentClick closeModal={closeActiveModal}
                          selectedSet={selectedSet} selectedPool={selectedPool}/>
        <div id={'vertical-post-wrap'}/>

    </div>);
}


let carouselGlobalSet, setCarouselGlobalSet;
let selectedSet, setSelectedSet;
let carouselGlobalIndex, setCarouselGlobalIndex;

const ArenaPickerPageOld = () => {

    [selectedSet, setSelectedSet] = useState({});

    return (<div id={'arena-picker-page'}>
        <div id={'main'}>
            <LeftSideArenaPicker/>
            <InventoryRightSide setSelectedSet={setSelectedSet} showPalm={true}
                                showActionButton={false}/>
        </div>
        <div id={'footer'}>
            {/*{`not footer technically`}*/}
            <div id={'ground'}/>
        </div>

        <img className='coco_arena' src={'/assets/coco-arena-picker.png'} id={'coco'}/>
        {/*<button onClick={openArenaConfirmation()}> TO BATTLE! </button>*/}
    </div>)
};

export default ArenaPickerPageOld;
