import {FaQuestionCircle} from "react-icons/fa";
import {FACTION, SET_PIECE} from "../../../helper/ENUMS.ts";
import ToolTip from "../../../component/Modal/ToolTip";
import React from "react";
import './SeasonalFactionContentToolTip.css';
import {getAssetHostCDN} from "../../../helper/externalLinks";

const assetsHost = getAssetHostCDN();
const SeasonalFactionContentToolTip = ({ faction, season = 0, trigger }) => {
    const factionName = FACTION[faction].toLowerCase();
    const factionSets = [1, 2, 3];
    const setPieceOrder = [SET_PIECE.SKIN, SET_PIECE.WEAPON, SET_PIECE.BODY, SET_PIECE.HEAD];

    
    



    const tooltipContent = <div className={'store-popup-container flex-container-rows'}>
        <div className="store-popup-text">
            <div className={`popup-text-color-${FACTION[faction].toLowerCase()}`} id="textContent">{FACTION [faction]}</div>
            <div className="popup-text">faction dolor sit amet</div>
        </div>
        <div class="store-popup-driver"></div>
        <div className={'faction-box-showcase container main flex-container-columns'}>
            {setPieceOrder.map(setPiece => {
                const setPieceName = SET_PIECE[setPiece].toLowerCase();
                return (
                    <div className={`faction-box-showcase container ${setPieceName} flex-container-rows`} key={setPieceName}> 
                        {factionSets.map(num =>
                        <>
                            <div className="background-store-popup">
                                <div className="faction-box-showcase" style={{backgroundImage: `url("${assetsHost}/all-skins-integration/season_${season}/set_season_${season}_${setPieceName}_${factionName}_${num}.png")`}}
                                    key={num}>

                                </div>
                            </div>
                            <div className="popup-bottom-border"></div>
                        </>
                        )}
                        </div>
                    
                    
            
                );
            })}
        </div>
    </div>;


    return (
        <ToolTip trigger={trigger}
                 key={`small-lootbox-help-${factionName}`} content={tooltipContent}
                 // keepInside={'.right-side-content'}
                 position={['left top', 'right top', 'left bottom', 'right bottom']}
        />
    );
}

export default SeasonalFactionContentToolTip;