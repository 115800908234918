import React, {useState} from 'react';

import './Shop.css';
import YVCurrencyManagement from "../../component/YVCurrencyManagement/YVCurrencyManagement";
import FactionBoxOpener from "./FactionBoxOpener/FactionBoxOpener";
import RookieBoxOpener from "./RookieBoxOpener/RookieBoxOpener";
import {assetsHost} from "../../helper/externalLinks";
import {MODAL_TYPE} from "../../helper/ENUMS.ts";
import UnpackModal from "../../component/Modal/UnpackModal";

const Shop = () => {
    const [unpackingFaction, setUnpackingFaction] = useState(0);
    const [unpackingModalOpenState, setUnpackingModalOpenState] = useState(false);
    const userOpenedFactionToolTip = (faction) => {
        setUnpackingFaction(faction);
    }
    const contentLock = process.env.REACT_APP_NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'testnet-vercel';

    return (
        <div id={'new-shop-page'} className={'page full-width'}>
            <div id={`currency-management`} className={`container-1600`}>
                <YVCurrencyManagement/>
            </div>
            <div className={`container-1600 divider`}></div>
            {contentLock ?
                <>
                    <div id={`shop-items`} className={`container-1600 flex-container-rows`}>
                        <h2>FACTION BOX</h2>
                        <div id={`faction-box-row`} className={'flex-container-columns flex-row'}>
                            <FactionBoxOpener setUnpackingFaction={setUnpackingFaction}
                                              setUnpackingModalOpenState={setUnpackingModalOpenState}
                                              faction={{index: 0, key: `red`}}/>
                            <FactionBoxOpener setUnpackingFaction={setUnpackingFaction}
                                              setUnpackingModalOpenState={setUnpackingModalOpenState}
                                              faction={{index: 1, key: `green`}}/>
                            <FactionBoxOpener setUnpackingFaction={setUnpackingFaction}
                                              setUnpackingModalOpenState={setUnpackingModalOpenState}
                                              faction={{index: 2, key: `blue`}}/>
                            <UnpackModal
                                modalType={MODAL_TYPE.UNPACK_BOX}
                                modalTitle={'Unpacking item'}
                                // itemCard={useState(null)}
                                buttonContent={null}
                                buttonFunctionParams={unpackingFaction}
                                // isFinished={unpackIsFinished}
                                openState={[unpackingModalOpenState, setUnpackingModalOpenState]}
                                disabled={false}
                            />
                        </div>
                    </div>
                    <div className={`container-1600 divider`}></div>
                    <div id={`shop-rookie`} className={`container-1600 flex-container-rows`}>
                        <h2>SPECIAL OFFERS</h2>
                        <div id={`rookie-box-row`} className={'flex-container-rows flex-row'}>
                            <RookieBoxOpener/>
                        </div>
                    </div>
                </> : <><div id={`shop-items`} className={`container-1600 flex-container-rows`}>
                    <h2>FACTION BOX PURCHASE TO BE OPEN SOON...</h2></div></>}
            <div className={`footer-spacer`}></div>
            <div className={`footer`}><img id={`coco-seller`} className={`coco`}
                                           src={`${assetsHost}/assets/shop/cocount.png`}/></div>
        </div>
    );
}

export default Shop;