import {AccountAllowanceApproveTransaction, NftId, TokenId} from "@hashgraph/sdk";
import {makeBytes, sendAllowanceTransaction} from "./blockchain/hashconnect";
import {getConfigFor} from "./blockchain/ContractParameters";
import {getUSDCurrencyAddress, getYVCurrencyAddress} from "../helper/mirrrorNode";
import {
    getAccountIdWithWaitV3,
    getSigner,
    sendTransactionV3
} from "../component/WalletConnectComponent/WalletConnectComponentV3";

/**
 * The number of decimal places for a YV Currency Token.
 * @type {number}
 */
export const yvcDecimals = 4; // TODO: get this from the contract
/**
 * The multiplier for converting a YV amount to a "tinybar" amount.
 * @type {number}
 */
export const yvcMod = 10 ** yvcDecimals;

/**
 * Create an allowance for a specified account.
 *
 * @async
 * @param {string} signingAcct - The account that will sign the allowance transaction.
 * @param {string} spenderContractIdString - The ID of the contract that will spend the allowance.
 * @param {number} amount - The amount of currency that will be allowed.
 * @param {Array<string>} [serials=null] - The serial numbers of the NFTs for which to approve an allowance.
 *                                          This parameter is optional, and if not provided, no NFT allowance will be created.
 * @param {string} [collectionName=null] - The name of the NFT collection. This parameter is optional,
 *                                         and if not provided, it will not affect the currency allowance.
 * @returns {Promise<boolean>} Returns a Promise that resolves to a boolean indicating whether the allowance transaction was successful.
 */
export const createAllowance = async (
    signingAcct,
    spenderContractIdString,
    amount,
    serials = null,
    collectionName = null
) => {
    const currencyId = (await getConfigFor('YV_CURRENCY_TOKEN')).contractId;

    let allowanceTx = new AccountAllowanceApproveTransaction();

    // if ((await getAccountTokenAllowance(signingAcct, spenderContractIdString)).amount < amount) {
    allowanceTx = allowanceTx.approveTokenAllowance(currencyId, signingAcct, spenderContractIdString, amount);
    // }

    if (serials && collectionName) {
        for (let i = 0; i < serials.length; i++) {
            const nftId = new NftId(
                TokenId.fromString((await getConfigFor(collectionName)).contractId),
                serials[i]
            );
            allowanceTx = allowanceTx.approveTokenNftAllowance(
                nftId,
                signingAcct,
                spenderContractIdString
            );
        }
        // allowanceTx = allowanceTx.approveHbarAllowance(signingAcct, spenderContractIdString, 1.4);
        // allowanceTx = allowanceTx.approveHbarAllowance(signingAcct, '0.0.2013599', 1.4);
        // allowanceTx = allowanceTx.approveHbarAllowance(signingAcct, '0.0.2013600', 1.4);
    }

    let allowanceBytes = await makeBytes(await allowanceTx, signingAcct);
    let allowanceRes = await sendAllowanceTransaction(allowanceBytes, signingAcct);

    return allowanceRes.success && allowanceRes.success === true;
};

export const createUSDAllowance = async (spenderContractIdString, amount) => {
    const signingAcctId = await getAccountIdWithWaitV3();
    const currencyId = await getUSDCurrencyAddress();
    let allowanceTx = new AccountAllowanceApproveTransaction().approveTokenAllowance(currencyId, signingAcctId, spenderContractIdString, amount)
    // const signer = getSigner();
    // allowanceTx = await allowanceTx.freezeWithSigner(signer);
    // let allowanceBytes = await makeBytes(await allowanceTx, signingAcct);
    let allowanceRes = await sendTransactionV3({tx: allowanceTx, acc: signingAcctId});
    return (allowanceRes.success && allowanceRes.success === true);
}

export const createYVCAllowance = async (signingAcct, spenderContractIdString, amountOfYVC) => {
    const currencyId = await getYVCurrencyAddress();
    let allowanceTx = new AccountAllowanceApproveTransaction().approveTokenAllowance(currencyId, signingAcct, spenderContractIdString, amountOfYVC);
    // const signer = getSigner();
    // allowanceTx = await allowanceTx.freezeWithSigner(signer);
    let allowanceRes = await sendTransactionV3({tx: allowanceTx, acc: signingAcct});
    return allowanceRes.success && allowanceRes.success === true;
}

export const createGovAllowance = async (
    signingAcct,
    spenderContractIdString,
    amount,
    serials = null,
    collectionName = null
) => {
    const currencyId = (await getConfigFor('GOV_TOKEN')).contractId;
    // TODO: combine with code above

    let allowanceTx = new AccountAllowanceApproveTransaction()
        .approveTokenAllowance(currencyId, signingAcct, spenderContractIdString, amount);

    if (serials && collectionName) {
        for (let i = 0; i < serials.length; i++) {
            const nftId = new NftId(
                TokenId.fromString((await getConfigFor(collectionName)).contractId),
                serials[i]
            );
            allowanceTx = allowanceTx.approveTokenNftAllowance(
                nftId,
                signingAcct,
                spenderContractIdString
            );
        }
    }

    let allowanceBytes = await makeBytes(await allowanceTx, signingAcct);
    let allowanceRes = await sendAllowanceTransaction(allowanceBytes, signingAcct);

    return allowanceRes.success && allowanceRes.success === true;
};

export const createCurrencyAllowance = (signingAcct, spenderContractIdString, amount) =>
    createAllowance(signingAcct, spenderContractIdString, amount);

export const createGovTokenAllowance = (signingAcct, spenderContractIdString, amount) =>
    createGovAllowance(signingAcct, spenderContractIdString, amount);

export const createCurrencyAndItemAllowance = (
    signingAcct,
    spenderContractIdString,
    amount,
    serials
) => createAllowance(signingAcct, spenderContractIdString, amount, serials, 'ITEM_COLLECTION');

/**
 *
 * @param signingAcct
 * @param spenderContractIdString
 * @param amount
 * @param serials
 * @returns {Promise<boolean>}
 */
export const createCurrencyAndSetAllowance = (
    signingAcct,
    spenderContractIdString,
    amount,
    serials
) => createAllowance(signingAcct, spenderContractIdString, amount, serials, 'FULLSET_COLLECTION');
