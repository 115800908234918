import {FACTION, SET_PIECE} from "../../helper/ENUMS.ts";
import {Scrollbars} from "react-custom-scrollbars";
import {FiRefreshCw} from "react-icons/fi";
import React, {useState} from "react";
import MintedItemsComponent, {redrawMintedItems} from "../CardList/MintedItemsComponent";
import PreMintedItemsComponent, {redrawPreMintedItems} from "../CardList/PreMintedItemsComponent";


const BackPack = () => {

    const [filterArray, setFilterArray] = useState(
        {
            'factions': [true, true, true],
            'setPiece': 0
        }
    );

    const [isRefreshing, setIsRefreshing] = useState(false);


    const filterButtonClass = (faction) => {
        return "filter-button " + FACTION[faction] + (isFactionActive(faction) ? ' active' : '');
    };
    const isFactionActive = (faction) => filterArray.factions[faction];

    const toggleFaction = (faction) => {
        let newFactionFilter = [...filterArray.factions];
        newFactionFilter[faction] = !newFactionFilter[faction];
        setFilterArray({...filterArray, factions: newFactionFilter});
    };

    function finishNftMint(item) {
        console.log("attempting to mint item", item);
    }

    const cardListTabsClass = (setPieceKey) => {
        return `tab ${SET_PIECE[setPieceKey].toString().toLowerCase()} ${filterArray.setPiece === setPieceKey ? 'active' : ''}`;
    }

    return (
        <div id={'backpack-horizontal-wrap'}>
            <div id={'backpack-vertical-pre-wrap'}></div>
            <div id={'backpack-vertical-wrap'}>
                <div id="backpack">
                    <div id={'card-list-tabs'}>
                        <button onClick={() => setFilterArray({...filterArray, setPiece: 0})}
                                className={cardListTabsClass(0)}>{SET_PIECE[0]}</button>
                        <button onClick={() => setFilterArray({...filterArray, setPiece: 3})}
                                className={cardListTabsClass(3)}>{SET_PIECE[3]}</button>
                        <button onClick={() => setFilterArray({...filterArray, setPiece: 2})}
                                className={cardListTabsClass(2)}>{SET_PIECE[2]}</button>
                        <button onClick={() => setFilterArray({...filterArray, setPiece: 1})}
                                className={cardListTabsClass(1)}>{SET_PIECE[1]}</button>
                    </div>
                    <div id={'card-list'}>
                        <div className={'scrollable'}>
                            <Scrollbars className={'scrollbars'}
                                        renderTrackVertical={props => <div className="vertical-scroll-container">
                                            <div  {...props} className={'vertical-scroll-container-inner'}></div>
                                        </div>}
                                        renderThumbVertical={props => <div {...props} className="begunok"/>}
                            >
                                <MintedItemsComponent filterArray={filterArray}/>
                                {/*<div className={'clearfix'}/>*/}
                                <PreMintedItemsComponent filterArray={filterArray}/>
                            </Scrollbars>
                            <div id={'card-list-sidebar'} className={'sidebar'}>
                                <div className={"filter"}>
                                    {[0, 1, 2].map((faction) => {
                                        return (
                                            <button onClick={() => toggleFaction(faction)}
                                                    className={filterButtonClass(faction)}/>
                                        );
                                    })}
                                </div>
                                <div className={'refresh-card-list'}>
                                    <button onClick={() => {
                                        setIsRefreshing(true);
                                        redrawPreMintedItems();
                                        redrawMintedItems().then(() => setIsRefreshing(false));
                                        // TODO: rework with Promise.all after we swap pre-minted items to async func
                                    }} className={`refresh-button`}><FiRefreshCw
                                        className={` ${isRefreshing ? 'rotate' : ''}`}/></button>
                                </div>
                                <div className={'scrollbar-render-container'}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default BackPack;