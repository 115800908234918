import {HashConnect} from 'hashconnect';
import {
    TransactionId,
    AccountId, TokenAssociateTransaction
} from "@hashgraph/sdk";
import MyCustomEventEmitter from "../../component/myCustomEvents";
import {getUnassociatedTokenIds} from "../../helper/mirrrorNode";

const NETWORK = {
    TESTNET: "testnet",
    PREVIEWNET: "previewnet",
    MAINNET: "mainnet",
}

const getNetwork = () => { // todo map to .env
    return process.env.REACT_APP_NODE_ENV === 'production' ? NETWORK.MAINNET : NETWORK.TESTNET;
}

const appMetadata = {
    // interface AppMetadata {
    // name: string;
    // description: string;
    // icon: string;
    // publicKey?: string;
    // encryptionKey?: string;
    // url?: string;
    // }
    name: process.env.REACT_APP_NODE_ENV === 'production' ?  "Banana.Capital" : "Banan TESTNET",
    description: process.env.REACT_APP_NODE_ENV === 'production' ? "Banana.Capital limited distributor contract connector. Should be used only to access https://banana.capital/distribution." : "Gimme the banana",
    icon: process.env.REACT_APP_NODE_ENV === 'production' ? "https://asset-host.b-cdn.net/assets/global/logos/logo_banana_361x361.png" : "https://avatars.githubusercontent.com/u/107772884?s=400&u=c433a7943286ac35776ef77016720b720af8a6a3&v=4",
    url: process.env.REACT_APP_NODE_ENV === 'production' ? "https://banana.capital" : "https://testnet-static.banana.capital"
}

let pairingData,
    availableExtension, // extension found by event
    hashState,
    hashTopic,
    pairingString,
    wholeInitData;
let hashConnect;

// hashConnect = new HashConnect(true);

export const setUpHashConnectEvents = () => {
    //This is fired when a extension is found
    // hashConnect.foundExtensionEvent.on((data) => {
        // console.log("hashConnect.foundExtensionEvent.on data = ", data);
        // console.log("hashConnect.foundExtensionEvent.connectToLocalWallet.on data = ", hashConnect.hcData);
        // availableExtension = data;
    // })
/*
    hashConnect.foundExtensionEvent.once((data) => {
        // console.log("hashConnect.foundExtensionEvent.once data = ", data)
        availableExtension = data;
        // console.log("hashConnect.foundExtensionEvent.once availableExtension = ", availableExtension);
    });

    //This is fired when a wallet approves a pairing
    // hashConnect.pairingEvent.on((data) => {
        // console.log("hashConnect.pairingEvent.on data = ", data);
        // pairingData = data.pairingData; // TODO: must not be null
        // console.log("hashConnect.pairingEvent.on hashConnect = ", hashConnect);
        // MyCustomEventEmitter.dispatch("pairingDataFound");
    // });

    //This is fired when HashConnect loses connection, pairs successfully, or is starting connection
    hashConnect.connectionStatusChangeEvent.on((state) => {
        // console.log("hashConnect.connectionStatusChangeEvent.on() state = ", state);
        // console.log("hashConnect.connectionStatusChangeEvent.connectToLocalWallet.on() state = ", state);
        hashState = state;
    })*/
}

/**
 * Initialises local instance of hashConnect
 * @dev must be run on each login or page hard refresh
 * @returns {Promise<HashConnectTypes.InitilizationData>}
 */
export const initHashConnect = () => {
    // console.log("initHashConnect() called up by ",  console.trace(0));
    // console.trace();
    //create the hashconnect instance
    // hashConnect = new HashConnect(false);

    //register events
    // setUpHashConnectEvents();

    //initialize and use returned data
    // let initData = await hashConnect.init(appMetadata, "testnet", true);
    return null;
    return hashConnect.init(appMetadata, getNetwork(), true).then((initData) => {
        hashTopic = initData.topic;
        pairingString = initData.pairingString;
        pairingData = initData.savedPairings[0];
        wholeInitData = initData;
        // console.log("initHashConnect() initData stored", initData);
        // console.log("initHashConnect()", {
        //     topic: hashTopic,
        //     pairingString: pairingString,
        // });
        if (pairingData) {
            MyCustomEventEmitter.dispatch("pairingDataFound");
            // console.log("initHashConnect() pairingData", pairingData);
        }
        return initData;
    }).catch((e) => {
        console.error("initHashConnect().return hashConnect.init().catch()", e);
        console.error(e.stackTrace);
        return null;
    });

    // console.log("initHashConnect privkey", initData.privKey);
    // console.log("initHashConnect pairingString", initData.pairingString)
    //Saved pairings will return here, generally you will only have one unless you are doing something advanced
    // console.log("initHashConnect pairingData ", pairingData);
    // hashConnect.findLocalWallets();
    // hashConnect.connectToLocalWallet();
}

/**
 *
 * @returns {string[]|null}
 * @deprecated Use WalletComponentV3.getAccountId instead
 */
export const getAccountIds = () => {
    if (pairingData) {
        return pairingData.accountIds;
    }
    return null;
}
export const getAccountId = () => {
    if (pairingData) {
        return pairingData.accountIds[0];
    }
    return null;
}

/**
 *
 * @returns {Promise<string>}
 * @deprecated Use WalletComponentV3.getAccountIdWithWait instead
 */
export const getAccountIdWithWait = async () => {
    if (pairingData) {
        return pairingData.accountIds[0];
    }
    let i = 1;
    while (!pairingData) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        console.log("getAccountIdWithWait() waiting for pairingData", i);
        if (pairingData) {
            return pairingData.accountIds[0];
        }
        i++;
    }
}

export const getAccountInSolidity = () => {
    if (pairingData) {
        // console.log("getAccountInSolidity()", pairingData.accountIds[0]);
        // console.log("getAccountInSolidity()", AccountId.fromString(pairingData.accountIds[0]).toSolidityAddress());
        return `0x${AccountId.fromString(pairingData.accountIds[0]).toSolidityAddress()}`;
    }
    return null;
}

export const setAccountId = () => {
    localStorage.setItem("accountId", getAccountIds()[0]);
}

export const getPairingString = () => {
    return pairingString;
}

export const getAvailableExtensions = () => {
    // console.log("getAvailableExtensions()", availableExtension);
    return availableExtension;
}

export const connectToExtension = (setAccountIdFunction, checkAuthTokenFunction) => {
    // console.log("connectToExtension() initData check", wholeInitData);
    // console.log("connectToExtension() initData check pairingString", pairingString);
    // console.log("connectToExtension() initData check pairingData", pairingData);
    // console.log("connectToExtension() initData check getPairingString", getPairingString());
    //this will automatically pop up a pairing request in the HashPack extension
    // console.log("    hashConnect.findLocalWallets();\n", hashConnect);
    // hashConnect.findLocalWallets();

    // console.log("    hashConnect.connectToLocalWallet();\n", hashConnect.hcData);
    // hashConnect.connectToLocalWallet();
    // console.log("    hashConnect.postpostposptostpo();\n", hashConnect);
    // hashTopic = hashConnect.topic; // maybe?
    /*
    hashConnect.pairingEvent.on((data) => {
        if (data.pairingData !== undefined && data.pairingData.accountIds.length > 0) {
            const accountId = data.pairingData.accountIds[0];
            setAccountIdFunction(accountId);
            checkAuthTokenFunction(accountId);
            MyCustomEventEmitter.dispatch("pairingDataFound");
        } else {
            console.error("connectToExtension() hashConnect.pairingEvent.on() data", data);
        }
    });*/
}

export const hashConnectAuthenticate = (signingAcct, signingData, payload) => {
    // console.log("hashConnectAuthenticate()");
    // return hashConnect.authenticate(
    //     hashTopic,
    //     signingAcct,
    //     signingData.serverSigningAccount,
    //     Buffer.from(Object.values(signingData.signature)), // {0: 142, 1: 57, 2: 34, ...} to [142, 57, 34, ...]
    //     payload
    // );
}

export const detachAllWallets = () => {
    // hashConnect.disconnect() removes connection of all previously paired by pairingString wallets from FE app
    // !!! as of now disconnecting topic does not remove the connection to app from users wallet!
    // hashConnect.clearConnectionsAndData(); removes entire hashConnect object, meaning new hashConnect.init must occur
    // obviously it requires multiple things to be re-initialised for no particular reason in our use case
    // pairingData object is redundant if hashTopic has been disconnected from app side
    // pairingString on other hand works as an anchor, destination to connect wallet TO
    // string can be still active and new connection can be by wallets connecting to it on a new hashTopic

    // hashConnect.clearConnectionsAndData();
    // pairingString = null;

    // This version of detachAllWallets was a weird one,
    // somehow after "disconnect" hashConnect instance still held pairing placeholder as well as topic!
    // Swapped to complete destruction with .clearConnectionsAndData();
    /*
    console.log("detachAllWallets() topic = ", hashConnect.getTopicInfo());
    return hashConnect.disconnect(hashConnect.topic)
        .then(() => {
            hashTopic = null;
            pairingData = null;
            return true;
        });
     */
return null;
    return hashConnect.clearConnectionsAndData()
        .then((clearResult) => {
            localStorage.removeItem("authToken"); // remove BE pairing
            localStorage.removeItem("associatedTokens");
            MyCustomEventEmitter.dispatch("authTokenPresent", false);
            hashTopic = null;
            pairingData = null;
            return true;
        })
        .catch((x) => {
            console.error(x);
        })
        ;

}

/**
 *
 * @param trans uint8Array
 * @param acctToSign String
 * @param return_trans boolean
 * @param get_record boolean
 * @param hideNfts boolean
 * @returns {*}
 * @deprecated Use WalletComponentV3.sendTransactionV3 instead
 */
export const sendTransaction = async (trans, acctToSign, return_trans = false, get_record = true, hideNfts = false) => {
    const transaction = {
        topic: hashTopic,
        byteArray: trans,

        metadata: {
            accountToSign: acctToSign,
            returnTransaction: return_trans,
            getRecord: get_record,
            hideNft: hideNfts
        }
    }

    return await hashConnect.sendTransaction(hashTopic, transaction)
}

export const sendAllowanceTransaction = async (trans, acctToSign) => {

    const transaction = {
        topic: hashTopic,
        byteArray: trans,

        metadata: {
            accountToSign: acctToSign,
            returnTransaction: false,
            hideNft: false
        }
    }

    return await hashConnect.sendTransaction(hashTopic, transaction);
}

/**
 *
 * @param trans Object
 * @param signingAcctId String
 * @deprecated Use WalletComponentV3.makeBytesV3 instead
 */
export const makeBytes = async (trans, signingAcctId) => {
    let transId = TransactionId.generate(signingAcctId)
    trans.setTransactionId(transId);
    // trans.setGas(0);
    // trans.setMaxTransactionFee(0);
    trans.setNodeAccountIds([new AccountId(3)]);


    await trans.freeze();

    let transBytes = trans.toBytes();

    return transBytes;
}


export const bytesToNumber = (byteArray) => {
    let result = 0;
    for (let i = 0; i <= byteArray.length - 1; i++) {
        result = (result * 256) + byteArray[i];
    }

    return result;
}

export const parseOffset = (offset, record) => {
    offset = 32 * offset;
    let arr = [];
    for (let i = 0; i < 32; i++) {
        arr[i] = record["" + (offset + i)];
    }
    return arr;
}

/**
 *
 * @returns {Promise<void>}
 * @deprecated Use WalletComponentV3.associateTokensWithAccountV3 instead
 */
export const associateTokensWithAccount = async () => {
    let signingAcct = getAccountIds()[0];
    const tokenIds = await getUnassociatedTokenIds(signingAcct);
    let trans = await new TokenAssociateTransaction()
            .setAccountId(signingAcct)
            .setTokenIds(tokenIds)
    ;

    let transactionBytes = await makeBytes(trans, signingAcct);
    let res = await sendTransaction(transactionBytes, signingAcct, false, false);

    if (res.success) {
        console.log(`tokens ${tokenIds} associated with account ${signingAcct}`, res);
    }
}

// setUpHashConnectEvents();
// let initData = await hashConnect.init(appMetadata, "testnet", false);
// hashConnect.connectToLocalWallet();

