import React, {useState} from 'react';
import InventoryRightSide from "./sides/inventoryRightSide";
import InventoryLeftSide from "./sides/inventoryLeftSide";
import './Inventory.css';
import GetInventoryList, {GetInventorySets} from "../../connector/GetInventoryList";


const Inventory = () => {

    let cards = GetInventoryList();
    let banans = GetInventorySets();
    // console.log('banans', banans);
    /* filterArray [
        [0, 1, 2],
        [2]
       ];
     */


    function dump(item) {
        console.log(item);
    }

    return (
        <div id={'inventory-page'}>
            {/*<h1 style={{color: "green"}}>*/}
            {/*    Inventory</h1>*/}
            <div id={'main'}>
                <InventoryLeftSide/>
                <InventoryRightSide showPalm={true}/>
            </div>
            <div id={'footer'}>
                {/*{`not footer technically`}*/}
                <div id={'ground'}></div>
                <div id={'flower-left'}/>
                <div id={'flower-right'}/>
            </div>
            <img src={'https://asset-host.b-cdn.net/assets/inventory/coconut.png'} id={'coco'}/>
        </div>
    )
};

export default Inventory;
