import {HashConnect} from 'hashconnect';
import {
    TransactionId,
    AccountId, TokenAssociateTransaction, LedgerId
} from "@hashgraph/sdk";
import MyCustomEventEmitter from "../../component/myCustomEvents";
import {getUnassociatedTokenIds} from "../../helper/mirrrorNode";

const NETWORK = {
    TESTNET: "testnet",
    PREVIEWNET: "previewnet",
    MAINNET: "mainnet",
}

const getNetwork = () => { // todo map to .env
    return process.env.REACT_APP_NODE_ENV === 'production' ? NETWORK.MAINNET : NETWORK.TESTNET;
}


let pairingData,
    availableExtension, // extension found by event
    hashState,
    hashTopic,
    pairingString,
    wholeInitData;
let hashConnectV1_5;

// hashConnectV1_5 = new HashConnect(true);

export const setUpHashConnectEvents1_5 = () => {
    //This is fired when a extension is found
    // hashConnectV1_5.foundExtensionEvent.on((data) => {
        // availableExtension = data;
    // })

    hashConnectV1_5.foundExtensionEvent.once((data) => {
        availableExtension = data;
    });

    //This is fired when a wallet approves a pairing
    hashConnectV1_5.pairingEvent.on((data) => {
        pairingData = data.pairingData; // TODO: must not be null
    });

    //This is fired when HashConnect loses connection, pairs successfully, or is starting connection
    hashConnectV1_5.connectionStatusChangeEvent.on((state) => {
        hashState = state;
    })
}

/**
 * Initialises local instance of hashConnectV1_5
 * @dev must be run on each login or page hard refresh
 * @returns {Promise<HashConnectTypes.InitilizationData>}
 */
export const initHashConnectV1_5 = () => {
    // console.log("initHashConnect() called up by ",  console.trace(0));
    // console.trace();
    //create the hashconnect instance

    const appMetadata = {
        // interface AppMetadata {
        // name: string;
        // description: string;
        // icon: string;
        // publicKey?: string;
        // encryptionKey?: string;
        // url?: string;
        // }
        name: "Banana.Capital",
        description: "Banana.Capital limited distributor contract connector. Should be used only to access https://banana.capital/distribution.",
        icon: "https://avatars.githubusercontent.com/u/107772884?s=400&u=c433a7943286ac35776ef77016720b720af8a6a3&v=4",
        url: "https://banana.capital",
    }
    hashConnectV1_5 = new HashConnect(
        process.env.REACT_APP_NODE_ENV !== 'production' ? LedgerId.TESTNET : LedgerId.MAINNET,
        process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
        appMetadata,
        process.env.REACT_APP_NODE_ENV !== 'production'
    );

    //register events
    setUpHashConnectEvents1_5();

    //initialize and use returned data
    // let initData = await hashConnectV1_5.init(appMetadata, "testnet", true);

    return hashConnectV1_5.init(appMetadata, getNetwork(), true).then((initData) => {
        hashTopic = initData.topic;
        pairingString = initData.pairingString;
        pairingData = initData.savedPairings[0];
        wholeInitData = initData;
        console.log("initHashConnect() initData stored", initData);
        console.log("initHashConnect()", {
            topic: hashTopic,
            pairingString: pairingString,
        });
        if (pairingData) {
            MyCustomEventEmitter.dispatch("pairingDataFound");
            console.log("initHashConnect() pairingData", pairingData);
        }
        return initData;
    }).catch((e) => {
        console.error("initHashConnect().return hashConnectV1_5.init().catch()", e);
        console.error(e.stackTrace);
        return null;
    });

    // console.log("initHashConnect privkey", initData.privKey);
    // console.log("initHashConnect pairingString", initData.pairingString)
    //Saved pairings will return here, generally you will only have one unless you are doing something advanced
    // console.log("initHashConnect pairingData ", pairingData);
    // hashConnectV1_5.findLocalWallets();
    // hashConnectV1_5.connectToLocalWallet();
}

export const getAccountIdsV1_5 = () => {
    if (pairingData) {
        return pairingData.accountIds;
    }
    return null;
}
export const getAccountIdV1_5 = () => {
    if (pairingData) {
        return pairingData.accountIds[0];
    }
    return null;
}

export const getAccountInSolidityV1_5 = () => {
    if (pairingData) {
        return `0x${AccountId.fromString(pairingData.accountIds[0]).toSolidityAddress()}`;
    }
    return null;
}

export const getPairingStringV1_5 = () => {
    return pairingString;
}

export const getAvailableExtensions = () => {
    return availableExtension;
}

export const connectToExtension = (setAccountIdFunction, checkAuthTokenFunction) => {
    // console.log("connectToExtension() initData check", wholeInitData);
    // console.log("connectToExtension() initData check pairingString", pairingString);
    // console.log("connectToExtension() initData check pairingData", pairingData);
    // console.log("connectToExtension() initData check getPairingString", getPairingString());
    //this will automatically pop up a pairing request in the HashPack extension
    console.log("    hashConnectV1_5.findLocalWallets();\n", hashConnectV1_5);
    hashConnectV1_5.findLocalWallets();

    console.log("    hashConnectV1_5.connectToLocalWallet();\n", hashConnectV1_5.hcData);
    hashConnectV1_5.connectToLocalWallet();
    console.log("    hashConnectV1_5.postpostposptostpo();\n", hashConnectV1_5);
    // hashTopic = hashConnectV1_5.topic; // maybe?
    hashConnectV1_5.pairingEvent.on((data) => {
        if (data.pairingData !== undefined && data.pairingData.accountIds.length > 0) {
            const accountId = data.pairingData.accountIds[0];
            setAccountIdFunction(accountId);
            checkAuthTokenFunction(accountId);
            MyCustomEventEmitter.dispatch("pairingDataFound");
        }
    });
}

export const hashConnectAuthenticate = (signingAcct, signingData, payload) => {
    console.log("hashConnectAuthenticate()");
    return hashConnectV1_5.authenticate(
        hashTopic,
        signingAcct,
        signingData.serverSigningAccount,
        Buffer.from(Object.values(signingData.signature)), // {0: 142, 1: 57, 2: 34, ...} to [142, 57, 34, ...]
        payload
    );
}

export const detachAllWallets = () => {
    // hashConnectV1_5.disconnect() removes connection of all previously paired by pairingString wallets from FE app
    // !!! as of now disconnecting topic does not remove the connection to app from users wallet!
    // hashConnectV1_5.clearConnectionsAndData(); removes entire hashConnectV1_5 object, meaning new hashConnectV1_5.init must occur
    // obviously it requires multiple things to be re-initialised for no particular reason in our use case
    // pairingData object is redundant if hashTopic has been disconnected from app side
    // pairingString on other hand works as an anchor, destination to connect wallet TO
    // string can be still active and new connection can be by wallets connecting to it on a new hashTopic

    // hashConnectV1_5.clearConnectionsAndData();
    // pairingString = null;

    // This version of detachAllWallets was a weird one,
    // somehow after "disconnect" hashConnectV1_5 instance still held pairing placeholder as well as topic!
    // Swapped to complete destruction with .clearConnectionsAndData();
    /*
    console.log("detachAllWallets() topic = ", hashConnectV1_5.getTopicInfo());
    return hashConnectV1_5.disconnect(hashConnectV1_5.topic)
        .then(() => {
            hashTopic = null;
            pairingData = null;
            return true;
        });
     */

    return hashConnectV1_5.clearConnectionsAndData()
        .then((clearResult) => {
            localStorage.removeItem("authToken"); // remove BE pairing
            localStorage.removeItem("associatedTokens");
            MyCustomEventEmitter.dispatch("authTokenPresent", false);
            hashTopic = null;
            pairingData = null;
            return true;
        })
        .catch((x) => {
            console.error(x);
        })
        ;

}

/**
 *
 * @param trans uint8Array
 * @param acctToSign String
 * @param return_trans boolean
 * @param get_record boolean
 * @param hideNfts boolean
 * @returns {*}
 */
export const sendTransaction = async (trans, acctToSign, return_trans = false, get_record = true, hideNfts = false) => {
    const transaction = {
        topic: hashTopic,
        byteArray: trans,

        metadata: {
            accountToSign: acctToSign,
            returnTransaction: return_trans,
            getRecord: get_record,
            hideNft: hideNfts
        }
    }

    return await hashConnectV1_5.sendTransaction(hashTopic, transaction)
}

export const sendAllowanceTransaction = async (trans, acctToSign) => {

    const transaction = {
        topic: hashTopic,
        byteArray: trans,

        metadata: {
            accountToSign: acctToSign,
            returnTransaction: false,
            hideNft: false
        }
    }

    return await hashConnectV1_5.sendTransaction(hashTopic, transaction);
}

/**
 *
 * @param trans Object
 * @param signingAcctId String
 */
export const makeBytes = async (trans, signingAcctId) => {
    let transId = TransactionId.generate(signingAcctId)
    trans.setTransactionId(transId);
    // trans.setGas(0);
    // trans.setMaxTransactionFee(0);
    trans.setNodeAccountIds([new AccountId(3)]);


    await trans.freeze();

    let transBytes = trans.toBytes();

    return transBytes;
}


export const bytesToNumber = (byteArray) => {
    let result = 0;
    for (let i = 0; i <= byteArray.length - 1; i++) {
        result = (result * 256) + byteArray[i];
    }

    return result;
}

export const parseOffset = (offset, record) => {
    offset = 32 * offset;
    let arr = [];
    for (let i = 0; i < 32; i++) {
        arr[i] = record["" + (offset + i)];
    }
    return arr;
}

export const associateTokensWithAccount = async () => {
    let signingAcct = getAccountIdsV1_5()[0];
    const tokenIds = await getUnassociatedTokenIds(signingAcct);
    let trans = await new TokenAssociateTransaction()
            .setAccountId(signingAcct)
            .setTokenIds(tokenIds)
    ;

    let transactionBytes = await makeBytes(trans, signingAcct);
    let res = await sendTransaction(transactionBytes, signingAcct, false, false);

    if (res.success) {
        console.log(`tokens ${tokenIds} associated with account ${signingAcct}`, res);
    }
}

// setUpHashConnectEvents();
// let initData = await hashConnectV1_5.init(appMetadata, "testnet", false);
// hashConnectV1_5.connectToLocalWallet();

