import './RookieBoxOpener.css';
import React, {useState} from "react";
import {assetsHost} from "../../../helper/externalLinks";
import {CurrencyIcon} from "../../../App";
import {EVENT_TYPE, FACTION, LOG_STATUS, MODAL_TYPE} from "../../../helper/ENUMS.ts";
import {UnpackModalOld} from "../../../component/Modal/Modal";
import {getAccountIds, makeBytes, sendTransaction} from "../../../connector/blockchain/hashconnect";
import {getConfigFor} from "../../../connector/blockchain/ContractParameters";
import {ContractExecuteTransaction, ContractFunctionParameters, ContractId} from "@hashgraph/sdk";
import {appendNewLog} from "../../../component/PopUpProcessLog/PopUpProcessLog";
import axios from "axios";
import MyCustomEventEmitter from "../../../component/myCustomEvents";
import {createCurrencyAllowance, yvcMod} from "../../../connector/generateAllowanceTransactions";

let unpackContentLog = "", setUnpackContentLog;
let unpackContentItemCards = {}, setUnpackContentItemCards;
let unpackingVariables = {};

async function CallUnpackRookieSetContract(setUnpackContentLog) {
    let signingAcct = getAccountIds()[0];
    // todo check if sufficient balance
    setUnpackContentLog([{
        status: LOG_STATUS.SUCCESS,
        text: <>Verifying YVC <CurrencyIcon/> balances</>
    }]);
    const amountWithoutDecimals = 100 * yvcMod;

    const unpackTransactionPromise = getConfigFor('LOOT_BOX_UNPACK_ROOKIE').then(callConfig => {
        return createCurrencyAllowance(
            signingAcct,
            callConfig.contractId,
            amountWithoutDecimals)
            .then((allowanceGiven) => {
                if (allowanceGiven) {
                    return new ContractExecuteTransaction()
                        .setContractId(ContractId.fromString(callConfig.contractId))
                        .setGas(callConfig.gasLimit) // Increase if revert
                        .setTransactionMemo(`Banana.Capital: Purchasing & Unpacking Rookie Set`)
                        .setFunction(callConfig.contractFunc
                            , new ContractFunctionParameters()
                        )
                }

                return null;
            }).catch((x) => {
                console.error(x);
            });

    });

    // setUnpackContentLog([]);
    // if we reference unpackContentLog here, it will somehow contain previous run state, so we have to reset it completely
    setUnpackContentLog([...appendNewLog([], "Generating Rookie Set Tx...", LOG_STATUS.SUCCESS)]);
    setUnpackContentItemCards(null);

    let transactionBytes = await makeBytes(await unpackTransactionPromise, signingAcct);

    let res = await sendTransaction(transactionBytes, signingAcct, false, true);
    await new Promise(resolve => setTimeout(resolve, 230))
        .then(setUnpackContentLog([...appendNewLog(unpackContentLog, "Waiting for Tx confirmation...")]));

    //handle response
    let responseData = {
        response: res,
        record: null,
        receipt: null
    };

    if (res.success) {
        await new Promise(resolve => setTimeout(resolve, 360))
            .then(setUnpackContentLog([...appendNewLog(unpackContentLog, "Parsing data...")]));
        console.info("GLORIOUS RESPONSE ", res);
        let item = {};
        const preMintSetBody = {
            txId: res.response.transactionId,
        };

        const urlHost = process.env.REACT_APP_BACK_END_HOST;
        axios.post(urlHost + "/item/pre-mint-set", preMintSetBody, {
            headers: {
                'Content-Type': "application/json",
                'Accept': "application/json",
                // "Access-Control-Allow-Origin": "*",
            },
            responseType: "json",
        })
            .then(function (backEndMirrorNodeResponse) {
                const itemArray = backEndMirrorNodeResponse.data.itemArray;
                setUnpackContentItemCards(itemArray);
                setUnpackContentLog([...appendNewLog(unpackContentLog, <>Success!</>, (backEndMirrorNodeResponse.data.stored ? LOG_STATUS.SUCCESS : LOG_STATUS.ERROR))]);
            })
            .catch(function (response) {
                setUnpackContentLog([...appendNewLog(unpackContentLog, <div
                    className={'clearfix'}>error: {response.data}</div>)]);
            })
        ;

        MyCustomEventEmitter.dispatch(EVENT_TYPE.FORCE_CURRENCY_UPDATE);
    } else {
        console.log("GLORIOUS FAIL", res);
    }
    // console.log("responseData ", responseData)
    // this.HashconnectService.showResultOverlay(responseData);
}

const RookieBoxOpener = () => {

    [unpackContentLog, setUnpackContentLog] = useState([{
        status: LOG_STATUS.PENDING,
        text: `Waiting for players' confirmation...`
    }]);
    [unpackContentItemCards, setUnpackContentItemCards] = useState(null);


    function UnpackRookieButtonText() {
        return (
            <>BUY & OPEN:<span className={`price`}>100.00 <CurrencyIcon size={24}/></span></>
        );
    }
    unpackingVariables.store = "set";
    unpackingVariables.faction = FACTION.GREEN;


    function initiateRookieUnpacking(unpackingObject) {
        console.log("Rookie unpacking confirmation");
        setUnpackContentLog([]);
        CallUnpackRookieSetContract(setUnpackContentLog);
    }

    return (
        <div id={`rookie-box-open`} className={'flex-container-rows flex-row rookie-box-opener'}>
            {/*<h3 className={`${factionKey}`}>{factionKey.toUpperCase()} faction item</h3>*/}
            <img src={`${assetsHost}/assets/shop/rookie_bundle.png`}
                 alt={`depiction of set of 4 unknown cards with question mark instead of items, indicating unknown randomly generated items of 4 banana set pieces: Helmet, Armor, Skin & Weapon item player receives upon unpacking an item`}/>
            {/*<button className={'button btn primary'}></button>*/}
            <UnpackModalOld
                modalType={MODAL_TYPE.UNPACK_ROOKIE}
                modalTitle={'ROOKIE BUNDLE'}
                modalContent={unpackContentLog}
                itemCards={unpackContentItemCards}
                contentLog={unpackContentLog}
                buttonContent={UnpackRookieButtonText()}
                buttonFunctionParams={unpackingVariables}
                buttonFunction={initiateRookieUnpacking}
            />
            <p><span className={`red flex-container-columns`}>BEWARE!</span> Cost reduction comes at a price of halved seasonal damage range values...</p>
        </div>
    );
}

export default RookieBoxOpener;