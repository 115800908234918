import {createContext, useEffect} from "react";

const DaVinciGraphLockContext = createContext({})
export const DaVinciGraphLockCardProvider = ({children}) => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const scriptCheck = document.getElementById("lock-card-script");
            if (scriptCheck?.id) return;

            const script = document.createElement('script');
            script.src = 'https://davincigraph.network/lockCard.js?t=1694170309675';
            script.async = true;
            script.id = 'lock-card-script';

            script.onload = function () {
                if ((window).createLock) {
                    (window).createLock();
                }
            };

            document.body.appendChild(script);

            return () => { document.body.removeChild(script); }
        }
    }, []);

    return <DaVinciGraphLockContext.Provider value={{}}>{children}</DaVinciGraphLockContext.Provider>
}