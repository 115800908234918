import Popup from "reactjs-popup";
import {FaQuestionCircle} from "react-icons/fa";
import React from "react";

const ToolTip = ({key, trigger, content, position, keepInside, onActions, onDocumentClick, nested, onOpen, onClick}) => {
    const pos = position || ['top center', 'bottom right', 'bottom left'];
    return (
        <Popup
            // key={`${key}-tooltip`}
            trigger={trigger || <div>              <FaQuestionCircle/>          </div>}
            position={pos}
            // on={['hover', 'focus']}
            on={onActions || ['click']}
            arrow={pos !== 'center center'}
            keepTooltipInside={keepInside || null}
            className={'tooltip'}
            closeOnDocumentClick={onDocumentClick || true}
            nested={nested}
            // onOpen={onOpen}
            // onClick={onClick}
        >
            {content || "Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae magni omnis delectus nemo, maxime molestiae"}
        </Popup>
    );
}

export const Tooltipi = () => {
  return (
      <Popup
          trigger={<div>              <FaQuestionCircle/>          </div>}
          position={['top center', 'bottom right', 'bottom left']}
          closeOnDocumentClick
          keepTooltipInside=".fees-wrapper"
      >
          Tooltip content
      </Popup>
    );
}

export default ToolTip;