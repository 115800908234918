import axios from "axios";
import {ASSOCIATIONS} from "./ENUMS.ts";
import {getHederaMirrorHost, HEDERA_CONTRACTS} from "../connector/config";
import axiosRetry, {isNetworkOrIdempotentRequestError} from 'axios-retry';
import {getConfigFor} from "../connector/blockchain/ContractParameters";


axiosRetry(axios, {
    retries: 5,
    retryDelay: (retryCount) => {
        return retryCount * 2500; // time interval between retries
    },
    onRetry: (onRetryCount, error, reqConfig) => {
        console.error("Axios retry, error occurred at ", error.request.host + error.request.path);
        console.error("Axios retry #" + onRetryCount);
    },
    retryCondition: e => {
        return isNetworkOrIdempotentRequestError(e) || e.response.status === 404
    },
});

const getAddressForConfig = (configName) => async () => (await getConfigFor(configName)).contractId;
const contentLock = process.env.REACT_APP_NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'testnet-vercel';
const contractEnv = contentLock ? 'TESTNET' : 'MAINNET';

export const getYVCurrencyAddress = getAddressForConfig(`${contractEnv}_YV_CURRENCY_TOKEN`);

export const getUSDCurrencyAddress = getAddressForConfig(`${contractEnv}_USD_TOKEN`);


/**
 *
 * @returns {Promise<string>}
 */
export const getYVCurrencyAddressSync = () => {
    return getYVCurrencyAddress().then((address) => {
        return address;
    });
}

export const getItemsCollectionAddress = getAddressForConfig('ITEM_COLLECTION');

export const getSetsCollectionAddress = getAddressForConfig('FULLSET_COLLECTION');


const getTokenAssociations = async (accountId) => {

    const mirrorHost = getHederaMirrorHost();
    const YVCurrencyAddress = await getYVCurrencyAddress();
    const itemCollectionAddress = await getItemsCollectionAddress();
    const setsCollectionAddress = await getSetsCollectionAddress();

    return axios.get(`${mirrorHost}/api/v1/accounts/${accountId}/tokens?limit=100`, {
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
            'Authorization-Web3': null,
            // "Access-Control-Allow-Origin": "*",
        },
        responseType: "json",
    }).then((mirrorNodeTokensResponse) => {
        const tokensFound = mirrorNodeTokensResponse.data.tokens;
        const YVCurrency = tokensFound.find(({token_id}) => token_id === YVCurrencyAddress);
        // const itemsCollection = tokensFound.find(({token_id}) => token_id === itemCollectionAddress);
        // const setsCollection = tokensFound.find(({token_id}) => token_id === setsCollectionAddress);
        // TODO remove when NFTs are deployed
        const itemsCollection = (itemCollectionAddress === YVCurrencyAddress) ? true : tokensFound.find(({token_id}) => token_id === itemCollectionAddress);
        const setsCollection = (setsCollectionAddress === YVCurrencyAddress) ? true : tokensFound.find(({token_id}) => token_id === setsCollectionAddress);

        console.log(`getTokens`, YVCurrency, itemsCollection, setsCollection)

        return {
            currency: (YVCurrency !== undefined),
            items: (itemsCollection !== undefined),
            sets: (setsCollection !== undefined),
        }
    })
        .catch((x) => {
            console.error(x);
            console.log("TOKENS MIRROR NODE FIALED ")
        })
        ;
}

export const getUnassociatedTokenIds = async (accountId) => {
    const tokens = await getTokenAssociations(accountId);
    const YVCurrencyAddress = await getYVCurrencyAddress();
    const itemCollectionAddress = await getItemsCollectionAddress();
    const setsCollectionAddress = await getSetsCollectionAddress();
    let unassociatedTokens = [];
    if (!tokens.currency) {
        unassociatedTokens.push(YVCurrencyAddress);
    }
    if (!tokens.items) {
        unassociatedTokens.push(itemCollectionAddress);
    }
    if (!tokens.sets) {
        unassociatedTokens.push(setsCollectionAddress);
    }
    return unassociatedTokens;
}
// todo swap to dynamic list of tokens
const checkAssociation = async (accountId, getAddressFunction) => {
    const tokenAddress = await getAddressFunction();
    return axios.get(`${getHederaMirrorHost()}/api/v1/accounts/${accountId}/tokens?limit=100`, {
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
            'Authorization-Web3': null,
        },
        responseType: "json",
    }).then((mirrorNodeTokensResponse) => {
        console.log("checkAssociation", mirrorNodeTokensResponse.data.tokens);
        return {
            isAssociated: mirrorNodeTokensResponse.data.tokens.find(({token_id}) => token_id === tokenAddress) !== undefined,
            tokens: mirrorNodeTokensResponse.data.tokens,
            tokenId: tokenAddress,
        };
    }).catch((x) => {
        console.error(x);
    });
}

export const isCurrencyAssociated = (accountId) => {
    return checkAssociation(accountId, getYVCurrencyAddress);
}

export const isItemCollectionAssociated = (accountId) => {
    return checkAssociation(accountId, getItemsCollectionAddress);
}

export const isSetCollectionAssociated = (accountId) => {
    return checkAssociation(accountId, getSetsCollectionAddress);
}

export const getTxErrorMessage = (error) => {
    console.log("getTxErrorMessage", error);
    const txPieces = error.transactionId.split('@');
    txPieces[1] = txPieces[1].replace('.', '-');
    return axios.get(`${getHederaMirrorHost()}/api/v1/contracts/results/${txPieces[0]}-${txPieces[1]}`, {
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
            'Authorization-Web3': null,
            // "Access-Control-Allow-Origin": "*",
        },
        responseType: "json",
    }).then((mirrorNodeTokensResponse) => {
        const errorMessage = mirrorNodeTokensResponse.data.error_message;
        // Omit error code and leading zeros
        const messageHex = errorMessage.slice(74); // Slice after the "Error(string)" hash and padding
        let message = '';
        for (let i = 0; i < messageHex.length; i += 2) {
            message += String.fromCharCode(parseInt(messageHex.substr(i, 2), 16));
        }
        return message;
    }).catch((x) => {
        console.error(x);
    });
}

export default getTokenAssociations;