import {io, ManagerOptions, Socket, SocketOptions} from "socket.io-client";
import MyCustomEventEmitter from "../../component/myCustomEvents";
import {getAccountIds} from "../blockchain/hashconnect";
import {AccountId} from "@hashgraph/sdk";
import {EVENT_TYPE} from "../../helper/ENUMS.ts";

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.REACT_APP_NODE_ENV === 'development' ? 'http://localhost:3003/clients' : process.env.REACT_APP_SOCKET_HOST ;
/**
 * @type {Partial<ManagerOptions & SocketOptions>}
 */
const clientSocketOptions = {
    withCredentials: true,
    extraHeaders: {
        "my-custom-header-sx3": "abcd"
    },
    query: {
        x: 42,
        stage: "default",
    },
    // path: "/clients",
    // retries: 3,
    // ackTimeout: 10000,
    // auth: false,
};

let socketClient = null


let socketPoolInfo = {
    0: {
        activePoolSize: 0,
        passivePoolSize: 0,
    },
    1: {
        activePoolSize: 0,
        passivePoolSize: 0,
    },
    2: {
        activePoolSize: 0,
        passivePoolSize: 0,
    }
};

export const getSocketPoolInfo = (poolRankId) => {
    if (poolRankId === undefined || poolRankId === null) {
        return socketPoolInfo;
    }
    return socketPoolInfo[poolRankId];
}
const initialiseClientSocket = () => {
    if (socketClient !== null) {
        console.log("initialiseClientSocket", "socketClient found, disconnecting");
        socketClient.disconnect();
    }
    const newSocket = io(URL, clientSocketOptions);
    console.log("initialiseClientSocket from URL ", URL);
    newSocket.on("connect", () => {
        const SOCKETLOG = "SOCKETLOG ";
        console.log(SOCKETLOG, "connection is opened ", newSocket.connected); // true
        const socketEngine = newSocket.io.engine;
        console.log(SOCKETLOG, "socketEngine transport name: ", socketEngine.transport.name); // in most cases, prints "polling"

        socketEngine.once("upgrade", () => {
            // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
            console.log(SOCKETLOG, "socketEngine upgrade name: ", socketEngine.transport.name); // in most cases, prints "websocket"
        });

        socketEngine.on("packet", ({type, data}) => {
            // called for each packet received
            console.log(SOCKETLOG, "packet received: ", type, data);
        });

        socketEngine.on("packetCreate", ({type, data}) => {
            // called for each packet sent
            console.log(SOCKETLOG, "packageCreate", type, data);
        });

        socketEngine.on("drain", () => {
            // called when the write buffer is drained
            console.log(SOCKETLOG, "buffer drain");
        });

        socketEngine.on("close", (reason) => {
            // called when the underlying connection is closed
            console.log(SOCKETLOG, "connection is closed");
        });

    });

    newSocket.on("pool_info", (payload) => {
        console.log("SOCKETLOG", "pools_info received", payload);
        socketPoolInfo = payload['poolInfo'];
        MyCustomEventEmitter.dispatch("poolInfoUpdated");
    });

    newSocket.on("new_duel_created", (payload) => {
        console.log("SOCKETLOG", "new_duel_created received", payload);
        MyCustomEventEmitter.dispatch(EVENT_TYPE.DUEL_EVENT_CREATED, payload);
    });

    newSocket.on("connect_error", (err) => {
        console.log("SOCKETLOG", `connect_error due to ${err.message}`);
    });

    return newSocket;
}

export const requestPoolInfoUpdate = () => {
    const client = getSocketClient();
    if (client) {
        console.log("requestPoolInfoUpdate", "requesting pool info update")
        client.emit("requesting", {updates: ["pool_info"]});
        return true;
    } else {
        console.log("requestPoolInfoUpdate", "socketClient is null");
        return false;
    }
}

/**
 *
 * @returns {Socket<DefaultEventsMap, ListenEvents>}
 */
export const getSocketClient = () => {
    if (socketClient === null) {
        socketClient = initialiseClientSocket();
    }
    return socketClient;
}

/**
 *
 * @returns {Socket<DefaultEventsMap, ListenEvents>|boolean}
 */
const connectSocketToPairData = () => {
    try {
        let cSocket = getSocketClient();
        if (cSocket === null) {
            console.log("connectSocketToPairData", "Unknown state, cSocket is null");
            return false;
        }

        let accountSolidity = null;
        if (getAccountIds()) {
            accountSolidity = '0x' + (AccountId.fromString(getAccountIds()[0]).toSolidityAddress());
        }
        if (localStorage.getItem('accountId').length > 0) {
            accountSolidity = '0x' + (AccountId.fromString(localStorage.getItem('accountId')).toSolidityAddress());
            // cSocket.join(`${accountSolidity}`);
            // MyCustomEventEmitter.dispatch("socketInitialised");
            // return initialiseSocket(accountSolidity);
        }

        // todo swap this for an auth token from accountSolidity
        clientSocketOptions.auth = {
            token: localStorage.getItem('authToken'),
            account: accountSolidity,
            blockchain: 0, // todo ENUM this
        };
        clientSocketOptions.query.stage = "connectSocketToPairData:94";
        console.log("connectSocketToPairData", "connecting cSocket to pair data", accountSolidity, cSocket.connected);
        if (socketClient.connected) {
            socketClient.disconnect();
        }
        if (cSocket.connected) {
            cSocket.disconnect();
        }
        cSocket = initialiseClientSocket();
        cSocket.connect();
        // cSocket.join(`${accountSolidity}`);
        cSocket.on("item_mint", (payload) => {
            const date = new Date();
            console.log((date.toLocaleTimeString() + '-' + date.getMilliseconds()), "SOCKETLOG", "new_item_mint received", payload);
        });

        socketClient = cSocket;
        // MyCustomEventEmitter.dispatch("socketInitialised");
    } catch (e) {
        console.log("connectSocketToPairData", e);
        return false;
    }
    return false;
}


MyCustomEventEmitter.subscribe("pairingDataFound", () => {
    console.log("pairingDataFound", "connecting socket to pair data");
    if (getSocketClient().connected && getSocketClient().auth !== undefined) {
        console.log("pairingDataFound", "socket already connected, auth = ", getSocketClient().auth);
        return;
    }
    connectSocketToPairData();
});