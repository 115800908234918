import * as React from "react";
import {useEffect, useState} from "react";
import {GetInventorySets} from "../../connector/GetInventoryList";
import {InventoryPortalNew} from "../../component/Portal/Portal";
import './InventoryNew.css';
import {PATH_NAME} from "../../helper/ENUMS.ts";
import UpperBoundManipulator from "../../component/UpperBoundManipulator/UpperBoundManipulator";
import InventoryCardModule from "../../component/InventoryCardModule/InventoryCardModule";
import SelectedSetItems from "../../component/SelectedSetItems/SelectedSetItems"

let carouselGlobalSet, setCarouselGlobalSet;
let carouselGlobalIndex, setCarouselGlobalIndex;

export const putOnNewSetItem = (item) => {
    let newBanans = carouselGlobalSet;
    let newBanan = newBanans[newBanans.length - 1];
    console.log('putOnNewSetItem', newBanan);
    newBanan.itemCards[item.setPiece] = item;
    console.log(newBanan);
    newBanan.damages = [0, 0, 0];
    newBanan.itemCards.map(item => {
        if (item !== undefined && item !== null) {
            newBanan.damages[item.faction] += item.damage;
        }
    });
    // console.log(newBanans);
    console.log('putOnNewSetItem', newBanans);
    newBanans[newBanans.length - 1] = newBanan;
    setCarouselGlobalSet([...newBanans]);
    setCarouselGlobalIndex(newBanans.length - 1);
}

export const UpperBlock = ({
                               carouselSetsParentState,
                               setCarouselSetsParentState,
                               carouselIndexParentState,
                               setCarouselIndexParentState,
                               PARENT_PATH_NAME,
                           }) => {

    carouselGlobalIndex = carouselIndexParentState;
    setCarouselGlobalIndex = setCarouselIndexParentState;
    carouselGlobalSet = carouselSetsParentState;
    setCarouselGlobalSet = setCarouselSetsParentState;

    const [carouselIndexLength, setCarouselIndexLength] = useState(0);

    useEffect(() => {
        GetInventorySets().then((sets) => {
            const {banans: bananas} = sets;

            if (bananas !== undefined && bananas.length > 0) {
                setCarouselGlobalIndex(bananas.length - 1);
                setCarouselIndexLength(bananas.length);
            }

            // if (allowNewSet) {
                // new banan :
                bananas.push({
                    itemCards: [null, null, null, null],
                    damages: [0, 0, 0],
                });
            // } else {
                // setCarouselIndexLength(bananas.length-1);
                // setCarouselGlobalIndex(carouselGlobalIndex-1);
            // }

            setCarouselGlobalSet(bananas);
        }).catch((x) => {
            console.error(x);
        });
    }, []);

    return (
        <>
            <div id={'top-manipulator'} className={'flex-container-columns'}>
                <UpperBoundManipulator type={PARENT_PATH_NAME} properties={{
                    lastIndex: carouselIndexLength,
                    carouselIndex: carouselGlobalIndex,
                    setCarouselIndexFunc: setCarouselGlobalIndex,
                }}/>
            </div>
            <div id={'inventory-showcase'} className={'flex-container-columns'}>
                <div id={'arsenal-selection'} className={'flex-container-rows'}>
                    <SelectedSetItems carouselSet={carouselGlobalSet[carouselGlobalIndex]}/>
                </div>
                <div id={'banana-profile'}>
                    <InventoryPortalNew carouselSets={carouselGlobalSet} carouselIndex={carouselGlobalIndex}
                                        setSelectedSet={null}
                                        setCarouselIndexFunc={setCarouselGlobalIndex}/>
                </div>
            </div>
        </>
    );
}

const InventoryNew = ({page}) => {


    const [selectedSetIndex, setSelectedSetIndex] = useState(0);
    const [carouselSets, setCarouselSets] = useState([]);

    const BottomHalf = () => {
        if (page === PATH_NAME.INVENTORY) {
            return (
                <div id={'card-module'}>
                    <InventoryCardModule/>
                </div>
            )
        } else if (page === PATH_NAME.ARENA_PICKER) {
            return (
                <div id={'card-module'}>
                </div>
            )
        } else {
            return <></>;
        }
    }

    return (
        <div id={'inventory'} className={'page full-width'}>
            <div id={'upper-block'} className={'container-1600 flex-container-rows'}>
                <UpperBlock carouselSetsParentState={carouselSets} setCarouselSetsParentState={setCarouselSets}
                            carouselIndexParentState={selectedSetIndex}
                            setCarouselIndexParentState={setSelectedSetIndex} allowNewSet={true} PARENT_PATH_NAME={PATH_NAME.INVENTORY}/>
                {/*<BottomHalf/>*/}
                <div id={'card-module'}>
                    <InventoryCardModule/>
                </div>
            </div>
        </div>
    )
}

export default InventoryNew;