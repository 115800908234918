import React, {useState} from 'react';
import './InventoryCardModule.css';
import {IoMdRefresh} from "react-icons/io";
import {BsArrowDown, BsArrowUp} from "react-icons/bs";
import {BiDownArrowAlt, BiUpArrowAlt} from "react-icons/bi";
import {FaArrowDown, FaArrowUp} from "react-icons/fa";
import MintedItemsComponent, {redrawMintedItems} from "../CardList/MintedItemsComponent";
import PreMintedItemsComponent, {redrawPreMintedItems} from "../CardList/PreMintedItemsComponent";

const RefreshCards = () => {
    return (
        <div id={'refresh-cards'} className={'flex-container-columns'}>
            <button className={`button btn secondary`} onClick={() => {
                redrawPreMintedItems();
                redrawMintedItems().then(() => {});
                // TODO: rework with Promise.all after we swap pre-minted items to async func
            }}>REFRESH&nbsp;<IoMdRefresh/></button>
        </div>
    );
}

const SortCards = ({sortObj, sortByIdFunc, sortByDmgFunc}) => {

    const IdSortArrow = () => {
        switch (sortObj.id) {
            case 1:
                return <FaArrowUp/>;
            case -1:
                return <FaArrowDown/>;
            default:
                return <></>;
        }
    }

    const DmgSortArrow = () => {
        switch (sortObj.damage) {
            case 1:
                return <FaArrowUp/>;
            case -1:
                return <FaArrowDown/>;
            default:
                return <></>;
        }
    }

    return (
        <div id={'sort-cards'} className={'flex-container-columns'}>
            <span className={`label`}>SORT BY: </span>
            <button className={'btn button secondary'} onClick={() => {
                sortByDmgFunc();
            }}>DAMAGE&nbsp;<DmgSortArrow/></button>
            <button className={'btn button secondary'} onClick={() => {
                sortByIdFunc();
            }}>#ID&nbsp;<IdSortArrow/></button>
        </div>
    );
}

const FilterCards = ({changeSetPieceFunc, changeFactionFunc, currentFilter}) => {

    const setPieceSelected = (index) => {
        return (currentFilter.setPiece === index);
    }
    const factionSelected = (index) => {
        return (currentFilter.factions[index]);
    }

    const selectSetPiece = (setPiece) => {
        if (setPieceSelected(setPiece)) {
            changeSetPieceFunc(null);
        } else {
            changeSetPieceFunc(setPiece);
        }
    }

    const selectFaction = (faction) => {
        changeFactionFunc(faction);
    }

    const setPieces = ['PEEL', 'HELMET', 'ARMOUR', 'WEAPON'];
    const factions = ['RED', 'GREEN', 'BLUE'];

    return (
        <div id={'filter-cards'} className={'flex-container-columns'}>
            <div id={'filter-groups'} className={'flex-container-columns'}>
                <span className={`label`}>FILTER: </span>
                {/*<div id={'filter-groups'} className={'flex-container-columns'}>*/}
                <div className={'flex-container-columns filter-group'}>
                    {/*<button className={'btn button secondary'}>ALL</button>*/}
                    {
                        setPieces.map((setPiece, index) => {
                            return (
                                <button
                                    className={`btn button secondary ${currentFilter.setPiece === null || setPieceSelected(index) ? 'selected' : ''} item-filter filter-index-${index}`}
                                    key={index} onClick={() => {
                                    selectSetPiece(index)
                                }}>{setPiece}</button>
                            )
                        })
                    }
                </div>
            </div>

                <div className={'flex-container-columns filter-group'}>
                    {
                        factions.map((faction, index) => {
                            return (
                                <button
                                    className={`btn button secondary ${faction.toLowerCase()} ${factionSelected(index) ? 'selected' : ''}`}
                                    key={index} onClick={() => {
                                    selectFaction(index)
                                }}>{faction}</button>
                            )
                        })
                    }
                </div>
            {/*</div>*/}
        </div>
    );
}

const InventoryCardModule = () => {

    const [filterArray, setFilterArray] = useState(
        {
            'factions': [true, true, true],
            'setPiece': null
        }
    );

    const [sortArray, setSortArray] = useState({
        damage: 0,
        id: -1
    });

    const changeSetPiece = (setPiece) => {
        setFilterArray({...filterArray, setPiece: setPiece});
    }
    const changeFaction = (faction) => {
        let newFactions = filterArray.factions;
        newFactions[faction] = !newFactions[faction];
        setFilterArray({...filterArray, factions: newFactions});
    }

    const sortById = () => {
        if (sortArray.id === 1) {
            setSortArray({...sortArray, damage: 0, id: -1});
        } else {
            setSortArray({...sortArray, damage: 0, id: 1});
        }
    }

    const sortByDamage = () => {
        if (sortArray.damage === 1) {
            setSortArray({...sortArray, id: 0, damage: -1});
        } else {
            setSortArray({...sortArray, id: 0, damage: 1});
        }
    }

    return (
        <div id={'card-module-container'} className={'container-1600 flex-container-rows'}>
            <div className={'divider'}/>
            <div id={'card-module-manipulator'} className={'flex-container-columns'}>
                <div className={'card-manipulator-top-level flex-container-columns'}>
                    <RefreshCards/>
                    <SortCards sortObj={sortArray} sortByIdFunc={sortById} sortByDmgFunc={sortByDamage}/>
                </div>
                <FilterCards changeSetPieceFunc={changeSetPiece} changeFactionFunc={changeFaction}
                             currentFilter={filterArray}/>
            </div>
            <div className={'divider'}/>
            <div id={'card-module-lists'} className={'flex-container-columns'}>
                <div id={'minted-cards-list'} className={'flex-container-rows'}>
                    <MintedItemsComponent filterArray={filterArray} sortObject={sortArray}/>
                    {/*<div className={'clearfix'}/>*/}
                </div>
                <div id={'preminted-cards-list'} className={'flex-container-rows'}>
                    <PreMintedItemsComponent filterArray={filterArray} sortObject={sortArray}/>
                </div>
            </div>
        </div>
    );
}

export default InventoryCardModule;