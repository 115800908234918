import React, {useEffect, useState} from 'react';
import './Distribution.css';
import {CurrencyIcon, GovIcon} from "../../App";
import {FaExternalLinkAlt} from "react-icons/fa";
import {assetsHost} from "../../helper/externalLinks";
import Slider from "rc-slider";
import {DaVinciGraphLockCardProvider} from "../../component/DaVinciLocker/DavinciLocker";
import {getAccountId} from "../../connector/blockchain/hashconnect";
import {getAccountBalance} from "../../connector/blockchain/Hedera/mirrorNode";
import {initHashConnectV1_5} from "../../connector/blockchain/hashconnect_v1_5";

export const pairingTokens = [
    {key: `token-0`, name: 'USDC', balance: 0, decimals: 6, hts: true, icon: 'USDC_HTS.png', tokenId: `0.0.1055459`},
    {key: `token-1`, name: 'USDC', balance: 0, decimals: 6, hts: false, icon: 'USDC.png', tokenId: `0.0.456858`},
    {key: `token-2`, name: 'USDT', balance: 0, decimals: 6, hts: true, icon: 'USDT_HTS.png', tokenId: `0.0.1055472`},
];

let pairingTokenBalance = null, setPairingTokenBalance = null;

const PairingTokenOption = ({token, onClickEvent, selectedKey}) => {
    return (
        <div className={'flex-container-rows '}>
            <div
                className={`pairing-token-option flex-container-columns ${token.key === selectedKey ? 'selected' : ''}`}
                onClick={() => {
                    // console.log(selectedToken);
                    onClickEvent(token);
                }}>
                <img src={`${assetsHost}/assets/global/logos/tokens/${token.icon}`} className={`icon token`}/>
                <div className={`label flex-container-columns`}>{token.name}{token.hts === true ?
                    <span className={`hts`}>&nbsp;(hts)</span> : null}</div>
            </div>
            <span>balance: {Number(token.balance / (10 ** token.decimals)).toFixed(2)}</span>
        </div>
    );
}

const PairingTokenPicker = ({tokens, onClickEvent, selectedKey}) => {


    return (
        <div className={'pairing-token-picker flex-container-columns form-row'}>
            <h3 className={'split-30'}>Payment token: </h3>
            <div className={'split-70 pairing-options-container flex-container-columns'}>
                {tokens.map((token, index) => {
                    return <PairingTokenOption token={token} key={token.key} onClickEvent={onClickEvent}
                                               selectedKey={selectedKey}/>
                })
                }
            </div>

        </div>
    );
}

const PaymentAmountSelector = ({
                                   sellingTokenAmount,
                                   maxSellingTokenAmount,
                                   updateSellingTokenAmount,
                                   selectedToken,
                                   selectedKey
                               }) => {
    return (
        <div className={'distribution-form-inputs flex-container-columns  form-row'}>
            <h3 className={'split-30'}>Payment amount:</h3>
            <div className={'split-70 amount-selector-container flex-container-columns'}>
                <Slider
                    trackStyle={[{backgroundColor: '#febd4e'}]}
                    handleStyle={{backgroundColor: '#ffd152', border: '2px solid #ffd152'}}
                    activeDotStyle={{boxShadow: '0 0 0 5px #fff, 0 0 0 10px red'}}
                    railStyle={{backgroundColor: 'black'}}
                    dotStyle={{backgroundColor: 'purple'}}
                    step={1} // minimal step, we can't send 1.5 into SC
                    max={maxSellingTokenAmount}
                    min={100}
                    value={sellingTokenAmount}
                    onChange={updateSellingTokenAmount}
                    keyboard={true}
                />
                <div className={'amount-selector-input flex-container-columns'}>
                    <img className={`icon selected-token`}
                         src={`${assetsHost}/assets/global/logos/tokens/${selectedToken ? selectedToken.icon : null}`}/>
                    <input type={"number"} value={sellingTokenAmount / 100} max={maxSellingTokenAmount}
                           onChange={(e) => updateSellingTokenAmount(e.target.value * 100)}/>
                </div>
            </div>
        </div>
    );
}

const MathCalculation = ({sellingTokenAmount, selectedToken, currentGovTokenPrice, govTokenAmount, selectedKey}) => {
    return (
        <div id={`form-calculation`} className={`flex-container-columns  form-row`}>
            <h3 className={'split-30'}>You receive:</h3>
            <div className={'split-70 flex-container-rows'}>
                <div id={'math-calculation'} className={'flex-container-columns'}>

                    {sellingTokenAmount / 100}<img className={`icon `}
                                                   alt={`${selectedToken ? selectedToken.name : 'undefined'} token icon, token key ${selectedKey}`}
                                                   src={`${assetsHost}/assets/global/logos/tokens/${selectedToken ? selectedToken.icon : null}`}/> X <span>{currentGovTokenPrice}</span> ≈ <span>{(govTokenAmount / 100).toFixed(8)} </span><GovIcon/>
                </div>
            </div>
        </div>
    );
}

const Distribution = () => {

    initHashConnectV1_5();

    const [sellingTokenAmount, setSellingTokenAmount] = useState(10);
    let [maxSellingTokenAmount, setMaxSellingTokenAmount] = useState(1_000); // todo get this from mirror
    const [currentGovTokenPrice, setCurrentGovTokenPrice] = useState(0.038); // todo get this from mirror
    const [govTokenAmount, setGovTokenAmount] = useState(sellingTokenAmount / currentGovTokenPrice); // todo get this from mirror
    const updateSellingTokenAmount = (newValue) => {
        if (newValue > maxSellingTokenAmount) {
            newValue = maxSellingTokenAmount;
        }
        if (newValue < 100) {
            newValue = 100;
        }
        setSellingTokenAmount(newValue);
        setGovTokenAmount(newValue / currentGovTokenPrice);
    }

    const [selectedToken, setSelectedToken] = useState(pairingTokens[1]);
    const [selectedKey, setSelectedKey] = useState(pairingTokens[1].key);
    const onClickEvent = (tkn) => {
        setSelectedToken(tkn);
        setSelectedKey(tkn.key);
        setMaxSellingTokenAmount(Math.max((tkn.balance / (10 ** (tkn.decimals)) * 100), 100));
    }

    useEffect(() => {
        getAccountBalance(getAccountId()).then((response) => {
            console.log(`token response : `, response);
            console.log(`pairingTokens : `, pairingTokens);
            pairingTokens.map((token) => {
                console.log(`token ${token.tokenId} balance: ${token.name}`);
                const tokenBalance = response.find((tokenBalance) => {
                    return tokenBalance.token_id === token.tokenId;
                });
                if (tokenBalance !== undefined && tokenBalance.balance !== undefined && tokenBalance.balance > 0) {
                    token.balance = tokenBalance.balance;
                }
            });
            setSelectedKey(pairingTokens[0].key);
        });
    }, [getAccountId()]);

    function adjustDecimals(num) {
        return num.toFixed(0);
        // Convert input string to a number and store as a variable.
        var value = String(num);
        // console.log(value);
        // Split the input string into two arrays containing integers/decimals
        var res = value.split(".");
        // If there is any decimals
        if (res.length > 1 || res[1] !== undefined && res[1].length > 8) {
            // Set the number to 8 decimal places
            value = Number(value).toFixed(8);
        }
        // Return updated or original number.
        return String(value);
    }

    function componentDidMount() {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = "(function (d) {\n" +
            "                                    var s, p = d.getElementsByTagName('script')[0];\n" +
            "                                    s = d.createElement('script');\n" +
            "                                    s.id = 'lock-card-script';\n" +
            "                                    s.src = 'https://davincigraph.network/lockCard.js';\n" +
            "                                    s.async = true;\n" +
            "\n" +
            "                                    s.onload = function () {\n" +
            "                                    createLock();\n" +
            "                                }\n" +
            "\n" +
            "                                    p.parentNode.insertBefore(s, p);\n" +
            "                                })(document);";
        this.instance.appendChild(s);
    }

    return (
        <div id={`distribution-page`}>
            <div id={'distribution-container'} className={'full-width flex-row-full flex-container-rows'}>
                <h1>BANANA.CAPITAL Token Distribution Page</h1>
                <div className={'container-1600 flex-container-rows iterations'}>
                    <div id={`gov-distribution-main`} className={'flex-container-columns'}>
                        <div id={`contract-box-preview`} className={'split-20 flex-container-rows'}>
                            <h2> Contracts </h2>
                            {/*<p> Below you can find complete Smart Contract code for both main distributor and our*/}
                            {/*    founders lock-up pools.</p>*/}
                            <div className={`contract-box`}>
                                <h3> Main Distributor: </h3>
                                <p>This contract is responsible for distributing tokens
                                    until <span>1 960 000 <GovIcon/></span> liquidity
                                    on open market is reached.</p>
                                <button className={`button btn gist-link`}>View on GitHub<FaExternalLinkAlt/>
                                </button>
                                <p className={`small`}>This code is protected by intrinsic copyright & EU laws and is
                                    hereby
                                    not to be used without YV Team consent. The code is published mainly to maintain
                                    transparency towards our investors. </p>
                            </div>
                            <div className={`contract-box`}>
                                <DaVinciGraphLockCardProvider>
                                    <h3> Founders Lock-up: </h3>
                                    <div className="davincigraph-lock"
                                         data-token-ids="0.0.3411916"
                                         data-lock-type="Lock"
                                         data-theme="Dark"
                                         data-layout="Classic"
                                         data-show-header="false"
                                         data-show-expiration="true"
                                         data-show-count-down="true"
                                    />
                                </DaVinciGraphLockCardProvider>
                            </div>
                        </div>
                        <div id={`token-distribution`} className={'split-60 flex-container-rows'}>
                            <div id={`token-distribution-title`} className={'flex-container-columns'}>
                                <div className={'split-30'}></div>
                                <div className={'split-70'}>
                                    <h2> Token Distribution </h2></div>
                            </div>
                            <div className={`distribution-form flex-container-rows`}>

                                <PairingTokenPicker tokens={pairingTokens} onClickEvent={onClickEvent}
                                                    selectedKey={selectedKey}/>
                                <PaymentAmountSelector maxSellingTokenAmount={maxSellingTokenAmount}
                                                       sellingTokenAmount={sellingTokenAmount}
                                                       updateSellingTokenAmount={updateSellingTokenAmount}
                                                       selectedToken={selectedToken} selectedKey={selectedKey}/>
                                <MathCalculation sellingTokenAmount={sellingTokenAmount}
                                                 currentGovTokenPrice={currentGovTokenPrice}
                                                 govTokenAmount={govTokenAmount} selectedToken={selectedToken}
                                                 selectedKey={selectedKey}/>
                                <span className={`spoiler`}> Approx. amount blah blah blah </span>

                                <div className={'distribution-form-buttons flex-container-columns actions'}>
                                    <button id={`buy-button`}
                                            className={`btn button main flex-container-columns`}>
                                        <div
                                            className={`split-80 flex-container-rows`}>BUY {adjustDecimals(govTokenAmount)}
                                            <span className={`bold`}>$BANANA</span></div>
                                        <div className={`split-20`}><GovIcon/></div>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Distribution;
