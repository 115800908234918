import axios from "axios";
import {getHederaMirrorHost, HEDERA_CONTRACTS} from "../../config";

const mirrorHost = getHederaMirrorHost();

/**
 *
 * @param accountId
 * @param spenderId
 * @returns {Promise<{amount: *, allowanceGiver: *, allowanceTaker: *, timestamp: *} | {}>}
 */
export const getAccountTokenAllowance = async (accountId, spenderId) => {
    return axios.get(`${mirrorHost}/api/v1/accounts/${accountId}/allowances/tokens`, {
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
            'Authorization-Web3': null,
            // "Access-Control-Allow-Origin": "*",
        },
        params: {
            'token.id': HEDERA_CONTRACTS.YV_CURRENCY.TESTNET,
            'spender.id': spenderId,
        },
        responseType: "json",
    }).then((mirrorNodeTokensResponse) => {
        if (mirrorNodeTokensResponse.data.allowances.length === 0) {
            return {
                amount: 0,
                allowanceGiver: accountId,
                allowanceTaker: spenderId,
                timestamp: null
            }
        }

        const allowance = mirrorNodeTokensResponse.data.allowances[0];
        console.debug(allowance, `${mirrorHost}/api/v1/accounts/${accountId}/allowances/tokens`);
        const {spender, owner, amount_granted, timestamp} = allowance;
        return {
            amount: amount_granted,
            allowanceGiver: owner,
            allowanceTaker: spender,
            timestamp: timestamp,
        }
    })
        .catch((x) => {
            console.error(`accountId: ${accountId}`, `spenderId: ${spenderId}`, x);
            return {};
        })
        ;

}

export const getAccountBalance = (accountId, tokensToFilter = []) => {
    return axios.get(`${mirrorHost}/api/v1/balances/`, {
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
            'Authorization-Web3': null,
            // "Access-Control-Allow-Origin": "*",
        },
        params: {
            'account.id': accountId,
            'limit' : 1000,
            //     'spender.id': spenderId,
        },
        responseType: "json",
    }).then((mirrorNodeTokensResponse) => {
        const balance = mirrorNodeTokensResponse.data.balances[0];
        if (balance === undefined) {
            return null;
        }
        if (balance.account !== accountId) {
            return null;
        }
        if (balance.tokens === undefined || balance.tokens.length === 0) {
            return null;
        }
        if (tokensToFilter.length === 0) {
            console.log('tokens', balance.tokens);
            return balance.tokens;
        }

        const filteredTokens = balance.tokens.filter((token) => {
            return tokensToFilter.includes(token.token_id);
        });

        console.log('tokens', balance.tokens)
        console.log('filteredTokens', filteredTokens)

        return filteredTokens;

        // if (mirrorNodeTokensResponse.data.allowances.length === 0 ) {
        //     return {
        //         amount: 0,
        //         allowanceGiver: accountId,
        //         allowanceTaker: spenderId,
        //         timestamp: null
        //     }
        // }
        //
        // const allowance = mirrorNodeTokensResponse.data.allowances[0];
        // console.debug(allowance, `${mirrorHost}/api/v1/accounts/${accountId}/allowances/tokens`);
        // const {spender, owner, amount_granted, timestamp} = allowance;
        // return {
        //     amount: amount_granted,
        //     allowanceGiver: owner,
        //     allowanceTaker: spender,
        //     timestamp: timestamp,
        // }
    })
        .catch((x) => {
            // console.error(`accountId: ${accountId}`, `spenderId: ${spenderId}`, x);
            return {};
        })
        ;
}