import axios from "axios";

export const CONFIG_CALL_TYPE = {
    GET_ACCOUNT_IDS: "getAccountIds",
    ITEM_MINT_PAY_FEE: "ITEM_MINT_PAY_FEE",
    ITEM_MINT: "ITEM_MINT_PAY_FEE", // label for ^
    SET_MINT_PAY_FEE: "FULL_SET_MINT_PAY_FEE",
    ACTIVE_MATCHMAKING: "MATCHMAKE_OR_ENTER_ACTIVE_POOL",
    PASSIVE_MATCHMAKING: "ENTER_PASSIVE_POOL",
    PURCHASE_FACTION_BOX: "LOOT_BOX_PURCHASE",
    UNPACK_ROOKIE_SET: "LOOT_BOX_UNPACK_ROOKIE",
}

const contentLock = process.env.REACT_APP_NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'testnet-vercel';
export const CONTRACT_ENV = contentLock ? 'TESTNET_' : 'MAINNET_';

/**
 *
 * @param CALL_TYPE
 * @returns {`TESTNET_${string}`|string}
 */
const getCallWithPrefix = (CALL_TYPE) => {
    if (CALL_TYPE.startsWith(`MAINNET_`)) {
        return `${CALL_TYPE}`;
    }
    if (CALL_TYPE.startsWith(`TESTNET_`)) {
        return `${CALL_TYPE}`;
    }
    return `${CONTRACT_ENV}${CALL_TYPE}`;
}

/**
 * @param {string | CONFIG_CALL_TYPE} CALL_TYPE
 * @returns {contractId: string, contractFunc: string, gasLimit: number}
 **/
export const getConfigFor = async (CALL_TYPE) => {
    localStorage.removeItem('hederaCommunicationConfig');
    const storedHederaCommunicationConfig = localStorage.getItem('hederaCommunicationConfig');
    if (storedHederaCommunicationConfig === undefined || storedHederaCommunicationConfig === null || storedHederaCommunicationConfig.length === 0) {
        console.log("storedHederaCommunicationConfig not defined");
        const backEndHost = process.env.REACT_APP_BACK_END_HOST;
        await axios.get(backEndHost + "/config/contract-call-defaults", {
            headers: {
                'Content-Type': "application/json",
                'Accept': "application/json",
                // "Access-Control-Allow-Origin": "*",
            },
            responseType: "json",
        })
            .then(function (response) {
                console.log('storedHederaCommunicationConfig parsed defaults: ', response.data);
                localStorage.setItem("storedHederaCommunicationConfig", JSON.stringify(response.data.contractDefaults));
            })
            .catch(function (response) {
                console.log('axios error occurred', response);
            })
        ;
    }

    const hederaConfig = JSON.parse(localStorage.getItem("storedHederaCommunicationConfig"));
    const callType = getCallWithPrefix(CALL_TYPE);
    console.log("searching storedHederaCommunicationConfig for  ", callType);
    const {contract_func = `${callType}_FUNC_MISSING`, contract_id, gas} = hederaConfig.filter(({code_name}) => code_name === callType)[0];

    return {
        contractId: contract_id,
        contractFunc: contract_func,
        gasLimit: gas,
    };

}