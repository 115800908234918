import './Store.css';
// import React from 'react';
import React from "react";
import 'rc-slider/assets/index.css';
import StoreRightSide from "./StoreRightSide/StoreRightSide";
import StoreLeftSide from "./StoreLeftSide";

const Store = () => {
    return (
        <div id={'store-page'} className={'page-contents'}>

            <div id={'footer'}>
                {/*{`not footer technically`}*/}
                <div id={'ground'}></div>
                {/*<div id={'flower-left'}/>*/}
                {/*<div id={'flower-right'}/>*/}
            </div>
            <StoreLeftSide/>
            <StoreRightSide/>

            <img src={'https://asset-host.b-cdn.net/assets/store/cocount.png'} id={'coco'}/>
        </div>
    );
}

export default Store;