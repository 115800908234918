import React, {useEffect, useState} from 'react';
import {ASSOCIATIONS, EVENT_TYPE, FACTION, MODAL_TYPE} from "../../helper/ENUMS.ts";
import './LootBoxHeaderComponent.css';
import {FiRefreshCw} from "react-icons/fi";
import axios from "axios";
import {getHederaMirrorHost, HEDERA_CONTRACTS} from "../../connector/config";
import {getAccountIds} from "../../connector/blockchain/hashconnect";
import MyCustomEventEmitter from "../myCustomEvents";
import {CurrencyIcon} from "../../App";
import {getYVCurrencyAddress} from "../../helper/mirrrorNode";
import ToolTip from "../Modal/ToolTip";
import {NavLink} from "react-router-dom";
import {UnpackModalOld} from "../Modal/Modal";
import UnpackModal from "../Modal/UnpackModal";
import CurrencyBalanceComponent from "./CurrencyBalanceComponent";

let internalUpdateFunc;
let associationDefault = {tokens: [null, null, null]};
let associations = (
        JSON.parse(localStorage.getItem('associatedTokens'))
            ? JSON.parse(localStorage.getItem('associatedTokens'))
            : associationDefault
    )
;

export const getAssociations = () => {
    console.log(associations);
    return (associations === undefined) ? associationDefault : associations;
}


export const refreshLootBoxBalance = () => {
    const urlHost = process.env.REACT_APP_BACK_END_HOST;
    axios.get(urlHost + "/store/lootbox-balance", {
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
            // "Access-Control-Allow-Origin": "*",
        },
        responseType: "json",
    }).then((lootBoxBalanceResponse) => {
        const {lbalance} = lootBoxBalanceResponse.data;
        if (lbalance) {
            lbalanceReceived(lbalance);
        }
    })
        .catch((x) => {
            console.error(x);
        })
    ;
}

export const resetLootBoxBalance = () => {
    lbalanceReceived({
        BLU: 0,
        GRE: 0,
        RED: 0
    });
}

export const lbalanceReceived = (lbalance) => {
    const {BLU, GRE, RED} = lbalance;
    const lootBalanceArr = {};
    lootBalanceArr[FACTION.RED] = RED;
    lootBalanceArr[FACTION.GREEN] = GRE;
    lootBalanceArr[FACTION.BLUE] = BLU;
    localStorage.setItem('lootboxBalance', JSON.stringify(lootBalanceArr));
    externalLootBoxLabelUpdate(lootBalanceArr);
}

let internalComponentBalances = {
    0: 0,
    1: 0,
    2: 0
};

export const getLootFromHeaderComponent = (index) => {
    return internalComponentBalances[index];
};

const LootBoxHeaderComponent = () => {

    const [lootBoxLabel, setLootBoxLabel] = useState([0, 0, 0]);
    const [YVCurrencyBalance, setYVCurrencyBalance] = useState(0);
    const [isSpinning, setSpinning] = useState(false);
    internalUpdateFunc = (newLabelValue) => {
        setSpinning(true);
        new Promise(resolve => setTimeout(resolve, 3250)) // delay to let mirror node update
            .then(() => {
                    setLootBoxLabel(newLabelValue);
                    setSpinning(false);
                }
            );
    }

    const updateYVCurrencyBalance = async () => {
        setSpinning(true);
        setYVCurrencyBalance(`-.--`);
        const YVCurrencyAddress = await getYVCurrencyAddress();
        const mirrorHost = getHederaMirrorHost();
        console.log("updateYVCurrencyBalance launched");
        if (getAccountIds()) {
            console.log("updateYVCurrencyBalance account found");
            const accountId = getAccountIds()[0];
            axios.get(`${mirrorHost}/api/v1/accounts/${accountId}/tokens`, {
                headers: {
                    'Content-Type': "application/json",
                    'Accept': "application/json",
                    'Authorization-Web3': null,
                    // "Access-Control-Allow-Origin": "*",
                },
                responseType: "json",
            }).then((mirrorNodeTokensResponse) => {
                const tokensFound = mirrorNodeTokensResponse.data.tokens;
                const YVCurrency = tokensFound.find(element => element.token_id === YVCurrencyAddress);
                let YVCurrencyParsedBalance = 0;
                if (YVCurrency !== undefined) {
                    console.log("YVCurrency found ", YVCurrency);
                    YVCurrencyParsedBalance = YVCurrency.balance / (10 ** 4); // TODO set decimals
                    console.log("YVCurrency balance ", YVCurrencyBalance);
                    if (YVCurrency.balance > 0) {
                        if (associations[ASSOCIATIONS.CURRENCY] === null || associations[ASSOCIATIONS.CURRENCY] === false) {
                            associations[ASSOCIATIONS.CURRENCY] = true;
                            localStorage.setItem('associatedTokens', JSON.stringify({tokens: associations}));
                        }
                        console.log(`YVCurrency and other associations: `, associations);
                    }
                } else {
                    console.log("YVCurrency not found, association may be required");
                    associations = (JSON.parse(localStorage.getItem('associatedTokens')) || associations).tokens;
                    associations[ASSOCIATIONS.CURRENCY] = false;
                    localStorage.setItem('associatedTokens', JSON.stringify({tokens: associations}));
                }

                setYVCurrencyBalance(YVCurrencyParsedBalance);
                localStorage.setItem('YVCurrencyBalance', `${YVCurrencyParsedBalance}`);
                setSpinning(false);
            })
                .catch((x) => {
                    console.error(x);
                    setSpinning(false);
                })
            ;
        } else {
            console.log("updateYVCurrencyBalance account not found ")
        }
    }

    useEffect(
        () => {
            if (localStorage.lootboxBalance) {
                externalLootBoxLabelUpdate(JSON.parse(localStorage.getItem("lootboxBalance")))
            }
        }, // <- function that will run on every dependency update
        [] // <-- empty dependency array, effect runs once
    )

    const handleLootBoxRefresh = () => {
        setSpinning(true);
        refreshLootBoxBalance();
    }

    const [unpackingFaction, setUnpackingFaction] = useState(0);
    const [unpackingModalOpenState, setUnpackingModalOpenState] = useState(false);
    const userOpenedFactionToolTip = (faction) => {
        setUnpackingFaction(faction);
    }

    const handleUnpack = (faction) => {
        setUnpackingFaction(faction);
        setUnpackingModalOpenState(true);
        // console.log("handleUnpack", unpackingFaction);
        MyCustomEventEmitter.dispatch(EVENT_TYPE.OPEN_ITEM_UNPACKING_MODAL, {faction: faction});
    }

    const formatYVCurrencyBalance = (yvBalance) => {
        if (yvBalance !== undefined && yvBalance !== null && typeof(yvBalance) === "number" ) {
            return yvBalance.toFixed(2);
        }
        return yvBalance || '-.--';
    }


    const HeaderLootBoxToolTip = ({faction}) => {
        const numOfLootBoxes = lootBoxLabel[faction];
        const factionLabel = FACTION[faction].toLowerCase();
        const lootBoxToolTipContent = (
            <div className={'lootbox-header-open-tooltip flex-container-rows'}>
                <p>You have <span className={`${factionLabel}`}> {numOfLootBoxes} {factionLabel} </span>
                    lootboxes
                </p>
                {numOfLootBoxes > 0 ?
                    <button className={'button btn'} onClick={() => {
                        handleUnpack(faction);
                    }}>OPEN ONE</button>
                    :
                    <NavLink to="/store">
                        <button className={'button btn secondary'}>Buy some in store</button>
                    </NavLink>}
            </div>
        );
        const lootBoxToolTipTrigger = (
            <div className={`${factionLabel} lootbox-click`}>
                <img
                    src={`https://asset-host.b-cdn.net/assets/store/${factionLabel}_box.png`}/> {numOfLootBoxes}
            </div>
        );


        return (
            <ToolTip keepInside={true}
                     onActions={['click']}
                     trigger={lootBoxToolTipTrigger}
                     position={'bottom center'}
                     content={lootBoxToolTipContent}
                     nested={true}
                // onDocumentClick={false}
                // onOpen={() => userOpenedFactionToolTip(faction)}
            />
        );
    }

    return (
        <div id={'currency-header'}>
            {/*<div id={'lootbox-label-header'}>
                <span className={'label label-lootboxes'}>LOOTBOXES:</span>
                <div id={'lootbox-count-header'}>
                    <div className={'red'}><img
                        src={'https://asset-host.b-cdn.net/assets/store/red_box.png'}/> {lootBoxLabel[FACTION.RED]}
                    </div>
                    <HeaderLootBoxToolTip faction={0}/>
                    <HeaderLootBoxToolTip faction={1}/>
                    <HeaderLootBoxToolTip faction={2}/>
                    <UnpackModal
                        modalType={MODAL_TYPE.UNPACK_BOX}
                        modalTitle={'Unpacking item'}
                        // itemCard={useState(null)}
                        buttonContent={null}
                        buttonFunctionParams={unpackingFaction}
                        // isFinished={unpackIsFinished}
                        openState={[unpackingModalOpenState, setUnpackingModalOpenState]}
                        disabled={false}
                    />
                    <div className={'blue'}><img
                        src={'https://asset-host.b-cdn.net/assets/store/blue_box.png'}/> {lootBoxLabel[FACTION.BLUE]}
                    </div>
                </div>
            </div>*/}
            <CurrencyBalanceComponent/>
        </div>
    );
}

export const externalLootBoxLabelUpdate = (newLBalance) => {
    internalUpdateFunc(newLBalance);
    internalComponentBalances = newLBalance;
    console.log("externalLootBoxLabelUpdate", newLBalance);
    MyCustomEventEmitter.dispatch("lootBoxBalanceUpdated", newLBalance);
}

export default LootBoxHeaderComponent;