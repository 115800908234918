import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getDuelDetails} from "../../connector/GetInventoryList";
import {ARENA_POOLS, DUEL_PLAYER, DUEL_VIEWED, PATH_NAME} from "../../helper/ENUMS.ts";
import DuelArenaPortal from "../../component/Portal/DuelArenaPortal";
import UpperBoundManipulator from "../../component/UpperBoundManipulator/UpperBoundManipulator";
import './DuelResultPage.css';
import {calculateDmgDef} from "../../connector/adapter/duelAdapter";
import SelectedSetItems from "../../component/SelectedSetItems/SelectedSetItems";
import DuelResolution from "../../component/DuelResolution/DuelResolution";
import ActiveEntryModal from "../arena/ActiveEntryModal";


let duelInfo, setDuelInfo; // info about the duel itself.
let duelWinnerSide, setDuelWinnerSide;


const DuelResultPage = () => {
    const {id} = useParams();

    [duelInfo, setDuelInfo] = useState({});
    const [duelComp, setDuelComp] = useState({}); // duel computation
    const [duelWinnerSide, setDuelWinnerSide] = useState(null);
    const [selectedSetLeft, setSelectedSetLeft] = useState({itemCards: []});
    const [selectedSetRight, setSelectedSetRight] = useState({itemCards: []});
    const [duelResolved, setDuelResolved] = useState(false);

    const [openActiveModal, setOpenActiveModal] = useState(false);
    const closeActiveModal = () => setOpenActiveModal(false);

    let lastViewedState;

    const loadDuel = (id) => {
        getDuelDetails(id).then(async (duelResp) => {
            const duel = duelResp.duel;
            console.log('getDuelDetails', duel);
            setDuelInfo(duel);
            const newComp = await calculateDmgDef({
                leftPlayer: duel.playerLeft,
                rightPlayer: duel.playerRight,
                duelId: id,
            });
            console.log('newComp', newComp);
            setDuelComp(newComp);
            console.log(`duelWinnerSide : ${newComp.winnerSide}, playerLeft: `, duelInfo.playerLeft);
            setDuelWinnerSide(newComp.winnerSide);
            console.log('setSelectedSetLeft', duel.playerLeft, 'setSelectedSetRight', duel.playerRight.info);
            setSelectedSetLeft(duel.playerLeft.info);
            setSelectedSetRight(duel.playerRight.info);
        }).catch((x) => {
            console.error(x, "newComp");
        });
    };

    // I'm too tired to check if this single use useEffect will work on latter hook as well, it should but I'll keep both for now
    useEffect(() => {
        if (id) {
            lastViewedState = DUEL_VIEWED.NEW_DUEL;
            setDuelResolved(false);
            setOpenActiveModal(false);
            setDuelWinnerSide(null);
            setDuelComp({});
            setDuelInfo({});
            setSelectedSetRight({itemCards: []});
            setSelectedSetLeft({itemCards: []});
            loadDuel(id);
        }
    }, [id]);
    useEffect(() => {
        if (id) {
            lastViewedState = DUEL_VIEWED.NEW_DUEL;
            setDuelResolved(false);
            setOpenActiveModal(false);
            setDuelWinnerSide(null);
            setDuelComp({});
            setDuelInfo({});
            setSelectedSetRight({itemCards: []});
            setSelectedSetLeft({itemCards: []});
            loadDuel(id);
        }
    }, []);

    const getTimerBasedOnDuelCompState = (viewedByUser) => {
        console.log('getClassBasedOnDuelCompState: viewedByUser', viewedByUser, 'lastViewedState', lastViewedState);
        if (viewedByUser > lastViewedState || lastViewedState === undefined) {
            lastViewedState = viewedByUser;
        }

        switch (lastViewedState) {
            case DUEL_VIEWED.ACC_NOT_FOUND:
                return 0;
                break;
            case DUEL_VIEWED.ACC_NOT_FOUND:
                return 0;
                break;
            case DUEL_VIEWED.NEW_DUEL:
                return 7;
                break;
            case DUEL_VIEWED.ALREADY_VIEWED:
                return 0;
                break;
            default:
                return 0;
                break;
        }
    }

    return (
        <div id={'duel-result'} className={'page full-width flex-container-rows'}>
            <div id={'upper-block'} className={'container-1600 flex-container-rows'}>
                <div id={'top-manipulator'} className={'flex-container-columns'}>
                    <UpperBoundManipulator type={PATH_NAME.DUEL_RESULT_PAGE} properties={{
                        duelInfo: duelInfo,
                    }}/>
                </div>
                {/*<UpperBlock carouselSetsParentState={carouselSets} setCarouselSetsParentState={setCarouselSets}*/}
                {/*            carouselIndexParentState={selectedSetIndex}*/}
                {/*            setCarouselIndexParentState={setSelectedSetIndex} allowNewSet={true} PARENT_PATH_NAME={PATH_NAME.DUEL_RESULT_PAGE}/>*/}
                {/*<div>*/}
                <div id={'banana-sets-showcase'} className={'flex-container-columns'}>

                    <DuelArenaPortal playerInfo={{...duelInfo.playerLeft, side: DUEL_PLAYER.LEFT}} htmlId={'left'}
                                     duelWinnerSide={duelWinnerSide} duelResolved={duelResolved}/>
                    {/*<ArenaResultPanel playersInfo={{*/}
                    {/*    // todo we should start thinking as to why did we propagate info object into player object in the first place*/}
                    {/*    left: (duelInfo.playerLeft !== undefined ? {*/}
                    {/*        ...duelInfo.playerLeft.info,*/}
                    {/*        ownerAddress: duelInfo.playerLeft.user,*/}
                    {/*        viewed: duelInfo.playerLeft.viewed*/}
                    {/*    } : {}),*/}
                    {/*    right: (duelInfo.playerRight !== undefined ? {*/}
                    {/*        ...duelInfo.playerRight.info,*/}
                    {/*        ownerAddress: duelInfo.playerRight.user,*/}
                    {/*        viewed: duelInfo.playerRight.viewed*/}
                    {/*    } : {})*/}
                    {/*}} duelId={id}/>*/}
                    <DuelArenaPortal playerInfo={{...duelInfo.playerRight, side: DUEL_PLAYER.RIGHT}} htmlId={'right'}
                                     duelWinnerSide={duelWinnerSide} duelResolved={duelResolved}/>
                </div>
            </div>
            <div id={'duel-detail-box'} className={'container-1600 flex-container-columns'}>
                <div id={'left-selected-set-items'} className={'flex-container-rows duel-selected-set'}>
                    {duelWinnerSide !== null ? <SelectedSetItems carouselSet={{...selectedSetLeft}} showNumbers={duelComp.currentSide === DUEL_PLAYER.LEFT ? true : duelResolved}/> : <></>}
                </div>
                <div id={'duel-resolution-box'} className={'flex-container-rows'}>
                    {duelWinnerSide !== null ? <DuelResolution duelId={id} duelComp={duelComp}
                                                               timerCount={getTimerBasedOnDuelCompState(duelComp.viewedByUser)}
                                                               duelResolvedState={duelResolved}
                                                               setDuelResolvedStateFunction={setDuelResolved}
                        openModalFunc={setOpenActiveModal}/> : <></>}
                    {/*duel viewed: {duelComp.viewedByUser ? 'true' : 'false'}*/}
                    {/*<div style={{width: '300px', height: '300px', backgroundColor: getClassBasedOnDuelCompState(duelComp.viewedByUser)}}></div>*/}
                </div>
                <div id={'right-selected-set-items'} className={'flex-container-rows duel-selected-set'}>
                    {duelWinnerSide !== null ? <SelectedSetItems carouselSet={{...selectedSetRight}} showNumbers={duelComp.currentSide === DUEL_PLAYER.RIGHT ? true : duelResolved}/> : <></>}
                </div>

                <ActiveEntryModal openModal={openActiveModal} closeOnDocumentClick closeModal={closeActiveModal}
                                  selectedSet={duelComp.currentSide === DUEL_PLAYER.RIGHT ? {...selectedSetRight} : {...selectedSetLeft}} selectedPool={ARENA_POOLS.SILVER} duelRedirect={true}/>


            </div>
        </div>
    )
};


export default DuelResultPage;