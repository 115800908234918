import {redrawMintedItems} from "./MintedItemsComponent";
import React, {useEffect, useState} from "react";
import {PreMintedItemCard} from "../itemCard/itemCard";
import axios from "axios";
import {BiError} from "react-icons/bi";
import {applyFactionFilter, applySort} from "./CardList";


let setPreMinRefreshingHook = null, setPreMintedItemsErrorHook = null, setPreMintedItemsHook = null;

const PreMintedCardList = ({cards, filterArray, sortObject, updatePostMintedItems}) => {
    let preMintedCardsRender = [];
    // const applyFactionFilter = (card, filter) => filter.factions[card.faction] && (filter.setPiece === null || filter.setPiece === card.setPiece);

    cards = applySort(cards, sortObject);

    cards.filter(card => applyFactionFilter(card, filterArray)).map((card) => {
        preMintedCardsRender.push(<PreMintedItemCard card={card} key={card.bxId}
                                                     updatePostMintedItems={updatePostMintedItems}/>);
    })
    return (<>{preMintedCardsRender}</>);
}


export const redrawPreMintedItems = () => {
    const urlHost = process.env.REACT_APP_BACK_END_HOST;
    try {
        setPreMinRefreshingHook(true);
        axios.get(urlHost + "/item/pre-mint-items", {
            headers: {
                'Content-Type': "application/json",
                'Accept': "application/json",
                // "Access-Control-Allow-Origin": "*",
            },
            responseType: "json",
        })
            .then(function (response) {
                console.debug('parsed pre minted items: ', response.data)
                setPreMintedItemsHook(response.data.items);
                setPreMintedItemsErrorHook(null);
                setPreMinRefreshingHook(false);
            })
            .catch(function (response) {
                console.error('axios error occurred', response);
                setPreMintedItemsHook([]);
                setPreMintedItemsErrorHook(response.message); // TODO REDO with a proper error object handling based on code etc
                setPreMinRefreshingHook(false);
            })
        ;
    } catch (error) {
        console.error('axios error occurred', error.message);
        setPreMintedItemsErrorHook(error.message); // TODO REDO with a proper error object handling based on code etc
        setPreMinRefreshingHook(false);
    }
    // the async await function looks much better than this
}

const PreMintedItemsComponent = ({filterArray, sortObject}) => {

    const [preMintedItems, setPreMintedItems] = useState([]);
    setPreMintedItemsHook = setPreMintedItems;

    const [preMintRefreshing, setPreMintRefreshing] = useState(true);
    setPreMinRefreshingHook = setPreMintRefreshing;

    const [preMintedItemsError, setPreMintedItemsError] = useState(null);
    setPreMintedItemsErrorHook = setPreMintedItemsError;

    const preMintedItemsErrorClick = () => {
        setPreMintedItemsError(null);
        redrawPreMintedItems();
    };

    useEffect(() => {
        redrawPreMintedItems();
    }, []);


    return (
        <div id={'pre-minted-items'}>

            {(typeof preMintedItemsError === "string") ?
                <div className={'error-message'} onClick={preMintedItemsErrorClick}>
                    <BiError/> Error: {preMintedItemsError} <BiError/>
                </div>
                : null}
            {typeof preMintedItems === "object" && preMintedItems.length > 0 ?
                <>
                    <h4 id={'pre-minted-items-h'}
                        className={'faded pre-minted-items'}>ELEGIBLE TO MINT (items you can mint into <span>NFTs</span>)<span/></h4>
                    {preMintRefreshing ? <div className={'refreshing'}>Refreshing...</div> : null}
                    <div className={'items'}>
                        <PreMintedCardList cards={preMintedItems} filterArray={filterArray} sortObject={sortObject}
                                           updatePostMintedItems={redrawMintedItems}/>
                    </div>
                </>
                : null}
            <div className={'clearfix'}/>
        </div>
    )
}

export default PreMintedItemsComponent;