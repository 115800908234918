import './StoreLeftSide.css';
import {EVENT_TYPE, FACTION, LOG_STATUS, MODAL_TYPE} from "../../helper/ENUMS.ts";
import {CurrencyIcon} from "../../App";
import {UnpackModalOld} from "../../component/Modal/Modal";
import React, {useState} from 'react';
import {getAccountIds, makeBytes, sendTransaction} from "../../connector/blockchain/hashconnect";
import {getConfigFor} from "../../connector/blockchain/ContractParameters";
import {
    AccountId,
    ContractExecuteTransaction,
    ContractFunctionParameters,
    TokenAssociateTransaction,
    TokenId
} from "@hashgraph/sdk";
import axios from "axios";
import {
    getLootFromHeaderComponent,
    refreshLootBoxBalance
} from "../../component/LootBoxHeaderComponent/LootBoxHeaderComponent";
import {ItemCardSemiImage} from "../../component/itemCard/itemCard";
import MyCustomEventEmitter from "../../component/myCustomEvents";
import {appendNewLog} from "../../component/PopUpProcessLog/PopUpProcessLog";

let unpackingVariables = {};
let unpackContentLog = "", setUnpackContentLog;
let unpackContentItemCard = {}, setUnpackContentItemCard;
let unpackContentItemCards = {}, setUnpackContentItemCards;
let unpackIsFinished = false, setUnpackIsFinished;
let unpackFaction = "", setUnpackFaction;

function renderCardToModal(card) {
    return (
        <ItemCardSemiImage card={card}/>
    );
}

/**
 *
 * @param faction
 * @returns {Promise<void>}
 * @deprecated
 */
async function CallUnpackContract(faction) {
    let signingAcct = getAccountIds()[0];

    // setUnpackContentLog([]);
    // if we reference unpackContentLog here, it will somehow contain previous run state, so we have to reset it completely
    setUnpackContentLog([...appendNewLog([], "Verifying balances...", LOG_STATUS.SUCCESS)]);

    await new Promise(resolve => setTimeout(resolve, 1000));

    const unpackTransactionPromise = getConfigFor('LOOT_BOX_UNPACK').then(callConfig => {
        console.log("storedHederaCommunicationConfig RESULT ", callConfig);
        return new ContractExecuteTransaction()
            .setContractId(callConfig.contractId) // todo contract numbers from back end
            .setGas(callConfig.gasLimit) // Increase if revert, as of now consumes 161850 gas
            .setTransactionMemo(`Banana.Capital: Unpacking ${FACTION[faction]} faction box`)
            .setFunction(callConfig.contractFunc
                , new ContractFunctionParameters()
                    .addUint8(faction) // FACTION todo FACTION to NUMBER
            )
            // .setPayableAmount(new Hbar(10))
            // .setMaxTransactionFee(new Hbar(0.75))
            ;
    });

    let transactionBytes = await makeBytes(await unpackTransactionPromise, signingAcct);

    let res = await sendTransaction(transactionBytes, signingAcct, false, true);
    await new Promise(resolve => setTimeout(resolve, 230))
        .then(setUnpackContentLog([...appendNewLog(unpackContentLog, "Waiting for Tx...", LOG_STATUS.SUCCESS)]));



    let responseData = {
        response: res,
        record: null,
        receipt: null
    };

    if (res.success) {
        await new Promise(resolve => setTimeout(resolve, 360))
            .then(setUnpackContentLog([...appendNewLog(unpackContentLog, <>Transaction: <a
            href={`https://hashscan.io/testnet/transaction/${res.response.transactionId}`}
            target={'_blank'}>{res.response.transactionId}</a></>)]));

        // console.info("GLORIOUS RESPONSE ", res);
        // let item = {};

        const urlHost = process.env.REACT_APP_BACK_END_HOST;
        axios.post(urlHost + "/item/pre-mint", {
            txId: res.response.transactionId,
        }, {
            headers: {
                'Content-Type': "application/json",
                'Accept': "application/json",
                // "Access-Control-Allow-Origin": "*",
            },
            responseType: "json",
        })
            .then(function (backEndMirrorNodeResponse) {
                const item = backEndMirrorNodeResponse.data.item;
                setUnpackContentItemCard(item);
                console.log(`backEndMirrorNodeResponse.data.item`, item);
                setUnpackContentLog([...appendNewLog(unpackContentLog, <>
                    Stored on blockchain & mirror, ready to be minted
                </>, backEndMirrorNodeResponse.data.stored ? LOG_STATUS.SUCCESS : LOG_STATUS.ERROR)]);
                if (backEndMirrorNodeResponse.data.lootBoxBalanceUpdated) {
                    refreshLootBoxBalance();
                }
                setUnpackIsFinished(true);
            })
            .catch(function (response) {
                setUnpackContentLog([...appendNewLog(unpackContentLog, <>error: {response.data}</>, LOG_STATUS.ERROR)]);
            })
        ;
    } else {
        setUnpackContentLog([...appendNewLog(unpackContentLog, <>Transaction failed: {res.error}</>, LOG_STATUS.ERROR)]);
    }
    // console.log("responseData ", responseData)
    // this.HashconnectService.showResultOverlay(responseData);
}

async function CallUnpackRookieSetContract(setUnpackContentLog) {
    let signingAcct = getAccountIds()[0];
    // todo check if sufficient balance
    setUnpackContentLog([{
        status: LOG_STATUS.SUCCESS,
        text: <>Verifying YVC <CurrencyIcon/> balances</>
    }]);

    const unpackTransactionPromise = getConfigFor('LOOT_BOX_UNPACK_ROOKIE').then(callConfig => {
        console.log("storedHederaCommunicationConfig RESULT ", callConfig);
        return new ContractExecuteTransaction()
            .setContractId(callConfig.contractId) // todo contract numbers from back end
            .setGas(callConfig.gasLimit) // Increase if revert, as of now consumes 161850 gas
            .setTransactionMemo(`Banana.Capital: Purchasing & Unpacking Rookie Set`)
            .setFunction(callConfig.contractFunc
                , new ContractFunctionParameters()
                // .addUint8(faction) // FACTION todo FACTION to NUMBER
            )
            // .setPayableAmount(new Hbar(10))
            // .setMaxTransactionFee(new Hbar(0.75))
            ;
    });

    // setUnpackContentLog([]);
    // if we reference unpackContentLog here, it will somehow contain previous run state, so we have to reset it completely
    setUnpackContentLog([...appendNewLog([], "Generating Rookie Set Tx...", LOG_STATUS.SUCCESS)]);
    setUnpackContentItemCards(null);

    let transactionBytes = await makeBytes(await unpackTransactionPromise, signingAcct);

    let res = await sendTransaction(transactionBytes, signingAcct, false, true);
    await new Promise(resolve => setTimeout(resolve, 230))
        .then(setUnpackContentLog([...appendNewLog(unpackContentLog, "Waiting for Tx confirmation...")]));

    //handle response
    let responseData = {
        response: res,
        record: null,
        receipt: null
    };

    if (res.success) {
        await new Promise(resolve => setTimeout(resolve, 360))
            .then(setUnpackContentLog([...appendNewLog(unpackContentLog, "Parsing data...")]));
        console.info("GLORIOUS RESPONSE ", res);
        let item = {};
        const preMintSetBody = {
            txId: res.response.transactionId,
        };

        const urlHost = process.env.REACT_APP_BACK_END_HOST;
        axios.post(urlHost + "/item/pre-mint-set", preMintSetBody, {
            headers: {
                'Content-Type': "application/json",
                'Accept': "application/json",
                // "Access-Control-Allow-Origin": "*",
            },
            responseType: "json",
        })
            .then(function (backEndMirrorNodeResponse) {
                const itemArray = backEndMirrorNodeResponse.data.itemArray;
                setUnpackContentItemCards(itemArray);
                setUnpackContentLog([...appendNewLog(unpackContentLog, <>Success!</>, (backEndMirrorNodeResponse.data.stored ? LOG_STATUS.SUCCESS : LOG_STATUS.ERROR))]);
            })
            .catch(function (response) {
                setUnpackContentLog([...appendNewLog(unpackContentLog, <div
                    className={'clearfix'}>error: {response.data}</div>)]);
            })
        ;

        MyCustomEventEmitter.dispatch(EVENT_TYPE.FORCE_CURRENCY_UPDATE);
    } else {
        console.log("GLORIOUS FAIL", res);
    }
    // console.log("responseData ", responseData)
    // this.HashconnectService.showResultOverlay(responseData);
}

const checkIfAllTokensAreAssociated = () => {
    const signingAccountId = getAccountIds()[0];
    const params = {
        'account.id': `${signingAccountId}`,
    };
    axios.get(`https://testnet.mirrornode.hedera.com/api/v1/tokens/`, {
        params: params,
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
            'Authorization-Web3': null,
            // "Access-Control-Allow-Origin": "*",
        },
        responseType: "json",
    }).then((associatedTokensMirrorNodeResponse) => {

        const associatedTokens = associatedTokensMirrorNodeResponse.data.tokens;
        let tokensToAssociate = ["0.0.49194656", "0.0.49190692"];
        console.log("node tokens, ", associatedTokens);
        associatedTokens.filter(token => {
            const {token_id} = token;
            const t = token_id;
            if (tokensToAssociate.includes(t)) {
                tokensToAssociate = tokensToAssociate.filter(v => v !== t);
            }
        });
        if (tokensToAssociate.length > 1) {
            console.log("Account has unassociated tokens: ", tokensToAssociate);

            let tokenAssociateTransaction = new TokenAssociateTransaction()
                .setAccountId(AccountId.fromString(signingAccountId))
                .setTokenIds(tokensToAssociate.map(t => TokenId.fromString(t)))
            ;

            makeBytes(tokenAssociateTransaction, signingAccountId).then((bytesOfTransaction) => {
                sendTransaction(bytesOfTransaction, signingAccountId, false, true).then((sentTransactionResponse) => {
                    if (sentTransactionResponse.success) {
                        console.info("Token Association Response", sentTransactionResponse);
                    } else {
                        console.log("Token Association Fail", sentTransactionResponse);
                    }
                })
                    .catch((x) => {
                        console.error(x);
                    })
                ;
            })
                .catch((x) => {
                    console.error(x);
                })
            ;

            return false;
        } else {
            console.debug("Account token association check passed");
        }
        return true;

    })
        .catch((x) => {
            console.error(x);
        })
    ;
};

const StoreLeftSide = ({}) => {
    [unpackContentLog, setUnpackContentLog] = useState([{
        status: LOG_STATUS.PENDING,
        text: `Waiting for players' confirmation...`
    }]);
    [unpackContentItemCard, setUnpackContentItemCard] = useState(null);
    [unpackContentItemCards, setUnpackContentItemCards] = useState(null);
    [unpackFaction, setUnpackFaction] = useState(0);
    [unpackIsFinished, setUnpackIsFinished] = useState(false);
    unpackingVariables.store = "set";
    unpackingVariables.faction = FACTION.GREEN;
    const [factionBoxesCountState, setFactionBoxesCountState] = useState(getLootFromHeaderComponent(unpackFaction));


    const lowerUnpackFaction = () => {
        if (unpackFaction > 0) {
            setFactionBoxesCountState(getLootFromHeaderComponent(unpackFaction - 1));
            setUnpackFaction(unpackFaction - 1);
            return;
        }
        if (unpackFaction === 0) {
            setUnpackFaction(2);
            setFactionBoxesCountState(getLootFromHeaderComponent(2));
        }
    }
    const raiseUnpackFaction = () => {
        if (unpackFaction < 2) {
            setFactionBoxesCountState(getLootFromHeaderComponent(unpackFaction + 1));
            setUnpackFaction(unpackFaction + 1);
            return;
        }
        if (unpackFaction === 2) {
            setUnpackFaction(0);
            setFactionBoxesCountState(getLootFromHeaderComponent(0));
        }
    }

    function initiateUnpacking(unpackingObject) {
        console.log("button trigger");
        console.log("unpackingVariables ", unpackingVariables);
        console.log("unpackingObject ", unpackingObject);
        setUnpackContentItemCard(null);
        CallUnpackContract(unpackFaction);
    }


    /**
     *
     * @param unpackingObject
     * @deprecated
     */
    function initiateRookieUnpacking(unpackingObject) {
        console.log("Rookie unpacking confirmation");
        setUnpackContentLog([]);
        CallUnpackRookieSetContract(setUnpackContentLog);
    }



    function log(value) {
        console.log(`slider value ${value}`); //eslint-disable-line
    }

    function UnpackRookieButtonText() {
        return (
            <><span>BUY &</span> OPEN</>
        );
    }

    function unpackLootBoxButtonText() {
        return (
            <>UNPACK <span>LOOTBOX</span></>
        );
    }

    let factionLabel = FACTION[unpackFaction].toLowerCase();
    MyCustomEventEmitter.subscribe("lootBoxBalanceUpdated", (data) => {
        console.log("lootBoxBalanceUpdated, factionBoxesCount = ", data[unpackFaction]);
        setFactionBoxesCountState(data[unpackFaction]);
        // factionBoxesCount = getLootFromHeaderComponent(unpackFaction);
    });

    return (
        <div id={'left-side'}>
            <div className={'counters'}>
                <div className={'upper-part flex-container-rows'}>
                    <div id={'purchase-currency-wrap'} className={'flex-container-rows'}>
                        {/*<CurrencyTokenManagement/>*/}
                    </div>
                </div>
                <div className={'upper-part flex-container-rows ownership_token'}>
                    <div id={'purchase-currency-wrap'} className={'flex-container-rows'}>
                        {/*<CurrencyTokenManagement/>*/}
                    </div>
                </div>
            </div>
            <div className={'lower-part flex-container-columns'}>
                <div id={'unpack-item-wrapper'} className={'unpack-container flex-container-rows'}>
                    <div className={`unpack-contents flex-container-rows ${factionLabel}`}>
                        <div className={`lootbox-representation ${factionLabel} flex-container-rows`}>
                            {/*<ToolTip trigger={<div className={'lootbox-purchase-info-tooltip'}><FaQuestionCircle className={'question-circle'}/></div>}*/}
                            {/*         key={`small-lootbox-help-${FACTION[unpackFaction].toLowerCase()}`} content={"Yay"}*/}
                            {/*         keepInside={'.small-lootbox-container'} position={'left top'}/>*/}
                            {/*<img className={'lootbox-image'} src={`https://asset-host.b-cdn.net/assets/store/${factionLabel}_box.png`}/>*/}
                            <span className={`lootbox-counter`}>{factionBoxesCountState}</span>
                        </div>
                        <div className={'control-panel flex-container-columns'}>
                            <button onClick={lowerUnpackFaction} className={'arrows arrow-left'}></button>
                            {/*<span style={{color: 'white'}}>{unpackFaction}</span>*/}
                            <div className={`modal-wrapper ${MODAL_TYPE[MODAL_TYPE.UNPACK_BOX].toLowerCase()}`}>
                            <UnpackModalOld
                                modalType={MODAL_TYPE.UNPACK_BOX}
                                modalTitle={'Unpacking item'}
                                modalContent={unpackContentLog}
                                contentLog={unpackContentLog}
                                itemCard={unpackContentItemCard}
                                buttonContent={unpackLootBoxButtonText()}
                                buttonFunctionParams={unpackingVariables}
                                buttonFunction={initiateUnpacking}
                                isFinished={unpackIsFinished}
                                disabled={factionBoxesCountState === 0}
                            />
                            </div>
                            <button onClick={raiseUnpackFaction} className={'arrows arrow-right'}></button>
                        </div>
                    </div>
                </div>
                <div id={'unpack-rookie-set'} className={'unpack-container '}>
                    <div className={`unpack-contents flex-container-rows rookie`}>
                        <div className={`lootbox-representation rookie flex-container-rows`}>
                            {/*<ToolTip trigger={<div className={'lootbox-purchase-info-tooltip'}><FaQuestionCircle className={'question-circle'}/></div>}*/}
                            {/*         key={`small-lootbox-help-${FACTION[unpackFaction].toLowerCase()}`} content={"Yay"}*/}
                            {/*         keepInside={'.small-lootbox-container'} position={'left top'}/>*/}
                            {/*<img className={'lootbox-image'} src={`https://asset-host.b-cdn.net/assets/store/${factionLabel}_box.png`}/>*/}
                            {/*<span className={`lootbox-counter`}>{factionBoxesCountState}</span>*/}
                        </div>
                        <div className={'control-panel flex-container-columns'}>
                            <div className={`modal-wrapper ${MODAL_TYPE[MODAL_TYPE.UNPACK_ROOKIE].toLowerCase()}`}>
                            <UnpackModalOld
                                modalType={MODAL_TYPE.UNPACK_ROOKIE}
                                modalTitle={'ROOKIE BUNDLE'}
                                modalContent={unpackContentLog}
                                itemCards={unpackContentItemCards}
                                contentLog={unpackContentLog}
                                buttonContent={UnpackRookieButtonText()}
                                buttonFunctionParams={unpackingVariables}
                                buttonFunction={initiateRookieUnpacking}
                            />
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default StoreLeftSide;