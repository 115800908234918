import FullSetCarousel from "../fullSetCarousel/FullSetCarousel";
import React from "react";
import {InventoryPortalFooter} from "./Portal";

const DuelArenaPortal = ({playerInfo, htmlId, duelWinnerSide, duelResolved}) => {
    console.log(`duelWinnerSide: ${duelWinnerSide}, playerInfo: `, playerInfo.side)

    return (
        <div id={`${htmlId}-arena-portal`} className={'flex-column banana-set'}>
            {/*<div className={'banana-container'}>*/}
            {/*<img className={'transparent'} src={'https://asset-host.b-cdn.net/assets/arena-res/arena_ratio.png'}/>*/}
            {/*<div className={'banana-inner-container'}>*/}

            <div className={'flex-container-rows carousel-wrapper'}>
                <FullSetCarousel glowColour={playerInfo.side === duelWinnerSide ? 'win' : 'lose'}
                                 glowReady={duelResolved}
                                 itemCards={(playerInfo && playerInfo.info) ? playerInfo.info.itemCards : []}
                                 winner={playerInfo.side === duelWinnerSide}/>
            </div>
            {/*<img className={'portal-arena'}*/}
            {/*     src={'https://asset-host.b-cdn.net/assets/arena-res/portal_arena.png'}*/}
            {/*     alt={`arena portal stone base`}/>*/}
            {/*</div>*/}
            {/*</div>*/}
            <img className={'coconut'} src={`https://asset-host.b-cdn.net/assets/d/cocount_${htmlId}.png`}/>
            {/*<div className={'ground-front'}/>*/}
        </div>
    );
}


export default DuelArenaPortal;