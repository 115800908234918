import Popup from "reactjs-popup";
import {FACTION, LOG_STATUS, MODAL_TYPE} from "../../helper/ENUMS.ts";
import {IoIosArrowUp} from "react-icons/io";
import PopUpProcessLogItems from "../PopUpProcessLog/PopUpProcessLog";
import React from "react";
import {ItemCardSemiImage, PreMintedItemCard} from "../itemCard/itemCard";
import {CardPlaceHolder} from "./UnpackModal";

const BattleInfoModal = ({playersInfo}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [unpackContentItemCards, setUnpackContentItemCards] = React.useState(null);
    const [unpackContentItemCard, setUnpackContentItemCard] = React.useState(null);
    const [modalTitle, setModalTitle] = React.useState('Combat Details');
    const renderCards = ({ itemCards }) => {
        if (itemCards !== undefined && itemCards !== null && itemCards !== {}) {
            return (
                <>
                    <div className={'flex-container-columns'}>
                        <ItemCardSemiImage card={itemCards[0]} key={itemCards[0].bxId} cardClickEvent={null}/>
                        <ItemCardSemiImage card={itemCards[1]} key={itemCards[1].bxId} cardClickEvent={null}/>
                    </div>
                    <div className={'flex-container-columns'}>
                        <ItemCardSemiImage card={itemCards[2]} key={itemCards[2].bxId} cardClickEvent={null}/>
                        <ItemCardSemiImage card={itemCards[3]} key={itemCards[3].bxId} cardClickEvent={null}/>
                    </div>
                </>);
        } else {
            return (
                <>
                    <div className={'flex-container-columns'}>
                        <div className={'item-card placeholder'}></div>
                        <div className={'item-card placeholder'}></div>
                    </div>
                    <div className={'flex-container-columns'}>
                        <div className={'item-card placeholder'}></div>
                        <div className={'item-card placeholder'}></div>
                    </div>
                </>
            );
        }
    }

    const modalType = MODAL_TYPE.BATTLE_INFO;
    const closeModal = () => setIsOpen(false);

    return (
        <div className={`modal-wrapper modal-battle-info`}>

                <button type="button" className={`button btn secondary flex-container-columns`}
                        onClick={() => {
                            setIsOpen(o => !o);
                        }}>
                    What happened here?
                </button>
            <Popup open={isOpen}
                   closeOnDocumentClick={true}
                // onClose={closeModal}
                   className={`modal modal-battle-info`}
                   onClose={() => {
                       console.log("onClose unpack modal");
                       setUnpackContentItemCards(null);
                       setUnpackContentItemCard(null);
                   }}
                   nested>
                <div className={`modal ${modalType}`}>
                    <a className="close" onClick={() => closeModal()}>
                        &times;
                    </a>
                    <div className={'content-after-x'}>
                        <h3>{modalTitle}</h3>
                        <div className="fading-line"/>
                        <div className={'content-container flex-container-rows'}>
                                <div className={'flex-container-columns'}>
                                    <div
                                        className={`card-container ${modalType} flex-container-rows`}>
                                        {renderCards({
                                            itemCards: playersInfo.left.itemCards
                                        })}
                                    </div>
                                    <div className={'middle-text flex-container-rows'}>
                                    </div>
                                    <div
                                        className={`card-container ${modalType} flex-container-rows`}>
                                        {renderCards({
                                            itemCards: playersInfo.right.itemCards
                                        })}
                                    </div>
                                </div>
                        </div>
                        <div className="actions">
                            {/*{props.isFinished === true ?*/}
                            {/*    <button*/}
                            {/*        className="button"*/}
                            {/*        onClick={() => {*/}
                            {/*            closeModal();*/}
                            {/*        }}*/}
                            {/*    >close</button> : <></>}*/}
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
    );
}

export default BattleInfoModal;