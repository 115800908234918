import Popup from "reactjs-popup";
import {ARENA_POOLS, EVENT_TYPE, LOG_STATUS} from "../../helper/ENUMS.ts";
import {CurrencyIcon} from "../../App";
import {TbInfoSquareRoundedFilled} from "react-icons/tb";
import React, {useState} from 'react';
import './ActiveEntryModal.css';
import {getPoolFee, getPoolFees} from "./ArenaPickerPageOld";
import {
    getAccountIds,
    makeBytes,
    sendAllowanceTransaction,
    sendTransaction
} from "../../connector/blockchain/hashconnect";
import {getConfigFor} from "../../connector/blockchain/ContractParameters";
import {
    AccountAllowanceApproveTransaction,
    ContractExecuteTransaction,
    ContractFunctionParameters,
    NftId,
    TokenId
} from "@hashgraph/sdk";
import axios from "axios";
import {
    createCurrencyAndItemAllowance,
    createCurrencyAndSetAllowance, yvcMod
} from "../../connector/generateAllowanceTransactions";
import {useNavigate} from "react-router-dom";
import PopUpProcessLogItems, {appendNewLog} from "../../component/PopUpProcessLog/PopUpProcessLog";
import MyCustomEventEmitter from "../../component/myCustomEvents";


const ActiveEntryModal = ({closeModal, openModal, selectedSet, selectedPool, duelRedirect = false}) => {

    const poolFees = getPoolFees();

    const userBalance = localStorage.getItem('YVCurrencyBalance') * 100; // TODO : connect to user Balance from helpers
    const maxBattles = Math.min(Math.floor(userBalance / (100 * poolFees[selectedPool])), 62);
    const [activeNumBattles, setActiveNumBattles] = useState(1);
    const totalFees = activeNumBattles * 100;
    const potentialPayout = `0 - ${activeNumBattles * 190}`;

    const [processLogItems, setProcessLogItems] = useState([{
        status: LOG_STATUS.PENDING,
        text: `Waiting for players' confirmation...`
    }]);


    const navigator = useNavigate();

    async function handleActivePoolEntry(bananaSerial, navigator, closeModal) {

        const poolIndex = ARENA_POOLS.SILVER;

        const signingAcct = getAccountIds()[0];
        const spenderWithdrawingContractId = (await getConfigFor('NFT_AND_FEE_STORAGE_CONTRACT')).contractId;

        const currencyId = (await getConfigFor('YV_CURRENCY_TOKEN')).contractId;
        const NFTId = new NftId(TokenId.fromString(((await getConfigFor('FULLSET_COLLECTION')).contractId)), bananaSerial); // todo rename to FULLSET_COLLECTION_TOKEN

        const allowanceGiven = await createCurrencyAndSetAllowance(signingAcct, spenderWithdrawingContractId, getPoolFee([poolIndex]) * yvcMod, [bananaSerial]);
        if (!allowanceGiven) {
            setProcessLogItems([...appendNewLog(processLogItems, `NFT Allowance rejected`, LOG_STATUS.ERROR)]);
            return null;
        } else {
            setProcessLogItems([...appendNewLog(processLogItems, `NFT Allowance approved`, LOG_STATUS.SUCCESS)]);
        }

        // let allowanceTx = new AccountAllowanceApproveTransaction()
        //     .approveTokenNftAllowance(NFTId, signingAcct, spenderWithdrawingContractId)
        //     .approveTokenAllowance(currencyId, signingAcct, spenderWithdrawingContractId, 1 * poolFees[poolIndex]);
        // let allowanceBytes = await makeBytes(await allowanceTx, signingAcct);
        // let allowanceRes = await sendAllowanceTransaction(allowanceBytes, signingAcct);
        //
        // if (allowanceRes.success !== true) {
            // return null;
        // }
        setProcessLogItems([...appendNewLog(processLogItems, `Transaction pending...`, LOG_STATUS.PENDING)]);

        const matchmakeTx = getConfigFor('MATCHMAKE_OR_ENTER_ACTIVE_POOL').then(callConfig => {
            return new ContractExecuteTransaction()
                .setContractId(callConfig.contractId)
                .setGas(callConfig.gasLimit)
                .setTransactionMemo(`Banana.Capital: Entering active Matchmaking with $BANWAR #${bananaSerial}`)
                .setFunction(callConfig.contractFunc,
                    new ContractFunctionParameters()
                        .addUint8(poolIndex)
                        .addInt64(bananaSerial)
                );
        });

        let transactionBytes = await makeBytes(await matchmakeTx, signingAcct);
        let res = await sendTransaction(transactionBytes, signingAcct, false, true);
        if (res.success) {
            console.log('Transaction successful', res.response);
            setProcessLogItems([...appendNewLog(processLogItems, <>Transaction successful <a
                href={`https://hashscan.io/testnet/transaction/${res.response.transactionId}`}
                target={'_blank'}>{res.response.transactionId}</a></>, LOG_STATUS.SUCCESS)]);
            const urlHost = process.env.REACT_APP_BACK_END_HOST;
            new Promise(resolve => setTimeout(resolve, 2500))
                .then(() => {
                    MyCustomEventEmitter.dispatch(EVENT_TYPE.FORCE_CURRENCY_UPDATE)
                });
            axios.post(urlHost + "/arena/duel-event", {
                txId: res.response.transactionId,
            }, {
                headers: {
                    'Content-Type': "application/json",
                    'Accept': "application/json",
                    // "Access-Control-Allow-Origin": "*",
                },
                responseType: "json",
            }).then(async (initPoolEntranceResponse) => {
                const {data} = initPoolEntranceResponse;
                console.log('Successfully matchmaked!');
                setProcessLogItems([...appendNewLog(processLogItems, `Successfully matchmaked ${JSON.stringify(data.data.callResult)}, ${JSON.stringify(data.stored)}, ${data.stored.recordId}`, LOG_STATUS.SUCCESS)]);
                if (data.stored && parseInt(data.stored.recordId) > 0 && data.data.matched === true) {
                    console.log('redirecting...', data.data, data.stored.recordId);
                    setProcessLogItems([...appendNewLog(processLogItems, `Redirecting...`, LOG_STATUS.PENDING)]);
                    if (duelRedirect) {
                        // const redirectUrl = `https://${window.location.host}/2/duel-result/${data.stored.recordId}`;
                        const redirectUrl = `/duel-result/${data.stored.recordId}`;
                        navigator(redirectUrl);

                    } else {
                        const redirectUrl = `/duel-result/${data.stored.recordId}`;
                        navigator(redirectUrl);
                    }
                } else {
                    setProcessLogItems([...appendNewLog(processLogItems, `TX Success but match not found`, LOG_STATUS.ERROR)]);
                    setProcessLogItems([...appendNewLog(processLogItems, `Redirecting...`, LOG_STATUS.PENDING)]);
                    const redirectUrl = `/arena-matchmaking/`;
                    navigator(redirectUrl);
                }
                await new Promise(resolve => setTimeout(resolve, 2000))
                    .then(() => {
                        closeModal();
                        setProcessLogItems([{
                            status: LOG_STATUS.PENDING,
                            text: `Waiting for players' confirmation...`
                        }])
                    });
            }).catch((x) => {
                setProcessLogItems([...appendNewLog(processLogItems, `Error attempting to match : ${x.toString()}`, LOG_STATUS.ERROR)]);
            });
        }

    }

    return (
        <Popup open={openModal} closeOnDocumentClick onClose={closeModal} className={`entry-passive-pool-popup`}>
            <div className="modal entry-passive-pool">
                <a className="close" onClick={closeModal}>
                    &times;
                </a>
                <div className={'content-after-x'}>
                    <h3>Confirmation</h3>
                    <div className="fading-line"/>
                    <div className={'modal-pair-content flex-container-columns'}>
                        <div className={'nft-details flex-container-rows'}>
                            <p>Selected NFT: <span
                                className={'selected-serial highlighted'}>#{selectedSet ? selectedSet.serialNumber : 'Parsing serial number...'}</span>
                            </p>
                            <img className={'selected-card'}
                                 src={`https://banana-capital-ipfs-mirror.b-cdn.net/ipfs/${selectedSet ? selectedSet.cardImageCID : 'TODO'}`}
                                 alt="Selected NFT"/>
                            {/*TODO: select card placeholder*/}
                        </div>
                        <div className={'entry-configuration flex-container-rows'}>

                            <div className={'selected-pool flex-container-columns'}>Selected pool: <span className={'selected-pool highlighted'}>{ARENA_POOLS[selectedPool]}</span></div>
                            <div className={'active-configuration-info flex-container-columns'}>
                                <div className={'flex-container-columns'}>
                                    <div className={'flex-container-rows'}>
                                        <div className={'flex-container-columns total-fees'}>Total fees: <span
                                            className={'highlighted-bg'}>{activeNumBattles * poolFees[selectedPool]}<CurrencyIcon
                                            size={24}/></span></div>
                                        <div className={'flex-container-columns total-reward '}>Potential gain: <span
                                            className={'highlighted-bg'}>{activeNumBattles * poolFees[selectedPool] * 1.9}<CurrencyIcon
                                            size={24}/></span></div>
                                    </div>
                                </div>
                            </div>

                            <PopUpProcessLogItems proccessLog={processLogItems}/>

                            <div className={'actions flex-row'}>
                                <button id={'pool-entry'} className={'btn'} onClick={() => {
                                    handleActivePoolEntry(selectedSet.serialNumber, navigator, closeModal);
                                }}>Pay Fee & Enter Pool <span className={'finance'}>({activeNumBattles * poolFees[selectedPool]}
                                    <CurrencyIcon size={24}/>)</span></button>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </Popup>
    );
}

export default ActiveEntryModal;