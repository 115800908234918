import * as React from "react";
import './Portal.css';
import {ItemCircle} from "../itemCard/itemCard";
import {FACTION, PATH_NAME, SET_PIECE} from "../../helper/ENUMS.ts";
import {formatNumber, indexOfMax} from "../../helper/calc";
import FullSetCarousel from "../fullSetCarousel/FullSetCarousel";
import {getAssetHostCDN} from "../../helper/externalLinks";
import PortalMintButton from "./PortalMintButton";
import {BiDownArrowAlt, BiLeftArrowAlt, BiRightArrowAlt} from "react-icons/bi";
import ToolTip from "../Modal/ToolTip";

const assetsHost = getAssetHostCDN();

const BanaturalSelection = ({carouselSet}) => {
    // const pathname = window.location.pathname //returns the current url minus the domain name
    // if (PATH_NAME.ARENA_PICKER === pathname || PATH_NAME.ARENA_PASSIVE_LIST === pathname) {
    //     return null;
    // }
    let bananFullSizeArray = [];

    const topCircleValues = [
        171 + 13,
        171 + 13 + 155 + 12 + 88 + 12, // is 32.1455% but should be 32.1045% , adj by -0.55px
        171 + 13 + 155 + 12 + 88 + 12 + 157 + 12 + 136 + 12, // is 55.1636% but should be ~55.036%-55.0536%, 0.11%-0.1276% adj by 0.25px
        171 + 13 + 155 + 12 + 88 + 12 + 157 + 12 + 136 + 12 + 155 + 12 + 104 + 12, // too lazy to calc
    ];

    const bottomCircleValues = [
        75 + 90 + 12.5 + 155 + 12.5 + 104 + 12.5 + 155 + 12.5 + 136 + 12.5 + 155 + 12.5 + 88 + 12.5,
        75 + 90 + 12.5 + 155 + 12.5 + 104 + 12.5 + 155 + 12.5 + 136 + 12.5,
        75 + 90 + 12.5 + 155 + 12.5 + 104 + 12.5,
        75 + 90 + 12.5,
    ];

    const bottomCircleValues2 = [
        75 + 956 + 12.5,
        75 + 689 + 12.5,
        75 + 373 + 12.5,
        75 + 90 + 12.5,
    ];

    const leftCircleValues = [
        10 + 158,
        10 + 17,
        10,
        10 + 109
    ];

    const rightCircleValues = [
        (323 + 12),
        (464 + 12),
        (481 + 12),
        (372 + 12)
    ];

    if (carouselSet !== undefined) {
        bananFullSizeArray = carouselSet.itemCards.map((item, index) => {

                const itemCircleStyles = {
                    top: `${topCircleValues[index] / 13.11}%`,
                    left: `${leftCircleValues[index] / 6.61}%`,
                    // right: `${rightCircleValues[index] / 6.61}%`,
                    // bottom: `${bottomCircleValues[index] / 13.11}%`,
                    // bottom: `${bottomCircleValues2[index] / 13.75}%`,
                    width: `${160 / 6.61}%`,
                    height: `${160 / 13.11}%`,
                    backgroundImage: null,
                };

                return <ItemCircle card={item} key={`key_circle_${index}`} circleStyle={itemCircleStyles}
                                   setUId={carouselSet.uId}/>

            }
        );
        return (
            <div id="banatural-selection"> {/*vetka*/}
                {bananFullSizeArray}
                <div id={'palm-overlay'}/>
            </div>
        );
    }

    console.log('vetkaUnDefined', carouselSet);
    return (
        <div id="banatural-selection"> {/*vetka*/}
            {/*{props.palmSelectionRender}*/}
            <div id={'palm-overlay'}/>
        </div>
    );

}


function PortalButton({itemCards, carouselIndex, lastIndex}) {
    return <PortalMintButton itemCards={itemCards}/>
}


export const InventoryPortal = ({
                                    carouselSets,
                                    setCarouselIndexFunc,
                                    carouselIndex,
                                    setSelectedSet,
                                    showActionButton
                                }) => {

    const lastIndex = carouselSets.length - 1;
    let bananDamage = [0, 0, 0];
    let biggestDMG = (FACTION[indexOfMax(bananDamage)]).toLowerCase();
    let carouselSet = null;
    let itemCards = [null, null, null, null];
    if (carouselSets.length > 0) {
        const carouselSet = carouselSets[carouselIndex];
        console.log(`carouselSet `, carouselSet);
        if (setSelectedSet && setSelectedSet instanceof Function) {
            setSelectedSet(carouselSet);
        } else {
            console.log(`setSelectedSet typeof ${typeof setSelectedSet}`);
        }
        bananDamage = carouselSet.damages;
        itemCards = [...carouselSet.itemCards];
        biggestDMG = (FACTION[indexOfMax(bananDamage)]).toLowerCase();
    }

    const pathname = window.location.pathname //returns the current url minus the domain name
    let disabledBool = (PATH_NAME.INVENTORY === pathname && carouselIndex === lastIndex) ||
        (PATH_NAME.ARENA_PICKER === pathname && carouselIndex >= lastIndex - 1);

    return (
        <>
            <div id="inventory-portal" className={"portal"}>
                <div id={'damage-numbers'}>
                    {/*<div>*/}
                    <span className={'damage-number pre-red'}>&nbsp;</span>
                    <div className={'damage-number red'}>
                        <span>{formatNumber(bananDamage[FACTION.RED] / 100, 4)}</span>
                    </div>
                    <div className={'damage-number green'}>
                        <span>{formatNumber(bananDamage[FACTION.GREEN] / 100, 4)}</span>
                    </div>
                    <div className={'damage-number blue'}>
                        <span>{formatNumber(bananDamage[FACTION.BLUE] / 100, 4)}</span>
                    </div>
                    {/*</div>*/}
                </div>
                <div id={"stone-wrapper"}>
                    {carouselSets.length > 0 ?
                        <><FullSetCarousel glowColour={biggestDMG} itemCards={itemCards} carouselSets={carouselSets}
                                           carouselIndex={carouselIndex} setIndexFunc={setCarouselIndexFunc}
                                           lastIndex={lastIndex} glowReady={true}/>

                        </>
                        : null}
                </div>
                {carouselSets.length > 0 && showActionButton ?
                    <PortalButton itemCards={carouselSets[lastIndex].itemCards} carouselIndex={carouselIndex}
                                  lastIndex={lastIndex}/> : null}

            </div>
            {carouselSets.length > 0 && pathname.includes(PATH_NAME.ARENA_RESULT_PAGE) ? null :
                <div id={'full-size-carousel-buttons'}>
                    <button className={'carousel-button'} id={'carousel-left'} onClick={() => {
                        if (carouselIndex > 0)
                            setCarouselIndexFunc(carouselIndex - 1);
                    }}>&nbsp;</button>
                    <button className={`carousel-button ${disabledBool ? 'disabled' : ''}`} id={'carousel-right'}
                            disabled={disabledBool}
                            onClick={() => {
                                if (carouselIndex < lastIndex) {
                                    // removing ability to display new set if we're on ArenaPicker picker page
                                    setCarouselIndexFunc(carouselIndex + 1)
                                }
                            }}>&nbsp;</button>
                </div>}
        </>
    );
}

const CarouselFactionDamage = ({damages}) => {
    return (
        <div id={'carousel-faction-damage'} className={'flex-container-rows'}>
            {damages.map((damage, index) => {
                const faction = FACTION[index].toLowerCase();
                const imgSrc = assetsHost + "/assets/i/" + faction + "_power.png"; //file
                return (<div className={`set-damage flex-container-columns ${faction}`}>
                    <img src={imgSrc} alt={`${faction} faction total damage of selected set`}/>
                    <div className={'flex-container-columns'}><span>&nbsp;-&nbsp;</span>{(damage / 100).toFixed(2)}</div>
                </div>);
            })}
        </div>
    );
}

export const InventoryPortalNew = ({carouselSets, carouselIndex, setSelectedSet, setCarouselIndexFunc}) => {
    let bananDamages = [0, 0, 0];
    let biggestDMG = (FACTION[3]).toLowerCase();
    let itemCards = [null, null, null, null];
    const lastIndex = carouselSets.length - 1;
    if (carouselSets.length > 0) {
        const carouselSet = carouselSets[carouselIndex];
        console.log(`carouselSet `, carouselSet);
        if (setSelectedSet && setSelectedSet instanceof Function) {
            setSelectedSet(carouselSet);
        } else {
            console.log(`setSelectedSet typeof ${typeof setSelectedSet}`);
        }
        bananDamages = (carouselSet) ? carouselSet.damages : [0, 0, 0];
        itemCards = (carouselSet) ? [...carouselSet.itemCards] : [null, null, null, null];
        biggestDMG = (FACTION[indexOfMax(bananDamages)]).toLowerCase();
    }

    const MintButton = () => {
        let buttonText = 'COMPLETE SET TO MINT';
        let isDisabled = true;

        if (itemCards.filter((item) => item !== null).length === 4) {
            buttonText = 'MINT SET';
            isDisabled = false;
        }

        return <button className={`btn button primary ${isDisabled ? 'disabled' : ''}`}
                       disabled={isDisabled}>{buttonText}</button>;
    }

    return (
        <div id={'set-wrapper'} className={'flex-container-columns'}>
            {carouselSets.length > 0 ?

                <div id={'carousel-wrapper'} className={'flex-container-rows'}>
                    <FullSetCarousel glowColour={biggestDMG} itemCards={itemCards} glowReady={true}
                                     carouselSets={carouselSets}
                                     carouselIndex={carouselIndex}
                                     setIndexFunc={setCarouselIndexFunc}
                                     lastIndex={lastIndex}/>


                    <div id={'carousel-info-bar'}>
                        <div id={'carousel-bar-actions'} className={`flex-container-rows`}>
                            {lastIndex === carouselIndex ?
                                <PortalMintButton itemCards={itemCards} mintButtonContent={MintButton}/> : <></>}
                        </div>
                        <InventoryPortalFooter lastIndex={lastIndex} carouselIndex={carouselIndex}
                                               setCarouselIndexFunc={setCarouselIndexFunc} carouselSets={carouselSets}/>
                    </div>
                </div>
                : null}
            <CarouselFactionDamage damages={bananDamages}/>
        </div>
    );
}

export const InventoryPortalFooter = ({lastIndex, carouselIndex, carouselSets, setCarouselIndexFunc}) => {
    const pathname = window.location.pathname //returns the current url minus the domain name
    // let disabledBool = (PATH_NAME.INVENTORY === pathname && carouselIndex === lastIndex) ||
    //     (PATH_NAME.ARENA_PICKER === pathname && carouselIndex >= lastIndex - 1);
    let disabledBool = lastIndex === 1;
    return (
        <div id={`portal-manipulator`} className={`flex-container-rows`}>
            {/*<div id={'manipulator-set-info'} className={'split-50'}>*/}
            {/*    <span id={'carousel-id'}>#{carouselSets[carouselIndex].uId}</span>*/}
            {/*</div>*/}
            <div id={'manipulator-help-tooltip'} className={'split-10'}>
                <ToolTip text={`You can only mint one set at a time.`} direction={`top`}
                         content={<div id={'manipulator-help-tooltip-content'} className={'flex-container-rows'}>
                             <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae magni omnis delectus nemo, maxime molestiae</p>
                            <img id="battle-mechanics-symbols" src="https://asset-host.b-cdn.net/assets/homepage/sector_6.png"></img>
                         </div>}
                         trigger={<img src={`https://asset-host.b-cdn.net/assets/i/how_arena_works.png`}/>}/>
            </div>
            <div id={'manipulator-set-index'} className={'split-50'}>
                {lastIndex === 0 ? <span>&nbsp;Create new set!&nbsp;</span> :
                    <span>&nbsp;{carouselIndex === lastIndex ? 'New set' : `Set ${carouselIndex + 1}`}&nbsp;/ {lastIndex}&nbsp;</span>}
            </div>
        </div>

    );
}

const Portal = ({
                    showPalm,
                    setSelectedSet,
                    showActionButton,
                    carouselGlobalSet,
                    carouselGlobalIndex,
                    setCarouselGlobalIndex
                }) => {
    const PalmExtension = () => {
        return (
            <div id="palm-extension"/>
        );
    }

    return (
        <>
            {showPalm ? <PalmExtension/> : null}
            <div className={'flex-container-columns'} style={{width: '100%', height: '100%', zIndex: '5'}}>
                {showPalm ? <BanaturalSelection carouselSet={carouselGlobalSet[carouselGlobalIndex]}/> : null}
                <InventoryPortal carouselSets={carouselGlobalSet} carouselMaxIndex={carouselGlobalSet.length}
                                 carouselIndex={carouselGlobalIndex} setCarouselIndexFunc={setCarouselGlobalIndex}
                                 setSelectedSet={setSelectedSet} showActionButton={showActionButton}/>
            </div>
        </>
    );
}

export default Portal;