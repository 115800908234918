import './FactionBoxOpener.css';
import React from "react";
import {assetsHost} from "../../../helper/externalLinks";
import {CurrencyIcon} from "../../../App";
import MyCustomEventEmitter from "../../../component/myCustomEvents";
import {EVENT_TYPE} from "../../../helper/ENUMS.ts";


const FactionBoxOpener = ({setUnpackingFaction, setUnpackingModalOpenState, faction}) => {

    const handleUnpack = (factionIndex) => {
        setUnpackingFaction(factionIndex);
        setUnpackingModalOpenState(true);
        // console.log("handleUnpack", unpackingFaction);
        MyCustomEventEmitter.dispatch(EVENT_TYPE.OPEN_ITEM_UNPACKING_MODAL, {faction: factionIndex});
    }

    return (
        <div id={`faction-box-${faction.key}`} className={'flex-container-rows flex-row faction-box-opener'}>
            <h3 className={`${faction.key}`}>{faction.key.toUpperCase()} faction item</h3>
            <img src={`${assetsHost}/assets/shop/${faction.key}_card.png`} className={`${faction.key}-card card-placeholder`}
                 alt={`depiction of unknown NFT card of ${faction.key} faction with question mark instead of item, indicating unknown randomly generated item player receives upon unpacking an item`}/>
            <button className={'button btn primary'}  onClick={() => {
                handleUnpack(faction.index);
            }}>BUY & OPEN:<span className={`price`}>100.00 <CurrencyIcon size={24}/></span></button>
        </div>
    );
}

export default FactionBoxOpener;