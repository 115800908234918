import React, {useEffect, useRef, useState} from 'react';
// import {getSocket} from "../../connector/socket/socketInit";
import ArenaResultPage from "../arena-result-page/ArenaResultPage";
import './ArenaMatchmaking.css';
import {ARENA_POOLS, EVENT_TYPE} from "../../helper/ENUMS.ts";
import {getSocketPoolInfo, requestPoolInfoUpdate} from "../../connector/socket/socketInit";
import MyCustomEventEmitter from "../../component/myCustomEvents";
import {useNavigate} from "react-router-dom";



// socket.join("the-room")

const ArenaMatchmaking = () => {

    const navigator = useNavigate();
    // console.log("ArenaMatchmaking", getSocket());

    // if (getSocket() !== null && getSocket()) {
    // const ioInstance = getSocket();
    console.log(`ArenaMatchmaking socket init`);

    let activePoolSize = useRef('loading...');
    let passivePoolSize = useRef('loading...');

    const [poolInfo, setPoolInfo] = useState({
        activePoolSize: 'loading...',
        passivePoolSize: 'loading...',
    });
    let poolInfoLastUpdated = useRef("0");

    // ioInstance.on("poolInfo", ({count}) => {
    //     console.log("poolInfo received");
    //     console.log("poolInfo, count = ", count);
    // });


    // }

    useEffect(() => {
        setPoolInfo(getSocketPoolInfo(1));
        MyCustomEventEmitter.subscribe("poolInfoUpdated", () => {
            console.log("poolInfoUpdated event received");
            // poolInfoLastUpdated = `${Date.now().toLocaleString()}0`;
            setPoolInfo({...getSocketPoolInfo(1), lastUpdated: `${Date.now().toLocaleString()}`});
        });
        if (poolInfo.lastUpdated === undefined || poolInfo.lastUpdated === null) {
            console.log("poolInfo.lastUpdated is undefined, requesting update");
            new Promise(resolve => setTimeout(resolve, 2500))
                .then(requestPoolInfoUpdate());
        } else {
            console.log("poolInfo.lastUpdated is not undefined, not requesting update");
        }
        MyCustomEventEmitter.subscribe(EVENT_TYPE.DUEL_EVENT_CREATED, (data) => {
            // todo move to notification component
            if (window.location.pathname === `/arena-matchmaking/` || window.location.pathname === `/arena-matchmaking`) {
                const redirectUrl = `/duel-result/${data.duelId}`;
                console.log("EVENT_TYPE.DUEL_EVENT_CREATED", "redirecting to", redirectUrl);
                navigator(redirectUrl);
            }
        });
    }, []);

    return (
        <div id={'arena-matchmaking'} className={'flex-container-rows'}>
            <div className="box"><img src={'https://asset-host.b-cdn.net/assets/arena-matchmaking/cocount_loading.png'} id={'dvd-coco'}/><h2
                className={'background-text'}>Searching for opponent...</h2></div>
            <div id={'matchmaking-info'} className={'flex-container-rows'}>
                <div className={'matchmaking-info-header flex-container-rows'}>
                    <h3>POOL: {ARENA_POOLS[1].toUpperCase()}</h3>
                </div>
                <div className={'pools-info flex-container-columns'}>
                    <div className={'passive-pool-info flex-container-columns'}>
                        Passive Queue: &nbsp;<span> {poolInfo.passivePoolSize}</span>
                    </div>
                    &nbsp; &nbsp; &nbsp;
                    <div className={'active-pool-info flex-container-columns'}>
                        Active Queue: &nbsp; <span>{poolInfo.activePoolSize}</span>
                    </div>
                </div>
                <div className={`pool-info-last-updated`}>
                    last updated: {poolInfo.lastUpdated || 'waiting for update...' }
                </div>
            </div>
        </div>
    );
};


export default ArenaMatchmaking;