import React, {useRef, useState} from 'react';
import './UpperBoundManipulator.css';
import {DUEL_PLAYER, PATH_NAME} from "../../helper/ENUMS.ts";
import ToolTip from "../Modal/ToolTip";
import {BiLeftArrowAlt, BiRightArrowAlt} from "react-icons/bi";
import {AccountId} from "@hashgraph/sdk";
import {getAccountIdWithWaitV3} from "../WalletConnectComponent/WalletConnectComponentV3";


const InventoryUpperBoundManipulator = ({lastIndex, carouselIndex, setCarouselIndexFunc, showNewSet = true}) => {

    const pathname = window.location.pathname //returns the current url minus the domain name
    // let disabledBool = (PATH_NAME.INVENTORY === pathname && carouselIndex === lastIndex) ||
    //     (PATH_NAME.ARENA_PICKER === pathname && carouselIndex >= lastIndex - 1);
    let disabledBool = lastIndex === 1;
    return (
        <div id={`portal-carousel-actions`} className={'flex-container-columns actions'}>
            <button className={`carousel-button ${disabledBool ? 'disabled' : ''} secondary btn `}
                    id={'carousel-left'}
                    disabled={disabledBool} onClick={() => {
                if ((showNewSet && carouselIndex <= 0)) {
                    setCarouselIndexFunc(lastIndex);
                } else if (!showNewSet && carouselIndex <= 0) {
                    setCarouselIndexFunc(lastIndex - 1);
                } else {
                    setCarouselIndexFunc(carouselIndex - 1);
                }
            }}><BiLeftArrowAlt/> PREVIOUS SET
            </button>
            <button className={`carousel-button ${disabledBool ? 'disabled' : ''} secondary btn `}
                    id={'carousel-right'}
                    disabled={disabledBool}
                    onClick={() => {
                        if ((showNewSet && carouselIndex < lastIndex) || (!showNewSet && carouselIndex < lastIndex - 1)) {
                            //TODO remove ability to display new set if we're on ArenaPicker picker page
                            setCarouselIndexFunc(carouselIndex + 1)
                        } else {
                            setCarouselIndexFunc(0);
                        }
                    }}>NEXT SET <BiRightArrowAlt/></button>
        </div>
    );
}

let lastProcessedDuelId = 0;
const DuelResultUpperBoundManipulator = ({duelInfo}) => {
    // const [currentPlayer , setCurrentPlayer] = useState('0x0');
    let leftOwner = '0000000000000000000000000000000000000000';
    let rightOwner = '0000000000000000000000000000000000000000';
    const [leftOwnerRender, setLeftPlayer] = useState('');
    const [rightOwnerRender, setRightPlayer] = useState('');
    let leftSetId = 'loading...';
    let rightSetId = 'loading...';
    console.log('duelInfo', duelInfo);
    if (duelInfo.playerLeft !== undefined) {
        leftSetId = duelInfo.playerLeft.info.uId;
        leftOwner = (duelInfo.playerLeft.ownerAddress).split('x')[1];
    }
    if (duelInfo.playerRight !== undefined) {
        rightSetId = duelInfo.playerRight.info.uId;
        rightOwner = (duelInfo.playerRight.ownerAddress).split('x')[1];
    }
    // const leftOwner = AccountId.fromSolidityAddress((duelInfo.playerLeft !== undefined) ? duelInfo.playerLeft.ownerAddress : '0000000000000000000000000000000000000000').toString();

    // if (duelInfo.playerLeft !== undefined) {
    //     const leftOwner = AccountId.fromSolidityAddress(duelInfo.playerLeft.ownerAddress).toString();
    //     setLeftPlayer(`${leftOwner}`);
    // }
    if (duelInfo !== undefined && duelInfo.playerRight !== undefined) {
        const rightPlayer = duelInfo.playerRight.ownerAddress;
        // setRightPlayer(`${AccountId.fromSolidityAddress(rightPlayer)}`);
    }

    let accountId = '';
    if (lastProcessedDuelId !== duelInfo.id) {
        getAccountIdWithWaitV3().then((x) => {
            const accInSolidity = `${AccountId.fromString(x).toSolidityAddress()}`;
            console.log('leftOwner', leftOwner, 'rightOwner', rightOwner, 'accountId', accInSolidity);
            if (accInSolidity !== leftOwner) {
                setLeftPlayer(AccountId.fromSolidityAddress(leftOwner).toString());
            } else {
                setLeftPlayer('YOU');
            }
            if (accInSolidity !== rightOwner) {
                setRightPlayer(AccountId.fromSolidityAddress(rightOwner).toString());
            } else {
                setRightPlayer('YOU');
            }
            lastProcessedDuelId = duelInfo.id;
        });
    }


    return (
        <div id={`portal-carousel-actions`} className={'flex-container-columns actions'}>
            <span className={'banana-warrior-label left '}>#{leftSetId} ({leftOwnerRender})</span>
            <span className={'banana-warrior-label right'}>#{rightSetId} ({rightOwnerRender})</span>
            {/*<span>{currentPlayer}</span>*/}
        </div>
    );
}

const UpperBoundManipulator = ({type, properties}) => {
    let content;
    switch (type) {
        case PATH_NAME.INVENTORY:
            content = <InventoryUpperBoundManipulator lastIndex={properties.lastIndex}
                                                      carouselIndex={properties.carouselIndex}
                                                      setCarouselIndexFunc={properties.setCarouselIndexFunc}
                                                      showNewSet={true}/>;
            break;
        case PATH_NAME.ARENA_PICKER:
            content = <InventoryUpperBoundManipulator lastIndex={properties.lastIndex}
                                                      carouselIndex={properties.carouselIndex}
                                                      setCarouselIndexFunc={properties.setCarouselIndexFunc}
                                                      showNewSet={false}/>;
            break;
        case PATH_NAME.DUEL_RESULT_PAGE:
            content = <DuelResultUpperBoundManipulator duelInfo={properties.duelInfo}/>;
            break;
    }

    return (
        <div id={'upper-bound-manipulator'}>
            {content}
        </div>
    );
}

export default UpperBoundManipulator;