import './HomePage.css';
import {assetsHost} from "../../helper/externalLinks";
import {FcReddit} from "react-icons/fc";
import {FaDiscord, FaHandPointRight} from "react-icons/fa";
import {TbChevronsDown, TbSwords} from "react-icons/tb";
import CookieConsent from "react-cookie-consent";
import {useRef} from "react";

let scrollToCoco;
const HomePageMainImage = () => {
    return (
        <div id={'homepage-main-image'} className={'full-width flex-row-full'}>
            <div className={'container-1600 flex-container-rows'}>
                {/*<h1>Banana.Capital</h1>*/}
                {/*<div className={'actions flex-container-columns'}>*/}
                {/*    <a href={'https://docs.banana.capital/'} className={'primary'} target={'_blank'}> <button className={'btn button secondary'}>Read the Docs</button></a>*/}
                {/*    <a href={'#'} onClick={() => {scrollToCoco()}}><button className={'btn button secondary'}>Scroll down for more! <TbChevronsDown/></button></a>*/}
                {/*</div>*/}
                <div id={'main-img-wrapper'} className={'flex-container-rows'}>
                    <img id='main-img' src={`${assetsHost}/assets/homepage/sector_11.png`}/>
                </div>
            </div>
        </div>
    );
}

const Splitter = () => {
    return (
        <hr className={'homepage-splitter'}/>
    );
}

const coconutSectionTitles = {1: 'MINT & EQUIP', 2: 'PLAY 2 EARN', 3: 'ALL 4 YOU'}
const coconutSectionTexts = {
    1: <>Our 2 tiered NFT system allows you to <span>roll, mint, trade</span> and equip
        your <span>NFT items</span> into <span>NFT sets</span>! In order to make this work we created a couple of our
        own blockchain tools ranging including universal EVM-compatible <a href={'https://github.com'}>RNG
            source</a>. </>,
    2: <>Unlike many other NFT games, we are <span>not</span> just <span>using NFTs for cosmetic collectibles</span>.
        Your tokens intrinsic values matter more than looks! And the better you are the more you can win. This brings a
        new level of financial integration into basic play 2 earn concept.</>,
    3: <>Our system, complex as it is, remains modular to improve based on users feedback. From your first unpack to
        your feedback on balance and matchmaking, <span>every player has a part in Ban.Cap's future.</span>
    </>,
}
const CoconutSection = ({index}) => {
    return (
        <div className={`coconut-section coco-${index} flex-container-rows`}>
            <div className={'info-box'}>
                <h2>{coconutSectionTitles[index]}</h2>
                <p>{coconutSectionTexts[index]}</p>
            </div>
            <img src={`${assetsHost}/assets/homepage/sector_1_coconut_${index}.png`}/>
        </div>
    );
}
const ThreeCocos = () => {
    const messagesEndRef = useRef(null)

    scrollToCoco = () => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"})
    }

    return (
        <div id={'homepage-three-cocos'} ref={messagesEndRef} className={'full-width flex-row-full'}>
            <div className={'container-1600 flex-container-columns'}>
                <CoconutSection index={1}/>
                <CoconutSection index={2}/>
                <CoconutSection index={3}/>
            </div>
        </div>
    );
}

const DocsBlock = () => {
    return (
        <div id={'homepage-docs-block'} className={'full-width flex-row-full'}>
            <div className={'container-1600 flex-container-rows'}>
                <h2>WANT TO LEARN MORE?</h2>
                <div className={'content flex-container-columns'}>
                    <div className={'yt-video flex-container-rows'}>
                        {/*<iframe src='https://www.youtube.com/embed/E7wJTI-1dvQ'*/}
                        {/*        frameBorder='0'*/}
                        {/*        allow='autoplay; encrypted-media'*/}
                        {/*        allowFullScreen*/}
                        {/*        autoplay={true}*/}
                        {/*        title='video'*/}
                        {/*/>*/}
                        {/*<iframe width="560" height="315"*/}
                        {/*        src="https://www.youtube.com/embed/z1gDwXGsdlk?mute=1&autoplay=1&loop=1&showinfo=0&color=white&iv_load_policy=3"*/}
                        {/*        frameBorder="0"*/}
                        {/*        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
                        {/*        allowFullScreen></iframe>*/}
                        <video loop muted autoPlay={true}>
                            <source src={`${assetsHost}/assets/homepage/bancap_intro.mp4`}
                                    type="video/mp4"/>
                            {/*<source src="movie.ogg" type="video/ogg"/>*/}
                            Your browser does not support the video tag.
                        </video>
                        <p>We couldn't figure out how to squeeze all the details into a single cute page. So pls read
                            this&nbsp;<FaHandPointRight className={'finger-point icon'}/></p>
                    </div>
                    <div className={'gitbook'}>
                        <p>We highly recommend reading through our GitBook<br/>(which basically acts as our White Paper
                            with its own Thesis).</p>
                        <img src={`${assetsHost}/assets/homepage/sector_2.png`}/>
                        <div className={'flex-container-columns actions'}>
                            <a href={'/'} className={'primary'}>
                                <button className={'btn button'}>Read GitBook</button>
                            </a>
                            <a href={'https://discord.gg/rz69yWqh6X'} target={'_blank'} className={'secondary'}>
                                <button className={'btn button secondary'}>Discord</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const BananaBattle = () => {
    return (
        <div id={'homepage-banana-battle'} className={'full-width flex-row-full'}>
            <div className={'container-1600 flex-container-rows'}>
                <div className={'text-box flex-container-rows'}>
                    <h2>WHAT IS BANANA.CAPITAL?</h2>
                    <p>Unpeeling the Potential of NFT Gam(bl)ing. A synergy of Strategy, Finance, and Luck.</p>
                </div>
                <div className={'flex-container-rows info-box'}>
                    <div className={'text-box flex-container-rows'}>
                        <h3>COLLECT THEM ALL</h3>
                        <p>Dive into our unique NFT ecosystem, where players create and determine the supply.<br/>Each
                            NFT has a tangible role and function within Banana.Capital environment.</p>
                    </div>
                    <div className={'actions flex-container-columns'}>
                        <a href={'https://docs.banana.capital/banana-capital/banana-set-nfts'} className={'primary'}
                           target={'_blank'}>
                            <button className={'btn button primary'}>Learn More About Our NFTs</button>
                        </a>
                        <a href={'#homepage-banana-battle'}>
                            <button className={'btn button primary'}>View Marketplace (Coming Soon)</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

const ArenaBattleSection = () => {
    return (
        <div id={'homepage-arena-battle'} className={'full-width flex-row-full'}>
            <div className={'container-1600 flex-container-rows'}>
                <h2>AN AUTO-BATTLER THEN?</h2>
                <div className={'arena-content flex-container-columns full-width'}>
                    <div className={'text-box flex-container-rows'}>
                        <p>Not quite. The Gam(bl)ing element of Banana.Capital serves as a definite
                            use-case for your NFTs, it merely acts as a powering mechanism and justification of market
                            pricing for your NFTs. But it certainly isn't the only reason to participate in our
                            economy.</p>
                        {/*<p></p>*/}
                        <p>While severely lacking in animation we took
                            priority in sound tokenomics and solid business model foundations upon which the nicer
                            interfaces can be built later on.</p>
                        <p>We hope the profits and fun experience can compensate
                            for this since day 1.</p>
                        <p style={{fontStyle: 'italic'}}>More game modes will be added in future seasons...</p>
                    </div>
                    <div className={'preview'}>
                        {/*<img src={`https://asset-host.b-cdn.net/assets/homepage/sector_4.png`}/>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}

const BattleMechanics = () => {
    return (
        <div id={'homepage-battle-mechanics'} className={'full-width flex-row-full'}>
            <div className={'container-1600 flex-container-rows'}>
                <h2>HOW DO I WIN?</h2>
                <p>Think of a two-way rock-paper-scissors. All your banana NFTs are comprised of
                    item NFTs of certain factions. Each faction is powerful and powerless against one other.</p>
                <p>Whoever blocks or deals more damage wins! As simple as that.</p>
                <img id={'battle-mechanics-symbols'} src={`https://asset-host.b-cdn.net/assets/homepage/sector_6.png`}/>
                <a href={'https://docs.banana.capital/banana-capital/competitive-betting-core/battling-mechanics'}
                   target={'_blank'} id={'mechanics-link'}
                   className={'btn button secondary'}>
                    <button className={'btn secondary'}><TbSwords/> Read more on GitBook >></button>
                </a>
            </div>
        </div>
    );
}

const IntroSet = () => {
    return (
        <div id={'homepage-intro-sets'} className={'full-width flex-row-full'}>
            <div className={'container-1600 flex-container-rows iterations'}>
                <h2>DON'T WANT TO FIGHT?</h2>
                <p>Collect and trade NFT sets!{/* Plenty of NFTs to speculate on with other players...*/}</p>
                <img id={'set-stack'} src={`https://asset-host.b-cdn.net/assets/homepage/sector_7.png`}/>
            </div>
        </div>
    );
}
const IntroItems = () => {
    return (
        <div id={'homepage-intro-items'} className={'full-width flex-row-full'}>
            <div className={'container-1600 flex-container-rows iterations'}>
                <h2>NOT INTO SET COMPLETION?</h2>
                <p>Mint & Trade Individual Items!</p>
                <img id={'item-stack'} src={`https://asset-host.b-cdn.net/assets/homepage/sector_8.png`}/>
            </div>
        </div>
    );
}
const IntroCurrency = () => {
    return (
        <div id={'homepage-intro-items'} className={'full-width flex-row-full'}>
            <div className={'container-1600 flex-container-rows iterations'}>
                <h2>BYPASSING NFTs ENTIRELY?</h2>
                <p>Speculate and Profit on Our Currency Token!</p>
                <img id={'currency-stack'} src={`https://asset-host.b-cdn.net/assets/homepage/sector_9_A.png`}/>
            </div>
        </div>
    );
}
const IntroGovTokens = () => {
    return (
        <div id={'homepage-intro-items'} className={'full-width flex-row-full'}>
            <div className={'container-1600 flex-container-rows iterations'}>
                <h2>JUST HERE FOR THE RIDE?</h2>
                <p>Hodl governance tokens and collect shares of profit!
                    <br/><span>Gov token pre-sale will be launched early autumn 2023 once our gov token is approved on required platforms...</span>
                </p>
                <img id={'gov-token-presale'} src={`https://asset-host.b-cdn.net/assets/homepage/sector_10_A.png`}/>
                <img id={'gov-token-stack'} src={`https://asset-host.b-cdn.net/assets/homepage/sector_10_B.png`}/>
            </div>
        </div>
    );
}

const RoadMapSection = () => {
    return (
        <div id={'homepage-more-text'} className={'full-width flex-row-full'}>
            <div className={'container-1600 flex-container-rows'}>
                <h2> ROADMAP </h2>
                <a href={`${assetsHost}/assets/homepage/sector_12_a.png`} target={'_blank'} className={'full-width'}
                   id={'roadmap-ref'}><img src={`${assetsHost}/assets/homepage/sector_12_a.png`}/></a>
            </div>
        </div>
    );
}
const Icon = (props) => {
    return (<svg className={'socials-icon twitter'} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="250"
                 height="250" viewBox="0 0 50 50">
        <path
            d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"></path>
    </svg>);
}
const HomePageFooter = () => {
    return (
        <div id={'homepage-footer'} className={'full-width flex-row-full flex-container-rows'}>
            <div className={'container-1600 flex-container-rows'}>
                <h2>SOCIALS</h2>
                <div className={'flex-container-columns socials'}>
                    {/*<div className={'flex-container-rows social-box'}>*/}
                    {/*    <h3>JOIN THE DISCUSSION</h3>*/}
                    {/*    <FcReddit className={'socials-icon reddit'}/>*/}
                    {/*    <a href={'https://www.reddit.com/r/BananaCapital/'} target={'_blank'}>REDDIT</a>*/}
                    {/*</div>*/}
                    {/*<div className={'flex-container-rows social-box'}>*/}
                    {/*    <h3>JOIN THE COMMUNITY</h3>*/}
                    {/*    <FaDiscord className={'socials-icon discord'}/>*/}
                    {/*    <a href={'/'}>DISCORD</a>*/}
                    {/*</div>*/}
                    <div className={'flex-container-rows social-box'}>
                        <h3>FOLLOW FOR NEWS</h3>

                        <a href={'https://twitter.com/BananaCapital_'} target={'_blank'} className={'flex-container-rows social-link'}>
                            <img alt={'Twitter.com social network icon'}
                                 className={'socials-icon twitter'}
                                 src={'https://asset-host.b-cdn.net/assets/socials/x_icon.png'}/>
                            <button className={'btn button secondary'}>Twitter/X</button>
                        </a>
                    </div>
                    <div className={'flex-container-rows social-box'}>
                        <h3>JOIN THE COMMUNITY</h3>
                        <a href={'https://discord.gg/rz69yWqh6X'} target={'_blank'} className={'flex-container-rows social-link'}>
                            <img alt={'Twitter.com social network icon'}
                                 className={'socials-icon discord'}
                                 src={'https://asset-host.b-cdn.net/assets/socials/discord_icon.png'}/>
                            <button className={'btn button secondary'}>Discord</button>
                        </a>
                    </div>
                    <div className={'flex-container-rows social-box'}>
                        <h3>FOLLOW FOR BETTER NEWS</h3>
                        <a href={'https://calaxy.com/@BananaCapital'} target={'_blank'} className={'flex-container-rows social-link'}>
                            <img alt={'Calaxy.com social network icon'}
                                 className={'socials-icon calaxy'}
                                 src={'https://asset-host.b-cdn.net/assets/socials/calaxy_icon.png'}/>
                            <button className={'btn button secondary'}>@BananaCapital</button>
                        </a>
                    </div>
                </div>
            </div>
            <div className={'flex-container-columns copyright'}>
                <div className={'container-1600 flex-container-columns'}>
                    <p>ver. {process.env.REACT_APP_VERSION}</p>

                    <p>© 2023 Banana.Capital - All Coconut & Copyright Rights Reserved</p>
                </div>
            </div>
        </div>
    );
}

const ReadMoreSection = () => {
    return (
        <>
            <ArenaBattleSection/>
            <Splitter/>
            <RoadMapSection/>
            <Splitter/>
            <BattleMechanics/>
            <Splitter/>
            <IntroSet/>
            <Splitter/>
            <IntroItems/>
            <Splitter/>
            <IntroCurrency/>
            <Splitter/>
            <IntroGovTokens/>
        </>
    );
}

const HomePage = () => {
    return (
        <div id={'homepage'}>
            <HomePageMainImage/>
            <Splitter/>
            <ThreeCocos/>
            <Splitter/>
            <DocsBlock/>
            <Splitter/>
            <BananaBattle/>
            <Splitter/>
            <ReadMoreSection/>
            <Splitter/>
            <HomePageFooter/>
            <CookieConsent location="bottom"
                           onAccept={(byScroll) => {
                               console.log(`consent given. By scrolling - ${byScroll}`);
                           }}
                           buttonText="Ok"
                           cookieName="myAwesomeCookieName2"
                           style={{
                               background: "#2B373B88",
                               margin: "auto auto 0 0",
                               display: "flex",
                               textAlign: "center",
                               width: "auto"
                           }}
                           buttonStyle={{color: "#4e503b", fontSize: "13px", margin: "auto 65px auto 45px "}}
                           overlay={false}
                           visible={"byCookieValue"}
                           debug={false}
                           hideOnAccept={true}
                           acceptOnScroll={true}
                           acceptOnScrollPercentage={25}

                           expires={150}>This website uses cookies to enhance the user experience.</CookieConsent>
        </div>
    );
}

export default HomePage;