import React, {useEffect, useState} from "react";
import {BiError} from "react-icons/bi";
import {ItemCardSemiImage} from "../itemCard/itemCard";
import axios from "axios";
import {putOnNewSetItem} from "../../pages/inventory/InventoryNew";
import {applyFactionFilter, applySort} from "./CardList";


let setPostMintRefreshingHook = null, setPostMintedItemsErrorHook = null, setPostMintedItemsHook = null;

export const redrawMintedItems = async () => {
    const urlHost = process.env.REACT_APP_BACK_END_HOST;
    try {
        setPostMintRefreshingHook(true);
        const response = await axios.get(urlHost + "/item/post-mint-items", {
            headers: {
                'Content-Type': "application/json",
                'Accept': "application/json",
            },
            responseType: "json",
            'axios-retry': {
                retries: 3
            }
        });
        console.debug('parsed minted items');
        setPostMintedItemsHook(response.data.items);
        setPostMintedItemsErrorHook(null);
    } catch (error) {
        console.error('axios error occurred', error.message);
        setPostMintedItemsErrorHook(error.message); // TODO REDO with a proper error object handling based on code etc
    }
    setPostMintRefreshingHook(false);
};

function MintedCardList({cards, filterArray, sortObject = {id: 0, damage: 0}, cardClickEvent}) { //somehow card list is called TWICE with each refresh. Weird, might have to check it out later. Probably filter + setPiece combo update
    let cardsRender = [];
    // const applyFactionFilter = (card, filter) => filter.factions[card.faction] && (filter.setPiece === null || filter.setPiece === card.setPiece);

    cards = applySort(cards, sortObject);

    cards.filter(card => applyFactionFilter(card, filterArray)).map((card) => { //TODO verify key mapping
        cardsRender.push(<ItemCardSemiImage card={card} key={card.bxId} cardClickEvent={cardClickEvent}/>);
        // TODO: add NFT id to an itemCard object
    })

    return (<>{cardsRender}</>);
}

const MintedItemsComponent = ({filterArray: filterObject, sortObject}) => {

    const [postMintedItemsError, setPostMintedItemsError] = useState(null);
    setPostMintedItemsErrorHook = setPostMintedItemsError;

    const [postMintedItems, setPostMintedItems] = useState([]);
    setPostMintedItemsHook = setPostMintedItems;

    const [postMintRefreshing, setPostMintRefreshing] = useState(true);
    setPostMintRefreshingHook = setPostMintRefreshing;

    const postMintedItemsErrorClick = () => {
        setPostMintedItemsError(null);
        redrawMintedItems();
    };

    useEffect(() => {
        redrawMintedItems();
    }, []);

    return (
        <div id={'minted-items'}>
            <h4 id={'pre-minted-items-h'}
                className={'faded pre-minted-items'}>YOUR ITEM <span>NFTs</span> (tradeable cards from your wallet)<span/></h4>
            {(typeof postMintedItemsError === "string") ?
                <div className={'error-message'} onClick={postMintedItemsErrorClick}>
                    <BiError/> Error: {postMintedItemsError} <BiError/>
                </div>
                : null}
            {postMintRefreshing ? <div className={'refreshing'}>Refreshing...</div> : null}
            {(typeof postMintedItems === "object") ?
                <div className={'items'}>
                    <MintedCardList cards={postMintedItems} filterArray={filterObject}
                                    cardClickEvent={putOnNewSetItem} sortObject={sortObject}/>
                </div>
                : null}

        </div>
    );
}

export default MintedItemsComponent;