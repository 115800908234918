
const Item = (
    bxId, //potentially in "o.o.#####" or "0x#######" format
    faction,
    season, // uint16
    part,
    skinSet, // uint8
    damage // unit32
) => {
    return {
        bxId: bxId,
        faction: faction,
        season: season,
        setPiece: part,
        skinSet: skinSet,
        damage: damage
    }
}

export default Item;