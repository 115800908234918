
export const uint64ToBytes = (number) => {
    // you can use constant number of bytes by using 8 or 4
    // const len = Math.ceil(Math.log2(number) / 8);
    const byteArray = new Uint8Array(8);

    for (let index = 0; index < byteArray.length; index++) {
        const byte = number & 0xff;
        byteArray[byteArray.length - 1 - index] = byte;
        number = (number - byte) / 256;
    }

    return byteArray;
}