import Popup from "reactjs-popup";
import {ARENA_POOLS} from "../../helper/ENUMS.ts";
import {CurrencyIcon} from "../../App";
import {TbInfoSquareRoundedFilled} from "react-icons/tb";
import React, {useState} from 'react';
import {getPoolFee, getPoolFees} from "./ArenaPickerPageOld";
import {getAccountIds, makeBytes, sendTransaction} from "../../connector/blockchain/hashconnect";
import {getConfigFor} from "../../connector/blockchain/ContractParameters";
import {ContractExecuteTransaction, ContractFunctionParameters} from "@hashgraph/sdk";
import axios from "axios";
import {createCurrencyAndSetAllowance, yvcMod} from "../../connector/generateAllowanceTransactions";


async function handlePassivePoolEntry(bananaSerial, numOfBattles) {
    // const txId = Math.floor(Math.random() * 1000000000); // Generate random transaction ID for testing
    const poolIndex = ARENA_POOLS.SILVER;
    console.log(poolIndex, bananaSerial, numOfBattles, "ENTER_PASSIVE_POOL");
    const signingAcct = getAccountIds()[0];

    const spenderWithdrawingContractId = (await getConfigFor('NFT_AND_FEE_STORAGE_CONTRACT')).contractId;
    const allowanceGiven = await createCurrencyAndSetAllowance(signingAcct, spenderWithdrawingContractId, numOfBattles * getPoolFee([poolIndex]) * yvcMod, [bananaSerial]);
    if (!allowanceGiven) {
        console.log("Allowance not given");
        return null;
    }

    const enterPassivePoolTx = getConfigFor('ENTER_PASSIVE_POOL').then(callConfig => {
        return new ContractExecuteTransaction()
            .setContractId(callConfig.contractId)
            .setGas(callConfig.gasLimit)
            .setTransactionMemo(`Banana.Capital: Entering passive Matchmaking with $BANWAR #${bananaSerial}`)
            .setFunction(callConfig.contractFunc,
                new ContractFunctionParameters()
                    .addUint8(poolIndex)
                    .addInt64(bananaSerial)
                    .addUint16(numOfBattles)
            );
    });
    let transactionBytes = await makeBytes(await enterPassivePoolTx, signingAcct);
    let res = await sendTransaction(transactionBytes, signingAcct, false, true);
    if (res.success) {
        const urlHost = process.env.REACT_APP_BACK_END_HOST;
        /*
            Endpoint: /arena/passive-entry
            Description: This endpoint is used to mirror the information about a player's pooled NFT on the server.
            The endpoint is called after a successful blockchain transaction where the NFT is deposited
            in the pool for a certain number of potential battles based on the fees paid by the player.
        */
        axios.post(urlHost + "/arena/enter-passive-pool", {
            txId: res.response.transactionId,
        }, {
            headers: {
                'Content-Type': "application/json",
                'Accept': "application/json",
                // "Access-Control-Allow-Origin": "*",
            },
            responseType: "json",
        }).then(async (initPoolEntranceResponse) => {
            console.log('Successfully submitted pool selection:', initPoolEntranceResponse.data);
        }).catch((x) => {
            console.error('Error submitting pool selection:', x);
        });
    }


}

const PassiveEntryModal = ({closeModal, openModal, selectedSet, selectedPool}) => {

    const poolFees = getPoolFees();

    const userBalance = localStorage.getItem('YVCurrencyBalance') * 100; // TODO : connect to user Balance from helpers
    const maxBattles = Math.min(Math.floor(userBalance / (100 * poolFees[selectedPool])), 62);
    const [numBattles, setNumBattles] = useState(1);
    const totalFees = numBattles * 100;
    const potentialPayout = `0 - ${numBattles * 190}`;


    function handleSubtractBattle() {
        if (numBattles > 1) {
            setNumBattles(numBattles - 1);
        }
    }


    function handleAddBattle() {
        console.log(`Number of battles: ${numBattles}, max battles: ${userBalance}`);
        if (numBattles < maxBattles) {
            setNumBattles(numBattles + 1);
        }
    }

    function handleLogBattles() {
        console.log(`Number of battles: ${numBattles}`);
    }

    function handleNumBattlesChange(e) {
        const newValue = parseInt(e.target.value);
        setNumBattles(newValue);
    }


    return (
        <Popup open={openModal} closeOnDocumentClick onClose={closeModal}>
            <div className="modal entry-passive-pool">
                <a className="close" onClick={closeModal}>
                    &times;
                </a>
                <div className={'content-after-x'}>
                    <h3>Confirmation</h3>
                    <div className="fading-line"/>
                    <div className={'flex-container-columns'}>
                        <div className={'nft-details flex-container-rows'}>
                            <p className={'flex-container-columns'}><span>Selected NFT: </span><span
                                className={'selected-serial highlighted'}>#{selectedSet.serialNumber}</span></p>
                            <img className={'selected-card'}
                                 src={`https://banana-capital-ipfs-mirror.b-cdn.net/ipfs/${selectedSet.cardImageCID}`}
                                 alt="Selected NFT"/>
                        </div>
                        <div className={'entry-configuration flex-container-rows'}>

                            <div className={'selected-pool flex-container-columns'}>Selected pool: <span className={'selected-pool highlighted'}>{ARENA_POOLS[selectedPool]}</span></div>

                            <div className={'battle-container flex-container-columns'}>
                                <div className="battle-input-container">
                                    Number of battles: <br/><br/>
                                    <button onClick={() => {
                                        handleSubtractBattle()
                                    }}>-
                                    </button>
                                    <input
                                        type="number"
                                        min="1"
                                        max={maxBattles}
                                        value={numBattles}
                                        onChange={handleNumBattlesChange}
                                    />
                                    <button onClick={() => {
                                        handleAddBattle()
                                    }}>+
                                    </button>
                                </div>
                                <div className={'battle-fee-container flex-container-columns'}>
                                    <div>
                                        <div className={'flex-container-columns total-fees'}>Total fees: <span
                                            className={'highlighted-bg'}>{numBattles * poolFees[selectedPool]}<CurrencyIcon
                                            size={24}/></span></div>
                                        <br/>
                                        <div>Potential gain: up to {numBattles * poolFees[selectedPool] * 1.9}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="tooltip">
                                <p>
                                    {/*<span>?</span>*/}
                                    <span className="tooltiptext"><TbInfoSquareRoundedFilled/> - The maximum number of battles is calculated based on your balance and must be between 1 and 63.</span>
                                </p>
                            </div>
                            <div className={'actions flex-row'}>
                                <button id={'pool-entry'} className={'btn'} onClick={() => {
                                    handlePassivePoolEntry(selectedSet.serialNumber, numBattles)
                                }}>Pay Fee & Enter Pool <span className={'finance'}>({numBattles * poolFees[selectedPool]}
                                    <CurrencyIcon size={24}/>)</span></button>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </Popup>
    );
}

export default PassiveEntryModal;