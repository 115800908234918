// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import './InventoryRightSide.css';
import {GetInventorySets} from "../../../connector/GetInventoryList";
import Portal from "../../../component/Portal/Portal";

let carouselGlobalSet, setCarouselGlobalSet;
let carouselGlobalIndex, setCarouselGlobalIndex;

export const changeNewSetItem = (item) => {
    let newBanans = carouselGlobalSet;
    let newBanan = newBanans[newBanans.length - 1];
    console.log('changeNewSetItem', newBanan);
    newBanan.itemCards[item.setPiece] = item;
    console.log(newBanan);
    newBanan.damages = [0, 0, 0];
    newBanan.itemCards.map(item => {
        if (item !== undefined && item !== null) {
            newBanan.damages[item.faction] += item.damage;
        }
    });
    // console.log(newBanans);
    console.log('changeNewSetItem', newBanans);
    newBanans[newBanans.length - 1] = newBanan;
    setCarouselGlobalSet([...newBanans]);
    setCarouselGlobalIndex(newBanans.length - 1);
}

export const changeRemoveSetItem = (item) => {
    let newBanans = carouselGlobalSet;
    let newBanan = newBanans[newBanans.length - 1];
    console.log('changeRemoveSetItem', newBanan);
    newBanan.itemCards[item.setPiece] = null;
    console.log(newBanan);
    newBanan.damages = [0, 0, 0];
    newBanan.itemCards.map(item => {
        if (item !== undefined && item !== null) {
            newBanan.damages[item.faction] += item.damage;
        }
    });
    // console.log(newBanans);
    console.log('changeRemoveSetItem', newBanans);
    newBanans[newBanans.length - 1] = newBanan;
    setCarouselGlobalSet([...newBanans]);
    setCarouselGlobalIndex(newBanans.length - 1);
}

const InventoryRightSide = ({setSelectedSet, showPalm, showActionButton = true}) => {

    const [carouselIndex, setCarouselIndex] = useState(0);
    const [carouselSets, setCarouselSets] = useState([]);
    carouselGlobalSet = carouselSets;
    setCarouselGlobalSet = setCarouselSets;
    carouselGlobalIndex = carouselIndex;
    setCarouselGlobalIndex = setCarouselIndex;

    useEffect(() => {
        GetInventorySets().then((sets) => {
            const {banans} = sets;
            console.log('GetInventorySets', banans);
            if (banans !== undefined && banans.length > 0) {
                setCarouselGlobalIndex(banans.length - 1);
            }

            // new banan :
            banans.push({
                itemCards: [null, null, null, null],
                damages: [0, 0, 0],
            });

            setCarouselGlobalSet(banans);
        }).catch((x) => {
            console.error(x);
        });
    }, []);

    console.log('carouselSets', carouselGlobalSet);


    return (
        <div id="right-side" className={'flex-container-rows'}>
            <Portal showPalm={showPalm} setSelectedSet={setSelectedSet} showActionButton={showActionButton}
                    carouselGlobalSet={carouselGlobalSet}
                    carouselGlobalIndex={carouselGlobalIndex} setCarouselGlobalIndex={setCarouselGlobalIndex}/>
        </div>
    );
};

export default InventoryRightSide;