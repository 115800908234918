import {DappMetadata, HashConnect, HashConnectConnectionState} from "hashconnect";
import {
    AccountId,
    ContractExecuteTransaction,
    ContractFunctionParameters,
    LedgerId,
    TokenAssociateTransaction,
    TransactionId
} from "@hashgraph/sdk";
import React, {useEffect, useState} from "react";
import {IoWalletOutline} from "react-icons/io5";
import {RiLogoutCircleRLine} from "react-icons/ri";
import './WalletConnectComponent.css';
import ToolTip from "../Modal/ToolTip";
import MyCustomEventEmitter from "../myCustomEvents";
import {getUnassociatedTokenIds} from "../../helper/mirrrorNode";

// const appMetadata = {
//     name: "Hashgraph Hub Test",
//     description: "Testing Hashgraph Hub hashconnect",
//     icons: ["<Image url>"],
//     url: "<Dapp url>",
// };

let pairingDataObj, setPairingDataFunc, accountId = null, hashConnectObj = null, setHashConnectFunc,
    hashTopic = null;

/**
 * @returns {string|null}
 */
export const checkAccountId = () => {
    if (accountId) {
        return accountId;
    }

    // TODO flip conditions
    if (pairingDataObj) {
        if (pairingDataObj.accountIds.length > 0) {
            accountId = pairingDataObj.accountIds[0];
            return accountId;
        }
    }
    return null;
}

/**
 *
 * @returns {string|null}
 */
export const getAccountIdV3 = () => {
    return accountId;
}

export const getAccountIdWithWaitV3 = async () => {
    if (accountId && accountId.length > 0) {
        return accountId;
    }
    let i = 1;
    while (!accountId || accountId.length === 0) {
        await new Promise(resolve => setTimeout(resolve, 1000+(i*100)));
        console.log("getAccountIdWithWaitV3() waiting for pairingData", i);
        if (pairingDataObj && pairingDataObj.accountIds.length > 0) {
            accountId = pairingDataObj.accountIds[0];
            return pairingDataObj.accountIds[0];
        }
        i++;
    }
}


/**
 *
 * @param trans Object
 * @param signingAcctId String
 */
export const makeBytesV3 = async (trans, signingAcctId) => {
    let transId = TransactionId.generate(signingAcctId)
    trans.setTransactionId(transId);
    // trans.setGas(0);
    // trans.setMaxTransactionFee(0);
    trans.setNodeAccountIds([new AccountId(3)]);

    await trans.freeze();
    let transBytes = trans.toBytes();
    return transBytes;
}

export const getSigner = async () => {
    const accId = await getAccountIdWithWaitV3();
    return hashConnectObj.getSigner(AccountId.fromString(accId));
}

/**
 *
 * @param txData Object
 * @returns {Promise<void>}
 */
export const makeTransactionV3 = async (txData = {
    contract: {id: "0.0.1", params: new ContractFunctionParameters()},
    function: ""
}) => {
    //Create the transaction
    const transaction = new ContractExecuteTransaction()
        .setContractId(txData.contract.id)
        .setGas(txData.gas || 1_000_000)
        .setFunction(txData.function, txData.contract.params);
}

export const associateTokensWithAccountV3 = async () => {
    let signingAcct = await getAccountIdWithWaitV3();
    const tokenIds = await getUnassociatedTokenIds(signingAcct);
    let trans = await new TokenAssociateTransaction()
        .setAccountId(signingAcct)
        .setTokenIds(tokenIds)
    ;

    // let transactionBytes = await makeBytesV3(trans, signingAcct);
    // let res = await sendTransaction(transactionBytes, signingAcct, false, false);
    let res = await hashConnectObj.sendTransaction(accountId, trans);
    if (res.success) {
        console.log(`tokens ${tokenIds} associated with account ${signingAcct}`, res);
    }
}

let internalSendTransaction;


/**
 * @param tx
 * @param acc
 * @returns {Promise<*>}
 */
export const sendTransactionV3 = async ({tx, acc = ""}) => {
    if (!acc || acc === "") {
        acc = accountId;
    }
    const response = await internalSendTransaction({accountId: acc, transaction: tx});
    response.status = response.success ? response.response.status : 500;
    return response;
}


const WalletConnectComponentV3 = () => {

    /**
     * @type {DappMetadata}
     */
    const appMetadata = {
        name: process.env.REACT_APP_NODE_ENV === 'production' ? "Banana.Capital" : "Banan TESTNET",
        description: process.env.REACT_APP_NODE_ENV === 'production' ? "Banana.Capital gaming/website platform connector" : "HashConnect v3 connector",
        icons: ["https://asset-host.b-cdn.net/assets/global/logos/logo_banana_361x361.png"], // INCORRECT
        url: process.env.REACT_APP_NODE_ENV === 'production' ? "https://banana.capital" : "localhost:3000"
    }

    const [pairingData, setPairingData] = useState(null);
    pairingDataObj = pairingData;
    setPairingDataFunc = setPairingData;
    const [hashConnect, setHashConnect] = useState(null);
    hashConnectObj = hashConnect;
    setHashConnectFunc = setHashConnect;


    const [connectionStatus, setConnectionStatus] = useState(HashConnectConnectionState.Disconnected);

    const init = async () => {
        //create the hashconnect instance
        const hashConnect = new HashConnect(
            process.env.REACT_APP_NODE_ENV === 'production' ?  LedgerId.MAINNET : LedgerId.TESTNET,
            process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
            appMetadata,
            true
        );


        console.log(`hashConnect v3 init, debug = ${process.env.REACT_APP_NODE_ENV !== 'production'}, ledger = ${process.env.REACT_APP_NODE_ENV !== 'production' ? 'LedgerId.TESTNET' : 'LedgerId.MAINNET'}`);

        //register events
        hashConnect.pairingEvent.on((newPairing) => {
            accountId = newPairing.accountIds[0];
            setPairingDataFunc(newPairing);
            console.log(`hashConnect v3 pairingEvent, newPairing = ${newPairing}`);
        });

        hashConnect.disconnectionEvent.on((data) => {
            accountId = null;
            setPairingDataFunc(null);
            console.log(`hashConnect v3 disconnectionEvent, data = ${data}`);
        });

        hashConnect.connectionStatusChangeEvent.on((connectionStatus) => {
            setConnectionStatus(connectionStatus);
            console.log(`hashConnect v3 connectionStatusChangeEvent, connectionStatus = ${connectionStatus}`);
        });

        //initialize
        await hashConnect.init();

        internalSendTransaction = ({accountId = "", transaction }) => {
            console.log(`hashConnect.internalSendTransaction() accountId = ${accountId}, transaction = `, transaction);
            if (transaction == null) {
                return {success: false};
            }
            const acc = AccountId.fromString(accountId);
            const signer = hashConnect.getSigner(acc);
            transaction.publicKey = acc.aliasKey;
            return hashConnect.sendTransaction(acc, transaction).then(response => {
                console.log(`hashConnect.internalSendTransaction() response = `, response);
                // const receipt = response.getReceiptWithSigner(signer);
                // receipt.then(receipt => {
                //     console.log(`hashConnect.internalSendTransaction() receipt = `, receipt);
                // })
                return {success: true, response: response};
            }).catch(err => {
                console.error(`hashConnect.internalSendTransaction() error = `, err);
                return {success: false, error: err};
            });
            // return hashConnect.sendTransaction(acc, transaction).then(response => {
            //     console.log(`hashConnect.internalSendTransaction() response = `, response);
            //     return response;
            // }).catch(err => {
            //     console.error(`hashConnect.internalSendTransaction() error = `, err);
            //     return err;
            // })
        }

        setHashConnectFunc(hashConnect);
    };

    const disconnect = () => {
        hashConnect.disconnect();
    };

    const connect = async () => {
        await hashConnect.openPairingModal();
    };


    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (pairingData && pairingData.accountIds.length > 0) {
            MyCustomEventEmitter.dispatch('authTokenPresent', true);
        } else {
            MyCustomEventEmitter.dispatch('authTokenPresent', false);
        }
    }, [pairingData]);

    /*
    const pairingExample = {
        "metadata": {
            "name": "HashPack",
            "description": "HashPack Extension",
            "url": "https://hashpack.app",
            "icons": ["https://assets-global.website-files.com/61ce2e4bcaa2660da2bb419e/62e14973c65367120073a891_app-icon.webp"]
        },
        "accountIds": ["0.0.3578046"],
        "network": "testnet"
    }
    */

    return (<div id="wallet-connect-component">
            {/*<p>*/}
            {/*    P: {JSON.stringify(pairingData)};*/}
            {/*</p>*/}
            {accountId ? (
                <>
                    <div className="wallet-icon">
                        <ToolTip
                            trigger={<div><IoWalletOutline/></div>}
                            position={['bottom center', "bottom left", "left bottom", "left center"]}
                            content={`WalletConnect status: ${connectionStatus}`}
                            // keepInside={"#wallet-connect-component"}
                            onActions={['hover']}
                        />
                    </div>
                    <span id="wallet-connected-address">{accountId}</span>
                    <button onClick={disconnect}>
                        <RiLogoutCircleRLine/>
                    </button>
                </>
            ) : <button className="login-button" onClick={connect}>
                Login
            </button>}
        </div>


    );
}

export default WalletConnectComponentV3;

