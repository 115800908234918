import Popup from "reactjs-popup";
import {EVENT_TYPE, FACTION, LOG_STATUS, MODAL_TYPE} from "../../helper/ENUMS.ts";
import {IoIosArrowUp} from "react-icons/io";
import PopUpProcessLogItems, {appendNewLog} from "../PopUpProcessLog/PopUpProcessLog";
import React, {useEffect, useState} from "react";
import {getAccountIds, makeBytes, sendTransaction} from "../../connector/blockchain/hashconnect";
import {getConfigFor} from "../../connector/blockchain/ContractParameters";
import {ContractExecuteTransaction, ContractFunctionParameters, ContractId} from "@hashgraph/sdk";
import axios from "axios";
import {refreshLootBoxBalance} from "../LootBoxHeaderComponent/LootBoxHeaderComponent";
import {CurrencyIcon} from "../../App";
import MyCustomEventEmitter from "../myCustomEvents";
import {PreMintedItemCard} from "../itemCard/itemCard";
import {createCurrencyAllowance, yvcMod} from "../../connector/generateAllowanceTransactions";
import recordFactionBox from "../../connector/UnpackWeb2Caller";

let unpackingVariables = {};
let unpackContentLog = "", setUnpackContentLog;
let unpackContentItemCard = {}, setUnpackContentItemCard;
let unpackContentItemCards = {}, setUnpackContentItemCards;
let unpackIsFinished = false, setUnpackIsFinished;
let unpackFaction = "", setUnpackFaction;
let isFinished = false, setIsFinished;


export const CardPlaceHolder = () => {
    return <img className={'placeholder'} src={'https://asset-host.b-cdn.net/assets/inventory/card_placeholder.png'}/>;
}

const renderFactionBoxCard = (itemCard) => {
    if (itemCard !== undefined && itemCard !== null && itemCard !== {}) {
        return <PreMintedItemCard card={itemCard} key={itemCard.bxId}
                                  updatePostMintedItems={function () {
                                  }}
        />
    } else {
        return <CardPlaceHolder/>;
    }
}

const renderRookieBoxCards = (itemCards) => {
    if (itemCards !== undefined && itemCards !== null && itemCards !== {}) {
        return (
            <>
                <div className={'flex-container-columns'}>
                    <PreMintedItemCard card={itemCards[0]} key={`rookie-unpack-card-${itemCards[0].bxId}`}
                                       updatePostMintedItems={function () {
                                       }}/>
                    <PreMintedItemCard card={itemCards[1]} key={`rookie-unpack-card-${itemCards[1].bxId}`}
                                       updatePostMintedItems={function () {
                                       }}/>
                </div>
                <div className={'flex-container-columns'}>
                    <PreMintedItemCard card={itemCards[2]} key={`rookie-unpack-card-${itemCards[2].bxId}`}
                                       updatePostMintedItems={function () {
                                       }}/>
                    <PreMintedItemCard card={itemCards[3]} key={`rookie-unpack-card-${itemCards[3].bxId}`}
                                       updatePostMintedItems={function () {
                                       }}/>
                </div>
            </>);
    } else {
        return (
            <>
                <div className={'flex-container-columns'}>
                    <div className={'item-card placeholder'}><CardPlaceHolder/></div>
                    <div className={'item-card placeholder'}><CardPlaceHolder/></div>
                </div>
                <div className={'flex-container-columns'}>
                    <div className={'item-card placeholder'}><CardPlaceHolder/></div>
                    <div className={'item-card placeholder'}><CardPlaceHolder/></div>
                </div>
            </>
        );
    }
}

const renderCards = ({itemCard, itemCards, modalType}) => {
    if (modalType === MODAL_TYPE.UNPACK_BOX) {
        return renderFactionBoxCard(itemCard);
    } else if (modalType === MODAL_TYPE.UNPACK_ROOKIE) {
        return renderRookieBoxCards(itemCards);
    }
};

async function CallUnpackContract(faction) {
    const signingAccount = getAccountIds()[0];
    // todo check if sufficient balance
    const yvPrices = {red: 100, green: 100, blue: 100};
    // todo convert to config
    const amountWithoutDecimals = (yvPrices.red * yvcMod);
    const factionBoxTxPrep = getConfigFor('LOOT_BOX_UNPACK').then(callConfig => {
        console.log("storedHederaCommunicationConfig RESULT ", callConfig);
        // const contractId = ContractId.fromString(callConfig.contractId);
        // console.log(`LOOT_BOX_PURCHASE contractId : ${contractId}`);
        return createCurrencyAllowance(
            signingAccount,
            callConfig.contractId,
            amountWithoutDecimals)
            .then((allowanceGiven) => {
                if (allowanceGiven) {
                    return new ContractExecuteTransaction()
                        .setContractId(ContractId.fromString(callConfig.contractId))
                        .setGas(callConfig.gasLimit) // Increase if revert
                        .setTransactionMemo(`Banana.Capital: Purchasing & Unpacking ${FACTION[faction]} faction box`)
                        .setFunction(callConfig.contractFunc
                            , new ContractFunctionParameters()
                                .addUint8(faction) // FACTION todo FACTION to NUMBER
                        )
                }

                return null;
            }).catch((x) => {
                console.error(x);
            });

    });


    // setUnpackContentLog([]);
    // if we reference unpackContentLog here, it will somehow contain previous run state, so we have to reset it completely
    setUnpackContentLog([...appendNewLog([], "Verifying balances...", LOG_STATUS.SUCCESS)]);
    setUnpackIsFinished(false);

    await new Promise(resolve => setTimeout(resolve, 1000));

    factionBoxTxPrep.then(contractExecuteTx => {
        makeBytes(contractExecuteTx, signingAccount).then(async (transactionBytes) => {
            let res = await sendTransaction(transactionBytes, signingAccount, false, true);
            await new Promise(resolve => setTimeout(resolve, 230))
                .then(setUnpackContentLog([...appendNewLog(unpackContentLog, "Waiting for Tx...", LOG_STATUS.SUCCESS)]));


            let responseData = {
                response: res,
                record: null,
                receipt: null
            };

            if (res.success) {
                await new Promise(resolve => setTimeout(resolve, 360))
                    .then(setUnpackContentLog([...appendNewLog(unpackContentLog, <>Transaction: <a
                        href={`https://hashscan.io/testnet/transaction/${res.response.transactionId}`}
                        target={'_blank'}>{res.response.transactionId}</a></>)]));

                // console.info("GLORIOUS RESPONSE ", res);
                // let item = {};
                recordFactionBox({
                    txId: res.response.transactionId,
                    processLog: unpackContentLog,
                    setProcessLog: setUnpackContentLog,
                    setItemCard: setUnpackContentItemCard,
                    setUnpackIsFinished: setUnpackIsFinished
                });
            } else {
                setUnpackContentLog([...appendNewLog(unpackContentLog, <>Transaction
                    failed: {res.error}</>, LOG_STATUS.ERROR)]);
            }
            // console.log("responseData ", responseData)
            // this.HashconnectService.showResultOverlay(responseData);
        });
    });


}

function initiateUnpacking(unpackingObject) {
    console.log("button trigger");
    console.log("unpackingObject ", unpackingObject);
    setUnpackContentItemCard(null);
    CallUnpackContract(unpackingObject).then(
        r => {
            console.log("CallUnpackContract ", r);
        }
    );
}

async function CallUnpackRookieSetContract(setUnpackContentLog) {
    let signingAcct = getAccountIds()[0];
    setUnpackContentLog([{
        status: LOG_STATUS.SUCCESS,
        text: <>Verifying YVC <CurrencyIcon/> balances</>
    }]);
    setUnpackIsFinished(false);
    // todo check if sufficient balance
    const yvPrices = {rookie: 100};
    // todo convert to config
    const amountWithoutDecimals = (yvPrices.rookie * yvcMod);
    const unpackTransactionPromise = getConfigFor('LOOT_BOX_UNPACK_ROOKIE').then(callConfig => {
        return createCurrencyAllowance(
            signingAcct,
            callConfig.contractId,
            amountWithoutDecimals)
            .then((allowanceGiven) => {
                if (allowanceGiven) {
                    return new ContractExecuteTransaction()
                        .setContractId(ContractId.fromString(callConfig.contractId))
                        .setGas(callConfig.gasLimit) // Increase if revert
                        .setTransactionMemo(`Banana.Capital: Purchasing & Unpacking Rookie Set`)
                        .setFunction(callConfig.contractFunc
                            , new ContractFunctionParameters()
                        )
                }

                return null;
            }).catch((x) => {
                console.error(x);
            });
    });

    // setUnpackContentLog([]);
    // if we reference unpackContentLog here, it will somehow contain previous run state, so we have to reset it completely
    setUnpackContentLog([...appendNewLog([], "Generating Rookie Set Tx...", LOG_STATUS.SUCCESS)]);
    setUnpackContentItemCards(null);

    let transactionBytes = await makeBytes(await unpackTransactionPromise, signingAcct);

    let res = await sendTransaction(transactionBytes, signingAcct, false, true);
    await new Promise(resolve => setTimeout(resolve, 230))
        .then(setUnpackContentLog([...appendNewLog(unpackContentLog, "Waiting for Tx confirmation...")]));

    //handle response
    let responseData = {
        response: res,
        record: null,
        receipt: null
    };

    if (res.success) {
        await new Promise(resolve => setTimeout(resolve, 360))
            .then(setUnpackContentLog([...appendNewLog(unpackContentLog, "Parsing data...")]));
        console.info("GLORIOUS RESPONSE ", res);
        let item = {};
        const preMintSetBody = {
            txId: res.response.transactionId,
        };

        const urlHost = process.env.REACT_APP_BACK_END_HOST;
        axios.post(urlHost + "/item/pre-mint-set", preMintSetBody, {
            headers: {
                'Content-Type': "application/json",
                'Accept': "application/json",
                // "Access-Control-Allow-Origin": "*",
            },
            responseType: "json",
        })
            .then(function (backEndMirrorNodeResponse) {
                const itemArray = backEndMirrorNodeResponse.data.itemArray;
                setUnpackContentItemCards(itemArray);
                setUnpackContentLog([...appendNewLog(unpackContentLog, <>Success!</>, (backEndMirrorNodeResponse.data.stored ? LOG_STATUS.SUCCESS : LOG_STATUS.ERROR))]);
            })
            .catch(function (response) {
                setUnpackContentLog([...appendNewLog(unpackContentLog, <div
                    className={'clearfix'}>error: {response.data}</div>)]);
            })
        ;

        MyCustomEventEmitter.dispatch(EVENT_TYPE.FORCE_CURRENCY_UPDATE);
    } else {
        console.log("GLORIOUS FAIL", res);
    }
    // console.log("responseData ", responseData)
    // this.HashconnectService.showResultOverlay(responseData);
}

function initiateRookieUnpacking(unpackingObject) {
    // console.log("Rookie unpacking confirmation");
    setUnpackContentLog([]);
    CallUnpackRookieSetContract(setUnpackContentLog).then(r => {
        // console.log("CallUnpackRookieSetContract ", r);
    });
}

let previousFaction = null;
let [isOpen, setIsOpen] = [false, function (bool) {
}];
MyCustomEventEmitter.subscribe(EVENT_TYPE.OPEN_ITEM_UNPACKING_MODAL, (data) => {
    console.log("EVENT_TYPE.OPEN_ITEM_UNPACKING_MODAL", data);
    if (data.faction !== undefined && data.faction !== null && data.faction !== {}) {
        setUnpackFaction(data.faction);
    }
    setIsOpen(true);
});
const UnpackModal = ({
                         buttonFunctionParams,
                         disabled,
                         modalType: modalTypeProp,
                         contentLog,
                         buttonContent,
                         // itemCards,
                         // openState,
                         // buttonFunction,
                         // modalTitle
                     }) => {

    [isOpen, setIsOpen] = useState(false);
    [unpackFaction, setUnpackFaction] = useState(buttonFunctionParams);
    [unpackContentItemCard, setUnpackContentItemCard] = useState(null);
    [unpackContentItemCards, setUnpackContentItemCards] = useState(null);
    [unpackIsFinished, setUnpackIsFinished] = useState(false);
    [unpackContentLog, setUnpackContentLog] = useState([{
        status: LOG_STATUS.PENDING,
        text: `Waiting for players' confirmation...`
    }]);

    const modalType = MODAL_TYPE[modalTypeProp].toLowerCase();
    const isDisabled = disabled || false;
    const [open, setOpen] = useState(false);
    const closeModal = () => setIsOpen(false);
    // const modalTitle = getTitle(modalTypeProp);
    const modalTitle = modalTypeProp === MODAL_TYPE.UNPACK_BOX ? 'Unpack Loot Box' : 'Unpack Rookie Box';
    const buttonFunction = modalTypeProp === MODAL_TYPE.UNPACK_BOX ? initiateUnpacking : initiateRookieUnpacking;


    return (
        <div className={`modal-wrapper ${modalType}`}>
            {buttonContent !== null ?
                <button type="button" className={`button btn ${isDisabled ? 'disabled' : ''}`}
                        disabled={isDisabled}
                        onClick={() => {
                            setIsOpen(o => !o);
                            buttonFunction(unpackFaction);
                        }}>
                    {buttonContent}
                </button> : null
            }
            <Popup open={isOpen || open}
                // closeOnDocumentClick={unpackContentItemCard !== null}
                   closeOnDocumentClick={false}
                // onClose={closeModal}
                   className={modalType}
                   onClose={() => {
                       console.log("onClose unpack modal");
                       setUnpackContentItemCards(null);
                       setUnpackContentItemCard(null);
                       setUnpackContentLog([{
                           status: LOG_STATUS.PENDING,
                           text: `Waiting for players' confirmation...`
                       }]);
                   }}
                   nested>
                <div className={`modal ${modalType}`}>
                    <a className="close" onClick={() => closeModal()}>
                        &times;
                    </a>
                    <div className={'content-after-x'}>
                        <h3>{modalTitle}</h3>
                        <div className="fading-line"/>
                        <div className={'content-container flex-container-rows'}>
                            <div className="text flex-row flex-row-full"><p>You're about to unpack a <span
                                className={`${FACTION[unpackFaction].toLowerCase()}`}>{FACTION[unpackFaction].toLowerCase()}</span> faction
                                item, this action is irreversible.<br/>Unpacked item can be minted
                                into <span>an NFT</span> and sold on zuse.market or packed into Banana Set.</p></div>
                            {modalTypeProp === MODAL_TYPE.UNPACK_BOX || modalTypeProp === MODAL_TYPE.UNPACK_ROOKIE ?
                                <div className={'flex-container-columns paired-content'}>
                                    <div
                                        className={`card-container ${modalType} flex-container-rows`}>
                                        {renderCards({
                                            itemCard: unpackContentItemCard,
                                            itemCards: unpackContentItemCards,
                                            modalType: modalTypeProp
                                        })}
                                        {unpackContentItemCard !== undefined && unpackContentItemCard !== null && unpackContentItemCard !== {} ?
                                            <div className={'card-unpack-info'}><IoIosArrowUp/>
                                                <span>Click to mint!</span>
                                            </div> : <></>}
                                    </div>


                                    <div className={'unpack-log flex-container-rows'}>
                                        {unpackContentLog !== undefined && unpackContentLog !== null && unpackContentLog !== {} ?
                                            <PopUpProcessLogItems proccessLog={unpackContentLog}/>
                                            : <></>}
                                        <div className={'actions'}>
                                            {unpackContentItemCard === null ?
                                                <button className={'btn button primary'} onClick={
                                                    () => {
                                                        buttonFunction(unpackFaction);
                                                    }
                                                }>{<>PAY 100.00 <CurrencyIcon size={24}/>&nbsp;& UNPACK</>}
                                                </button> : <button className={'btn button secondary'}
                                                                    onClick={() => closeModal()}> Close </button>}

                                        </div>

                                    </div>
                                </div>

                                : <></>}
                        </div>
                        <div className="actions">
                            {/*{props.isFinished === true ?*/}
                            {/*    <button*/}
                            {/*        className="button"*/}
                            {/*        onClick={() => {*/}
                            {/*            closeModal();*/}
                            {/*        }}*/}
                            {/*    >close</button> : <></>}*/}
                        </div>
                        <a id={'powered-by-true-random-sol'}
                           href={'https://www.npmjs.com/package/@yv-work/true-random-sol'} target={'_blank'}><img
                            src={'https://asset-host.b-cdn.net/assets/true-random/logo_light_powered_by.png'}/></a>
                    </div>
                </div>
            </Popup>
        </div>
    );
}

export default UnpackModal;