import Popup from "reactjs-popup";
import './Modal.css';
import './ModalCustom.css';
import React, {useState} from "react";
import {FACTION, MODAL_TYPE, PROCESS_STATUS} from "../../helper/ENUMS.ts";
import {ItemCard, ItemCardSemiImage, PreMintedItemCard} from "../itemCard/itemCard";
import PopUpProcessLogItems from "../PopUpProcessLog/PopUpProcessLog";
import {formatNumber} from "../../helper/calc";
import {IoIosArrowUp} from "react-icons/io";


export const TooltipExample = () => (
    <Popup trigger={<button> Trigger</button>} position="right center">
        <div>Popup content here !!</div>
    </Popup>
);

export const TooltipExampleTwo = () => (
    <Popup trigger={<button>Trigger</button>} position="top left">
        {close => (
            <div>
                Content here
                <a className="close" onClick={close}>
                    &times;
                </a>
            </div>
        )}
    </Popup>
);

// export const Modal = (trigger, content) => (
//     <Popup trigger={<button className="button"> Open Modal </button>} modal>
//         <span> Modal content </span>
//     </Popup>
// );
export const Modal = (props) => {
    console.log("Modal caught, trigger, content", props.content);
    return (<Popup trigger={props.trigger} modal>
            {props.content}
        </Popup>
    );
}

const CardPlaceHolder = () => {
    return <img className={'placeholder'} src={'https://asset-host.b-cdn.net/assets/inventory/card_placeholder.png'}/>;
}


export const UnpackModalOld = (props) => {
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    const modalClass = MODAL_TYPE[props.modalType].toLowerCase();

    console.log("CustomModal caught, props", props);

    const renderCards = () => {

        const cardClickEvent = () => { // perhaps we should move it to card.cardClickEvent instead?
            alert("instant mint will be implemented later");
        };
        if (props.modalType === MODAL_TYPE.UNPACK_BOX) {
            if (props.itemCard !== undefined && props.itemCard !== null && props.itemCard !== {}) {
                // return <ItemCardSemiImage card={props.itemCard} cardClickEvent={cardClickEvent}/>;
                return <PreMintedItemCard card={props.itemCard} key={props.itemCard.bxId}
                                          updatePostMintedItems={function () {
                                          }}/>
            } else {
                return <CardPlaceHolder/>;
            }
        } else if (props.modalType === MODAL_TYPE.UNPACK_ROOKIE) {
            if (props.itemCards !== undefined && props.itemCards !== null && props.itemCards !== {}) {
                return (
                    <>
                        <div className={'flex-container-columns full-width'}>
                            <PreMintedItemCard card={props.itemCards[0]} key={`rookie-unpack-card-${props.itemCards[0].bxId}`}
                                               updatePostMintedItems={function () {
                                               }}/>
                            <PreMintedItemCard card={props.itemCards[1]} key={`rookie-unpack-card-${props.itemCards[1].bxId}`}
                                               updatePostMintedItems={function () {
                                               }}/>
                        </div>
                        <div className={'flex-container-columns full-width'}>
                            <PreMintedItemCard card={props.itemCards[2]} key={`rookie-unpack-card-${props.itemCards[2].bxId}`}
                                               updatePostMintedItems={function () {
                                               }}/>
                            <PreMintedItemCard card={props.itemCards[3]} key={`rookie-unpack-card-${props.itemCards[3].bxId}`}
                                               updatePostMintedItems={function () {
                                               }}/>
                        </div>
                    </>);
            } else {
                return (
                    <>
                        <div className={'flex-container-columns'}>
                            <div className={'item-card placeholder'}><CardPlaceHolder/></div>
                            <div className={'item-card placeholder'}><CardPlaceHolder/></div>
                        </div>
                        <div className={'flex-container-columns'}>
                            <div className={'item-card placeholder'}><CardPlaceHolder/></div>
                            <div className={'item-card placeholder'}><CardPlaceHolder/></div>
                        </div>
                    </>
                );
            }
        }
    };

    return (

            <>
            <button type="button" className={`button btn primary ${props.disabled ? 'disabled' : ''}`}
                    disabled={props.disabled || false}
                    onClick={() => {
                        setOpen(o => !o);
                        props.buttonFunction(props.buttonFunctionParams);
                    }}>
                {props.buttonContent}
            </button>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}
                   className={MODAL_TYPE[props.modalType].toLowerCase()}
                   nested>
                <div className={`modal ${modalClass}`}>
                    <a className="close" onClick={closeModal}>
                        &times;
                    </a>
                    <div className={'content-after-x'}>
                        <h3>{props.modalTitle}</h3>
                        <div className="fading-line"/>
                        <div className={'content-container flex-container-columns'}>
                            {props.modalType === MODAL_TYPE.UNPACK_BOX || props.modalType === MODAL_TYPE.UNPACK_ROOKIE ?
                                <div
                                    className={`card-container ${MODAL_TYPE[props.modalType].toLowerCase()} flex-container-rows`}>
                                    {renderCards()}
                                    {props.itemCards !== undefined && props.itemCards !== null && props.itemCards !== {} ?
                                        <div className={'card-unpack-info'}><IoIosArrowUp/> <span>Click to mint!</span>
                                        </div> : <></>}
                                </div> : <></>}
                            {props.contentLog !== undefined && props.contentLog !== null && props.contentLog !== {} ?
                                <div className={'unpack-log flex-container-rows'}>
                                    {/*{props.contentLog}*/
                                        <PopUpProcessLogItems proccessLog={props.contentLog}/>}
                                </div> : <></>}
                        </div>
                        <div className="actions">
                            {/*{props.isFinished === true ?*/}
                            {/*    <button*/}
                            {/*        className="button"*/}
                            {/*        onClick={() => {*/}
                            {/*            closeModal();*/}
                            {/*        }}*/}
                            {/*    >close</button> : <></>}*/}
                        </div>
                        <a id={'powered-by-true-random-sol'}
                           href={'https://www.npmjs.com/package/@yv-work/true-random-sol'} target={'_blank'}><img
                            src={'https://asset-host.b-cdn.net/assets/true-random/logo_light_powered_by.png'}/></a>
                    </div>
                </div>
            </Popup></>
    )
}