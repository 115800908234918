import React, {useEffect, useState} from 'react';
import 'swiper/css';
import './ArenaResultPage.css';
import FullSetCarousel, {isFullSet} from "../../component/fullSetCarousel/FullSetCarousel";
import {DUEL_PLAYER, DUEL_RESULT, DUEL_WINNER, EVENT_TYPE, FACTION} from "../../helper/ENUMS.ts";
import {getDuelDetails, notifyServerOfDuelView} from "../../connector/GetInventoryList";
import {MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight} from "react-icons/md";
import {useParams} from "react-router-dom";
import {AccountId} from "@hashgraph/sdk";
import {getAccountInSolidity} from "../../connector/blockchain/hashconnect";
import MyCustomEventEmitter from "../../component/myCustomEvents";
import {GiShieldReflect} from "react-icons/gi";
import ToolTip from "../../component/Modal/ToolTip";
import {BsArrowRightShort} from "react-icons/bs";
import {AiFillQuestionCircle} from "react-icons/ai";
import BattleInfoModal from "../../component/Modal/BattleInfoModal";
// import { Carousel } from 'react-carousel3d';


const openArenaConfirmation = (props) => {

}

let duelWinnerSide, setDuelWinnerSide;

const ArenaResultPortal = ({playerInfo, htmlId}) => {
    return (
        <div id={`${htmlId}-arena-portal`} className={'flex-column banana-wrapper'}>
            <div className={'banana-container'}>
                <img className={'transparent'} src={'https://asset-host.b-cdn.net/assets/arena-res/arena_ratio.png'}/>
                <div className={'banana-inner-container'}>
                    <div className={'assembly-container '}>
                        <FullSetCarousel glowColour={playerInfo.side === duelWinnerSide ? 'win' : 'lose'}
                                         itemCards={(playerInfo && playerInfo.info) ? playerInfo.info.itemCards : []}
                                         winner={playerInfo.side === duelWinnerSide}/>
                    </div>
                    <img className={'portal-arena'}
                         src={'https://asset-host.b-cdn.net/assets/arena-res/portal_arena.png'}
                         alt={`arena portal stone base`}/>
                </div>
            </div>
            <img className={'coconut'} src={`https://asset-host.b-cdn.net/assets/arena-res/cocount_${htmlId}.png`}/>
            <div className={'ground-front'}/>
        </div>
    );
}

/**function calculateDamage(int32 itemDamage, Structs.Faction itemFaction, Structs.Faction opposingItemFaction) internal pure returns (int64) {
 if ((itemFaction == Structs.Faction.RED && opposingItemFaction == Structs.Faction.GRE) ||
 (itemFaction == Structs.Faction.GRE && opposingItemFaction == Structs.Faction.BLU) ||
 (itemFaction == Structs.Faction.BLU && opposingItemFaction == Structs.Faction.RED)) {
 return itemDamage * 4; // Increased damage multiplier from 2 to 4 for counters
 } else if ((itemFaction == Structs.Faction.RED && opposingItemFaction == Structs.Faction.BLU) ||
 (itemFaction == Structs.Faction.GRE && opposingItemFaction == Structs.Faction.RED) ||
 (itemFaction == Structs.Faction.BLU && opposingItemFaction == Structs.Faction.GRE)) {
 return itemDamage; // reduced damage
 } else {
 return itemDamage * 2; // Add multiplier of 2 for same faction
 }
 }**/

const calculateDamage = (itemDmg, itemFaction, opposingItemFaction) => {
    // console.log(`Item DMG: ${itemDmg}, Faction: ${itemFaction}, Opp: ${opposingItemFaction}`);
    if ((itemFaction === FACTION.RED && opposingItemFaction === FACTION.GREEN) ||
        (itemFaction === FACTION.GREEN && opposingItemFaction === FACTION.BLUE) ||
        (itemFaction === FACTION.BLUE && opposingItemFaction === FACTION.RED)) {
        return itemDmg * 2; // Increased damage multiplier from 2 to 4 for counters
    } else if ((itemFaction === FACTION.RED && opposingItemFaction === FACTION.BLUE) ||
        (itemFaction === FACTION.GREEN && opposingItemFaction === FACTION.RED) ||
        (itemFaction === FACTION.BLUE && opposingItemFaction === FACTION.GREEN)) {
        return itemDmg / 2; // reduced damage
    } else {
        return itemDmg; // Add multiplier of 2 for same faction
    }
};

const calculateDefence = (itemDef, itemFaction, opposingItemFaction) => {
    if ((itemFaction === FACTION.RED && opposingItemFaction === FACTION.BLUE) ||
        (itemFaction === FACTION.GREEN && opposingItemFaction === FACTION.RED) ||
        (itemFaction === FACTION.BLUE && opposingItemFaction === FACTION.GREEN)) {
        return itemDef * 2; // Increased damage multiplier from 2 to 4 for counters
    } else if ((itemFaction === FACTION.RED && opposingItemFaction === FACTION.GREEN) ||
        (itemFaction === FACTION.GREEN && opposingItemFaction === FACTION.BLUE) ||
        (itemFaction === FACTION.BLUE && opposingItemFaction === FACTION.RED)) {
        return itemDef / 2; // reduced damage
    } else {
        return itemDef; // Add multiplier of 2 for same faction
    }
};

const ArenaResultPanel = ({playersInfo, duelId}) => {

    const damageDefaults = [0, 0, 0];

    let damagesLeft = [0, 0, 0], damagesRight = [0, 0, 0],
        defencesLeft = [0, 0, 0], defencesRight = [0, 0, 0],
        result = DUEL_RESULT.DRAW,
        sumDmgLeft = 0, sumDefLeft = 0,
        sumDmgRight = 0, sumDefRight = 0,
        bottomSum = {damage: 0, side: null}, topSum = {damage: 0, side: null},
        centerDMG,
        leftPairCompResults = [], rightPairCompResults = [], // these are the arrays of middle numbers separated by sides
        userInSolidity = getAccountInSolidity()
    ;

    let playerLeftFullSet, playerRightFullSet;

    if (playersInfo.left !== undefined && playersInfo.left.itemCards !== undefined && playersInfo.right) {
        const leftItems = playersInfo.left.itemCards;
        const rightItems = playersInfo.right.itemCards;
        for (let l = 0; l < leftItems.length; l++) {
            const player1ItemDamage = leftItems[l].damage;
            const player1Faction = leftItems[l].faction;
            for (let r = 0; r < rightItems.length; r++) {
                const player2ItemDamage = rightItems[r].damage;
                const player2Faction = rightItems[r].faction;
                damagesLeft[player1Faction] += calculateDamage(player1ItemDamage, player1Faction, player2Faction);
                damagesRight[player2Faction] += calculateDamage(player2ItemDamage, player2Faction, player1Faction);
                defencesLeft[player1Faction] += calculateDefence(player1ItemDamage, player1Faction, player2Faction);
                defencesRight[player2Faction] += calculateDefence(player2ItemDamage, player2Faction, player1Faction);
            }
            playerLeftFullSet = isFullSet(leftItems);
            playerRightFullSet = isFullSet(rightItems);
            if (playerLeftFullSet) {
                damagesRight.map((dmg, i) => {
                    damagesRight[i] /= 2;
                });
            }
            if (playerRightFullSet) {
                damagesLeft.map((dmg, i) => {
                    damagesLeft[i] /= 2;
                });
            }
        }
        sumDmgLeft = damagesLeft.reduce((a, b) => a + b, 0);
        sumDmgRight = damagesRight.reduce((a, b) => a + b, 0);
        sumDefLeft = defencesLeft.reduce((a, b) => a + b, 0);
        sumDefRight = defencesRight.reduce((a, b) => a + b, 0);
        console.log(`DAMAGES LEFT: ${damagesLeft}, RIGHT: ${damagesRight}`);
        console.log(`DEFENCES LEFT: ${defencesLeft}, RIGHT: ${defencesRight}`);
        if (sumDefLeft > sumDmgRight) {
            topSum.damage = sumDefLeft - sumDmgRight;
            topSum.side = DUEL_PLAYER.LEFT;
            leftPairCompResults.push(sumDefLeft - sumDmgRight);
        } else {
            topSum.damage = sumDmgRight - sumDefLeft;
            topSum.side = DUEL_PLAYER.RIGHT;
            rightPairCompResults.push(sumDmgRight - sumDefLeft);
        }

        if (sumDefRight > sumDmgLeft) {
            bottomSum.damage = sumDefRight - sumDmgLeft;
            bottomSum.side = DUEL_PLAYER.RIGHT;
            rightPairCompResults.push(sumDefRight - sumDmgLeft);
        } else {
            bottomSum.damage = sumDmgLeft - sumDefRight;
            bottomSum.side = DUEL_PLAYER.LEFT;
            leftPairCompResults.push(sumDmgLeft - sumDefRight);
        }


        if (userInSolidity === null) {
            // todo make entire thing asynchronous and wait for hashpack to respond
            userInSolidity = AccountId.fromString(localStorage.getItem('accountId')).toSolidityAddress();
            console.error(`USER PARSING ERROR, falling back to local storage`, localStorage.getItem('accountId'), userInSolidity);
        } else {
            console.log(`USER PARSING SUCCESS`, userInSolidity);
            if ((userInSolidity === playersInfo.left.ownerAddress && !playersInfo.left.viewed)
                || (userInSolidity === playersInfo.right.ownerAddress && !playersInfo.right.viewed)) {
                // new Promise(resolve => setTimeout(resolve, 1500))
                //     .then(notifyServerOfDuelView(duelId));
            }
            // MyCustomEventEmitter.dispatch(EVENT_TYPE.DUEL_EVENT_VIEWED, {duelId: duelId});
        }
        const leftCompResult = leftPairCompResults.reduce((a, b) => a + b, 0);
        const rightCompResult = rightPairCompResults.reduce((a, b) => a + b, 0);
        const WINNER = leftCompResult > rightCompResult ? DUEL_WINNER.LEFT : DUEL_WINNER.RIGHT;
        centerDMG = Math.abs(leftCompResult - rightCompResult);
        if (WINNER === DUEL_WINNER.LEFT) {
            console.log(`WINNER IS LEFT 0x${userInSolidity}`, playersInfo.left.ownerAddress);
            result = (`${userInSolidity}` === playersInfo.left.ownerAddress) ? DUEL_RESULT.VICTORY : DUEL_RESULT.DEFEAT;
            setDuelWinnerSide(DUEL_PLAYER.LEFT);
        } else if (WINNER === DUEL_WINNER.RIGHT) {
            console.log(`WINNER IS RIGHT 0x${userInSolidity}`, playersInfo.right.ownerAddress);
            result = (`${userInSolidity}` === playersInfo.right.ownerAddress) ? DUEL_RESULT.VICTORY : DUEL_RESULT.DEFEAT;
            setDuelWinnerSide(DUEL_PLAYER.RIGHT);
        }
    }

    /**
     * Will perform all necessary dmg number adjustments
     * @param num
     */
    const rounded = (num) => {
        if (num === 0) return '0';
        if (typeof num === 'number') {
            return <ToolTip
                trigger={<span>{num.toFixed(2).toString()}</span>}
                // key={num.toString()}
                content={<>{num.toString()}</>}
                onActions={['hover', 'focus', 'click']}
                position={['top center', 'bottom center', 'right center', 'left center']}
                keepInside={'#game-result-numbers'}
            />;
        }
        return "loading";
    }

    return (
        <div id={'middle-panel'} className={'flex-column flex-container-rows'}>
            <div id={'middle-buffer-top'}>

            </div>
            <div id={'middle-panel-contents'} className={'flex-container-rows'}>
                <div id={'game-result-title'} className={'flex-row'}>
                    <h2>{DUEL_RESULT[result]}</h2>
                </div>
                <div id={'game-result-numbers'} className={'flex-row flex-container-rows'}>
                    <div id={'numbers-top-buffer'} className={'flex-row flex-container-rows'}>
                        <div className={'divider'}/>
                    </div>
                    <div id={'numbers-first-row'} className={'flex-row flex-container-columns'}>
                        <div className={'damage-circle left defence counter-clockwise'}>
                            <div className={"damage-number-wrap top"}>
                                <span
                                    className={'damage-number top blue'}>{rounded(defencesLeft[FACTION.BLUE] / 100)}</span>
                            </div>
                            <div className={"damage-number-wrap bottom"}>
                                <span
                                    className={'damage-number left green'}>{rounded(defencesLeft[FACTION.GREEN] / 100)}</span>
                                <span
                                    className={'damage-number right red'}>{rounded(defencesLeft[FACTION.RED] / 100)}</span>
                            </div>
                        </div>
                        <div className={'damage-circles-middle flex-container-columns'}>
                            <span className={'damage-line left green'}></span>
                            <span
                                className={`damage-line-text ${topSum.side === DUEL_PLAYER.LEFT ? 'green' : 'red'}`}><>{rounded(Math.abs(topSum.damage / 100))}</></span>
                            <span className={'damage-line right red'}></span>
                        </div>
                        <div className={'damage-circle right attack clockwise'}>
                            <div className={"damage-number-wrap top"}>
                                <span
                                    className={'damage-number top blue'}>{rounded(damagesRight[FACTION.BLUE] / 100)}</span>
                            </div>
                            <div className={"damage-number-wrap bottom"}>
                                <span
                                    className={'damage-number left green'}>{rounded(damagesRight[FACTION.GREEN] / 100)}</span>
                                <span
                                    className={'damage-number right red'}>{rounded(damagesRight[FACTION.RED] / 100)}</span>
                            </div>
                        </div>
                    </div>
                    <div id={'numbers-total-result'} className={'flex-row'}>
                        <span className={'total-result-number'}>{rounded(Math.abs(centerDMG) / 100)}</span>
                    </div>
                    <div id={'numbers-second-row'} className={'flex-row flex-container-columns'}>
                        <div className={'damage-circle left attack clockwise'}>
                            <div className={"damage-number-wrap top"}>
                                <span
                                    className={'damage-number top blue '}>{rounded(damagesLeft[FACTION.BLUE] / 100)}</span>
                            </div>
                            <div className={"damage-number-wrap bottom"}>
                                <span
                                    className={'damage-number left green'}>{rounded(damagesLeft[FACTION.GREEN] / 100)}</span>
                                <span
                                    className={'damage-number right red'}>{rounded(damagesLeft[FACTION.RED] / 100)}</span>
                            </div>
                        </div>
                        <div className={'damage-circles-middle flex-container-columns'}>
                            <span className={'damage-line left  red'}>{playerRightFullSet ?
                                <GiShieldReflect/> : <></>}</span>
                            <span
                                className={`damage-line-text ${bottomSum.side === DUEL_PLAYER.RIGHT ? 'green' : 'red'}`}>{rounded(Math.abs(bottomSum.damage / 100))}</span>
                            <span className={'damage-line right green'}></span>
                        </div>
                        <div className={'damage-circle right defence counter-clockwise'}>
                            <div className={"damage-number-wrap top"}>
                                <span
                                    className={'damage-number top blue'}>{rounded(defencesRight[FACTION.BLUE] / 100)}</span>
                            </div>
                            <div className={"damage-number-wrap bottom"}>
                                <span
                                    className={'damage-number left green'}>{rounded(defencesRight[FACTION.GREEN] / 100)}</span>
                                <span
                                    className={'damage-number right red'}>{rounded(defencesRight[FACTION.RED] / 100)}</span>
                            </div>
                        </div>
                    </div>
                    <div className={'info-bar flex-container-rows'}>
                        <div className={'divider'}/>
                        {/*<button className={'button btn secondary flex-container-columns'}>What happened here?</button>*/}
                        <BattleInfoModal playersInfo={playersInfo}/>
                    </div>
                </div>
                <div id={'game-result-button-container'} className={'flex-row'}>
                    <div id={'game-result-button-wrapper'}>
                        <button id={'game-result-button'} className={'flex-container-rows'} onClick={() => {
                            alert('to be implemented');
                        }}>
                            <span className={'play'}>PLAY</span>
                            <span className={'again'}>AGAIN</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

let duelInfo, setDuelInfo;

const ArenaResultPage = () => {
    const {id} = useParams();
    [duelInfo, setDuelInfo] = useState({});

    const loadDuel = (id) => {
        getDuelDetails(id).then((duelResp) => {
            const duel = duelResp.duel;
            console.log('getDuelDetails', duel);
            setDuelInfo(duel);
        }).catch((x) => {
            console.error(x);
        });
    }

    [duelWinnerSide, setDuelWinnerSide] = useState(DUEL_WINNER.DRAW);

    // I'm too tired to check if this single use useEffect will work on latter hook as well, it should but I'll keep both for now
    useEffect(() => {
        if (id) {
            loadDuel(id);
        }
    }, []);

    useEffect(() => {
        if (id) {
            loadDuel(id);
        }
    }, [id]);

    return (
        <div id={'arena-result-page'} className={'flex-container-columns'}>
            {/*<div>*/}
            <div id={'main'}>
                <ArenaResultPortal playerInfo={{...duelInfo.playerLeft, side: DUEL_PLAYER.LEFT}} htmlId={'left'}/>
                <ArenaResultPanel playersInfo={{
                    // todo we should start thinking as to why did we propagate info object into player object in the first place
                    left: (duelInfo.playerLeft !== undefined ? {
                        ...duelInfo.playerLeft.info,
                        ownerAddress: duelInfo.playerLeft.user,
                        viewed: duelInfo.playerLeft.viewed
                    } : {}),
                    right: (duelInfo.playerRight !== undefined ? {
                        ...duelInfo.playerRight.info,
                        ownerAddress: duelInfo.playerRight.user,
                        viewed: duelInfo.playerRight.viewed
                    } : {})
                }} duelId={id}/>
                <ArenaResultPortal playerInfo={{...duelInfo.playerRight, side: DUEL_PLAYER.RIGHT}} htmlId={'right'}/>
            </div>
            <div id={'footer'}>
                <div id={'ground'}/>
            </div>
        </div>
    )
};

export default ArenaResultPage;
