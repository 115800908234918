import {BiLoader, BiLoaderCircle} from "react-icons/bi";
import * as React from 'react';
import {GiCheckMark, GiCrossMark} from "react-icons/gi";
import {LOG_STATUS} from "../../helper/ENUMS.ts";
import {IoWarning} from "react-icons/io5";

export const appendNewLog = (oldProcessLog, text, newLogStatus = LOG_STATUS.PENDING) => {
    console.log(`appendNewLog: ${text}`);
    console.log(`oldProcessLog - 1`, oldProcessLog[oldProcessLog.length - 1], oldProcessLog.length, (oldProcessLog.typeof));
    if (oldProcessLog === undefined || oldProcessLog === null || oldProcessLog.length === 0) {
        oldProcessLog = [];
    }
    if (oldProcessLog.length > 0) {
        oldProcessLog[oldProcessLog.length - 1].status = LOG_STATUS.SUCCESS;
    }
    console.log(`oldProcessLog`, oldProcessLog);
    let newLog = {status: newLogStatus, text: text};
    console.log(`newLog`, newLog   );
    oldProcessLog.push(newLog);
    console.log(`returning`, oldProcessLog);
    return oldProcessLog;
}


const PopUpProcessLogItems = (props) => {
    // const uuid = Math.random().toString(36).substring(7);
    return props.proccessLog.map((l, index) => {
        let icon;
        switch (l.status) {
            case LOG_STATUS.PENDING:
                icon = <BiLoader className={'process-icon loader rotate'}/>;
                break;
            case LOG_STATUS.SUCCESS:
                icon = <GiCheckMark className={'process-icon checkmark'}/>;
                break;
            case LOG_STATUS.ERROR:
                icon = <GiCrossMark className={'process-icon x-mark'}/>; // Display cross icon for error
                break;
            case LOG_STATUS.WARNING:
                icon = <IoWarning className={'process-icon warning'}/>; // Display cross icon for error
                break;
            default:
                icon = <BiLoader className={'process-icon loader rotate'}/>;
                break;
        }

        return (
            <p className={'log-entry'} key={`mint-log-${index}`}>
                <span>{icon}</span>
                {l.text}
            </p>
        );
    });
}

export default PopUpProcessLogItems;