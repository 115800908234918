import './StoreRightSide.css';
import {ALIGNMENT, EVENT_TYPE, FACTION} from "../../../helper/ENUMS.ts";
import React, {useEffect, useState} from 'react';
import {
    getAccountIds,
    makeBytes,
    sendAllowanceTransaction,
    sendTransaction
} from "../../../connector/blockchain/hashconnect";
import {getConfigFor} from "../../../connector/blockchain/ContractParameters";
import {
    AccountAllowanceApproveTransaction,
    ContractExecuteTransaction,
    ContractFunctionParameters,
    ContractId
} from "@hashgraph/sdk";
import axios from "axios";
import {externalLootBoxLabelUpdate} from "../../../component/LootBoxHeaderComponent/LootBoxHeaderComponent";
import {CurrencyIconDiv} from "../../../App";
import {createCurrencyAllowance, yvcMod} from "../../../connector/generateAllowanceTransactions";
import ToolTip from "../../../component/Modal/ToolTip";
import {FaQuestionCircle} from "react-icons/fa";
import {RxCross2} from "react-icons/rx";
import SeasonalFactionContentToolTip from "./SeasonalFactionContentToolTip";
import {HiMinus, HiPlus} from "react-icons/hi";
import { ImDisplay } from 'react-icons/im';
import MyCustomEventEmitter from "../../../component/myCustomEvents";


let boxPurchaseTotal, setBoxPurchaseTotal, boxPurchaseObject, setBoxPurchaseObject;


const purchaseLootBoxes = (lootBoxesObject = {red: 0, green: 0, blue: 0}) => {
    const signingAccount = getAccountIds()[0];
    const yvPrices = {red: 100, green: 100, blue: 100};
    const amountWithoutDecimals = (lootBoxesObject.red * yvPrices.red) + (lootBoxesObject.green * yvPrices.green) + (lootBoxesObject.blue * yvPrices.blue);
    const purchaseTransactionPrep = getConfigFor('LOOT_BOX_PURCHASE').then(callConfig => {
        console.log("storedHederaCommunicationConfig RESULT ", callConfig);
        // const contractId = ContractId.fromString(callConfig.contractId);
        // console.log(`LOOT_BOX_PURCHASE contractId : ${contractId}`);
        return createCurrencyAllowance(
            signingAccount,
            callConfig.contractId,
            amountWithoutDecimals * yvcMod)
            .then((allowanceGiven) => {
                if (allowanceGiven) {
                    return new ContractExecuteTransaction()
                        .setContractId(ContractId.fromString(callConfig.contractId))
                        .setGas(callConfig.gasLimit) // Increase if revert
                        .setTransactionMemo(`Banana.Capital: Purchasing faction boxes`)
                        .setFunction(callConfig.contractFunc
                            , new ContractFunctionParameters()
                                .addUint64(lootBoxesObject.red)
                                .addUint64(lootBoxesObject.green)
                                .addUint64(lootBoxesObject.blue)
                        );
                }

                return null;
            }).catch((x) => {
                console.error(x);
            });

    });

    purchaseTransactionPrep.then(contractExecuteTx => {
        makeBytes(contractExecuteTx, signingAccount).then((purchaseCallBytes) => {
            sendTransaction(purchaseCallBytes, signingAccount, false, true).then((purchaseCallResult) => {
                if (purchaseCallResult.success) {

                    // console.info("LOOTBOX GLORIOUS RESPONSE ", purchaseCallResult);
                    console.info("LOOTBOX PURCHASE RESP ", purchaseCallResult);
                    console.info("LOOTBOX PURCHASE TXID ", purchaseCallResult.response.transactionId);
                    // console.info("LOOTBOX PURCHASE receipt ", );
                    // todo add popup "yup, blockchain tx succes (with tx link)"

                    const urlHost = process.env.REACT_APP_BACK_END_HOST;
                    axios.put(urlHost + "/store/update-lootbox-balance", {
                        txId: purchaseCallResult.response.transactionId,
                    }, {
                        headers: {
                            'Content-Type': "application/json",
                            'Accept': "application/json",
                        },
                        responseType: "json",
                    })
                        .then(function (backEndMirrorNodeResponse) {
                            const {BLU, GRE, RED} = backEndMirrorNodeResponse.data.lbalance;
                            const lootBalanceArr = {};
                            lootBalanceArr[FACTION.RED] = RED;
                            lootBalanceArr[FACTION.GREEN] = GRE;
                            lootBalanceArr[FACTION.BLUE] = BLU;
                            localStorage.setItem('lootboxBalance', JSON.stringify(lootBalanceArr));
                            externalLootBoxLabelUpdate(lootBalanceArr);
                            // weird place, should return something
                        })
                        .catch(function (backEndErrorResponse) {
                            console.log("LOOTBOX BACKEND ERROR ", backEndErrorResponse.data);
                            // todo add popup "uh oh, failed to update the back end balance, retry?
                        })
                    ;
                    MyCustomEventEmitter.dispatch(EVENT_TYPE.FORCE_CURRENCY_UPDATE);
                } else {
                    console.log("LootBox Purchase Call Fail", purchaseCallResult);
                }
            })
                .catch((purchaseTxError) => {
                    console.error(purchaseTxError);
                })
            ;
        })
            .catch((x) => {
                console.error(x);
            })
        ;
    })
        .catch((x) => {
            console.error(x);
        })
    ;

    // console.log(`Red boxes on user : ${unpackedItemRx.contractFunctionResult.getUint64(0)}`); // faction
    // console.log(`Green boxes on user : ${unpackedItemRx.contractFunctionResult.getUint64(1)}`); // faction
    // console.log(`Blue boxes on user : ${unpackedItemRx.contractFunctionResult.getUint64(2)}`); // faction
}

const MiniPortalBox = ({boxPurchaseObject, faction}) => {

    const lowerBoxPurchase = (faction) => {
        const purchaseObj = boxPurchaseObject;
        if (purchaseObj[faction] > 0) {
            purchaseObj[faction] = purchaseObj[faction] - 1;
            setBoxPurchaseObject(purchaseObj);
        }
        setBoxPurchaseTotal((purchaseObj.reduce((a, b) => a + b, 0)) * 10000);
    }

    const raiseBoxPurchase = (faction) => {
        const purchaseObj = boxPurchaseObject;
        purchaseObj[faction] = purchaseObj[faction] + 1;
        setBoxPurchaseObject(purchaseObj);
        setBoxPurchaseTotal((purchaseObj.reduce((a, b) => a + b, 0)) * 10000);
    }

    return (
        <div className={`lootbox-container ${FACTION[faction].toLowerCase()} flex-container-rows`}>
            <div className={'control-panel flex-container-rows'}>
                {/*{FACTION[faction]}*/}
                <div className={'controls flex-container-columns'}>
                    <button onClick={() => {
                        lowerBoxPurchase(faction)
                    }} className={'arrows arrow-left'}>-
                    </button>
                    <span className={'lootbox-purchase-counter'}>{boxPurchaseObject[faction]}</span>
                    <button onClick={() => {
                        raiseBoxPurchase(faction)
                    }} className={'arrows arrow-right'}>+
                    </button>
                </div>
                <div className={'bottom-divider'}></div>
            </div>
            <div className={'lootbox-pricing-container flex-container-columns'}>

                <div className={'small-lootbox-container flex-container-rows'}>
                    <div className={'lootbox-representation flex-container-rows'}>
                        <SeasonalFactionContentToolTip faction={faction} trigger={<div className={'lootbox-purchase-info-tooltip'}><FaQuestionCircle className={'question-circle'}/></div>}/>
                        <span className={`lootbox-counter`}>{boxPurchaseObject[faction]}</span>
                    </div>
                    <div className={'lootbox-pricing-calc flex-container-columns'}>
                        <div className={'lootbox-pricing decrease-amount-container'}>
                            <button  onClick={() => {
                                lowerBoxPurchase(faction)
                            }} className={'lootbox-pricing decrease-amount btn button'}><HiMinus/></button>
                        </div>
                        <div className={'flex-container-rows'}>
                            <div className={'lootbox-pricing single-pricing flex-container-columns'}>
                                {/*<span>10.00</span>&nbsp;<CurrencyIconDiv size={32}/>&nbsp;&nbsp;<RxCross2/>&nbsp;{boxPurchaseObject[faction]}*/}
                                <span  className={`price`}>100.00&nbsp;</span><CurrencyIconDiv size={32}/>&nbsp;<span className='pcs' style={{marginTop: 'auto', marginBottom: '0'}}>/&nbsp;pcs</span>
                            </div>
                            <div className={'lootbox-pricing faction-pricing flex-container-columns'}>
                                {/*=&nbsp;{(boxPurchaseObject[faction]*10).toFixed(2)}&nbsp;<CurrencyIconDiv size={32}/>*/}
                                <span className={`price`}>{(boxPurchaseObject[faction]*100).toFixed(2)}&nbsp;</span><CurrencyIconDiv size={32}/>&nbsp;<span className='total' style={{marginTop: 'auto', marginBottom: '0'}}>total</span>
                            </div>
                        </div>
                        <div className={'lootbox-pricing increase-amount-container'}>
                            <button onClick={() => {
                                raiseBoxPurchase(faction)
                            }} className={'lootbox-pricing increase-amount btn button'}><HiPlus /></button>
                        </div>
                    </div>
                </div>
                <div className={`vertical-divider ${ALIGNMENT[faction].toLowerCase()}`}></div>
            </div>
            <div className={'bottom-divider bottom-driver-small-lootbox'}></div>
        </div>
    );
}

const StoreRightSide = ({}) => {

    const [purchasePriceArray, setPurchasePriceArray] = useState([0, 0, 0]);
    const purchaseObjDefault = [];
    purchaseObjDefault[FACTION.RED] = 1;
    purchaseObjDefault[FACTION.GREEN] = 1;
    purchaseObjDefault[FACTION.BLUE] = 1;
    [boxPurchaseObject, setBoxPurchaseObject] = useState(purchaseObjDefault);
    [boxPurchaseTotal, setBoxPurchaseTotal] = useState(30000);

    const setUpLootBoxPurchase = () => {
        const purchaseObj = boxPurchaseObject;
        // console.log("purchaseLootBoxes", {
        //     red: purchaseObj[FACTION.RED],
        //     green: purchaseObj[FACTION.GREEN],
        //     blue: purchaseObj[FACTION.BLUE]
        // });
        purchaseLootBoxes({
            red: purchaseObj[FACTION.RED],
            green: purchaseObj[FACTION.GREEN],
            blue: purchaseObj[FACTION.BLUE]
        });
    }

    return (
        <div id={'right-side'} className={'flex-container-columns'}>
            <div className={'main-divider vertical-divider'}/>
            <div className={'right-side-content flex-container-rows'}>

                <div className={'upper-part flex-container-rows'}>
                    <div className={'upper-spacer'}></div>
                    <div className={'purchase-total-container flex-container-rows'}>
                        <span className={'timer faction-box-refresh'}>Faction Box prices are stable (for now)...</span>
                        <button onClick={setUpLootBoxPurchase} className={'btn '}>BUY</button>
                        <span id={'purchase-total-label'}>{(boxPurchaseTotal/100).toFixed(2)} <CurrencyIconDiv size={32}/></span>
                    </div>
                    <div className={'bottom-divider'}></div>
                </div>
                <div className={'bottom-part lower-part flex-container-columns'}>
                    <MiniPortalBox boxPurchaseObject={boxPurchaseObject} faction={FACTION.RED}/>
                    <MiniPortalBox boxPurchaseObject={boxPurchaseObject} faction={FACTION.GREEN}/>
                    <MiniPortalBox boxPurchaseObject={boxPurchaseObject} faction={FACTION.BLUE}/>
                </div>
            </div>
        </div>
    );
};

export default StoreRightSide;