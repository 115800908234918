import * as React from "react";
import {useRef, useState} from "react";
import {EVENT_TYPE, LOG_STATUS, PROCESS_STATUS} from "../../helper/ENUMS.ts";
import {assetsHost} from "../../helper/externalLinks";
import Popup from "reactjs-popup";
import {ItemCardSemiImage} from "../itemCard/itemCard";
import PopUpProcessLogItems, {appendNewLog} from "../PopUpProcessLog/PopUpProcessLog";
import {
    associateTokensWithAccount,
    getAccountIds, getPairingString,
    makeBytes,
    sendTransaction
} from "../../connector/blockchain/hashconnect";
import {isSetCollectionAssociated} from "../../helper/mirrrorNode";
import axios from "axios";
import {uint64ToBytes} from "../../helper/dataConverters";
import {getConfigFor} from "../../connector/blockchain/ContractParameters";
import {createCurrencyAndItemAllowance, yvcMod} from "../../connector/generateAllowanceTransactions";
import {ContractExecuteTransaction, ContractFunctionParameters} from "@hashgraph/sdk";
import {PrepSetNft} from "../../connector/adapter/PrepSetNft";
import MyCustomEventEmitter from "../myCustomEvents";

let setProcessLogFunc, setProcessStatusFunc;
let cardImageCID, setCardImageCID;
const urlHost = process.env.REACT_APP_BACK_END_HOST;

const nextMintProcessRequest = (urlHost, processUUID, serialsArray) => {
    return axios.put(urlHost + "/full-set/mint-process", {
        processId: processUUID,
        serialsArray: serialsArray,
    });
}

const attemptToMintFullSetNFTWrapper = async (prepNFT, processLog) => {
    setProcessLogFunc([...appendNewLog(processLog, prepNFT.lastStep + 'Attempting to mint your set...')]);
    const nftResponse = (await nextMintProcessRequest(urlHost, prepNFT.processId, prepNFT.serialBytes)).data;
    console.log('attemptToMintFullSetNFT', nftResponse);
    const {lastStep, serial} = nftResponse;
    if (!(parseInt(serial).valueOf() > 0)) {
        setProcessLogFunc([...appendNewLog(processLog, `Error! NFT ${serial} not minted`, LOG_STATUS.ERROR)]);
    }
    prepNFT.serialNumber = serial;
    prepNFT.fullSetCID = nftResponse.fullSetCID;
    prepNFT.cardImageCID = nftResponse.cardImageCID;
    prepNFT.lastStep = lastStep;
    setProcessLogFunc([...appendNewLog(processLog, `Success! NFT with Serial ${serial} minted`, LOG_STATUS.SUCCESS)]);

    if (setCardImageCID !== null && setCardImageCID !== undefined && typeof setCardImageCID === 'function') {
        setCardImageCID(nftResponse.cardImageCID);
    }
    if (setProcessStatusFunc !== null && setProcessStatusFunc !== undefined && typeof setProcessStatusFunc === 'function') {
        setProcessStatusFunc(PROCESS_STATUS.FINISHED);
    }
}
const generateMetadataWrapper = async (prepNFT, processLog) => {
    setProcessLogFunc([...appendNewLog(processLog, prepNFT.lastStep + 'Generating metadata...')]);
    const nextMintProcessReq = (await nextMintProcessRequest(urlHost, prepNFT.processId, prepNFT.serialBytes)).data;
    prepNFT.lastStep = nextMintProcessReq.lastStep;
    prepNFT.metadataCID = nextMintProcessReq.metadataCID;
    console.log('generateMetadataWrapper', nextMintProcessReq);
    console.log('generateMetadataWrapper', prepNFT);
    performNextStep(prepNFT, processLog);
}

const generateImageWrapper = async (prepNFT, processLog) => {
    setProcessLogFunc(appendNewLog(processLog, prepNFT.lastStep + 'Generating NFT IMG... '));
    const imageResponse = (await nextMintProcessRequest(urlHost, prepNFT.processId, prepNFT.serialBytes)).data;
    prepNFT.lastStep = imageResponse.lastStep;
    prepNFT.cardImageCID = imageResponse.cardImageCID;
    prepNFT.setImageCID = imageResponse.setImageCID;
    performNextStep(prepNFT, processLog);
}

const payMintingFee = async (oldProcessLog, prepNFT) => {
    setProcessLogFunc([...appendNewLog(oldProcessLog, 'Generating FEE TX... ')]);
    const {contractFunc, contractId, gasLimit} = await getConfigFor('FULL_SET_MINT_PAY_FEE');
    const allowanceGiven = await createCurrencyAndItemAllowance(prepNFT.signer, contractId, 2 * yvcMod, prepNFT.serials);
    if (!allowanceGiven) {
        setProcessLogFunc([...appendNewLog(oldProcessLog, 'Allowance not given', LOG_STATUS.ERROR)]);
        return null;
    } else {
        setProcessLogFunc([...appendNewLog(oldProcessLog, 'Allowance given', LOG_STATUS.SUCCESS)]);
    }

    let serialsBytesArr = new Uint8Array(32), offset = 0;

    prepNFT.serials.map(s => {
        serialsBytesArr.set(uint64ToBytes(s), offset);
        offset += 8;
    })
    prepNFT.serialBytes = serialsBytesArr;
    console.log('serialsBytesArr', serialsBytesArr);
    console.log('serialsBytesArr', prepNFT.serialBytes);
    console.log('serialsBytesArr serials', prepNFT.serials);

    const payFeeTx = new ContractExecuteTransaction()
        .setContractId(contractId)
        .setGas(gasLimit)
        .setTransactionMemo(`Banana.Capital: Paying fee for $BANWAR NFT mint, process ${prepNFT.processId}`)
        .setFunction(contractFunc,
            new ContractFunctionParameters()
                .addBytes32(serialsBytesArr)
        );

    const txBytes = await makeBytes(payFeeTx, prepNFT.signer);
    const txResult = await sendTransaction(txBytes, prepNFT.signer, false, true);
    console.log('payFeeTx result', txResult);

    if (!txResult.success) {
        setProcessLogFunc([...appendNewLog(oldProcessLog, 'FEE TX ERROR', LOG_STATUS.ERROR)]);
        return null;
    }

    setProcessLogFunc([...appendNewLog(oldProcessLog, <>FEE TX: <a
        href={`https://hashscan.io/testnet/transaction/${txResult.response.transactionId}`}
        target={'_blank'}>{txResult.response.transactionId}</a>
    </>, LOG_STATUS.SUCCESS)]);
    setProcessLogFunc([...appendNewLog(oldProcessLog, 'Awaiting node confirmation...', LOG_STATUS.PENDING)]);

    const resp = await axios.put(urlHost + "/full-set/mint-process", {
        txId: txResult.response.transactionId,
        processId: prepNFT.processId,
        serialsArray: serialsBytesArr,
    }, {
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
        },
        responseType: "json",
    });
    if (resp.status === 201) {
        oldProcessLog[oldProcessLog.length - 1].status = LOG_STATUS.SUCCESS;
        // idk if it does anything;
    }
    prepNFT.lastStep = resp.data.lastStep;
    prepNFT.feeRecorded = resp.data.feeRecorded;
    prepNFT.nextExpectedStep = resp.data.nextExpectedStep;

    MyCustomEventEmitter.dispatch(EVENT_TYPE.FORCE_CURRENCY_UPDATE);

    performNextStep(prepNFT, oldProcessLog);
}
const resolveMintingFee = async (oldProcessLog, prepNFT) => {

    let serialsBytesArr = new Uint8Array(32), offset = 0;

    prepNFT.serials.map(s => {
        serialsBytesArr.set(uint64ToBytes(s), offset);
        offset += 8;
    })
    prepNFT.serialBytes = serialsBytesArr;
    console.log('serialsBytesArr', serialsBytesArr);
    console.log('serialsBytesArr', prepNFT.serialBytes);
    console.log('serialsBytesArr serials', prepNFT.serials);

    if (prepNFT.feeTxId === null || prepNFT.feeTxId === '' || prepNFT.feeTxId === undefined) {
        setProcessLogFunc([...appendNewLog(oldProcessLog, 'Can\'t proceed, fee tx not given by user', LOG_STATUS.ERROR)]);
        return null;
    }

    setProcessLogFunc([...appendNewLog(oldProcessLog, <>FEE TX: <a
        href={`https://hashscan.io/testnet/transaction/${prepNFT.feeTxId}`}
        target={'_blank'}>{prepNFT.feeTxId}</a>
    </>, LOG_STATUS.SUCCESS)]);
    setProcessLogFunc([...appendNewLog(oldProcessLog, 'Awaiting mirror node validation...', LOG_STATUS.PENDING)]);

    const resp = await axios.put(urlHost + "/full-set/mint-process", {
        txId: prepNFT.feeTxId,
        processId: prepNFT.processId,
        serialsArray: serialsBytesArr,
    }, {
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
        },
        responseType: "json",
    });
    if (resp.status === 201) {
        oldProcessLog[oldProcessLog.length - 1].status = LOG_STATUS.SUCCESS;
        // idk if it does anything;
    }
    prepNFT.lastStep = resp.data.lastStep;
    prepNFT.feeRecorded = resp.data.feeRecorded;
    prepNFT.nextExpectedStep = resp.data.nextExpectedStep;

    performNextStep(prepNFT, oldProcessLog);
}


export function performNextStep(prepNFT, processLog, resolverObj = {}) {

    if (resolverObj.processLogFunc !== undefined && resolverObj.processLogFunc !== null) {
        setProcessLogFunc = resolverObj.processLogFunc;
        let serialsBytesArr = new Uint8Array(32), offset = 0;
        prepNFT.serials.map(s => {
            serialsBytesArr.set(uint64ToBytes(s), offset);
            offset += 8;
        })
        prepNFT.serialBytes = serialsBytesArr;
    }

    switch (prepNFT.lastStep) {
        // case 0:
        case 1:
            if (resolverObj.processLogFunc !== undefined && resolverObj.processLogFunc !== null) {
                setProcessLogFunc = resolverObj.processLogFunc;
                resolveMintingFee(processLog, prepNFT).then().catch(err => {
                    setProcessLogFunc([...appendNewLog(processLog, 'Validation error: ' + err.response.data.error, LOG_STATUS.ERROR)]);
                    console.log('Error paying fee', err);
                });
            } else {
                payMintingFee(processLog, prepNFT).then().catch(err => {
                    setProcessLogFunc([...appendNewLog(processLog, 'Error paying fee', LOG_STATUS.ERROR)]);
                    console.log('Error paying fee', err);
                });
            }
            break;
        case 2:
                generateImageWrapper(prepNFT, processLog).then().catch(err => {
                        setProcessLogFunc([...appendNewLog(processLog, 'Error generating image: ' + err.response.data.error, LOG_STATUS.ERROR)]);
                        console.log('Error generating image', err);
                    }
                );
            break;
        case 3:
            generateMetadataWrapper(prepNFT, processLog).then().catch(err => {
                    setProcessLogFunc([...appendNewLog(processLog, 'Error generating metadata', LOG_STATUS.ERROR)]);
                    console.log('Error generating metadata', err);
                }
            );
            break;
        case 4:
        case 5:
            attemptToMintFullSetNFTWrapper(prepNFT, processLog).then().catch(err => {
                setProcessLogFunc([...appendNewLog(processLog, 'Error minting NFT', LOG_STATUS.ERROR)]);
                console.log('Error minting NFT', err);
            });
            break;

    }
}

const initSetMinting = async (selectedBanana, oldProcessLog) => {
    setProcessLogFunc([...appendNewLog(oldProcessLog, 'Initialising Banana mint... ')]);
    // todo promise all init with a token assoc check
    const signingAcct = getAccountIds()[0];

    let isSetCollectionAssoc = null;
    if (!(await isSetCollectionAssociated(signingAcct)).isAssociated) {
        setProcessLogFunc([...appendNewLog(oldProcessLog, 'Item collection not associated ', LOG_STATUS.WARNING)]);
        isSetCollectionAssoc = associateTokensWithAccount();
    }
    const initProcessRequest = axios.post(urlHost + "/full-set/init-mint-process", {
        bxIds: selectedBanana.map(card => card.bxId),
    }, {
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
            // "Access-Control-Allow-Origin": "*",
        },
        responseType: "json",
    });
    const [assocReturn, initMintResponse] = await Promise.all([isSetCollectionAssoc, initProcessRequest]);
    console.log(assocReturn, 'assocReturn');
    // const {serialsArray, lastStep, processUUID} = initMintResponse.data;
    // let serialsBytesArr = new Uint8Array(32), offset = 0;
    // serialsArray.map(s => { // why is it here if we get serials from above?
    //     serialsBytesArr.set(uint64ToBytes(s), offset);
    //     offset += 8;
    // });
    if (initMintResponse.data.processUUID === null || initMintResponse.data.processUUID === undefined) {
        console.error('initMintResponse.data.processUUID not found', initMintResponse.data);
    } else {
        setProcessLogFunc([...appendNewLog(oldProcessLog, <>Process ID:&nbsp;<span className={'highlighted'}
                                                                                   style={{cursor: 'pointer'}}
                                                                                   onClick={async () => {
                                                                                       await navigator.clipboard.writeText(initMintResponse.data.processUUID);
                                                                                       alert('Copied to clipboard');
                                                                                   }}>{initMintResponse.data.processUUID}</span></>, LOG_STATUS.SUCCESS)]);
    }
    const prepNFT = new PrepSetNft({data: initMintResponse.data, signingAcct: signingAcct});
    performNextStep(prepNFT, oldProcessLog);
}


const PortalMintButton = ({itemCards, mintButtonContent = null}) => {
    const numberOfItemsSelected = itemCards.filter(i => i !== undefined && i !== null).length;
    let isComplete = numberOfItemsSelected === 4;
    [cardImageCID, setCardImageCID] = useState(null);
    const [processLogItems, setProcessLogItems] = useState([{
        status: LOG_STATUS.PENDING,
        text: `Waiting for players' confirmation...`
    }]);
    setProcessLogFunc = setProcessLogItems;
    let portalText2 = useRef();
    portalText2 = isComplete ? "MINT!" : (numberOfItemsSelected === 0 ? "" : "Complete new set");
    // console.log("isComplete", isComplete, numberOfItemsSelected, itemCards);
    const [processStatus, setProcessStatus] = useState(PROCESS_STATUS.WAITING);
    setProcessStatusFunc = setProcessStatus;

    function getMintButtonText(status) {
        switch (status) {
            case PROCESS_STATUS.WAITING:
                return (
                    <>CONFIRM & BEGIN MINTING... 2 <img className={'yv-currency-icon'}
                                                        src={`${assetsHost}/assets/global/logos/logo_diamond_64x64.png`}/></>);

            case PROCESS_STATUS.INITIALISED:
                // setMintButtonDisabled(true);
                return "MINTING...";
            case PROCESS_STATUS.FINISHED:
                // setMintButtonDisabled(false);
                return "Close and update inventory";
            case PROCESS_STATUS.ERROR:
                // setMintButtonDisabled(false);
                return "Error, try again?";
            default:
                // setMintButtonDisabled(false);
                return "process error";
        }
    }

    const mintButtonDisabled = !isComplete;

    return (
        // <div id={`portal-button-wrapper`} className={` ${isComplete ? '' : 'in'}complete`}>
            <>
                <Popup className={'mint-popup mint-set'}
                       trigger={
                           mintButtonContent !== null ? mintButtonContent :
                               <>
                                   <button
                                       className={`portal-button ${isComplete ? '' : 'in'}complete`}
                                       disabled={!isComplete}
                                       onClick={() => {
                                           // isShownMintedBanan ? unwrapBananToItems(forgedBanan) : wrapItemsToBanan(selectedBanan)
                                       }}
                                   >{portalText2}</button>
                               </>

                           /*TODO: rewrite the component to use Button as a state flip with additional processLog wipe functionality
                           *  trigger doesn't allow us to do this */
                       }
                       modal
                       closeOnDocumentClick={processStatus !== PROCESS_STATUS.INITIALISED}
                >
                    {close => (
                        <div id='mint-set-from-inventory-modal' className="modal flex-container-rows">
                            <button className="close" onClick={close}>
                                &times;
                            </button>
                            <div className={'content-after-x'}>
                                <h3>Confirmation...</h3>
                                <div className="fading-line"/>
                                <div className={'content-container flex-container-rows'}>
                                    <div className={' text flex-row flex-row-full'}>
                                        ATTENTION! You can not 'unmint' the set once it's been configured! You'll
                                        permanently lose access to these 4 items!
                                        Double check if these are the items you want to assemble:
                                    </div>
                                    <div className={'set-row flex-row flex-container-columns'}>
                                        <div id={'mint-popup-left'} className={'mint-popup-left flex-column'}>
                                            <div
                                                className={"item-card item-card-semi-img"}
                                            >
                                                {cardImageCID !== null
                                                    ? <img className={"set"}
                                                           src={`${process.env.REACT_APP_IPFS_CDN_HOST}/ipfs/${cardImageCID}`}/>
                                                    : <img className={"placeholder set"}
                                                           src={`${assetsHost}/assets/inventory/set_placeholder.png`}/>}
                                            </div>
                                        </div>

                                        <div id={'mint-popup-right'}
                                             className={'mint-popup-right flex-column flex-container-rows'}>
                                            <div className={'flex-row flex-row-full'}>
                                                {itemCards.map(itemCardData => {
                                                    return <ItemCardSemiImage card={itemCardData}/>
                                                })}
                                            </div>
                                            <div className={'action-log flex-row flex-container-rows'}>
                                                <PopUpProcessLogItems proccessLog={processLogItems}/>
                                            </div>
                                            <div className="actions flex-row">
                                                <>

                                                    <button
                                                        className={`flex-all-center btn ${mintButtonDisabled === true ? 'disabled' : ''}`}
                                                        onClick={() => {
                                                            if (processStatus === PROCESS_STATUS.WAITING) {
                                                                initSetMinting(itemCards, processLogItems);
                                                            } else if (processStatus === PROCESS_STATUS.FINISHED) {
                                                                close();
                                                            }
                                                        }}
                                                    >{getMintButtonText(processStatus)}</button>
                                                    {processStatus === PROCESS_STATUS.WAITING ? //TODO
                                                        <button className="button secondary btn" onClick={() => {
                                                            close();
                                                        }}>NO, GO BACK!
                                                        </button>
                                                        :
                                                        <></>
                                                    }
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Popup>
            </>
        // </div>
    );
}

export default PortalMintButton;