// @flow
import * as React from 'react';
import {useRef, useState} from 'react';
import './itemCard.css';
import {BLOCKCHAIN, EVENT_TYPE, FACTION, LOG_STATUS, PROCESS_STATUS, SET_PIECE} from "../../helper/ENUMS.ts";
import Popup from "reactjs-popup";
import axios from "axios";
import {ContractExecuteTransaction, ContractFunctionParameters} from "@hashgraph/sdk";
import {
    associateTokensWithAccount,
    getAccountIds,
    makeBytes,
    sendTransaction
} from "../../connector/blockchain/hashconnect";
import {getConfigFor} from "../../connector/blockchain/ContractParameters";
import {getAssetHostCDN} from "../../helper/externalLinks";
import PopUpProcessLogItems, {appendNewLog} from "../PopUpProcessLog/PopUpProcessLog";
import {formatNumber} from "../../helper/calc";
import {createCurrencyAllowance, yvcMod} from "../../connector/generateAllowanceTransactions";
import {SiHedera} from "react-icons/si";
import {getAccountTokenAllowance} from "../../connector/blockchain/Hedera/mirrorNode";
import {getTxErrorMessage, isItemCollectionAssociated} from "../../helper/mirrrorNode";
import MyCustomEventEmitter from "../myCustomEvents";
import {changeRemoveSetItem} from "../../pages/inventory/sides/inventoryRightSide";
import ToolTip from "../Modal/ToolTip";
import {ImCross} from "react-icons/im";
import {VscBlank} from "react-icons/vsc";

// const assetsHost = "https://asset-host.fra1.digitaloceanspaces.com";
const assetsHost = getAssetHostCDN();
// cards-integration/season_0/card_season_0_body_blue_2.png
// DO host    : https://asset-host.fra1.digitaloceanspaces.com/cards-integration/season_0/card_season_0_body_blue_2.png
// BUNNY host :                   https://asset-host.b-cdn.net/cards-integration/season_0/card_season_0_body_blue_2.png
function bxIdSlice(bxId, length = 0) {
    const s = "000" + bxId;
    return s.substr(s.length - 4);
}

export const ItemCard = (props) => {
    const card = props.card;
    const imgSrc = [card.season, card.setPiece, card.skinSet].join('-') + ".png";
    const alt = [FACTION[card.faction], SET_PIECE[card.setPiece], "set " + card.skinSet].join(',') + ".png";
    // TODO replace with srcSet in nearest future
    return (
        <div className={"item-card item-card-" + FACTION[card.faction]} onClick={() => props.cardClickEvent(card)}>
            <span className={"id"}>#{card.bxId}</span>
            <img className={"thumbnail"} src={imgSrc} alt={alt}/>
            <span className={"damage-number"}>{formatNumber(card.damage / 100)}</span>
        </div>
    );
};

export const ItemCardSemiImage = ({card, cardClickEvent}) => {
    const {season, setPiece, skinSet, faction, bxId, damage} = card;
    const imgSrc = `${season}-${setPiece}-${skinSet}.png`;
    const bgSrc = `url(${assetsHost}/cards-integration/season_${season}/card_season_${season}_${SET_PIECE[setPiece].toLowerCase()}_${FACTION[faction].toLowerCase()}_${skinSet}.png)`;
    const alt = `${FACTION[faction]},${SET_PIECE[setPiece]},set ${skinSet}.png`;
    // TODO replace with srcSet in nearest future
    const cardClick = () => {
        if (cardClickEvent instanceof Function) {
            cardClickEvent(card);
        }
    }

    return (
        <div
            className={`item-card item-card-semi-img item-card-${FACTION[faction]}`}
            onClick={cardClick}
            style={{backgroundImage: bgSrc}}
        >
            <img className="aspect-ratio"
                 src={`${assetsHost}/assets/inventory/aspect-ratio/aspect-ratio-cards-debug.png`}/>
            <div className="text-overlay">
                <span className="id">#{bxIdSlice(bxId, 4)}</span>
                <span className="damage-number">{formatNumber(card.damage / 100)}</span>
            </div>
        </div>
    );
};

/**
 *
 * @param card
 * @param processId
 * @returns {Promise<((css: (string | {toString(): string} | Result | LazyResult | Root), options?: ProcessOptions) => (LazyResult | NoWorkResult)) | ((dataUpdate?: (CryptoES.lib.WordArray | string)) => CryptoES.lib.WordArray) | ((state: StateCore) => void) | child.ChildProcess | ((selectors: parser.Selectors, options?: Partial<parser.Options>) => Promise<string>) | ((sourceText: string, sourcePath: Config.Path, options: TransformOptions<OptionType>) => TransformedSource) | ((sourceText: string, sourcePath: Config.Path, options: TransformOptions<OptionType>) => TransformedSource) | {error: string}>}
 */
const proceedToNextStep = (card, processId) => {
    const urlHost = process.env.REACT_APP_BACK_END_HOST;
    return axios.put(urlHost + "/item/mint-prep-process", {
        bxId: card.bxId,
        processId: processId,
    }, {
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
        },
        responseType: "json",
    })
        .then(function (backEndProccessResponse) {
            const processUpd = backEndProccessResponse.data.process;
            console.log(processUpd);
            return processUpd;
            /*if (processUpd.nstep > 0 && processUpd.nstep < 4) {
                console.log("engaging next step");
                // if ()
                ItemCardMintAction(card);
            } else if (processUpd.nstep === 4) {
                if (isNaN(processUpd.serialnumber) && processUpd.serialnumber > 0) {
                    console.log("minted");
                } else if (processUpd.serialnumber === 'ERROR_DURING_NFT_MINTING_TX') {
                    console.log("oopise");
                }
            }*/
        })
        .catch(function (backEndErrorResponse) {
            console.log("CARD MINT BACKEND ERROR ", backEndErrorResponse.data);
            return {error: "CARD MINT BACKEND ERROR "};
            // todo add popup "uh oh, failed to update the back end balance, retry?
        })
        ;
}


function proceedToAllowanceTx(callConfig, card, signingAcct, oldProcessLog, setProcessLog, yvMod, setMintButtonDisabled, setProcessStatus, processId) {
    console.log("storedHederaCommunicationConfig RESULT ", callConfig);
    setProcessLog([...appendNewLog(oldProcessLog, 'Generating transactions...')]);

    const generateFeePaymentTransaction = ({callConfig, card}) => {
        return new ContractExecuteTransaction()
            .setContractId(callConfig.contractId)
            .setGas(callConfig.gasLimit)
            .setTransactionMemo(`Banana.Capital: Minting fee for $BANARS card #${card.bxId}, process: ${processId}`)
            .setFunction(callConfig.contractFunc,
                new ContractFunctionParameters()
                    .addUint160(card.bxId)
            );
    }

    return getAccountTokenAllowance(
        signingAcct,
        callConfig.contractId)
        .then((allowance) => {

            if (allowance.amount >= 1000) {
                setProcessLog([...appendNewLog(oldProcessLog, 'Allowance checked , Proceeding to tx signing...')]);
                return generateFeePaymentTransaction({callConfig, card});
            } else {
                return createCurrencyAllowance(
                    signingAcct,
                    callConfig.contractId,
                    1 * yvMod)
                    .then((allowanceGiven) => {
                        if (allowanceGiven) {
                            setProcessLog([...appendNewLog(oldProcessLog, 'Fee allowance given, Proceeding to tx signing...')]);
                            return generateFeePaymentTransaction({callConfig, card});
                        } else {
                            setProcessLog([...appendNewLog(oldProcessLog, 'Allowance not given, process aborted', LOG_STATUS.ERROR)]);
                            setMintButtonDisabled(false);
                            setProcessStatus(PROCESS_STATUS.ERROR);
                            return null;
                        }
                    }).catch((x) => {
                        console.log("error in createCurrencyAllowance", x.status, x.error);
                        console.error(x);
                    });
            }
        })
        .catch((error) => {
            console.error(error);
        });
}

const proceedToItemNextStep = async ({isInitialised, processLog, setProcessLog, card, process, setProcessStatus = null}) => {
    let processCompletedStep = 0;
    if (isInitialised) {
        let newStepResponse = {};
        let oldProcessLog;
        while (process.nextStep < 5 && (newStepResponse.serialnumber !== 'ERROR_DURING_NFT_MINTING_TX') && isInitialised) {
            newStepResponse = await proceedToNextStep(card, process.processId);
            console.log("newStepResponse", newStepResponse);
            console.log("processStep", process.nextStep);
            process.nextStep = newStepResponse.nstep;
            processCompletedStep = process.nextStep - 1;
            oldProcessLog = processLog;

            let newLog = {status: LOG_STATUS.PENDING, text: ''};
            switch (process.nextStep) {
                case 2: // this is a wrong step, should not be here
                // "proceed to step 2" means we're uploading the card image

                case 3: // image uploaded to ipfs
                    newLog.text = `2 card uploaded onto IPFS`;
                    break;
                case 4:
                    newLog.text = `3 metadata uploaded onto IPFS`;
                    break;
                case 5:
                    newLog.text = <>New card serial number: <span className={'bx-serial'}>&nbsp;
                        <SiHedera/>&nbsp;#{newStepResponse.serialnumber}</span></>;
                    newLog.status = LOG_STATUS.SUCCESS;
                    if (setProcessStatus !== undefined && typeof setProcessStatus === 'function') {
                        setProcessStatus(PROCESS_STATUS.FINISHED);
                    }
                    // processStep  = 6;
                    processCompletedStep = 5;
                    break;
            }

            await new Promise(resolve => setTimeout(resolve, 750))
                .then(setProcessLog([...appendNewLog(oldProcessLog, newLog.text, newLog.status)]))
                .catch((x) => {
                    setProcessLog([...appendNewLog(oldProcessLog, x.toString(), LOG_STATUS.ERROR)]);
                    if (setProcessStatus !== undefined && typeof setProcessStatus === 'function') {
                        setProcessStatus(PROCESS_STATUS.ERROR);
                    }
                });
        }
        if (processCompletedStep !== 5) {
            setProcessLog([...appendNewLog(oldProcessLog, "process finished on step " + processCompletedStep, LOG_STATUS.ERROR)]);
            if (setProcessStatus !== undefined && typeof setProcessStatus === 'function') {
                setProcessStatus(PROCESS_STATUS.ERROR);
            }
        }
    }
}

/**
 * @param card
 * @param processLog
 * @param setProcessLog
 * @param setProcessStatus
 * @param setMintButtonDisabled
 * @returns {Promise<void>}
 * @constructor
 */
const ItemCardMintAction = async (card, processLog, setProcessLog, setProcessStatus, setMintButtonDisabled, processNextStep, setProcessStep) => {
    const urlHost = process.env.REACT_APP_BACK_END_HOST;
    let oldProcessLog = processLog;
    setProcessStatus(PROCESS_STATUS.INITIALISED);
    const signingAcct = getAccountIds()[0];

    let res = undefined;

    const processId = await axios.post(`${urlHost}/item/initialise-mint-process`, {
        "bxId": card.bxId,
        "blockchainId": BLOCKCHAIN.HEDERA,
    }).then((res) => {
        const processId = res.data.processId || "";
        setProcessLog([...appendNewLog(oldProcessLog, <>Process ID:&nbsp;<span className={'highlighted'}
                                                                               onClick={() => {
                                                                                   alert('will be copied on click')
                                                                               }}>{processId}</span></>, LOG_STATUS.SUCCESS)]);
        if (res.data.lastKnownStep > 0) {
            processNextStep = parseInt(res.data.lastKnownStep) + 1;
            setProcessLog([...appendNewLog(oldProcessLog, 'Process already initialised, proceeding to step ' + processNextStep, LOG_STATUS.SUCCESS)]);
        } else {
            processNextStep = 1;
        }
        return processId;
    }).catch((err) => {
        setProcessLog([...appendNewLog(oldProcessLog, 'Failed to initialise minting process'), LOG_STATUS.ERROR]);
        console.log(`err`, err);
        return "";
    });

    console.log(`processStep`, processNextStep, (processNextStep === 1), (processNextStep === 0));
    let initialised = false;

    if (processNextStep === 1 || processNextStep === 0) {
        const payFeeTransactionPromise = getConfigFor('ITEM_MINT_PAY_FEE').then(async callConfig => {
            const isItemCollectionAssoc = await isItemCollectionAssociated(signingAcct);
            console.log("isItemCollectionAssoc", isItemCollectionAssoc);
            if (isItemCollectionAssoc.isAssociated) {
                return await proceedToAllowanceTx(callConfig, card, signingAcct, oldProcessLog, setProcessLog, yvcMod, setMintButtonDisabled, setProcessStatus, processId);
            } else {
                setProcessLog([...appendNewLog(oldProcessLog, 'Item collection not associated ', LOG_STATUS.WARNING)]);
                return associateTokensWithAccount().then(async (res) => {
                    return await proceedToAllowanceTx(callConfig, card, signingAcct, oldProcessLog, setProcessLog, yvcMod, setMintButtonDisabled, setProcessStatus, processId);
                });
            }
        }).catch(
            (error) => {
                console.error(error);
            }
        );
        // todo connect allowance to payFeeTransactionPromise

        let transactionBytes = await makeBytes(await payFeeTransactionPromise, signingAcct);
        res = await sendTransaction(transactionBytes, signingAcct, false, true);
        if (res !== undefined) {
            if (res.success) {
                setProcessLog([...appendNewLog(oldProcessLog, <>Fee paid <a
                    href={`https://hashscan.io/testnet/transaction/${res.response.transactionId}`}
                    target={'_blank'}>{res.response.transactionId}</a>
                </>)]);
                MyCustomEventEmitter.dispatch(EVENT_TYPE.FORCE_CURRENCY_UPDATE);
                setProcessLog([...appendNewLog(oldProcessLog, 'Initiating minting process...')]);
                await axios.post(urlHost + "/item/init-mint-process", {
                    txId: res.response.transactionId,
                    bxId: card.bxId,
                }, {
                    headers: {
                        'Content-Type': "application/json",
                        'Accept': "application/json",
                        // "Access-Control-Allow-Origin": "*",
                    },
                    responseType: "json",
                }).then((initMintProcessRespons) => {
                    processNextStep = 2;
                    ({initialised} = initMintProcessRespons.data);
                    console.log("/item/init-mint-process", initMintProcessRespons.data);
                }).catch((err) => {
                    initialised = false;
                    processNextStep = 0;
                    console.error("/item/init-mint-process", err);
                });
            } else {
                setProcessLog([...appendNewLog(oldProcessLog, <>Failed to pay mint fee, tx <a
                    href={`https://hashscan.io/testnet/transaction/${res.error.transactionId}`}
                    target={'_blank'}>{res.error.transactionId}</a></>, LOG_STATUS.ERROR)]);
                setProcessLog([...appendNewLog(oldProcessLog, <>Contract Error: Parsing... </>, LOG_STATUS.PENDING)]);
                getTxErrorMessage(res.error).then((errorMessage) => {
                    oldProcessLog[oldProcessLog.length - 1].text = <>Contract Error: {errorMessage}</>;
                    oldProcessLog[oldProcessLog.length - 1].status = LOG_STATUS.ERROR;
                    setProcessLog([...oldProcessLog]);
                    if (errorMessage === 'Failed to withdraw YVC to Burner') { // TODO switch to unified error code

                    }
                });
                setProcessStatus(PROCESS_STATUS.ERROR);
                console.error("Failed to pay fee", res);
                setMintButtonDisabled(false);
            }
        }
    } else {
        console.log("skipping fee tx storing and minting process init");
        initialised = true;
    }

    if (processNextStep > 1) {
        let processCompletedStep = 0;
        if (initialised) {
            let newStepResponse = {};
            while (processNextStep < 5 && (newStepResponse.serialnumber !== 'ERROR_DURING_NFT_MINTING_TX') && initialised) {
                newStepResponse = await proceedToNextStep(card, processId);
                console.log("newStepResponse", newStepResponse);
                console.log("processStep", processNextStep);
                processNextStep = newStepResponse.nstep;
                processCompletedStep = processNextStep - 1;
                oldProcessLog = processLog;
                // oldProcessLog[oldProcessLog.length - 1].status = LOG_STATUS.SUCCESS;
                let newLog = {status: LOG_STATUS.PENDING, text: ''};
                switch (processNextStep) {
                    case 2: // this is a wrong step, should not be here
                    // "proceed to step 2" means we're uploading the card image

                    case 3: // image uploaded to ipfs
                        newLog.text = `2 card uploaded onto IPFS`;
                        break;
                    case 4:
                        newLog.text = `3 metadata uploaded onto IPFS`;
                        break;
                    case 5:
                        newLog.text = <>New card serial number: <span className={'bx-serial'}>&nbsp;
                            <SiHedera/>&nbsp;#{newStepResponse.serialnumber}</span></>;
                        newLog.status = LOG_STATUS.SUCCESS;
                        setProcessStatus(PROCESS_STATUS.FINISHED);
                        // processStep  = 6;
                        processCompletedStep = 5;
                        setMintButtonDisabled(false);
                        break;
                }
                await new Promise(resolve => setTimeout(resolve, 750))
                    .then(setProcessLog([...appendNewLog(oldProcessLog, newLog.text, newLog.status)]))
                    .catch((x) => {
                        setProcessLog([...appendNewLog(oldProcessLog, x.toString(), LOG_STATUS.ERROR)]);
                        setProcessStatus(PROCESS_STATUS.ERROR);
                        setMintButtonDisabled(false);
                    });
            }
            if (processCompletedStep !== 5) {
                setProcessLog([...appendNewLog(oldProcessLog, "process finished on step " + processCompletedStep, LOG_STATUS.ERROR)]);
                setProcessStatus(PROCESS_STATUS.ERROR);
                setMintButtonDisabled(false);
            }
        }
    }
}

export const PreMintedItemCard = (props) => {
    const {card, updatePostMintedItems, showTooltip} = props;
    const bgSrc = "url(" + assetsHost + "/cards-integration/season_" + card.season + "/card_season_" + card.season + "_" + SET_PIECE[card.setPiece].toLowerCase() + "_" + FACTION[card.faction].toLowerCase() + "_" + card.skinSet + ".png)";
    const [processLogItems, setProcessLogItems] = useState([{
        status: LOG_STATUS.PENDING,
        text: `Waiting for players' confirmation...`
    }]);

    const [openCardMintModal, setOpenCardMintModal] = useState(false);
    let processStep = useRef(1);

    function closeCardMintModal() {
        setOpenCardMintModal(false)
    };

    // const [mintButtonText, setMintButtonText] = useState("Mint");
    const [mintButtonDisabled, setMintButtonDisabled] = useState(false);

    const [processStatus, setProcessStatus] = useState(PROCESS_STATUS.WAITING);


    function getMintButtonText(processStatus) {
        switch (processStatus) {
            case PROCESS_STATUS.WAITING:
                return (<>
                    MINT ITEM 1<img
                    className={'yv-currency-icon'}
                    src={`${assetsHost}/assets/global/logos/logo_diamond_64x64.png`}/></>);
            case PROCESS_STATUS.INITIALISED:
                // setMintButtonDisabled(true);
                return "MINTING...";
            case PROCESS_STATUS.FINISHED:
                // setMintButtonDisabled(false);
                return "Close and update inventory";
            case PROCESS_STATUS.ERROR:
                // setMintButtonDisabled(false);
                return "Error, try again?";
            default:
                // setMintButtonDisabled(false);
                return "process error";
        }
    }

    function getMintItemPopupTitle(processStatus) {
        switch (processStatus) {
            case PROCESS_STATUS.WAITING:
                return "Just Double Checking...";
            case PROCESS_STATUS.INITIALISED:
                return "Waiting...";
            case PROCESS_STATUS.FINISHED:
                return "Success!";
            default:
                return "Hm...";
        }
    }


    return (
        <>
            <Popup className={'mint-popup'}
                   trigger={
                       <div
                           className={`item-card item-card-semi-img ${processStatus === PROCESS_STATUS.FINISHED ? 'finished' : ''} item-card-${FACTION[card.faction]}`}
                           style={{
                               backgroundImage: bgSrc,
                               cursor: (processStatus !== PROCESS_STATUS.FINISHED ? 'pointer' : 'default')
                           }}>
                           <img className={"aspect-ratio"}
                                src={`${assetsHost}/assets/inventory/aspect-ratio/aspect-ratio-cards-debug.png`}/>
                           {/*{processStatus !== PROCESS_STATUS.FINISHED ?*/}
                           <div className={"text-overlay"}>
                               <span className={"id"}>#{bxIdSlice(card.bxId, 4)}</span>
                               <span className={"damage-number"}>{formatNumber(card.damage / 100)}</span>
                           </div>
                           {/*: <></>}*/}
                       </div>
                   }
                   modal
                   closeOnDocumentClick={processStatus === PROCESS_STATUS.FINISHED || processStatus === PROCESS_STATUS.WAITING}
                   open={openCardMintModal}
                   onClose={closeCardMintModal}
                   nested
            >
                {close => (
                    <div id='mint-from-inventory-modal' className="modal">
                        <button
                            className={`close`}
                            onClick={() => {
                                updatePostMintedItems();
                                close();
                            }}>
                            &times;
                        </button>

                        <div className={'content-after-x'}>
                            <h3> {getMintItemPopupTitle(processStatus)}</h3>
                            <div className="fading-line"/>
                            <div className={'content-container flex-container-rows'}>
                                <div className={'text flex-row flex-row-full'}>
                                    <p>You're about to mint an item <span
                                        className={'item-card-id'}>#{card.bxId}</span>,
                                        we're just making sure you didn't miss click or anything...</p>
                                </div>
                                <div className={'flex-row flex-container-columns'}>

                                    <div id={'mint-popup-left'} className={'flex-column'}>
                                        <div className={' item-card-container'}>
                                            <div
                                                className={"item-card item-card-semi-img item-card-" + FACTION[card.faction]}
                                                style={{backgroundImage: bgSrc}}>
                                                <img className={"aspect-ratio"}
                                                     src={`${assetsHost}/assets/inventory/aspect-ratio/aspect-ratio-cards-debug.png`}/>
                                                <div className={"text-overlay"}>
                                                    <span className={"id"}>#{bxIdSlice(card.bxId, 4)}</span>
                                                    <span
                                                        className={"damage-number"}>{formatNumber(card.damage / 100)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id={'mint-popup-right'} className={'flex-column flex-container-rows'}>
                                        <div className={'action-log flex-row flex-container-rows'}>
                                            <PopUpProcessLogItems proccessLog={processLogItems}/>
                                        </div>
                                        <div className="actions flex-row">
                                            <>
                                                <button
                                                    className={`flex-all-center btn ${mintButtonDisabled === true ? 'disabled' : ''}`}
                                                    onClick={() => {
                                                        switch (processStatus) {
                                                            case PROCESS_STATUS.WAITING:
                                                                setMintButtonDisabled(true);
                                                                processStep = 1;
                                                                ItemCardMintAction(card, processLogItems, setProcessLogItems, setProcessStatus, setMintButtonDisabled, processStep);
                                                                break;
                                                            case PROCESS_STATUS.INITIALISED:
                                                                break;
                                                            case PROCESS_STATUS.FINISHED:
                                                                updatePostMintedItems();
                                                                closeCardMintModal();
                                                                close();
                                                                break;
                                                            case PROCESS_STATUS.ERROR:
                                                                ItemCardMintAction(card, processLogItems, setProcessLogItems, setProcessStatus, setMintButtonDisabled, processStep);
                                                            default:
                                                                break;
                                                        }
                                                        console.log(`processStatus`, processStatus);
                                                    }}
                                                    disabled={mintButtonDisabled}>
                                                    {getMintButtonText(processStatus)}
                                                </button>
                                                {PROCESS_STATUS.WAITING === processStatus ?
                                                    <button className="button secondary btn" onClick={() => {
                                                        updatePostMintedItems();
                                                        close();
                                                    }}>NO, GO BACK!
                                                    </button>
                                                    :
                                                    <></>
                                                }
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                )}
            </Popup>
        </>
    );
}

export const ItemCircle = ({card, circleStyle, setUId}) => {
    if (card !== undefined && card !== null) {
        const unfinished = setUId === undefined || setUId === null || setUId === "";
        const faction = FACTION[card.faction].toLowerCase();
        const imgSrcCdn = `${assetsHost}/equipped-integration/season-${card.season}/equipped_season_${card.season}_${SET_PIECE[card.setPiece].toLowerCase()}_${faction}_${card.skinSet}.png`;
        // const alt = [FACTION[props.card.faction], SET_PIECE[props.card.setPiece], "set "+props.card.skinSet].join(',')+".png";
        // TODO replace with srcSet in nearest future
        return (
            <div style={{...(circleStyle), backgroundImage: `url(${imgSrcCdn})`}}
                 className={`item-circle item-circle-div item-faction-${faction} item-part-${SET_PIECE[card.setPiece]} ${unfinished ? 'unfinished' : ''}`}
                 onClick={() => {
                     if (!unfinished) {
                         return;
                     }
                     changeRemoveSetItem(card);
                 }}
            >

                <ToolTip
                    content={<span className={`damage-hover`}>Item&nbsp;<span
                        className={` ${faction}`}>#{card.bxId}</span>&nbsp;dmg:  <span
                        className={` ${faction}`}>&nbsp;{card.damage / 100}&nbsp;</span></span>}
                    trigger={<span className={'text-over-text'}>{unfinished ? <ImCross/> : <VscBlank/>}</span>}
                    position="left center"
                    keepInside={true}
                    onActions={['hover', 'focus']}
                    onDocumentClick={true}
                    nested={false}
                />

            </div>
        );
    }
};

export const ItemSquareInventory = ({item, showNumbers = true}) => {
    if (item !== undefined && item !== null) {
        //equipped_season_0_body_blue_2
        const imgSrc = assetsHost + "/assets/i/equipped_season_" + item.season //path
            + "_" + SET_PIECE[item.setPiece].toLowerCase() + "_" + FACTION[item.faction].toLowerCase() + "_" + item.skinSet + ".png"; //file
        return (
            <div className={`square-inventory-wrap flex-container-columns`}>
                <img className={SET_PIECE[item.setPiece] + " square-icon"} src={`${process.env.PUBLIC_URL}` + imgSrc}/>
                <div className={`square-inventory-damage ${FACTION[item.faction].toLowerCase()}`}><span>&nbsp;-&nbsp;</span>{showNumbers ? formatNumber(item.damage / 100) : '??.??'}</div>
            </div>

        );
    }
    return (
        <div className={`square-inventory-wrap flex-container-columns`}>
            <img className={" square-icon"}
                 src={`${process.env.PUBLIC_URL}` + assetsHost + "/assets/i/equipped_empty.png"}/>
            <div className={`square-inventory-damage`}><span>&nbsp;-&nbsp;</span>{showNumbers ? formatNumber(0) : '??.??'}</div>
        </div>

    );
}

export const ItemFullSize = (props) => {
    // console.log("ImageFullSize", props.card);
    if (props.card !== undefined && props.card !== null) {
        const imgSrc = assetsHost + "/all-skins-integration/season_" + props.card.season //path
            + "/set_season_" + props.card.season + "_" + SET_PIECE[props.card.setPiece].toLowerCase() + "_" + FACTION[props.card.faction].toLowerCase() + "_" + props.card.skinSet + ".png"; //file
        return (
            <img className={SET_PIECE[props.card.setPiece] + " full"} src={`${process.env.PUBLIC_URL}` + imgSrc}/>
        );
    }
    if (props.index !== undefined && (props.index === 0 || props.index === 3)) {
        const imgSrc = assetsHost + "/all-skins-integration"
            + "/set_season_X_" + SET_PIECE[props.index].toLowerCase() + "_base.png"; //file
        console.log("ImageFullSizeIndex", imgSrc);
        return (
            <img className={SET_PIECE[props.index] + " full"} src={`${process.env.PUBLIC_URL}` + imgSrc}/>
        );
    }
};