import React from 'react';
import './inventoryLeftSide.css';
import BackPack from "../../../component/BackPack/BackPack";


const InventoryLeftSide = () => {

    return (
        <div id="left-side">
            <BackPack/>
        </div>
    )
        ;
}

export default InventoryLeftSide;