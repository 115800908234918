import {isFullSet} from "../../component/fullSetCarousel/FullSetCarousel";
import {DUEL_PLAYER, DUEL_RESULT, DUEL_VIEWED, DUEL_WINNER, EVENT_TYPE, FACTION} from "../../helper/ENUMS.ts";
import {AccountId} from "@hashgraph/sdk";
import {notifyServerOfDuelView} from "../GetInventoryList";
import MyCustomEventEmitter from "../../component/myCustomEvents";
import {getAccountInSolidity} from "../blockchain/hashconnect";


/**function calculateDamage(int32 itemDamage, Structs.Faction itemFaction, Structs.Faction opposingItemFaction) internal pure returns (int64) {
 if ((itemFaction == Structs.Faction.RED && opposingItemFaction == Structs.Faction.GRE) ||
 (itemFaction == Structs.Faction.GRE && opposingItemFaction == Structs.Faction.BLU) ||
 (itemFaction == Structs.Faction.BLU && opposingItemFaction == Structs.Faction.RED)) {
 return itemDamage * 4; // Increased damage multiplier from 2 to 4 for counters
 } else if ((itemFaction == Structs.Faction.RED && opposingItemFaction == Structs.Faction.BLU) ||
 (itemFaction == Structs.Faction.GRE && opposingItemFaction == Structs.Faction.RED) ||
 (itemFaction == Structs.Faction.BLU && opposingItemFaction == Structs.Faction.GRE)) {
 return itemDamage; // reduced damage
 } else {
 return itemDamage * 2; // Add multiplier of 2 for same faction
 }
 }**/

const calculateDamage = (itemDmg, itemFaction, opposingItemFaction) => {
    // console.log(`Item DMG: ${itemDmg}, Faction: ${itemFaction}, Opp: ${opposingItemFaction}`);
    if ((itemFaction === FACTION.RED && opposingItemFaction === FACTION.GREEN) ||
        (itemFaction === FACTION.GREEN && opposingItemFaction === FACTION.BLUE) ||
        (itemFaction === FACTION.BLUE && opposingItemFaction === FACTION.RED)) {
        return itemDmg * 2; // Increased damage multiplier from 2 to 4 for counters
    } else if ((itemFaction === FACTION.RED && opposingItemFaction === FACTION.BLUE) ||
        (itemFaction === FACTION.GREEN && opposingItemFaction === FACTION.RED) ||
        (itemFaction === FACTION.BLUE && opposingItemFaction === FACTION.GREEN)) {
        return itemDmg / 2; // reduced damage
    } else {
        return itemDmg; // Add multiplier of 2 for same faction
    }
};

const calculateDefence = (itemDef, itemFaction, opposingItemFaction) => {
    if ((itemFaction === FACTION.RED && opposingItemFaction === FACTION.BLUE) ||
        (itemFaction === FACTION.GREEN && opposingItemFaction === FACTION.RED) ||
        (itemFaction === FACTION.BLUE && opposingItemFaction === FACTION.GREEN)) {
        return itemDef * 2; // Increased damage multiplier from 2 to 4 for counters
    } else if ((itemFaction === FACTION.RED && opposingItemFaction === FACTION.GREEN) ||
        (itemFaction === FACTION.GREEN && opposingItemFaction === FACTION.BLUE) ||
        (itemFaction === FACTION.BLUE && opposingItemFaction === FACTION.RED)) {
        return itemDef / 2; // reduced damage
    } else {
        return itemDef; // Add multiplier of 2 for same faction
    }
};


export const calculateDmgDef = async ({leftPlayer, rightPlayer, duelId}) => {
    const leftItems = leftPlayer.info.itemCards, rightItems = rightPlayer.info.itemCards;
    let damagesLeft = [0, 0, 0];
    let damagesRight = [0, 0, 0];
    let defencesLeft = [0, 0, 0];
    let defencesRight = [0, 0, 0];
    let sumDmgLeft = 0, sumDmgRight = 0, sumDefLeft = 0, sumDefRight = 0;
    let bottomSum = {damage: 0, side: null}, topSum = {damage: 0, side: null};
    let leftPairCompResults = [], rightPairCompResults = [], // these are the arrays of middle numbers separated by sides
        centerDMG = 0, battleResult = DUEL_RESULT.DRAW;
    let userInSolidity = getAccountInSolidity();

    for (let l = 0; l < leftItems.length; l++) {
        const player1ItemDamage = leftItems[l].damage;
        const player1Faction = leftItems[l].faction;
        for (let r = 0; r < rightItems.length; r++) {
            const player2ItemDamage = rightItems[r].damage;
            const player2Faction = rightItems[r].faction;
            damagesLeft[player1Faction] += calculateDamage(player1ItemDamage, player1Faction, player2Faction);
            damagesRight[player2Faction] += calculateDamage(player2ItemDamage, player2Faction, player1Faction);
            defencesLeft[player1Faction] += calculateDefence(player1ItemDamage, player1Faction, player2Faction);
            defencesRight[player2Faction] += calculateDefence(player2ItemDamage, player2Faction, player1Faction);
        }
    }

    if (isFullSet(leftItems)) {
        damagesRight.map((dmg, i) => {
            damagesRight[i] /= 2;
        });
    }
    if (isFullSet(rightItems)) {
        damagesLeft.map((dmg, i) => {
            damagesLeft[i] /= 2;
        });
    }

    sumDmgLeft = damagesLeft.reduce((a, b) => a + b, 0);
    sumDmgRight = damagesRight.reduce((a, b) => a + b, 0);
    sumDefLeft = defencesLeft.reduce((a, b) => a + b, 0);
    sumDefRight = defencesRight.reduce((a, b) => a + b, 0);

    if (sumDefLeft > sumDmgRight) {
        topSum.damage = sumDefLeft - sumDmgRight;
        topSum.side = DUEL_PLAYER.LEFT;
        leftPairCompResults.push(sumDefLeft - sumDmgRight);
    } else {
        topSum.damage = sumDmgRight - sumDefLeft;
        topSum.side = DUEL_PLAYER.RIGHT;
        rightPairCompResults.push(sumDmgRight - sumDefLeft);
    }

    if (sumDefRight > sumDmgLeft) {
        bottomSum.damage = sumDefRight - sumDmgLeft;
        bottomSum.side = DUEL_PLAYER.RIGHT;
        rightPairCompResults.push(sumDefRight - sumDmgLeft);
    } else {
        bottomSum.damage = sumDmgLeft - sumDefRight;
        bottomSum.side = DUEL_PLAYER.LEFT;
        leftPairCompResults.push(sumDmgLeft - sumDefRight);
    }

    const leftCompResult = leftPairCompResults.reduce((a, b) => a + b, 0);
    const rightCompResult = rightPairCompResults.reduce((a, b) => a + b, 0);
    const WINNER = leftCompResult > rightCompResult ? DUEL_WINNER.LEFT : DUEL_WINNER.RIGHT;

    centerDMG = Math.abs(leftCompResult - rightCompResult);
    //
    /*if (WINNER === DUEL_WINNER.LEFT) {
        console.log(`WINNER IS LEFT 0x${userInSolidity}`, playersInfo.left.ownerAddress);
        battleResult = (`${userInSolidity}` === playersInfo.left.ownerAddress) ? DUEL_RESULT.VICTORY : DUEL_RESULT.DEFEAT;
        setDuelWinnerSide(DUEL_PLAYER.LEFT);
    } else if (WINNER === DUEL_WINNER.RIGHT) {
        console.log(`WINNER IS RIGHT 0x${userInSolidity}`, playersInfo.right.ownerAddress);
        battleResult = (`${userInSolidity}` === playersInfo.right.ownerAddress) ? DUEL_RESULT.VICTORY : DUEL_RESULT.DEFEAT;
        setDuelWinnerSide(DUEL_PLAYER.RIGHT);
    }*/

    let viewedByUser = DUEL_VIEWED.LOADING;
    let currentSide = DUEL_PLAYER.LOADING;
    let currentSideResult = DUEL_RESULT.UNRESOLVED;
    let userParsingCounter = 0;


    while (userInSolidity === null && userParsingCounter < 6) {
        console.info(`USER PARSING ERROR, performing looped parse`, userParsingCounter);
        // TODO don't like making whole function async,
        //  we may convert to looped resolver with different timeouts for each iteration and inner conditional execution
        await new Promise(resolve => setTimeout(resolve, 750));
        userInSolidity = getAccountInSolidity();
        userParsingCounter++;
    }

    if (userInSolidity === null) {
        // todo make entire thing asynchronous and wait for hashpack to respond
        userInSolidity = AccountId.fromString(localStorage.getItem('accountId')).toSolidityAddress();
        console.error(`USER PARSING ERROR, falling back to local storage`, localStorage.getItem('accountId'), userInSolidity);
    }

    // after refill with local storage we should have users account or something is wrong
    if (userInSolidity === null) { // so we check for null again
        viewedByUser = DUEL_VIEWED.ACC_NOT_FOUND;
    } else {
        console.log(`USER PARSING SUCCESS`, userInSolidity);
        if (userInSolidity === leftPlayer.ownerAddress) {
            currentSide = DUEL_PLAYER.LEFT;
            currentSideResult = DUEL_WINNER.LEFT === WINNER ? DUEL_RESULT.VICTORY : DUEL_RESULT.DEFEAT;
            if (leftPlayer.viewed) {
                viewedByUser = DUEL_VIEWED.ALREADY_VIEWED;
            } else { // = (!leftPlayer.viewed) if user is on left side and has not viewed the duel yet
                viewedByUser = DUEL_VIEWED.NEW_DUEL;
                new Promise(resolve => setTimeout(resolve, 1500))
                    .then(() => {
                        notifyServerOfDuelView(duelId)
                    });
            }
        } else if (userInSolidity === rightPlayer.ownerAddress) {
            currentSide = DUEL_PLAYER.RIGHT;
            currentSideResult = DUEL_WINNER.RIGHT === WINNER ? DUEL_RESULT.VICTORY : DUEL_RESULT.DEFEAT;
            if (rightPlayer.viewed) {
                viewedByUser = DUEL_VIEWED.ALREADY_VIEWED;
            } else { // = (!rightPlayer.viewed) if user is on right side and has not viewed the duel yet
                viewedByUser = DUEL_VIEWED.NEW_DUEL;
                new Promise(resolve => setTimeout(resolve, 1500))
                    .then(() => {
                        notifyServerOfDuelView(duelId)
                    });
            }
        }

        MyCustomEventEmitter.dispatch(EVENT_TYPE.DUEL_EVENT_VIEWED, {duelId: duelId});
    }

    // TODO convert to Class object
    return {
        left: {
            total: {
                damage: sumDmgLeft,
                defence: sumDefLeft,
            },
            damages: damagesLeft,
            isWinner: WINNER === DUEL_WINNER.LEFT,
        },
        right: {
            total: {
                damage: sumDmgRight,
                defence: sumDefRight,
            },
            damages: damagesRight,
            isWinner: WINNER === DUEL_WINNER.RIGHT,
        },
        center: {
            damage: centerDMG,
        },
        winner: WINNER,
        winnerSide: WINNER === DUEL_WINNER.LEFT ? DUEL_PLAYER.LEFT : DUEL_PLAYER.RIGHT,
        currentSide: currentSide,
        currentUserResult: currentSideResult,
        viewedByUser: viewedByUser,
    };
}